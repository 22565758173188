<template v-if="ready">
  <q-dialog ref="dialog"
    ><div class="q-dialog-plugin SendWelcomeEmailModal">
      <q-form @submit="sendMails">
        <q-select label="Välj företag" v-model="position" :options="positionList" :option-label="instrumentName" />

        <q-card-actions class="q-mt-md" align="right">
          <q-btn :flat="true" @click="closeModal">Avbryt</q-btn>
          <q-btn :disable="!position" type="submit">Skicka välkomstmail</q-btn>
        </q-card-actions>
      </q-form>
    </div></q-dialog
  >
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { Address, Entity, Position } from '@shared/web/models';
import { search } from '@/models/position/positionService';
import { uniqBy } from 'lodash';
import PositionSearchQuery from '@/models/position/PositionSearchQuery';

@Component({
  components: {},
})
export default class SendWelcomeEmailModal extends Vue {
  @Prop() entity: Entity;
  @Prop() address: Address;
  @Prop() sendMail: (position: Position) => Promise<void>;
  position: null | Position = null;
  positions: Array<Position>;

  get ready() {
    return !!this.positionList;
  }

  async mounted() {
    this.positions = await this.loadPositions();
  }
  async sendMails() {
    const email = this.address.email;
    if (!email) {
      throw { text: 'Email saknas' };
    }
    await this.sendMail(this.position);
    this.closeModal();
  }
  closeModal() {
    this.$emit('hide');
  }

  instrumentName(position: Position) {
    return position.instrument.entity.legalEntity.name;
  }

  get positionList() {
    return uniqBy(this.positions, position => position.instrument.entity.legalEntity.name);
  }

  async loadPositions() {
    const { entity } = this;

    return await search(
      PositionSearchQuery.newModel({
        ownerOrInvestor: entity,
      }),
    );
  }
}
</script>
