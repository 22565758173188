<template>
  <a-page-section class="LegalEntityBasics padded">
    <div class="row q-col-gutter-x-xl">
      <q-list :separator="true" class="standard-list col-md-7">
        <a-label-item label="Nationellt bolagsnr">
          <InputNationalId v-bind="inputDefaults" v-model="entity.nationalId" :readonly="autoUpdate" :entity="entity" />
        </a-label-item>
        <a-label-item label="Namn">
          <div class="flex justify-between">
            <q-input
              v-bind="inputDefaults"
              v-model="entity.legalEntity.name"
              :rules="validation.name"
              :readonly="autoUpdate"
            />
            <q-btn @click="refreshLegalEntityInfo" flat dense icon="refresh">Hämta från roaring</q-btn>
          </div>
        </a-label-item>
        <a-label-item label="Kortnamn">
          <q-input v-bind="inputDefaults" v-model="entity.legalEntity.shortName" />
        </a-label-item>
        <a-label-item label="Land">
          <SelectCountry
            v-model="entity.countryCode"
            v-bind="inputDefaults"
            :rules="validation.countryCode"
            :readonly="autoUpdate"
          />
        </a-label-item>
        <a-label-item label="LEI">
          <a-blur-input
            v-bind="inputDefaults"
            v-model="entity.legalEntity.LEI"
            :rules="validation.validateLEI"
          ></a-blur-input>
        </a-label-item>
        <a-label-item label="NACE">
          <q-input v-bind="inputDefaults" v-model="entity.legalEntity.naceCode" />
        </a-label-item>
        <a-list-item label="Skapad för" class="test-creator" v-if="!releaseEntity">
          <a-link :target="entity.creator" v-if="readonly" />
          <SelectIssuer v-model="entity.creator" :hideCreate="true" v-if="!readonly" />
        </a-list-item>
        <a-list-item label="Aktieboken hanteras av Kaptena" v-if="isKapclearIssuer">
          <a-select
            v-model="entity.legalEntity.managedByKaptena"
            v-bind="inputDefaults"
            :options="options.booleanOptions"
            emit-value
            map-options
            :readonly="readonly"
          />
        </a-list-item>
        <a-list-item label="Ändra till global person" v-if="entity.countryCode !== 'SE'">
          <q-checkbox v-model="releaseEntity" v-bind="inputAttributes" :disable="readonly || isGlobal" />
        </a-list-item>
      </q-list>
      <div class="col-md-5">
        <h4 class="table-header">Typ av juridisk person</h4>
        <a-checkbox-group
          :options="typeOptions"
          :rules="validation.types"
          v-model="entity.types"
          :readonly="readonly"
          class="type-options"
        />

        <q-list :separator="true" class="standard-list col-md-6">
          <a-label-item label="Aktiv Emittent" v-if="entity.isKapclearIssuer">
            <a-select
              v-model="entity.activeTypes.issuer"
              v-bind="inputDefaults"
              :options="options.booleanOptions"
              emit-value
              map-options
              :readonly="readonly"
            />
          </a-label-item>
          <a-label-item label="Aktiv Kapsure Emittent" v-if="entity.isKapsureIssuer">
            <a-select
              v-model="entity.activeTypes.kapsureIssuer"
              v-bind="inputDefaults"
              :options="options.booleanOptions"
              emit-value
              map-options
              :readonly="readonly"
            />
          </a-label-item>
          <a-label-item label="Autouppdatering">
            {{ toBooleanLabel(entity.autoUpdate) }}
          </a-label-item>
          <a-label-item label="Moderbolag">
            <SelectEntity :disable="readonly" type="legalEntity" v-model="entity.legalEntity.parentCompany" />
          </a-label-item>
          <a-label-item label="Struktur">
            <TreeStructureList :entity="entity" />
          </a-label-item>
          <EntityConnections v-if="showParticipants" :entity="entity" :readonly="readonly" />
        </q-list>
      </div>
    </div>
    <LastActivity :model="entity" />
  </a-page-section>
</template>
<script lang="ts">
// @ts-nocheck
import { Component, Prop, Vue } from 'vue-facing-decorator';

import { validation, options, instructionStates } from '@shared/common';
import { Entity } from '@/models';
import LastActivity from '@/views/activity/LastActivity.vue';
import SelectIssuer from '@/views/entity/SelectIssuer.vue';
import { SelectCountry, InputNationalId } from '@/components';
import { updateLegalEntityFromRoaring } from '@/models/entity/entityService';
import TreeStructureList from '@shared/views/common/treeStructureList/TreeStructureList.vue';
import { InstructionState } from '@shared/models/types';
import SelectEntity from '@/views/entity/SelectEntity.vue';
import EntityConnections from './EntityConnections.vue';

import { EntityType } from '@shared/index';

@Component({
  components: {
    SelectEntity,
    SelectCountry,
    InputNationalId,
    LastActivity,
    SelectIssuer,
    TreeStructureList,
    EntityConnections,
  },
})
export default class LegalEntityBasics extends Vue {
  @Prop() entity: Entity;
  @Prop() readonly: boolean;

  get options() {
    return options;
  }

  get inputDefaults() {
    return {
      dense: true,
      filled: true,
      square: true,
      readonly: this.readonly,
    };
  }

  get isKapclearIssuer() {
    return this.entity.isKapclearIssuer;
  }

  get validation() {
    return validation;
  }

  get autoUpdate() {
    return this.entity.autoUpdate || this.readonly;
  }

  get typeOptions() {
    return options.typeOptions
      .filter(({ value }) => value != null)
      .map(type => {
        return {
          value: type.value,
          label: this.handleNewIssuerLabel(type),
        };
      });
  }

  handleNewIssuerLabel({ value, label }) {
    const isNew = value === 'issuer' && this.shareRegisterState === InstructionState.NEW;
    return isNew ? `${label} ${instructionStates.NEW}` : label;
  }
  get shareRegisterState() {
    return this.entity.issuerData?.shareRegisterState;
  }

  async refreshLegalEntityInfo() {
    await updateLegalEntityFromRoaring(this.entity);
    this.$q.dialog({
      title: 'Klart! Nu är det uppdaterat!',
      message: 'Kontakta IT om du behöver hjälp',
    });
  }

  get releaseEntity() {
    return this.entity.isGlobal;
  }
  set releaseEntity(value) {
    this.entity.isGlobal = value;
    if (value) {
      this.entity.creator = null;
    }
  }
  get isGlobal() {
    return this.entity.isGlobal === true;
  }

  get showParticipants() {
    return !!this.entity.types.find(type => [EntityType.insuranceCompany, EntityType.participant].includes(type));
  }
}
</script>
<style lang="scss">
.LegalEntityBasics {
  .type-options {
    .q-field__control {
      height: auto;
    }
  }
}
</style>
