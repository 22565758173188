<template>
  <div class="IssueRightsContent">
    <a-page-section class="padded">
      <div class="row q-col-gutter-x-xl">
        <q-list separator class="standard-list col-md-6">
          <a-list-item label="Emittent">
            <a-link :target="instruction.entity" v-if="readonly" />
            <SelectEntity
              v-model="instruction.entity"
              entityType="issuer"
              v-bind="$defs.input"
              :readonly="instruction.entity != null"
              :hideCreate="true"
              @update:modelValue="$emit('changeEntity')"
              v-else
            />
          </a-list-item>
          <template v-if="shareRegister">
            <a-list-item label="Kvotvärde">
              {{ formatNumber(issuerData.quotaValue, 2) }}
            </a-list-item>
            <a-list-item label="Totalt antal aktier">
              {{ formatNumber(issuerData.totalQuantity) }}
            </a-list-item>
            <a-list-item label="Totalt aktiekapital">
              {{ formatNumber(issuerData.totalCapital, 2) }}
            </a-list-item>
            <a-list-item label="Avstämningsbolag">
              {{ toBooleanLabel(issuerData.isRecordCompany) }}
            </a-list-item>
            <a-list-item label="Status">
              {{ toInstructionStateLabel(instruction.calculatedState) }}
            </a-list-item>
          </template>
          <p v-if="instruction.warning" class="text-small">
            Det finns pågående ägarbyten med datum för införande i aktieboken efter avstämningsdagen.
          </p>
        </q-list>
        <q-list separator class="standard-list col-md-6" v-if="shareRegister">
          <template v-if="instruction.isRightsIssue">
            <a-list-item label="Teckningskurs">
              <a-input-number
                v-model="instruction.price"
                v-bind="$defs.input"
                :readonly="readonly"
                :fractionDigits="2"
                :rules="priceRules"
              />
            </a-list-item>

            <a-list-item label="Totalt nya aktier"> {{ formatNumber(entityValidation.totalQuantity) }}</a-list-item>
            <a-list-item label="Totalt nytt  aktiekapital">
              {{ formatNumber(entityValidation.totalCapitalForCurrentQuota) }}</a-list-item
            >
            <a-list-item label="Överkursfond"> {{ formatNumber(entityValidation.totalCapitalDifference) }}</a-list-item>
            <a-list-item label="Total"> {{ formatNumber(entityValidation.totalCapital, 2) }}</a-list-item>
            <a-list-item label="Avstämningsdag">
              <a-input-date
                v-model="instruction.corporateEvent.recordDate"
                v-bind="$defs.input"
                :readonly="readonly || isInterim"
              />
            </a-list-item>
            <a-list-item label="Införd i aktieboken">
              <a-input-date v-model="instruction.settleDate" v-bind="$defs.input" :readonly="readonly" />
            </a-list-item>
          </template>
          <a-list-item label="Registreringsdag BTA">
            <a-input-date
              v-model="instruction.corporateEvent.interimRegistrationDate"
              v-bind="$defs.input"
              :readonly="true"
            />
          </a-list-item>
          <a-list-item label="Registreringsdag">
            <a-input-date v-model="instruction.registrationDate" v-bind="$defs.input" :readonly="true" />
          </a-list-item>
          <LastActivity :model="instruction" />
        </q-list>
      </div>
    </a-page-section>

    <template v-if="shareRegister">
      <h4 class="text-h4 text-weight-regular q-mb-md q-mt-xl">Aktier</h4>
      <InstrumentSourceTable
        :shareRegister="shareRegister"
        :readonly="readonly || isInterim"
        :instruction="instruction"
        :validator="validator"
      />
    </template>

    <template v-if="showInterimDestinations">
      <h4 class="text-h4 text-weight-regular q-mb-md q-mt-xl">Innehav BTA</h4>
      <InterimDestinationsTableCard
        :shareRegister="settleDateShareRegister"
        :instruction="instruction"
        :validator="validator"
        :readonly="readonly || isInterim"
      />
    </template>

    <template v-if="shareRegister">
      <h4 class="text-h4 text-weight-regular q-mb-md q-mt-xl">Teckningslista Nyemission</h4>
      <DestinationsTableCard
        :shareRegister="shareRegister"
        :instruction="instruction"
        :validator="validator"
        :readonly="readonly || isInterim"
      />
    </template>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';

import InstrumentSourceTable from './InstrumentSourceTable.vue';
import { Instruction, InstructionState, ShareRegister } from '@/models';
import { options, toInstructionStateLabel } from '@shared/common';
import SelectEntity from '@/views/entity/SelectEntity.vue';
import { validation } from '@shared/common';
import { requiredNumber } from '@shared/utils/validation';
import DestinationsTableCard from './DestinationsTableCard.vue';
import InterimDestinationsTableCard from './InterimDestinationsTableCard.vue';
import LastActivity from '@/views/activity/LastActivity.vue';
import { getInterimPositions } from '@/models/instruction/instructionService';

@Component({
  components: {
    SelectEntity,
    InstrumentSourceTable,
    DestinationsTableCard,
    InterimDestinationsTableCard,
    LastActivity,
  },
})
export default class IssueRightsContent extends Vue {
  @Prop() instruction: Instruction;
  @Prop() shareRegister: ShareRegister;
  @Prop() settleDateShareRegister: ShareRegister;
  @Prop({ default: true }) readonly: boolean;
  @Prop() validator;

  toInstructionStateLabel(val) {
    return toInstructionStateLabel(val);
  }

  mounted() {
    this.onSettleDateShareRegisterChange();
  }

  @Watch('settleDateShareRegister')
  @Watch('instruction.state')
  @Watch('readonly')
  onSettleDateShareRegisterChange() {
    if (this.instruction.state === InstructionState.EXECUTED_INTERIM) {
      this.instruction.interimPositions = this.interimPositions;
    }
  }

  get interimPositions() {
    return getInterimPositions(this.instruction, this.settleDateShareRegister);
  }

  get entityValidation() {
    return this.validator.forEntity();
  }

  get issuerData() {
    return this.shareRegister && this.shareRegister.getHistoricIssuerData();
  }

  get priceRules() {
    return [requiredNumber];
  }

  get options() {
    return options;
  }

  get validation() {
    return validation;
  }

  get isInterim() {
    return this.instruction.state === InstructionState.EXECUTED_INTERIM;
  }

  get showInterimDestinations() {
    return (
      this.settleDateShareRegister &&
      [InstructionState.EXECUTED_INTERIM, InstructionState.EXECUTED].includes(this.instruction.state)
    );
  }
}
</script>
<style lang="scss">
.IssueRightsContent {
  .q-markup-table {
    overflow: initial;
  }
  th.number {
    width: 12%;
  }
  .w50 {
    width: 50%;
  }
}
</style>
