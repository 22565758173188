<template>
  <a-page-section :header="isEdit ? 'Filer' : ''" class="FileList q-mx-md" :data-testid="dataTestId">
    <InputFileGeneral :model="model" :uploadFile="uploadFile" :FileUpload="FileUpload" />
    <q-markup-table flat class="standard-table DestinationsTable" separator="horizontal">
      <tbody v-if="isEdit">
        <tr v-for="file in model.files" :key="file.url">
          <td>
            <a :href="file.url" target="_blank" @click.prevent="openFile(file)">{{ file.name }}</a>
          </td>
          <td class="q-table--col-auto-width">
            <q-btn flat round color="grey-7" icon="delete_forever" size="md" @click="remove(file)" />
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr v-for="file in model.files" :key="file.url">
          <td>
            <a :href="file.url" target="_blank" @click.prevent="openFile(file)">{{ file.name }}</a>
          </td>
        </tr>
      </tbody>
    </q-markup-table>
  </a-page-section>
</template>
<script lang="ts">
import { Component, Vue, Prop, Ref } from 'vue-facing-decorator';
import axios from 'axios';
import { remove } from '@/utils';
import InputFileGeneral from './InputFileGeneral.vue';

@Component({
  components: { InputFileGeneral },
})
export default class FileList extends Vue {
  @Prop() model;
  @Prop() isEdit = false;
  @Prop() uploadFile!: any;
  @Prop() FileUpload!: any;
  @Ref('fileInput') readonly fileInput!: HTMLInputElement;

  get dataTestId(): string {
    return `file-list${this.model ? `-${this.model.id}` : ''}`;
  }
  async openFile(file) {
    const { data } = await axios.get(file.url);
    window.open(data.url, '_blank');
  }
  async deleteFile(file) {
    await axios.delete(file.url);
    remove(this.model.files, file);
  }

  async remove(file): Promise<void> {
    this.$q
      .dialog({
        title: 'Radera erbjudandet',
        message: `Är du säker på att du vill radera filen: ${file.name}?`,
        ok: 'Ja',
        cancel: 'Nej',
      })
      .onOk(async () => {
        await this.deleteFile(file);
      });
  }
}
</script>
<style lang="scss">
.FileList {
  h4.table-header {
    margin-top: 0;
  }
}
</style>
