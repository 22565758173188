<template>
  <q-table
    table-class="full-width"
    :columns="columns"
    :loading="loading"
    :rows="data"
    v-model:pagination="pagination"
    :filter="filter"
    row-key="id"
    :flat="flat"
    @request="onRequest"
  >
    <template v-slot:body-cell-payload="props">
      <q-td :props="props" :style="`width: 100%; ${props.value ? 'padding: 16px 16px 0;' : ''}`">
        <template v-if="isInstruction(props.value)">
          <RouterLink :to="instructionLink(props.value)">Instruktion</RouterLink>
        </template>
        <template v-else-if="isPostmarkEmail(props.value)">
          <a target="_blank" :href="postmarkLink(props.value)">Postmark mejl</a> till
          <RouterLink :to="emailEntityLink(props.value)">{{ props.value.message.To }}</RouterLink>
        </template>
        <div class="q-mt-sm">
          <FoldableCodeBox v-if="props.value" :data="props.value" />
          <span v-else class="text-italic text-grey-3">Ingen data</span>
        </div>
      </q-td>
    </template>
    <template v-slot:body-cell-by="props">
      <q-td>
        <div v-if="props.row.by">{{ props.row.by.name || props.row.by.username }}</div>
        <div v-if="props.row.by && props.row.as.name && props.row.by.name !== props.row.as.name">
          som <span class="text-bold">{{ props.row.as.name || props.row.as.username }}</span>
        </div>
      </q-td>
    </template>
  </q-table>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';
import axios from 'axios';
import { formatDateTime } from '@shared/utils';
import { EventLogTypeLabels } from '@shared/utils/eventLog';
import FoldableCodeBox from '@shared/components/FoldableCodeBox.vue';
import { QTableProps } from 'quasar';

@Component({
  components: { FoldableCodeBox },
})
export default class SystemLogsTable extends Vue {
  @Prop({ default: null }) types;
  @Prop() users;
  @Prop({ default: null }) documentId;
  @Prop({ default: false }) flat;
  data = [];
  loading = false;
  filter = '';
  pagination = {
    sortBy: 'createdAt',
    descending: true,
    page: 1,
    rowsPerPage: 5,
    rowsNumber: null,
  };

  get columns(): QTableProps['columns'] {
    return [
      { name: 'type', label: 'Händelse', align: 'left', field: this.eventName, sortable: true },
      { name: 'by', label: 'Vem / som', align: 'left', field: 'by', sortable: true },
      {
        name: 'payload',
        label: 'Data',
        align: 'left',
        field: 'payload',
        sortable: true,
      },
      {
        name: 'createdAt',
        label: 'Tidpunkt',
        align: 'right',
        field: row => formatDateTime(new Date(row.createdAt)),
        sortable: true,
      },
    ];
  }

  eventName({ type }) {
    return EventLogTypeLabels?.[type] || type;
  }

  isInstruction(payload) {
    return payload?.collection === 'instructions' && payload?.document;
  }

  instructionLink(payload) {
    return '/instruction/view/' + payload?.document?._id;
  }

  emailEntityLink(payload) {
    return `/legal-entity/view/${payload?.message.TemplateModel?.entityId}`;
  }

  isPostmarkEmail(payload) {
    return payload?.response?.MessageID;
  }

  postmarkLink(payload) {
    return `${import.meta.env.VITE_APP_POSTMARK_MESSAGE_LINK}${payload?.response?.MessageID}`;
  }

  async mounted() {
    await this.loadInitialData();
  }

  @Watch('types')
  @Watch('users')
  async loadInitialData() {
    await this.onRequest({ pagination: this.pagination });
  }

  async onRequest({ pagination }) {
    this.loading = true;
    const url = `/api/event-logs`;
    const body = {
      ...pagination,
      types: !this.types ? null : this.types.map(t => t.value),
      documentId: this.documentId,
      users: this.users && JSON.stringify(this.users) !== '["*"]' ? this.users : null,
    };
    const { data, rowsNumber } = (await axios.post(url, body)).data;
    this.pagination = { ...pagination };
    this.pagination.rowsNumber = rowsNumber;
    this.data = data;
    this.loading = false;
  }
}
</script>
<style lang="scss">
.full-width table {
  width: 100%;
  table-layout: fixed;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}
</style>
