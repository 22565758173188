<template>
  <div>
    Aktie
    <q-list class="standard-list" :separator="true">
      <a-list-item class="text-right" label="Aktiepost" v-if="shareNumbers"> </a-list-item>
      <a-list-item class="text-right" label="Aktienummer" v-if="shareNumbers">
        {{ shareNumbers }}
      </a-list-item>
      <a-list-item class="text-right quantity-share" label="Antal">
        {{ formatNumber(position.quantity) }}
      </a-list-item>
      <a-list-item class="text-right" label="Kvotvärde">
        {{ formatNumber(quotaValue, 2, 20) }}
      </a-list-item>
      <a-list-item class="text-right" label="Postens aktiekapital">
        {{ formatNumber(totalCapital) }}
      </a-list-item>
      <a-list-item class="text-right" label="Antal röster">
        {{ formatNumber(totalVoting) }}
      </a-list-item>
      <a-list-item class="text-right" label="Förvar" v-if="position.custodian">
        {{ position.custodian.viewName }}
      </a-list-item>
      <a-list-item class="text-right" label="Försäkringsnummer">
        <InsuranceLink :instructionParty="position" />
      </a-list-item>
      <a-list-item class="text-right" label="Depånummer" v-if="position.custodianAccountNumber">
        {{ position.custodianAccountNumber }}
      </a-list-item>
      <a-list-item class="text-right" label="Förbehåll">
        <div v-for="reservation in position.reservations" :key="reservation">
          {{ toReservationLabel(reservation) }}
        </div>
      </a-list-item>
    </q-list>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { Position } from '@/models';
import { toReservationLabel } from '@shared/common';
import InsuranceLink from '@/views/insurance/InsuranceLink.vue';
@Component({ components: { InsuranceLink } })
export default class Share extends Vue {
  @Prop() position: Position;

  toReservationLabel(val) {
    return toReservationLabel(val);
  }

  get shareNumbers() {
    const { shareNumberFrom, shareNumberTo } = this.position;
    if (shareNumberFrom && shareNumberTo) {
      let shareNumberFromFormatted = this.formatNumber(shareNumberFrom);
      let shareNumberToFormatted = this.formatNumber(shareNumberTo);
      return shareNumberFromFormatted + ' - ' + shareNumberToFormatted;
    }
    return null;
  }

  get totalCapital() {
    return this.quotaValue * this.position.quantity;
  }

  get quotaValue() {
    return this.position.instrument.entity.issuerData.quotaValue;
  }

  get totalVoting() {
    return this.position.instrument.shareData?.votingPower * this.position.quantity;
  }

  get inputDefaults() {
    return {
      dense: true,
      filled: true,
      square: true,
    };
  }
}
</script>
