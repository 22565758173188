<template>
  <div>
    <q-table
      :rows="entity.addresses"
      :columns="columns"
      row-key="id"
      flat
      hide-pagination
      hide-no-data
      class="standard-table"
    >
      <template v-slot:body-cell-edit="props">
        <q-td :props="props">
          <q-btn flat round color="primary" icon="edit" size="sm" @click="edit(props.row)" />
        </q-td>
      </template>
      <template v-slot:bottom-row>
        <a-table-row-btn @click="addAddress" label="Lägg till adress" />
      </template>
    </q-table>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import EditAddressModal from './EditAddressModal.vue';
import { Address, Entity } from '@/models';
import { QTableProps } from 'quasar';
@Component
export default class AddressTable extends Vue {
  @Prop() entity: Entity;
  @Prop() performSave;
  edit(address) {
    const { entity, performSave } = this;
    this.$q.dialog({
      component: EditAddressModal,
      componentProps: {
        entity,
        value: address,
        performSave,
      },
    });
  }

  addAddress() {
    const address = Address.newModel({
      countryCode: this.entity.countryCode,
    });
    return this.edit(address);
  }

  columns: QTableProps['columns'] = [
    {
      name: 'address1',
      field: 'address1',
      label: 'Adress 1',
      align: 'left',
    },
    {
      name: 'address2',
      field: 'address2',
      label: 'Adress 2',
      align: 'left',
    },
    {
      name: 'zip',
      field: 'zip',
      label: 'Postnummer',
      align: 'left',
    },
    {
      name: 'city',
      field: 'city',
      label: 'Stad',
      align: 'left',
    },
    {
      name: 'countryCode',
      field: 'countryCode',
      label: 'Land',
      align: 'left',
    },
    {
      name: 'email',
      field: 'email',
      label: 'Email',
      align: 'left',
    },
    {
      name: 'phone',
      field: 'phone',
      label: 'Telefon',
      align: 'left',
    },
    {
      name: 'edit',
      field: null,
      label: '',
      classes: 'q-table--col-auto-width',
    },
  ];
}
</script>
<style lang="scss"></style>
