<template>
  <div :class="{ backdrop: true, open: isOpen }" @click="isOpen = false"></div>
  <div :class="{ 'nav-search': true, open: isOpen }" @keyup="handleKeyUp">
    <div class="nav-search-body">
      <div>
        <q-input
          v-model="text"
          :dark="!isOpen"
          placeholder="Sök företag, personer, värdepapper..."
          :standout="!isOpen"
          @focus="isOpen = true"
          @keyup="handleKeyUp"
        >
          <template v-slot:append>
            <q-icon v-if="text !== ''" name="clear" class="cursor-pointer" @click="handleClear" />
            <q-icon v-if="hasSearchPage()" name="search" class="cursor-pointer" @click="handleEnter" />
          </template>
        </q-input>
      </div>
      <div v-if="isOpen" class="content">
        <q-tabs v-model="tab" inline-label class="text-grey-4 bg-grey-2">
          <q-tab name="all" label="Allt" />
          <q-tab name="legalEntities" icon="apartment" label="Juridiska personer" />
          <q-tab name="persons" icon="group" label="Fysiska personer" />
          <q-tab name="instruments" icon="description" label="Värdepapper" />
        </q-tabs>
        <div class="content-list test-result-list">
          <div v-if="isWaiting" class="skeletons">
            <q-skeleton v-for="index in 5" :key="index" height="40px" />
          </div>
          <q-list v-else bordered>
            <q-item :href="modelUrl(entry)" clickable v-for="(entry, index) in filteredResultItems" :key="index">
              <q-item-section avatar>
                <q-icon :name="modelIcon(entry)" />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ modelLabel(entry) }}</q-item-label>
                <q-item-label caption>{{ modelCaption(entry) }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </div>
      </div>
    </div>
    <div class="footer text-center q-py-sm text-sm">
      <button class="bg-transparent no-border cursor-pointer" @click="goToAdvancedSearch">
        Avancerad sökning &raquo;
      </button>
    </div>
  </div>
</template>
<script lang="ts">
import { Entity, Instrument } from '@shared/web/models';
import { Component, Vue, Watch } from 'vue-facing-decorator';
import { getHistoricNavigationModelsFromLocalStorage } from '@/utils';
import { reactive } from 'vue';
import { debounce } from 'lodash';
import { searchEntities } from '@/models/entity/entityService';
import LESearchQuery from '@/models/entity/LESearchQuery';
import { searchInstruments } from '@/models/instrument/instrumentService';
import InstrumentSearchQuery from '@/models/instrument/InstrumentSearchQuery';

type SearchResultModel = Instrument | Entity;

@Component
export default class NavSearch extends Vue {
  tab: 'all' | 'legalEntities' | 'persons' | 'instruments' = 'all';
  text = '';
  isOpen = false;
  resultItems: SearchResultModel[] = [];
  allHistoricModels = [];
  debouncedSearch;
  isWaiting = false;
  get filteredResultItems() {
    return this.resultItems.filter(this.shouldBeVisibleOnCurrenTab);
  }
  mounted() {
    this.debouncedSearch = debounce(async () => {
      await this.search();
    }, 300);
    this.showLastVisited();
  }
  showLastVisited() {
    this.allHistoricModels = getHistoricNavigationModelsFromLocalStorage();
    this.resultItems = reactive(this.allHistoricModels.filter(this.shouldBeVisibleOnCurrenTab).reverse());
  }
  async search() {
    this.isWaiting = true;
    if (this.text === '') {
      this.showLastVisited();
    } else {
      const searchResult = await Promise.all([
        searchEntities(new LESearchQuery({ query: this.text, limit: 100 })),
        searchInstruments(new InstrumentSearchQuery({ query: this.text, limit: 100 })),
      ]);
      this.resultItems = [...searchResult[0], ...searchResult[1]];
    }
    this.isWaiting = false;
  }
  shouldBeVisibleOnCurrenTab(model: Entity | Instrument) {
    if (this.tab === 'legalEntities') {
      return model instanceof Entity && model.isLegalEntity;
    }
    if (this.tab === 'persons') {
      return model instanceof Entity && model.isPerson;
    }
    if (this.tab === 'instruments') {
      return model instanceof Instrument;
    }
    return true;
  }
  modelIcon(model: Entity | Instrument) {
    return model instanceof Entity ? (model.isPerson ? 'person' : 'apartment') : 'description';
  }
  modelLabel(model: Entity | Instrument) {
    return model.viewName;
  }
  modelCaption(model: Entity | Instrument) {
    return model instanceof Entity ? model.nationalId : model.shortName;
  }
  modelUrl(model: Entity | Instrument) {
    return model.viewUrl;
  }
  @Watch('text')
  handleInputChange() {
    this.isWaiting = true;
    this.debouncedSearch();
  }
  @Watch('isOpen')
  handleOpenClose() {
    if (this.text === '') {
      this.showLastVisited();
    }
  }
  handleKeyUp(ev) {
    if (ev.code === 'Escape') {
      this.handleEsc(ev);
    }
    if (ev.code === 'Enter') {
      this.handleEnter(ev);
    }
  }
  handleEsc(ev) {
    this.isOpen = false;
    this.blur(ev);
  }
  hasSearchPage() {
    return this.getSearchPageUrl('');
  }
  getSearchPageUrl(query) {
    if (this.tab === 'instruments') {
      return `/search/instruments?query=${query}`;
    }
    if (this.tab === 'legalEntities') {
      return `/search/legal-entities?query=${query}`;
    }
    if (this.tab === 'persons') {
      return `/search/persons?query=${query}`;
    }
  }
  handleEnter(ev) {
    if (this.hasSearchPage()) {
      this.$router.push(this.getSearchPageUrl(this.text));
      this.isOpen = false;
      this.blur(ev);
    }
  }
  handleClear(ev) {
    ev.stopPropagation();
    this.text = '';
  }
  blur(ev) {
    if (ev.target.tagName.toLowerCase() === 'input') {
      ev.target.blur();
    } else {
      const input = ev.target.closest('.nav-search').querySelector('input');
      input.blur();
    }
  }
  goToAdvancedSearch() {
    this.isOpen = false;
    const uri = this.getSearchPageUrl(this.text) || '/search/legal-entities';
    this.$router.push(uri);
  }
}
</script>
<style lang="scss">
.backdrop.open {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1;
}
.nav-search {
  flex-grow: 1;
  .q-tabs {
    margin: 0 !important;
  }
  .footer {
    display: none;
    button {
      color: rgba(255, 255, 255, 0.7);
      text-decoration: underline;
    }
  }
  &.open {
    position: fixed;
    inset: 5vw;
    bottom: auto;
    z-index: 10;
    width: 90%;
    max-width: 780px;
    margin: 0 auto;
    .nav-search-body {
      background-color: rgba(255, 255, 255, 1);
    }
    .footer {
      display: block;
    }
    .q-field__control {
      padding: 0 12px;
    }
  }
  .content {
    color: $text-color;
  }
  .content-list {
    overflow-y: auto;
    max-height: min(390px, calc(100vh - 230px));
    .q-item {
      border-bottom: 1px solid $grey-2;
    }
  }
  .q-skeleton {
    margin: 10px;
  }
  @for $i from 0 through 5 {
    .q-skeleton:nth-child(#{$i}) {
      animation-delay: $i * 0.25s;
    }
  }
}
</style>
