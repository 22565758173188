<template>
  <q-drawer
    v-if="submenuItems.length"
    class="LeftPanel"
    :value="false"
    :width="200"
    behavior="desktop"
    :show-if-above="true"
  >
    <q-list>
      <template v-if="submenuItems.length">
        <q-item class="entity-header">
          <q-item-section>{{ activeNavigationModel.viewName }}</q-item-section>
        </q-item>
        <q-item
          class="q-gutter-none q-py-xs test-panel-items entity-item"
          v-for="item in submenuItems"
          :key="item.url"
          clickable
          :disable="item.disabled"
          :to="item.url"
          v-show="!item.hide"
          no-ripple
          active-class="is-active"
        >
          <q-item-section class="text-subtitle1">{{ item.title }}</q-item-section>
        </q-item>
      </template>
    </q-list>
  </q-drawer>
</template>
<script lang="ts">
// @ts-nocheck
import { Component, Vue } from 'vue-facing-decorator';
import store from '@/store';
import { Entity, Instrument } from '@shared/web/models';
@Component
export default class LeftPanel extends Vue {
  get activeNavigationModel() {
    return store.activeNavigationModel;
  }

  get submenuItems() {
    const activeNavigationModel = store.activeNavigationModel;

    if (activeNavigationModel instanceof Entity) {
      return this.entityItems;
    }
    if (activeNavigationModel instanceof Instrument) {
      return [
        {
          title: 'Värdepapper',
          url: `/instrument/view/${activeNavigationModel.id}`,
        },
        {
          title: 'Innehav',
          url: `/instrument/positions/${activeNavigationModel.id}`,
        },
        {
          title: 'Transaktioner',
          url: `/instrument/transactions/${activeNavigationModel.id}`,
        },
      ];
    }
    return [];
  }

  get entityItems() {
    const entity = store.activeNavigationModel as Entity;
    const isPerson = entity.isPerson;
    const entityId = entity.id;

    if (isPerson) {
      return [
        {
          title: 'Fysisk person',
          url: `/person/view/${entityId}`,
        },
        {
          title: 'Innehav',
          url: `/owner/positions/${entityId}`,
        },
        {
          title: 'Transaktioner',
          url: `/owner/transactions/${entityId}`,
        },
      ];
    }

    return [
      {
        title: 'Juridisk person',
        url: `/legal-entity/view/${entityId}`,
      },
      {
        title: 'Aktiebok',
        url: `/legal-entity/share-register/${entityId}`,
        hide: !entity.isIssuer,
      },
      {
        title: 'Investerarbok',
        url: `/legal-entity/investor-book/${entityId}`,
        hide: !entity.isIssuer,
      },
      {
        title: 'Optionsbok',
        url: `/legal-entity/rights-register/${entityId}`,
        hide: !entity.isIssuer,
      },
      {
        title: 'Skuldbok',
        url: `/legal-entity/debts-register/${entityId}`,
        hide: !entity.isIssuer,
      },
      {
        title: 'Innehav',
        url: `/position?entity=${entityId}`,
        hide: !entity.isIssuer,
      },
      {
        title: 'Transaktioner',
        url: `/transaction?entity=${entityId}`,
        hide: !entity.isIssuer,
      },
      {
        title: 'Värderingar',
        url: `/legal-entity/valuations/${entityId}`,
      },
      {
        title: 'Ägarbyten',
        url: `/instruction?entity=${entityId}`,
        hide: !entity.isIssuer,
      },
      {
        title: 'Bolagshändelser',
        url: `/corporate-event?entity=${entityId}`,
        hide: !entity.isIssuer,
      },
      {
        title: 'Kommentarer',
        url: `/legal-entity/comments/${entityId}`,
      },
      {
        title: 'Innehav (som ägare)',
        url: `/owner/positions/${entityId}`,
      },
      {
        title: 'Transaktioner (som ägare)',
        url: `/owner/transactions/${entityId}`,
      },
      {
        title: 'Loggar',
        url: `/legal-entity/logs/${entityId}`,
      },
    ];
  }
}
</script>
<style lang="scss">
.LeftPanel {
  margin: 0 auto;
  .q-list {
    padding-top: 20px;
    .q-item__label--header {
      color: $dark;
      font-size: 14px;
    }
    .q-item {
      min-height: 43px;
      &.q-item--active {
        .q-item__section {
          color: $secondary;
        }
      }
      .q-item__section {
        font-size: 14px;
        color: $dark;
        &.q-item__section--avatar {
          min-width: 30px;
        }
        .q-icon {
          font-size: 17px;
        }
      }
      &.disabled {
        opacity: 1 !important;
        .q-item__section {
          color: $grey-3;
        }
      }
      &.is-active {
        .q-item__section {
          color: $secondary;
        }
      }
    }
  }
  .entity-header {
    font-weight: bold;
  }
  .entity-item {
    padding-left: 36px;
  }
}
</style>
