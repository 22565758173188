<template>
  <q-page class="ShareRegisterPage" v-if="ready">
    <div class="row q-gutter-lg q-mt-sm">
      <div class="col">
        <h1>Aktiebok</h1>
      </div>
      <q-checkbox
        name="historicVersion"
        v-if="!entity.issuerData.locked"
        label="Historisk version"
        left-label
        v-model="skipIssuerDataValidation"
      />
      <q-btn-group unelevated class="col col-md-auto action-buttons" v-if="!entity.issuerData.locked">
        <q-btn
          :label="action.label"
          v-for="action in actionButtons"
          v-bind="$defs.btn"
          :key="action.state"
          @click="updateState(action.state)"
        />

        <q-btn
          label="Rensa Allt"
          v-bind="$defs.btn"
          @click="clearShareRegister"
          :disable="isEmpty(shareRegister.positions)"
        />
      </q-btn-group>
    </div>
    <q-banner v-if="requestedChangeMessage" class="q-mt-md bg-orange-1 bg-orange-3">
      begärd komplettering med kommentar: {{ requestedChangeMessage }}
    </q-banner>
    <a-page-section class="IssuerAndSharesInfo padded">
      <div class="row row q-col-gutter-x-md justify-between">
        <IssuerAndSharesInfo @refresh="refresh" :shareRegister="shareRegister" :readonly="true" class="col-md-8" />
      </div>
    </a-page-section>
    <div v-if="shareRegister" class="relative-position" :style="loading ? 'max-height:400px;overflow:hidden;' : ''">
      <ShareRegisterView
        :shareRegister="shareRegister"
        :readonly="shareRegister.entity.issuerData.locked"
        stage="view"
      />
      <q-inner-loading :showing="loading" />
      <FileList :parent="instruction" v-if="instruction"></FileList>
    </div>
  </q-page>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import { findEntity } from '@/models/entity/entityService';
import {
  clearShareRegister,
  findShareRegister,
  setShareRegisterState,
} from '@/models/shareRegister/shareRegisterService';
import { findShareRegisterCreatedInstruction } from '@/models/instruction/instructionService';
import {
  Entity,
  Instruction,
  InstructionState,
  InstrumentCategory,
  IssuerInfo,
  ShareRegister,
} from '@shared/web/models';
import { formatNationalId } from '@shared/common';
import formatDate from 'date-fns/format';
import { PDFCategory, PDFProps } from '@shared/utils/pdf';
import shareRegisterExcel from '@shared/excel/shareRegisterExport';
import ownerRegisterExcel from '@shared/excel/ownerRegisterExport';
import ShareRegisterView from '@shared/views/shareRegister/ShareRegisterView.vue';
import PdfDownload from '@shared/components/PdfDownload.vue';
import IssuerData from '@/views/legalEntity/IssuerData.vue';
import IssuerAndSharesInfo from './IssuerAndSharesInfo.vue';
import LockShareRegisterModal from './LockShareRegisterModal.vue';
import FileList from '@/views/file/FileList.vue';
import ExportExcel from '@/views/excel/ExportExcel.vue';
import TableSettings from '@shared/web/models/shareRegister/TableSettings';
import { findIssuerInfo } from '@/models/entity/issuerInfoService';
import { ViewType } from '@shared/shareRegister/tableSettings';
import { setActiveNavigationModel } from '@/utils';
import { Products } from '@shared/index';

const actionButtons = {
  LOCKED: {
    state: InstructionState.EXECUTED,
    label: 'Skapa aktiebok',
  },
  REVIEW: {
    state: InstructionState.REVIEW,
    label: 'Påbörja granskning',
  },
  REQUEST_CHANGE: {
    state: InstructionState.REQUEST_CHANGE,
    label: 'Begär komplettering',
  },
};

@Component({
  components: {
    ExportExcel,
    IssuerData,
    IssuerAndSharesInfo,
    FileList,
    ShareRegisterView,
    PdfDownload,
  },
})
export default class ShareRegisterPage extends Vue {
  entity: Entity = null;
  shareRegister: ShareRegister = null;
  instruction?: Instruction = null;
  issuerInfo: IssuerInfo = null;
  viewType: ViewType = null;
  requestedChangeMessageValue = '';
  _skipIssuerDataValidation = false;

  async created() {
    await this.loadData();
  }

  async loadData() {
    const entity = await findEntity(this.$route.params.id);
    const shareRegister = await findShareRegister({
      entity,
      instrumentCategory: InstrumentCategory.SHA,
      instrumentProduct: Products.KAPCLEAR,
      onlyPreliminary: !entity.issuerData?.locked,
    });
    await setActiveNavigationModel(shareRegister.entity);
    let instruction = null;
    if (entity.issuerData.shareRegisterState !== InstructionState.EXECUTED) {
      instruction = await findShareRegisterCreatedInstruction(entity);
    }
    const issuerInfo = await findIssuerInfo(shareRegister.entity);

    Object.assign(this, { entity, shareRegister, issuerInfo, instruction });
  }

  get skipIssuerDataValidation() {
    if (this.instruction) {
      return this.instruction.skipIssuerDataValidation;
    }
    return this._skipIssuerDataValidation;
  }

  set skipIssuerDataValidation(value) {
    this._skipIssuerDataValidation = value;
    if (this.instruction) {
      this.instruction.skipIssuerDataValidation = value;
    }
  }

  get ready() {
    return this.entity != null && this.shareRegister != null;
  }

  get state() {
    return this.entity.issuerData.shareRegisterState;
  }
  get requestedChangeMessage() {
    return this.state === InstructionState.REQUEST_CHANGE && this.entity.shareRegisterRequestedChangeMessage;
  }
  get shareRegisterExcel() {
    return shareRegisterExcel(this.shareRegister, this.issuerInfo, new TableSettings());
  }
  get ownerRegisterExcel() {
    return ownerRegisterExcel(this.shareRegister, this.issuerInfo);
  }

  get actionButtons() {
    const state = this.entity.issuerData.shareRegisterState;
    if (state === InstructionState.NEW || state === InstructionState.SUBMITTED) {
      return [actionButtons.REVIEW];
    }
    if (state === InstructionState.REVIEW) {
      return [actionButtons.REQUEST_CHANGE, actionButtons.LOCKED];
    }
    if (state === InstructionState.REQUEST_CHANGE) {
      return [actionButtons.LOCKED];
    }
    return [];
  }

  public async refresh(): Promise<void> {
    this.shareRegister = await findShareRegister({
      entity: this.entity,
      instrumentCategory: InstrumentCategory.SHA,
      onlyPreliminary: !this.entity.issuerData.locked,
    });
  }

  get pdfConfig(): PDFProps & { filename: string } {
    const { entity } = this.shareRegister;
    return {
      entity: this.entity.id,
      filename: `${formatNationalId(entity, false)} ${formatDate(new Date(), 'yyyyMMdd')}`,
      category: PDFCategory.SHA,
      instrumentProduct: Products.KAPCLEAR,
    };
  }

  async updateState(state) {
    if (state === InstructionState.EXECUTED) {
      return this.createShareRegister();
    } else if (state === InstructionState.REQUEST_CHANGE) {
      return this.$q
        .dialog({
          title: 'Kommentar till företaget',
          ok: 'Begär komplettering',
          cancel: 'Avbryt',
          prompt: {
            model: this.requestedChangeMessageValue,
            type: 'text',
            isValid: val => !!val,
          },
        })
        .onOk(async value => {
          this.entity.shareRegisterRequestedChangeMessage = value;
          return await setShareRegisterState(this.shareRegister, {
            state,
            requestedChangeMessage: value,
          });
        });
    }
    return await setShareRegisterState(this.shareRegister, {
      state,
      skipIssuerDataValidation: this.skipIssuerDataValidation,
    });
  }

  createShareRegister() {
    this.$q.dialog({
      component: LockShareRegisterModal,
      componentProps: {
        shareRegister: this.shareRegister,
        instruction: this.instruction,
        skipIssuerDataValidation: this.skipIssuerDataValidation,
      },
    });
  }

  async clearShareRegister() {
    await this.useConfirmModal({
      header: 'Rensa aktiebok',
      text: 'Detta kommer rensa alla positioner i aktieboken',
    });
    await clearShareRegister(this.shareRegister);
  }
}
</script>
