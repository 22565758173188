<template>
  <q-btn v-if="isTradeEvent" label="Nytt ägarbyte" icon="add" @click="openCreateTradeInstruction" />
  <q-btn-dropdown v-if="isCorporateEvent" color="primary" dropdown-icon="expand_more" label="Ny bolagshändelse">
    <q-list>
      <q-item
        v-for="(transactionType, label) in corporateEventButtons"
        :key="label"
        clickable
        v-close-popup
        @click="newInstructionWithType(transactionType)"
      >
        <q-item-section avatar>
          <q-icon name="add" />
        </q-item-section>
        <q-item-section>
          <q-item-label>{{ label }}</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </q-btn-dropdown>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { InstructionMainType, TransactionType } from '@shared/models/types';
import { openNewInstruction } from '@/actions';
import { asModelId } from '@shared/utils';

@Component
export default class NewInstructionButton extends Vue {
  @Prop({ required: true }) mainType;
  @Prop({ required: true }) entity;
  get corporateEventButtons() {
    return {
      'Nyemission bef aktieslag': TransactionType.RIGHTS_ISSUE,
      'Nyemission nytt aktieslag': TransactionType.ISSUE_SHARE,
      Teckningsoptionsprogram: TransactionType.ISSUE_WARRANT,
      Split: TransactionType.SPLIT,
      'Ge ut konvertibel': TransactionType.ISSUE_CONVERTIBLE,
      'Ge ut obligation': TransactionType.ISSUE_BOND,
      'Ge ut vinstandelslån': TransactionType.ISSUE_DEBENTURE,
      'Registrera aktieägartillskott': TransactionType.ISSUE_SHAREHOLDER_CONTRIBUTION,
      'Ta bort aktiebok': TransactionType.SHAREREGISTER_CLOSED,
      'Ta bort värdepapper': TransactionType.INSTRUMENT_CLOSED,
    };
  }

  newInstructionWithType(type: TransactionType) {
    openNewInstruction({ type, entity: asModelId(this.entity) });
  }

  openCreateTradeInstruction() {
    this.newInstructionWithType(TransactionType.TRADE);
  }

  get isTradeEvent() {
    return this.mainType === InstructionMainType.TRADE_EVENT;
  }

  get isCorporateEvent() {
    return this.mainType === InstructionMainType.CORPORATE_EVENT;
  }
}
</script>
