<template>
  <div v-if="ready">
    <div class="row q-gutter-lg">
      <TransactionSearch class="col" @search="search" :searchQuery="searchQuery" />
    </div>
    <q-card flat class="q-pa-none PageSection q-mt-lg" v-if="transactionsData">
      <h4 class="table-header">Transaktioner</h4>
      <q-card-section class="q-pa-none">
        <TransactionsTable :transactionsData="transactionsData" />
        <div>
          <ExportTransactionsExcel :transactionsData="transactionsData" v-if="transactionsData" />
          <PdfDownload v-bind="pdfConfig"> PdfDownload </PdfDownload>
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>
<script lang="ts">
import * as _ from 'lodash';
import formatDate from 'date-fns/format';
import { Component, Vue } from 'vue-facing-decorator';

import TransactionSearchQuery from '@shared/web/models/transaction/TransactionSearchQuery';
import TransactionSearch from './TransactionSearch.vue';
import { TransactionsData } from '@/models';
import ExportTransactionsExcel from './ExportTransactionsExcel.vue';
import { findEntity } from '@/models/entity/entityService';
import { findInstrument } from '@/models/instrument/instrumentService';
import { formatNationalId } from '@shared/common';
import TransactionsTable from './TransactionsTable.vue';
import PdfDownload from '@shared/components/PdfDownload.vue';
import { PDFType } from '@shared/utils/pdf';
import { setActiveNavigationModel } from '@/utils';
import { isKapsureMode } from '@/appMode';

@Component({
  components: {
    TransactionSearch,
    TransactionsTable,
    ExportTransactionsExcel,
    PdfDownload,
  },
})
export default class TransactionSearchWithResult extends Vue {
  transactionsData: TransactionsData = null;
  searchQuery = null;

  async created() {
    await this.loadData();
  }

  async loadData() {
    const searchQuery = TransactionSearchQuery.newModel(this.$route.query);
    searchQuery.onlyInsurance = isKapsureMode();

    if (searchQuery.entity && _.isString(searchQuery.entity)) {
      searchQuery.entity = await findEntity(searchQuery.entity);
      setActiveNavigationModel(searchQuery.entity);
    }
    if (searchQuery.instrument && _.isString(searchQuery.instrument)) {
      searchQuery.instrument = await findInstrument(searchQuery.instrument);
    }

    Object.assign(this, { searchQuery });
  }

  search(searchResult) {
    this.transactionsData = searchResult;
  }

  get pdfConfig() {
    const { entity, instrument, owner, custodian, dateType, from, to } = this.searchQuery;
    const filename = `${formatNationalId(entity, false)} ${formatDate(new Date(), 'yyyyMMdd')}`;
    return {
      type: PDFType.transactions,
      filename,
      entity: entity,
      instrument: instrument?.id,
      owner: owner?.id,
      custodian: custodian?.id,
      dateType,
      from,
      to,
    };
  }

  get ready() {
    return this.searchQuery != null;
  }
}
</script>
