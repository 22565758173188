<template>
  <q-dialog ref="dialog" persistent class="upload-error-rows">
    <div class="q-dialog-plugin bg-white relative-position upload-error-rows__container">
      <h4 class="q-pa-md">Fel vid uppladdning på följande rader</h4>
      <q-markup-table flat>
        <thead>
          <tr>
            <th class="text-left" style="width: 10%">Rad</th>
            <th class="text-left">Felmeddelande</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in rows" :key="`row_${index}`">
            <td>{{ row.data.row }}</td>
            <td>
              {{ row.text }}
              <div class="text-grey-3 text-subtitle">({{ row.key }})</div>
              <FoldableCodeBox :data="row.data.rowData" />
            </td>
          </tr>
        </tbody>
      </q-markup-table>
      <q-card-actions align="right" class="q-pa-lg">
        <q-btn @click="hide">Stäng</q-btn>
      </q-card-actions>
    </div></q-dialog
  >
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { DialogComponent } from '@shared/mixins';
import { ApplicationError } from '@shared/models/types';
import FoldableCodeBox from '@shared/components/FoldableCodeBox.vue';

@Component({
  components: { FoldableCodeBox },
  mixins: [DialogComponent],
})
export default class EmailAllModal extends Vue {
  @Prop()
  public rows!: ApplicationError[];
}
</script>
<style lang="scss" scoped>
.upload-error-rows {
  &__container {
    width: 740px;
    max-width: initial !important;
  }
}
</style>
