import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '@/views/Home.vue';

import LegalEntityListPage from '@/views/legalEntityList/LegalEntityListPage.vue';
import LegalEntityPage from '@/views/legalEntity/LegalEntityPage.vue';
import PersonListPage from '@/views/personList/PersonListPage.vue';
import PersonPage from '@/views/person/PersonPage.vue';
import InstrumentListPage from '@/views/instrumentList/InstrumentListPage.vue';
import InstrumentPage from '@/views/instrument/InstrumentPage.vue';
import InstrumentPositionsPage from '@/views/instrument/InstrumentPositionsPage.vue';
import OwnerPositionsPage from '@/views/owner/OwnerPositionsPage.vue';
import InstrumentTransactionsPage from '@/views/instrument/InstrumentTransactionsPage.vue';
import OwnerTransactionsPage from '@/views/owner/OwnerTransactionsPage.vue';
import InsurancePage from '@/views/insurance/InsurancePage.vue';
import ShareRegisterPage from '@/views/shareRegister/ShareRegisterPage.vue';
import PositionListPage from '@/views/positionList/PositionListPage.vue';
import TransactionListPage from '@/views/transaction/TransactionListPage.vue';
import InstructionListPage from '@/views/instructionList/InstructionListPage.vue';
import CorporateEventListPage from '@/views/instructionList/CorporateEventListPage.vue';
import InstructionPage from '@/views/instruction/InstructionPage.vue';
import DashboardPage from '@/views/dashboard/DashboardPage.vue';
import { findProfile } from '@/models/profileService';
import { getEnv } from '@/utils';
import SystemLogsPage from '@/views/system/SystemLogsPage.vue';
import EntityCommentsPage from '@/views/comment/EntityCommentsPage.vue';
import EntitySystemLogsPage from '@/views/entity/EntitySystemLogsPage.vue';
import SearchPage from '@/views/search/SearchPage.vue';
import EntityValuationsListPage from '@/views/legalEntity/LegalEntityValuationsListPage.vue';
import InvestmentOffersPage from '@/views/investmentOffer/InvestmentOffersPage.vue';
import InvestmentOfferAddEdit from '@/views/investmentOffer/InvestmentOfferAddEdit.vue';
import InvestmentOfferDetailsPage from '@/views/investmentOffer/InvestmentOfferView.vue';
import CurrencyRatesPage from '@/views/currencyRates/CurrencyRatesPage.vue';
import InvestorBookPage from '@/views/investorBook/InvestorBookPage.vue';
import DebtsRegisterPage from '@/views/debtsRegister/DebtsRegisterPage.vue';
import RightsRegisterPage from '@/views/rightsRegister/RightsRegisterPage.vue';
import { getCurrencyRates } from '@/models/rates/currencyRatesService';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      public: true,
    },
  },
  {
    path: '/valutakurser',
    name: 'CurrencyRatesPage',
    component: CurrencyRatesPage,
  },
  {
    path: '/legal-entity',
    name: 'LegalEntityListPage',

    component: LegalEntityListPage,
  },
  {
    path: '/legal-entity/view/:id',
    name: 'LegalEntityPage',

    component: LegalEntityPage,
  },
  {
    path: '/legal-entity/valuations/:id',
    name: 'EntityValuationsListPage',

    component: EntityValuationsListPage,
  },
  {
    path: '/legal-entity/share-register/:id',
    name: 'ShareRegisterPage',
    meta: {
      pledgeEditable: true,
    },
    component: ShareRegisterPage,
  },

  {
    path: '/legal-entity/investor-book/:id',
    name: 'InvestorBookPage',
    component: InvestorBookPage,
  },
  {
    path: '/legal-entity/debts-register/:id',
    name: 'DebtsRegisterPage',
    component: DebtsRegisterPage,
  },
  {
    path: '/legal-entity/rights-register/:id',
    name: 'RightsRegisterPage',
    component: RightsRegisterPage,
  },
  {
    path: '/legal-entity/comments/:id',
    name: 'EntityCommentsPage',

    component: EntityCommentsPage,
  },
  {
    path: '/legal-entity/logs/:id',
    name: 'EntityLogsPage',

    component: EntitySystemLogsPage,
  },
  {
    path: '/person',
    name: 'PersonListPage',

    component: PersonListPage,
  },
  {
    path: '/person/view/:id',
    name: 'PersonPage',

    component: PersonPage,
  },
  {
    path: '/owner/positions/:id',
    name: 'OwnerPositionsPage',
    component: OwnerPositionsPage,
  },
  {
    path: '/owner/transactions/:id',
    name: 'OwnerTransactionsPage',
    component: OwnerTransactionsPage,
  },
  {
    path: '/investment-offer/',
    name: 'InvestmentOffersPage',
    component: InvestmentOffersPage,
  },
  {
    path: '/investment-offer/new',
    name: 'InvestmentOfferCreate',
    component: InvestmentOfferAddEdit,
  },
  {
    path: '/investment-offer/:id',
    name: 'InvestmentOfferEdit',
    component: InvestmentOfferAddEdit,
  },
  {
    path: '/investment-offer/details/:id',
    name: 'InvestmentOfferDetails',
    component: InvestmentOfferDetailsPage,
  },
  {
    path: '/instrument',
    name: 'InstrumentListPage',

    component: InstrumentListPage,
  },
  {
    path: '/instrument/view/:id',
    name: 'InstrumentPage',

    component: InstrumentPage,
  },
  {
    path: '/instrument/positions/:id',
    name: 'InstrumentPositionsPage',

    component: InstrumentPositionsPage,
  },
  {
    path: '/instrument/transactions/:id',
    name: 'InstrumentTransactionsPage',

    component: InstrumentTransactionsPage,
  },
  {
    path: '/insurance/view/:id',
    name: 'InsurancePage',

    component: InsurancePage,
  },
  {
    path: '/position',
    name: 'PositionListPage',

    component: PositionListPage,
  },
  {
    path: '/transaction',
    name: 'TransactionListPage',

    component: TransactionListPage,
  },
  {
    path: '/instruction',
    name: 'InstructionListPage',

    component: InstructionListPage,
  },
  {
    path: '/corporate-event',
    name: 'CorporateEventListPage',
    component: CorporateEventListPage,
  },
  {
    path: '/corporate-event/:id',
    name: 'CorporateEventPage',
    component: InstructionPage,
  },
  {
    path: '/instruction/view/:id',
    name: 'InstructionPage',

    component: InstructionPage,
  },
  {
    path: '/dashboard',
    name: 'DashboardPage',

    component: DashboardPage,
  },
  {
    path: '/search/:type',
    name: 'SearchPage',

    component: SearchPage,
  },
  {
    path: '/system',
    name: 'SystemLogs',
    component: SystemLogsPage,
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const profile = await findProfile();
  if (profile.loggedIn) {
    await getCurrencyRates();
  }
  if (!to.meta.public && !profile.loggedIn) {
    next({ path: '/' });
    return;
  }
  setEnvToTitle();
  next();
});

function setEnvToTitle() {
  document.title = `${getEnv()} | Kapclear`;
}

export default router;
