<template>
  <q-page class="OwnerTransactionsPage" v-if="ready">
    <div class="row q-gutter-lg q-mt-sm">
      <div class="col">
        <h1>Transaktioner för {{ owner.viewName }}</h1>
      </div>
    </div>
    <q-card flat class="q-pa-none PageSection">
      <q-card-section class="q-pa-none">
        <OwnerTransactionsTable :transactions="transactions" />
      </q-card-section>
    </q-card>
  </q-page>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator';

import { Entity, Transaction } from '@/models';
import OwnerTransactionsTable from './OwnerTransactionsTable.vue';

import { isToNewPage, setActiveNavigationModel } from '@/utils';
import { search } from '@/models/transaction/transactionService';
import TransactionSearchQuery from '@shared/web/models/transaction/TransactionSearchQuery';
import { loadEntity } from '@/models/entity/entityService';
@Component({
  components: { OwnerTransactionsTable },
  mixins: [],
})
export default class OwnerTransactionsPage extends Vue {
  owner: Entity;
  transactions: Array<Transaction>;
  async created() {
    await this.loadData();
  }

  @Watch('$route')
  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }
    const { id } = this.$route.params;

    this.owner = await loadEntity(id as string);

    setActiveNavigationModel(this.owner);
    this.transactions = await this.loadTransactions();
  }

  get ready() {
    return this.transactions != null;
  }

  async loadTransactions(): Promise<Array<Transaction>> {
    const { owner } = this;

    const data = await search(
      TransactionSearchQuery.newModel({
        ownerOrInvestor: owner,
        includeCounterparts: false,
      }),
    );
    return data.transactions;
  }
}
</script>
