<template>
  <q-table
    :rows="positions"
    :columns="columns"
    :pagination="pagination"
    row-key="id"
    flat
    hide-pagination
    hide-no-data
    class="standard-table OwnerPositionsTable"
  >
    <template v-slot:body-cell-ownership="props">
      <q-td :props="props">
        <InsuranceLink :instructionParty="props.row" />
      </q-td>
    </template>
    <template v-slot:body-cell="props">
      <q-td :props="props">
        <template v-if="props.value?.viewUrl">
          <router-link :to="props.value.viewUrl"> {{ props.value.viewName }}</router-link>
        </template>
        <template v-else>{{ props.value }}</template>
      </q-td>
    </template>
  </q-table>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { Position } from '@/models';
import InsuranceLink from '@/views/insurance/InsuranceLink.vue';
import { QTableProps } from 'quasar';
import { formatDay, formatNumber } from '@shared/utils';
@Component({
  components: {
    InsuranceLink,
  },
})
export default class OwnerPositionsTable extends Vue {
  @Prop() positions: Array<Position>;

  columns: QTableProps['columns'] = [
    {
      field: 'instrument',
      name: 'instrument',
      label: 'Värdepapper',
      align: 'left',
    },
    {
      field: 'ownership',
      name: 'ownership',
      label: 'Försäkring',
      align: 'left',
    },
    {
      field: 'quantity',
      format: value => formatNumber(value),
      name: 'quantity',
      label: 'Antal',
      align: 'right',
      classes: 'test-position-row-quantity',
    },
    {
      field: position => position.instrument.valuation?.priceSEK,

      format: value => value && formatNumber(value),
      name: 'valuationPrice',
      label: 'Senaste värdering',
      align: 'right',
    },
    {
      field: position => position.instrument.valuation?.date,
      format: value => value && formatDay(value),
      name: 'date',
      label: 'Värderingsdag',
      align: 'right',
    },
    {
      field: position => position.currentValue,
      format: value => formatNumber(value),
      name: 'currentValue',
      label: 'Värde',
      align: 'right',
    },
    {
      field: 'acquisitionValue',
      format: value => formatNumber(value),
      name: 'acquisitionValue',
      label: 'Anskaffningsvärde',
    },
  ];
  pagination = {
    rowsPerPage: 0,
    sortBy: 'owner',
  };
}
</script>
