<template>
  <tr>
    <td colspan="10">
      <div class="wrapper">
        <Share :position="position" />
        <ViewEntityInfo
          :entityInfo="position.owner"
          :issuerInfo="issuerInfo"
          v-if="position.owner"
          title="Ägare"
          :label="position.owner.isPerson ? 'PersonNummer' : 'OrgNummer'"
        />
        <ViewEntityInfo
          v-if="position.investor"
          :entityInfo="position.investor"
          :issuerInfo="issuerInfo"
          title="Investerare"
          :label="position.investor.isPerson ? 'PersonNummer' : 'OrgNummer'"
        />
        <ViewEntityInfo
          v-if="position.investorManager"
          :entityInfo="position.investorManager"
          :issuerInfo="issuerInfo"
          title="Investeringsansvarig"
          label="PersonNummer"
        />
      </div>
    </td>
  </tr>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { Position, IssuerInfo } from '@/models';
import Share from './Share.vue';
import ViewEntityInfo from './ViewEntityInfo.vue';
import { options } from '@shared/common';

@Component({
  components: { Share, ViewEntityInfo },
})
export default class ExpandingPositionRow extends Vue {
  @Prop() position: Position;
  @Prop() issuerInfo: IssuerInfo;
  get options() {
    return options;
  }
}
</script>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  margin-top: 2%;
  text-align: center;
}
.reservations {
  width: 25%;
}
.wrapper > * {
  margin: 10px;
}
</style>
