<template>
  <q-dialog ref="dialog" persistent>
    <div class="q-dialog-plugin MergeInstrumentModal">
      <h4 class="table-header">
        Värdepappret som du kryssar i här försvinner och slås ihop med det värdepappret du valde i tidigare vy:
        <div class="targeted-instrument">
          <div>{{ instrumentName }}</div>
          <div>{{ classType }}</div>
          <div>{{ group }}</div>
          <div>{{ totalQuantity }}</div>
        </div>
      </h4>
      <q-table
        :rows="instruments"
        :columns="columns"
        :pagination="pagination"
        row-key="id"
        flat
        hide-pagination
        hide-no-data
        hideSelectedBanner
        class="standard-table"
        selection="single"
        v-model:selected="selected"
        :onSelection="onSelection"
      >
        <template v-slot:body-cell-viewName="props">
          <q-td :props="props">
            <router-link v-if="props.value" :to="props.row.viewUrl">{{ props.value }}</router-link>
          </q-td>
        </template>
        <template v-slot:bottom-row>
          <q-tr>
            <q-td colspan="100%" align="right">
              <q-btn size="small" v-bind="$defs.btn" flat @click="closeModal" label="Avbryt" />
              <q-btn
                flat
                color="grey-2"
                textColor="primary"
                label="Slå ihop värdepapper"
                size="small"
                @click="merge"
                :disabled="selected.length === 0"
              />
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
  </q-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-facing-decorator';
import { QTableProps } from 'quasar';
import { Instrument } from '@/models';
import { toInstrumentGroupLabel, toShareClassTypeLabel } from '@shared/common';
import { formatNumber, formatPercent } from '@/utils';

type ExtendedInstrument = {
  selected: boolean;
} & Instrument;

type SelectedItemType = {
  rows: readonly ExtendedInstrument[];
  keys: readonly string[];
  added: boolean;
  evt: Event;
};
@Component({})
export default class MergeInstrumentModal extends Vue {
  @Prop() instruments: Instrument[];
  @Prop() instrument: Instrument;
  @Prop() instrumentValidationMap;
  @Prop() onMerge: Function;

  selected = [];
  selectedObject = null;

  onSelection(selectedItem: SelectedItemType) {
    selectedItem.rows[0].selected = selectedItem.added;
    if (selectedItem.rows[0].selected) {
      this.selectedObject = selectedItem.rows[0];
    }
  }
  get instrumentName() {
    return this.instrument.viewName;
  }
  get classType() {
    return toShareClassTypeLabel(this.instrument.shareData.classType);
  }
  get group() {
    return toInstrumentGroupLabel(this.instrument.group);
  }
  get totalQuantity() {
    return formatNumber(this.instrument.shareData.totalQuantity);
  }
  async merge() {
    if (this.selectedObject !== null) {
      await this.onMerge(this.selectedObject.id);
      this.closeModal();
    }
  }

  @Watch('$route')
  closeModal() {
    this.$emit('hide');
  }

  get columns(): QTableProps['columns'] {
    return [
      {
        name: 'viewName',
        field: 'viewName',
        label: 'Namn',
        align: 'left',
      },
      {
        field: instrument => instrument.shareData.classType,
        name: 'classType',
        label: 'Aktieslag',
        align: 'right',
        format: value => toShareClassTypeLabel(value),
      },
      {
        field: instrument => instrument.group,
        name: 'group',
        label: 'Grupp',
        align: 'right',
        format: (value, row) => toInstrumentGroupLabel(value || row.shareData?.group),
      },
      {
        field: instrument => instrument.shareData?.votingPower,
        name: 'votingPower',
        format: value => formatNumber(value, 2, 20),
        label: 'Röstandel',
        align: 'right',
      },
      {
        field: instrument => instrument.shareData.totalQuantity,
        name: 'totalQuantity',
        format: value => formatNumber(value),
        label: 'Antal Aktier',
        align: 'right',
      },
      {
        field: instrument => {
          return this.instrumentValidationMap.get(instrument)?.votingPowerQuota;
        },
        name: 'votingPowerQuota',
        format: value => formatPercent(value),
        label: 'Röstandel',
        align: 'right',
      },
      {
        field: instrument => instrument.shareData.totalCapital,
        name: 'totalCapital',
        format: value => formatNumber(value),
        label: 'Aktiekapital',
        align: 'right',
      },
      {
        field: instrument => instrument.valuation?.priceSEK,
        name: 'valuationPrice',
        format: value => formatNumber(value),
        label: 'Värdering',
        align: 'right',
      },

      {
        name: 'status',
        field: instrument => instrument.shareData.positionsValid,

        label: 'Status',
        align: 'right',
      },
      {
        name: 'actions',
        field: null,
        label: '',
      },
    ].filter(col => {
      return this.instrument.entity.isKapclearIssuer || col.name != 'status';
    }) as QTableProps['columns'];
  }

  pagination = {
    rowsPerPage: 0,
    sortBy: 'owner',
  };
}
</script>

<style lang="scss">
.MergeInstrumentModal {
  min-width: 1000px;

  .targeted-instrument {
    display: flex;

    background: #2c612c;
    max-width: fit-content;
    gap: 1rem;
    padding: 0.5rem 1rem;
    color: #f7f8f7;
    font-weight: 600;
  }
}
</style>
