import store from '@/store';
import { CurrencyRates } from '@shared/web/models';
import axios from 'axios';

const baseUrl = '/api/currency-rates';

export async function getCurrencyRates(): Promise<CurrencyRates> {
  if (store.currencyRates) {
    return store.currencyRates;
  }
  const { data } = await axios.get(baseUrl);

  store.currencyRates = CurrencyRates.newModel(data, true);
  return store.currencyRates;
}

export async function updateCurrencyRates() {
  const { data } = await axios.put(`${baseUrl}/update`);
  store.currencyRates = CurrencyRates.newModel(data, true);
  return store.currencyRates;
}
