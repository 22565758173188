import * as _ from 'lodash';
import FileUpload from './FileUpload';
import { signFile, uploadToAmazon } from './uploadutils';
export function pickUploadFiles(fileParent) {
  const fileInput = initInputElement() as any;

  fileInput.click();

  fileInput.addEventListener('change', onChange);

  function onChange(ev) {
    const fileUploads = _.map(ev.target.files, file => new FileUpload(file, fileParent));

    for (const fileUpload of fileUploads) {
      uploadFile(fileUpload);
    }
  }

  function initInputElement() {
    const body = document.querySelector('body'),
      input = body.querySelector('input#file-input');
    input && input.remove();
    body.insertAdjacentHTML('beforeend', '<input multiple="true" type="file" id="file-input"/>');

    return body.querySelector('input#file-input');
  }
}

async function uploadFile(fileUpload: FileUpload) {
  const { signedUrl, file } = await signFile(fileUpload);
  await uploadToAmazon(signedUrl, fileUpload);
  fileUpload.parent.hasFiles = true;
  fileUpload.parent.files.push(file);
}
