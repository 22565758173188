import { Model } from '@shared/web/models/base';
import { Activity, Entity, User } from '@shared/web/models';

export default class Comment extends Model {
  entity: Entity;
  author: User;
  text: string;
  archived: boolean = false;
  createdAt: Date;
  updatedAt: Date;
  lastActivity: Activity;

  static define = () => ({
    entity: {
      model: Entity,
    },
    author: {
      model: User,
    },
  });
}
