<template>
  <q-dialog ref="dialog" persistent>
    <div class="q-dialog-plugin ConfirmModal">
      <a-page-section :header="header" class="padded">
        <q-banner rounded class="bg-red text-white q-mb-md" v-if="errorMessage">
          {{ getErrorText(errorMessage) }}
        </q-banner>
        {{ text }}
      </a-page-section>

      <q-card-actions align="right" class="q-pa-md">
        <q-btn v-bind="$defs.btn" flat @click="cancel" v-if="useConfirm">Avbryt</q-btn>
        <q-btn label="Fortsätt" type="submit" v-bind="$defs.btn" @click="confirm" />
      </q-card-actions>
    </div>
  </q-dialog>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { DialogComponent } from '@shared/mixins';

@Component({
  mixins: [DialogComponent],
})
export default class ConfirmModal extends Vue {
  @Prop() header: string;
  @Prop() text: string;
  @Prop({ default: () => true }) useConfirm: boolean;
  @Prop() onConfirm: Function;
  @Prop() onReject: Function;
  errorMessage: string = null;

  cancel() {
    this.onReject && this.onReject();
    this.$emit('hide');
  }

  async confirm() {
    try {
      if (this.onConfirm) {
        await this.onConfirm();
      }
      this.done();
    } catch (error) {
      this.errorMessage = error;
    }
  }
}
</script>
