<template>
  <q-tr colspan="100%">
    <q-td key="desc">{{ activityMadeByAuthorText }}</q-td>
    <q-td key="date">{{ date }}</q-td>
    <q-td auto-width>
      <q-btn dense flat class="cursor-pointer full-width text-right" @click="expand = !expand">
        <q-icon :name="expand ? 'expand_less' : 'expand_more'" size="24px"></q-icon>
      </q-btn>
    </q-td>
  </q-tr>
  <q-tr v-show="expand">
    <q-td colspan="100%">
      <div class="q-pa-md">
        <pre v-if="getFormattedDataAsJsonString"><code>{{ getFormattedDataAsJsonString }}</code></pre>
        <span v-else class="text-italic text-grey-3">Ingen data</span>
      </div>
    </q-td>
  </q-tr>
</template>

<script lang="ts">
import { Activity } from '@/models';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { activityMadeByAuthorText } from './utils';
import { format } from 'date-fns';

@Component
export default class ActivityTableRow extends Vue {
  @Prop() activity: Activity = null;
  expand: boolean = false;

  get date() {
    return format(this.activity.at, 'yyyy-MM-dd HH:mm');
  }
  get activityMadeByAuthorText() {
    return activityMadeByAuthorText(this.activity);
  }
  get getFormattedDataAsJsonString() {
    if (!this.activity || Object.keys(this.activity['data']).length === 0) {
      return '';
    }

    return JSON.stringify(this.activity, null, 2);
  }
}
</script>
