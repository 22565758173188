<template>
  <q-card flat class="q-pa-none PageSection">
    <div class="table-tabs">
      <q-tabs v-model="viewType" narrow-indicator>
        <q-tab name="shareRegister" label="Aktiebok" />
        <q-tab name="investorRegister" label="Investerarbok" />
      </q-tabs>

      <q-tabs v-model="groupProperty" narrow-indicator class="margin-left-auto">
        <q-tab name="instrument" label="Värdepapper" />
        <q-tab name="owner" label="Ägare" v-if="viewType === 'shareRegister' || viewType === 'publicRegister'" />
        <q-tab name="absoluteInvestor" label="Investerare" v-if="viewType === 'investorRegister'" />
      </q-tabs>
    </div>

    <q-card-section class="q-pa-none">
      <DestinationsTable
        :shareRegister="shareRegister"
        :tableSettings="tableSettings"
        :instruction="instruction"
        :validator="validator"
        :readonly="readonly"
        :disableAdd="disableAdd"
        @change="$emit('change')"
      />
    </q-card-section>
    <ExportDestinationsExcel :instruction="instruction" :validator="validator" />
  </q-card>
</template>

<script lang="ts">
// @ts-nocheck
import { Component, Vue, Prop } from 'vue-facing-decorator';

import { ShareRegister, Instruction } from '@/models';

import DestinationsTable from './DestinationsTable.vue';
import TableSettings from '@shared/web/models/shareRegister/TableSettings';
import ExportDestinationsExcel from './ExportDestinationsExcel.vue';

@Component({
  components: { DestinationsTable, ExportDestinationsExcel },
})
export default class DestinationsTableCard extends Vue {
  @Prop() shareRegister: ShareRegister;
  @Prop() instruction: Instruction;
  @Prop() disableAdd: boolean;
  @Prop() readonly: boolean;

  @Prop() validator;
  tableSettings = new TableSettings();

  get viewType() {
    return this.tableSettings.viewType;
  }

  set viewType(val) {
    if (val === 'investorRegister') {
      this.groupProperty = 'absoluteInvestor';
    } else {
      this.groupProperty = 'instrument';
    }
    this.tableSettings.viewType = val;
  }

  get groupProperty() {
    return this.tableSettings.groupProperty;
  }

  set groupProperty(val) {
    this.tableSettings.groupProperty = val;

    if (val === 'instrument') {
      this.tableSettings.sortProperty = 'owner';
    }

    if (val === 'absoluteInvestor' || val === 'owner') {
      this.tableSettings.sortProperty = 'quantity';
      this.tableSettings.sortDesc = true;
    }
  }
}
</script>
