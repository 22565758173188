<template>
  <q-item class="label-item" v-if="displayAddId && idTypes.length">
    <q-item-section side class="label">
      <a-select
        v-model="idType"
        v-bind="attributes.input"
        :options="idTypes"
        emit-value
        map-options
        class="width-100"
      />
    </q-item-section>
    <q-item-section>
      <q-input
        v-bind="attributes.input"
        v-model="idValue"
        @blur="onBlur"
        lazy-rules
        v-on:keyup.enter="onBlur"
        :rules="rules"
        ref="input"
      />
    </q-item-section>
  </q-item>
  <q-item class="label-item" v-else-if="idTypes.length">
    <q-item-section side class="label"> </q-item-section>
    <q-item-section>
      <q-btn
        label="Lägg till id"
        type="a"
        class="btn-fixed-width"
        align="left"
        color="primary"
        flat
        icon="add"
        @click="newId"
      />
    </q-item-section>
  </q-item>
</template>
<script lang="ts">
import { Instrument } from '@/models';
import * as _ from 'lodash';
import { Component, Vue, Prop, Ref } from 'vue-facing-decorator';
import { instrumentIdTypes, validation } from '@shared/common';

@Component
export default class AddInstrumentId extends Vue {
  @Prop() instrument: Instrument;
  @Ref() readonly input;
  selectedIdType;
  displayAddId;
  idValue;

  created() {
    const existingIds = instrumentIdTypes.filter(type => !_.isEmpty(this.instrument[type.value]));
    Object.assign(this, {
      idValue: null,
      displayAddId: existingIds.length === 0,
      selectedIdType: null,
    });
  }

  get rules() {
    return this.idType === 'ISIN' ? validation.ISIN : null;
  }

  validate() {
    return this.input.validate();
  }

  onBlur() {
    if (_.isEmpty(this.idValue)) {
      return;
    }

    const valid = this.input.validate();
    if (!valid) {
      return;
    }
    this.$emit('add', {
      type: this.idType,
      value: this.idValue,
    });
    this.displayAddId = false;
  }

  newId() {
    this.idValue = '';
    this.selectedIdType = null;
    this.displayAddId = true;
  }

  get idType() {
    return this.selectedIdType || this.idTypes[0].value;
  }
  set idType(val) {
    this.selectedIdType = val;
  }

  get idTypes() {
    return instrumentIdTypes.filter(type => _.isEmpty(this.instrument[type.value]));
  }
  get attributes() {
    return {
      input: {
        dense: true,
        filled: true,
        square: true,
      },
    };
  }
}
</script>
