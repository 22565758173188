<template>
  <q-page>
    <h1 class="q-mb-xl">Systemloggar</h1>
    <div class="row q-mb-lg q-col-gutter-md">
      <div class="col-12 col-md-6">
        <EventLogTypesSelect v-model="selectedTypes" />
      </div>
      <div class="col-12 col-md-6">
        <q-skeleton height="56px" v-if="usersLoading" />
        <q-select
          v-if="!usersLoading"
          label="Användare"
          v-bind="$defs.input"
          v-model="selectedUser"
          :options="availableUsers"
        />
      </div>
    </div>
    <SystemLogsTable :types="selectedTypes" :users="selectedUser ? [selectedUser.value] : null" />
  </q-page>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import SystemLogsTable from '@/views/system/SystemLogsTable.vue';
import axios from 'axios';
import { groupBy, sumBy } from 'lodash';
import EventLogTypesSelect from '@/components/EventLogTypesSelect.vue';

@Component({
  components: { EventLogTypesSelect, SystemLogsTable },
})
export default class SystemLogsPage extends Vue {
  selectedTypes = [];
  selectedUser = null;
  availableUsers = [];
  usersLoading = true;
  async mounted() {
    await this.fetchUserOptions();
  }

  async fetchUserOptions() {
    this.usersLoading = true;
    const response = await axios.get('/api/event-logs/users');
    const { asUsersCounts, byUsersCounts, users } = response.data;
    this.availableUsers = [];
    const grouped = groupBy([...asUsersCounts, ...byUsersCounts], r => r._id || '');
    Object.keys(grouped).forEach(userId => {
      const count = sumBy(grouped[userId], 'count');
      const user = userId ? users.find(u => u.id === userId) : null;
      const userName = user ? user.name || user.username : 'system/okänd';
      this.availableUsers.push({
        label: `${userName} - ${count}`,
        value: userId,
      });
    });
    this.selectedUser = {
      label: 'Alla',
      value: '*',
    };
    this.availableUsers.push(this.selectedUser);
    this.usersLoading = false;
  }
}
</script>
