<template>
  <a-page-section header="Filer" class="FileList" :data-testid="dataTestId">
    <q-markup-table flat class="standard-table DestinationsTable" separator="horizontal">
      <tbody>
        <tr v-for="file in parent.files" :key="file.url">
          <td>
            <a :href="file.url" target="_blank" @click.prevent="openFile(file)">{{ file.name }}</a>
          </td>
          <td class="q-table--col-auto-width">
            <q-btn flat round color="grey-7" icon="delete_forever" size="md" @click="deleteFile(file)" />
          </td>
        </tr>
        <a-table-row-btn label="Lägg till dokument" @click="uploadDocuments" />
      </tbody>
    </q-markup-table>
  </a-page-section>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import axios from 'axios';
import { Instruction } from '@/models';
import { pickUploadFiles } from '@/models/document/fileHandler';
import { asModelId, remove } from '@/utils';
@Component
export default class CorporateEventList extends Vue {
  @Prop() parent: Instruction;
  files = null;

  get dataTestId(): string {
    return `file-list${this.parent ? `-${this.parent.id}` : ''}`;
  }

  async mounted() {
    if (this.parent?.id) {
      const { entity, id } = this.parent;

      const { data } = await axios.get(`/api/file/list/${asModelId(entity)}/${id}`);

      this.parent.files = data;
    }
  }

  async openFile(file) {
    const { data } = await axios.get(file.url);
    window.open(data.url, '_blank');
  }

  async deleteFile(file) {
    await axios.delete(file.url);
    remove(this.parent.files, file);
  }

  uploadDocuments() {
    pickUploadFiles(this.parent);
  }
}
</script>
