<template>
  <q-page class="PersonListPage">
    <h1>Fysiska Personer</h1>
    <PersonSearch class="col" @search="search" />
    <PersonList :list="persons" class="q-mt-lg" />
  </q-page>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import PersonSearch from './PersonSearch.vue';
import PersonList from './PersonList.vue';
@Component({
  components: {
    PersonSearch,
    PersonList,
  },
})
export default class PersonPage extends Vue {
  persons = [];

  search(searchResult) {
    this.persons = searchResult;
  }
}
</script>
<style lang="scss"></style>
