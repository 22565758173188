<template>
  <q-select
    v-model="model"
    use-input
    input-debounce="500"
    :options="extendedOptions"
    :disable="disable"
    map-options
    hide-selected
    fill-input
    option-label="viewName"
    input-class="destinationOwner"
    class="SelectEntity"
    :dropdownIcon="useSearch ? 'search' : 'expand_more'"
    v-bind="onlyProps($attrs)"
    :label="label"
    @filter="filterFn"
    @filter-abort="abortFilterFn"
    ref="input"
  >
    <template #option="scope">
      <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
        <q-item-section v-if="scope.opt">
          <q-item-label v-html="scope.opt.viewName" />
          <q-item-label caption>{{ formatNationalId(scope.opt) }} </q-item-label>
        </q-item-section>
        <q-item-section v-else>
          <q-item-label> <span class="SelectEntity-none-option">Ingen vald</span></q-item-label>
        </q-item-section>
      </q-item>
    </template>
    <template #no-option>
      <q-item class="SelectEntity-add-item" v-if="!hideCreate">
        <q-item-section>
          <q-item-label>
            <q-btn flat color="primary" :label="createLabel" @click="createNew" no-caps icon="add" />
          </q-item-label>
        </q-item-section>
      </q-item>
    </template>
    <template #after-options>
      <q-item class="SelectEntity-add-item" v-if="!hideCreate">
        <q-item-section>
          <q-item-label>
            <q-btn flat color="primary" :label="createLabel" @click="createNew" no-caps icon="add" />
          </q-item-label>
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>
<script lang="ts">
// @ts-nocheck
import { sortBy, isEmpty } from 'lodash';
import { Entity } from '@/models';
import { searchEntity, findEntity } from '@/models/entity/entityService';
import { EntitySearchQuery } from '@shared/web/models/entity/EntitySearchQuery';
import { EntitySearchOptions } from '@shared/entity/EntitySearchOptions';
import { Component, Vue, Prop, Ref } from 'vue-facing-decorator';
import LookupEntityModal from './LookupEntityModal.vue';
import { pickDefined } from '@shared/utils';

@Component({ inheritAttrs: false })
export default class SelectEntity extends Vue {
  @Prop() modelValue: Entity;
  @Prop({ default: () => [] }) defaultOwners: Array<Entity>;
  @Prop({ required: false }) entityType: string;
  @Prop({ required: false }) type: 'legalEntity' | 'person';
  @Prop({ required: false }) creatorForForeigner: string;
  @Prop({ default: () => 'Skapa ny' }) createLabel: string;
  @Prop() hideCreate: boolean;
  @Prop({ default: () => true }) useSearch: boolean;
  @Ref() readonly input;
  @Prop({ default: () => true }) nullOption: boolean;
  @Prop({ default: false }) disable: boolean;
  @Prop({ default: '' }) label?: string;
  options: Array<Entity> = null;
  searchQuery: EntitySearchQuery = new EntitySearchQuery();

  get searchOptions(): EntitySearchOptions {
    const { entityType, type, creatorForForeigner } = this;
    return pickDefined({ entityType, type, creatorForForeigner });
  }

  get model() {
    return this.modelValue;
  }
  set model(val) {
    this.setEntity(val);
  }

  async setEntity(val) {
    if (isEmpty(val)) {
      this.$emit('update:modelValue', null);
    } else {
      const entity = await findEntity(val.id);
      this.$emit('update:modelValue', entity);
    }
  }

  createNew() {
    this.$q
      .dialog({
        component: LookupEntityModal,
      })
      .onOk(owner => {
        this.input.hidePopup();
        this.$emit('update:modelValue', owner);
      });
  }

  get extendedOptions() {
    const options = this.options;
    if (options == null) {
      return null;
    }

    if (this.nullOption) {
      return ['', ...this.options]; // quasar cant handle null as option
    } else {
      return this.options;
    }
  }

  validate() {
    return this.input.validate();
  }

  async filterFn(val, update) {
    if (val.length === 0 || !this.useSearch) {
      return update(() => {
        this.options = [...this.defaultOwners];
      });
    }
    this.searchQuery.options = this.searchOptions;
    this.searchQuery.query = val;

    const owners = await searchEntity(this.searchQuery);

    update(() => {
      this.options = sortBy(owners, 'viewName');
    });
  }

  abortFilterFn() {
    console.log('abort filter');
  }
}
</script>
<style lang="scss">
.SelectEntity {
  .q-select__dropdown-icon {
    font-size: 20px;
    transform: rotate(0deg);
  }
}
.SelectEntity-add-item {
  margin-left: -16px;
}
</style>
