<template>
  <q-page class="CorporateEventListPage">
    <InstructionSearchWithResult :main-type="mainType" header="Bolagshändelser" :show-create-button="true" />
  </q-page>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import InstructionSearch from './InstructionSearch.vue';
import CorporateEventList from './CorporateEventList.vue';
import InstructionSearchWithResult from '@/views/instructionList/InstructionSearchWithResult.vue';
import { InstructionMainType } from '@shared/models/types';
@Component({
  components: {
    InstructionSearchWithResult,
    CorporateEventList,
    InstructionSearch,
  },
})
export default class CorporateEventListPage extends Vue {
  get mainType() {
    return InstructionMainType.CORPORATE_EVENT;
  }
}
</script>
