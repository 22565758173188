import axios from 'axios';
import { Entity, EntityAdmin, User } from '@/models';
const baseUrl = '/api/entityadmin';
import { asModelId } from '@/utils';
import { modelHandler } from '@/models/base';

const { saveModel, destroyModel } = modelHandler(EntityAdmin, baseUrl);

export async function findEntityAdminsForEntity(entity: string | Entity): Promise<EntityAdmin[]> {
  const { data } = await axios.get(`${baseUrl}/forentity/${asModelId(entity)}`);
  return EntityAdmin.newModels(data, true);
}

export async function lookupUser(personalNumber: string): Promise<User> {
  const { data } = await axios.post(`${baseUrl}/lookup-user`, { personalNumber });
  return User.newModel(data, true);
}

export async function save(admin: EntityAdmin): Promise<EntityAdmin> {
  const { model } = await saveModel(admin);
  return model;
}

export async function deleteAdmin(admin: EntityAdmin) {
  return await destroyModel(admin);
}
