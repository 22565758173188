<template>
  <q-select
    :options="extendedOptions"
    v-model="model"
    v-bind="$attrs"
    ref="input"
    :option-label="toLabel"
    dropdown-icon="expand_more"
    :rules="rules"
    :class="{ 'test-has-options': hasOptions }"
  >
  </q-select>
</template>
<script lang="ts">
import { isEmpty } from 'lodash';
import { Component, Vue, Prop, Ref } from 'vue-facing-decorator';

@Component
export default class Select extends Vue {
  @Prop() options: Array<any>;
  @Prop() modelValue;
  @Prop() readonly optionLabel;
  @Prop() readonly nullLabel;

  @Ref() readonly input;
  @Prop() rules;

  get hasOptions() {
    return !isEmpty(this.options);
  }

  get extendedOptions() {
    const options = this.options;
    if (options == null) {
      return null;
    }

    if (this.nullLabel) {
      return [null, ...this.options];
    } else {
      return this.options;
    }
  }

  toLabel(item) {
    if (item === null) {
      return this.nullLabel && this.options?.length > 0 ? this.nullLabel : null;
    }
    const labelProp = this.optionLabel || 'label';

    return item[labelProp];
  }

  validate() {
    return this.input.validate();
  }

  get model() {
    return this.modelValue;
  }
  set model(val) {
    this.$emit('update:modelValue', val);
  }
}
</script>
