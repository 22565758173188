import { EntityValuation } from '@/models';
import axios from 'axios';
import { modelHandler } from '../base';

const valuationBaseUrl = '/api/entity/valuation';
const { saveModel, destroyModel } = modelHandler(EntityValuation, valuationBaseUrl);

export async function loadValuations(id) {
  const { data } = await axios.get(valuationBaseUrl + `/${id}`);
  return EntityValuation.newModels(data, true);
}

export async function deleteValuation(valuation: EntityValuation) {
  await destroyModel(valuation);
}

export async function save(valuation: EntityValuation) {
  await saveModel(valuation);
}
