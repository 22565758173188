<template>
  <q-dialog ref="dialog">
    <div class="q-dialog-plugin EditAddressModal">
      <h4 class="table-header">Editera adress</h4>
      <a-page-section class="padded">
        <q-banner rounded class="bg-red text-white q-mb-md" v-if="errorMessage">
          {{ getErrorText(errorMessage) }}
        </q-banner>
        <q-form @submit="done" class="q-gutter-md validation-form" ref="form">
          <q-list :separator="false" class="standard-list">
            <a-label-item label="Adress 1">
              <q-input
                v-bind="$defs.input"
                v-model="address.address1"
                :disable="autoUpdate"
                :rules="validation.required('Address')"
              />
            </a-label-item>
            <a-label-item label="Adress 2">
              <q-input v-bind="$defs.input" v-model="address.address2" :disable="autoUpdate" />
            </a-label-item>
            <a-label-item label="Postnummer">
              <q-input
                v-bind="$defs.input"
                v-model="address.zip"
                :disable="autoUpdate"
                :rules="validation.required('Postnummer')"
              />
            </a-label-item>
            <a-label-item label="Stad">
              <q-input
                v-bind="$defs.input"
                v-model="address.city"
                :disable="autoUpdate"
                :rules="validation.required('Stad')"
              />
            </a-label-item>
            <a-label-item label="Land">
              <SelectCountry
                v-model="address.countryCode"
                v-bind="$defs.input"
                :rules="validation.countryCode"
                :disable="autoUpdate"
              />
            </a-label-item>
            <a-label-item label="Email">
              <q-input v-bind="$defs.input" v-model="address.email" :rules="validation.email" lazy-rules
            /></a-label-item>
            <a-label-item label="Telefon">
              <q-input v-bind="$defs.input" v-model="address.phone" />
            </a-label-item>
          </q-list>
        </q-form>
      </a-page-section>
      <q-card-actions align="right" class="q-pa-md">
        <q-btn v-bind="$defs.btn" flat @click="cancel">Avbryt</q-btn>
        <q-btn :label="doneLabel" v-bind="$defs.btn" @click="done" :loading="loading" />
      </q-card-actions>
    </div>
  </q-dialog>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { DialogComponent, FormComponent } from '@shared/mixins';
import { SelectCountry } from '@/components';
import { save as saveEntity } from '@/models/entity/entityService';

import { Address, Entity } from '@/models';
import { validation, options } from '@shared/common';

async function save(entity) {
  return saveEntity(entity);
}

@Component({ components: { SelectCountry }, mixins: [DialogComponent, FormComponent] })
export default class EditAddress extends Vue {
  @Prop() entity: Entity;
  @Prop() value: Address;
  @Prop() performSave: boolean;
  address: Address;
  doneLabel: string;

  created() {
    Object.assign(this, { address: this.value.copy(), doneLabel: this.performSave ? 'Spara' : 'Klar' });
  }

  get attributes() {
    return {
      input: {
        dense: true,
      },
    };
  }

  get options() {
    return options;
  }
  get autoUpdate() {
    return this.entity.autoUpdate;
  }

  get validation() {
    return validation;
  }

  async done() {
    const submitted = await this.submitForm(async () => {
      const index = this.entity.addresses.indexOf(this.value);
      const entity = this.performSave ? this.entity.copy() : this.entity;

      if (index < 0) {
        entity.addresses.push(this.address);
      } else {
        entity.addresses.splice(index, 1, this.address);
      }
      this.performSave && (await save(entity));
    });

    if (submitted) {
      this.hide();
    }
  }
}
</script>
<style lang="scss">
.EditAddressModal {
  .q-item__section--side {
    width: 120px;
  }
  &:not(.step1) {
    width: 600px;
    max-width: 600px;
  }
}
</style>
