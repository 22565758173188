<template>
  <div class="PersonSearch">
    <h4 class="table-header">Sök Fysisk Person</h4>

    <q-form @submit="search" class="q-gutter-x-md search-form">
      <div class="row">
        <q-input v-bind="$defs.input" v-model="searchQuery.query" placeholder="Namn eller personnummer" />

        <q-btn label="Sök" type="submit" v-bind="$defs.btn" :loading="loading" />
      </div>
      <div class="row">
        <a-select
          v-model="searchQuery.type"
          v-bind="$defs.input"
          :options="typeOptions"
          emit-value
          map-options
          label="Typ"
        />
      </div>
    </q-form>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import { search } from '@/models/entity/entityService';
import SearchQuery from '@/models/entity/LESearchQuery';
import { options } from '@shared/common';
import { EntityType } from '@shared/index';

@Component
export default class PersonSearch extends Vue {
  loading = false;
  searchQuery = null;

  get options() {
    return options;
  }

  created() {
    this.searchQuery = new SearchQuery(this.$route.query);
  }

  get typeOptions() {
    return [
      { value: null, label: 'Alla' },
      {
        value: EntityType.investor,
        label: 'Investerare',
      },
      {
        value: EntityType.insuranceOwner,
        label: 'Försäkringsägare',
      },
    ];
  }

  mounted() {
    if (!this.searchQuery.isEmpty()) {
      this.search();
    }
  }

  async search() {
    this.loading = true;

    this.$router.replace({
      query: this.searchQuery.asRequestParams(),
    });

    try {
      const searchResult = await search(this.searchQuery, { isPerson: true });
      this.$emit('search', searchResult);
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style lang="scss">
.PersonSearch {
  .search-form {
    max-width: 400px;
  }
}
</style>
