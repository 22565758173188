<template>
  <a-page-section header="Försäkringar" class="InsurancesTable">
    <q-table
      :rows="ownerships"
      :columns="columns"
      :pagination="pagination"
      row-key="id"
      flat
      hide-pagination
      hide-no-data
      class="standard-table"
    >
      <template v-slot:body-cell-insuranceNumber="props">
        <q-td :props="props">
          <router-link :to="props.row.viewUrl">{{
            props.row.insuranceNumber ?? 'Försäkringsnummer saknas'
          }}</router-link>
        </q-td>
      </template>
      <template v-slot:body-cell-actions="props">
        <q-td key="actions">
          <q-btn
            v-if="!props.row.isClosed"
            flat
            round
            color="grey-4"
            icon="more_vert"
            size="sm"
            class="test-insurance-row-more-dropdown"
          >
            <q-menu self="center left" anchor="center left" :offset="[70, 0]" content-class="a-tooltip shadow-1">
              <q-list>
                <q-item v-if="!props.row.isClosed" clickable v-close-popup @click="closeInsurance(props.row)">
                  <q-item-section class="test-close-insurance">Stäng försäkringen</q-item-section>
                </q-item>
                <q-item
                  v-if="!props.row.isClosed && ownerships.length > 1"
                  clickable
                  v-close-popup
                  @click="mergeInsurance(props.row)"
                >
                  <q-item-section>Slå ihop försäkring</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </q-td>
      </template>
      <template v-slot:bottom-row>
        <a-table-row-btn @click="addInsurance" label="Lägg till försäkring" />
      </template>
    </q-table>
  </a-page-section>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { Entity, Ownership } from '@/models';
import { openNewInsurance } from '@/actions';
import { QTableProps } from 'quasar';
import { findOwnershipsByInvestor, mergeOwnership } from '@/models/ownership/ownershipService';
import closeInsuranceModal from './closeInsurance';
import MergeOwnershipModal from '@/components/MergeOwnershipModal.vue';
import { MergeItems } from '@shared/models/types';

@Component({})
export default class InsurancesTable extends Vue {
  @Prop() investor: Entity;

  ownerships: Array<Ownership>;

  async mounted() {
    await this.loadData();
  }

  async loadData() {
    this.ownerships = await findOwnershipsByInvestor(this.investor);
  }

  addInsurance() {
    openNewInsurance({
      investor: this.investor,
    });
  }

  closeInsurance(ownership: Ownership) {
    closeInsuranceModal(ownership);
  }

  mergeInsurance(ownership: Ownership) {
    const filteredOwnerships = this.ownerships.filter(_ownership => _ownership !== ownership);

    this.$q.dialog({
      component: MergeOwnershipModal,
      componentProps: {
        targetedOwnershipId: ownership.id,
        ownerships: filteredOwnerships,
        onMerge: async (targetedId: string, selectedId: string) => {
          const _mergeOwnership: MergeItems = { targetedId, selectedId };

          await mergeOwnership(_mergeOwnership);
          await this.loadData();
        },
      },
    });
  }

  columns: QTableProps['columns'] = [
    {
      name: 'owner',
      field: 'owner',
      label: 'Försäkringsbolag',
      align: 'left',
      format: owner => owner?.viewName,
      classes: 'insurance-row-test-class',
    },
    {
      field: 'insuranceNumber',
      name: 'insuranceNumber',
      label: 'Försäkringsnummer',
      align: 'left',
    },
    {
      name: 'custodian',
      field: 'custodian',
      label: 'Depåinstitut',
      align: 'left',
      format: custodian => custodian?.viewName,
    },
    {
      field: 'custodianAccountNumber',
      name: 'custodianAccountNumber',
      label: 'Depånummer',
      align: 'left',
    },
    {
      field: insurance => insurance.status,
      name: 'status',
      label: 'Status',
      align: 'left',
    },
    {
      name: 'actions',
      field: null,
      label: '',
    },
  ];

  pagination = {
    rowsPerPage: 0,
    sortBy: 'owner',
  };
}
</script>
