<template>
  <a-section-footer v-if="model.lastActivity">
    <div class="text">{{ text }}</div>

    <q-btn label="Visa historik" type="a" color="primary" dense flat @click="viewHistory" />
  </a-section-footer>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';

import { ActivityHolder } from '@shared/web/models/activity/ActivityHolder';
import AllActivityModal from './AllActivityModal.vue';
import { activityMadeByAuthorText } from './utils';
@Component
export default class InstructionList extends Vue {
  @Prop() model: ActivityHolder;

  viewHistory() {
    this.$q.dialog({
      component: AllActivityModal,
      componentProps: {
        model: this.model,
      },
    });
  }

  get lastActivity() {
    return this.model.lastActivity;
  }
  get text() {
    return activityMadeByAuthorText(this.model.lastActivity);
  }
}
</script>
