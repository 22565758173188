<template>
  <q-page class="InstructionPage" v-if="ready">
    <div class="row">
      <div class="col">
        <h1>{{ header }} {{ isCorrection ? ' - Korrigering' : '' }}</h1>
      </div>

      <q-btn-group unelevated class="col col-md-auto action-buttons" v-if="instruction.isKapsureEvent">
        <q-btn
          label="Spara och genomför korrigering"
          v-bind="$defs.btn"
          @click="saveAndExecuteCorrection"
          v-if="isCorrection"
        />
        <q-btn label="Spara och genomför" v-bind="$defs.btn" @click="saveAndExecute" v-else-if="isNew" />
        <q-btn :label="correctButtonLabel" v-bind="$defs.btn" @click="correctInstruction" v-else-if="isExecuted" />
      </q-btn-group>

      <q-btn-group unelevated class="col col-md-auto action-buttons" v-else>
        <template v-if="instruction.state !== 'EXECUTED'">
          <template v-if="editMode">
            <q-btn
              v-bind="$defs.btn"
              color="red-5"
              class="margin-right-auto"
              @click="deleteInstruction"
              v-if="!isCreate"
              >Radera</q-btn
            >
            <q-btn label="Avbryt" v-bind="$defs.btn" @click="cancel" flat />
            <q-btn label="Spara" v-bind="$defs.btn" @click="save" />
          </template>
          <q-btn v-else label="Editera" icon="edit" v-bind="$defs.btn" @click="edit" />
        </template>
        <template v-else-if="validForSupplement">
          <q-btn v-bind="$defs.btn" class="margin-right-auto" @click="initSupplementInstruction"
            >Komplettera deltagare</q-btn
          >
        </template>

        <template v-if="!editMode">
          <q-btn
            :label="action.label"
            v-for="action in actionButtons"
            v-bind="$defs.btn"
            :key="action.state"
            @click="updateState(action.state)"
          />
          <portal-target name="instruction-buttons" />
        </template>
      </q-btn-group>
    </div>
    <q-form ref="form">
      <q-banner id="error-text" rounded class="bg-red-5 text-white q-mt-md" v-if="errorMessage">
        {{ getErrorText(errorMessage) }}
      </q-banner>
      <q-banner
        v-if="instruction.state === InstructionState.REQUEST_CHANGE && instruction.requestedChangeMessage"
        class="q-mt-md bg-orange-1 bg-orange-3"
      >
        begärd komplettering med kommentar: {{ instruction.requestedChangeMessage }}
      </q-banner>

      <IssueRightsContent
        :instruction="instruction"
        :shareRegister="shareRegister"
        :settleDateShareRegister="settleDateShareRegister"
        :validator="validator"
        v-if="instruction.type === 'RIGHTS_ISSUE'"
        :readonly="!editMode"
        @changeEntity="changeEntity"
      />

      <CloseInstrumentContent
        :instruction="instruction"
        :shareRegister="shareRegister"
        v-if="instruction.type === 'INSTRUMENT_CLOSED'"
        :readonly="!editMode"
      />

      <CloseShareRegisterContent
        :instruction="instruction"
        :shareRegister="shareRegister"
        v-if="instruction.type === 'SHAREREGISTER_CLOSED'"
        :readonly="!editMode"
        @changeEntity="changeEntity"
      />

      <IssueShareContent
        :instruction="instruction"
        :shareRegister="shareRegister"
        :settleDateShareRegister="settleDateShareRegister"
        v-if="instruction.type === 'ISSUE_SHARE'"
        :readonly="!editMode"
        @changeEntity="changeEntity"
      />

      <SplitContent
        :instruction="instruction"
        :shareRegister="shareRegister"
        :validator="validator"
        v-if="instruction.type === 'SPLIT'"
        :readonly="!editMode"
        @changeEntity="changeEntity"
      />

      <div v-if="instruction.isWarrant && instruction.entity && !shareRegisterLocked">
        <q-card style="background-color: red; color: #fff" class="q-pa-lg q-my-lg">
          Aktieboken är inte godkänd.<br />
          Teckningsoptionerna ska inte genomföras!
        </q-card>
      </div>

      <IssueWarrantContent
        :instruction="instruction"
        :shareRegister="shareRegister"
        :readonly="!editMode"
        :validator="validator"
        v-if="instruction.type === 'ISSUE_WARRANT'"
        @changeEntity="changeEntity"
      />

      <ExerciseWarrantContent
        :instruction="instruction"
        :shareRegister="shareRegister"
        :settleDateShareRegister="settleDateShareRegister"
        :readonly="!editMode"
        :validator="validator"
        v-if="instruction.type === 'EXERCISE_WARRANT'"
        @changeEntity="changeEntity"
      />

      <IssueConvertibleContent
        :instruction="instruction"
        :shareRegister="shareRegister"
        :readonly="!editMode"
        :entity="instruction.entity"
        v-if="instruction.type === 'ISSUE_CONVERTIBLE' || instruction.type === 'EXERCISE_CONVERTIBLE'"
        @changeEntity="changeEntity"
      />

      <IssueBondContent
        :instruction="instruction"
        :shareRegister="shareRegister"
        :readonly="!editMode"
        v-if="instruction.type === 'ISSUE_BOND' || instruction.type === 'EXERCISE_BOND'"
        @changeEntity="changeEntity"
      />

      <TradeEventContent
        :validator="validator"
        :instruction="instruction"
        :shareRegister="shareRegister"
        v-if="instruction.isTradeEvent"
        :readonly="!editMode"
        @changeEntity="changeEntity"
      />

      <KapsureEventContent
        :instruction="instruction"
        :shareRegister="shareRegister"
        v-if="instruction.isKapsureEvent"
        :readonly="!editMode && !isCorrection"
        @changeEntity="changeEntity"
      />

      <IssueDebentureContent
        :instruction="instruction"
        :shareRegister="shareRegister"
        :readonly="!editMode"
        v-if="instruction.type === 'ISSUE_DEBENTURE' || instruction.type === 'EXERCISE_DEBENTURE'"
        @changeEntity="changeEntity"
      />

      <SupplementContent
        :instruction="instruction"
        :shareRegister="shareRegister"
        :readonly="!editMode"
        v-if="instruction.type === 'SUPPLEMENT'"
        @changeEntity="changeEntity"
      />

      <IssueShareholderContributionContent
        :instruction="instruction"
        :shareRegister="shareRegister"
        :readonly="!editMode"
        v-if="instruction.type === 'ISSUE_SHAREHOLDER_CONTRIBUTION'"
        @changeEntity="changeEntity"
      />
    </q-form>

    <FileList :parent="instruction" class="mt-larger" />
  </q-page>
</template>
<script lang="ts">
import { Component, Watch, Vue } from 'vue-facing-decorator';
import { FormComponent } from '@shared/mixins';
import {
  findInstructionAndShareRegister,
  makeInstructionValidator,
  makeNewInstruction,
  saveInstruction,
  loadShareRegisterForInstruction,
  findNewInsuranceOwnersWithNoInvestor,
  loadShareRegisterForSettleDate,
  destroyInstruction,
} from '@/models/instruction/instructionService';
import { Instruction, ShareRegister, TransactionType } from '@/models';
import TradeEventContent from './tradeEvent/TradeEventContent.vue';
import IssueRightsContent from './corporateEvent/IssueRightsContent.vue';
import CloseShareRegisterContent from './corporateEvent/CloseShareRegisterContent.vue';
import CloseInstrumentContent from './corporateEvent/CloseInstrumentContent.vue';
import IssueWarrantContent from './warrantEvent/IssueWarrantContent.vue';
import IssueShareContent from './issueShare/IssueShareContent.vue';
import IssueConvertibleContent from './issueConvertible/IssueConvertibleContent.vue';
import ExerciseWarrantContent from './warrantEvent/ExerciseWarrantContent.vue';
import SplitContent from './split/SplitContent.vue';
import KapsureEventContent from './tradeEvent/KapsureEventContent.vue';
import { isSameDay, isToNewPage, setActiveNavigationModel } from '@/utils';
import { toTransactionTypeLabel } from '@shared/common';
import FileList from '@/views/file/FileList.vue';
import { findEntity } from '@/models/entity/entityService';
import store from '@/store';
import IssueBondContent from '@/views/instruction/issueBond/IssueBondContent.vue';
import IssueDebentureContent from '@/views/instruction/issueDebenture/issueDebentureContent.vue';
import SupplementContent from '@/views/instruction/supplement/SupplementContent.vue';
import IssueShareholderContributionContent from '@/views/instruction/issueShareholderContribution/IssueShareholderContributionContent.vue';
import { InstructionState } from '@shared/models/types';
import { isEmpty } from 'lodash';

import { openNewInstruction } from '@/actions';

const actionButtons = {
  EXECUTED: {
    state: InstructionState.EXECUTED,
    label: 'Genomför instruktion',
  },
  INTERIM_EXECUTED: {
    state: InstructionState.EXECUTED,
    label: 'Genomför instruktion (Konvertera BTA)',
  },
  EXECUTED_INTERIM: {
    state: InstructionState.EXECUTED_INTERIM,
    label: 'Genomför instruktion (Skapa BTA)',
  },
  REVIEW: {
    state: InstructionState.REVIEW,
    label: 'Påbörja granskning',
  },
  REQUEST_CHANGE: {
    state: InstructionState.REQUEST_CHANGE,
    label: 'Begär komplettering',
  },
  REVERTED: {
    state: InstructionState.REVERTED,
    label: 'Makulera',
  },
};

function getInsuranceOwner(instruction: Instruction) {
  if (!isEmpty(instruction.destinations)) {
    return instruction.destinations[0].ownership?.investor;
  }
  if (!isEmpty(instruction.sources)) {
    return instruction.sources[0].ownership?.investor;
  }
}

@Component({
  components: {
    IssueBondContent,
    IssueWarrantContent,
    IssueShareContent,
    ExerciseWarrantContent,
    IssueConvertibleContent,
    TradeEventContent,
    IssueRightsContent,
    SplitContent,
    FileList,
    KapsureEventContent,
    IssueDebentureContent,
    IssueShareholderContributionContent,
    CloseShareRegisterContent,
    SupplementContent,
    CloseInstrumentContent,
  },
  mixins: [FormComponent],
})
export default class InstructionPage extends Vue {
  instruction: Instruction = null;
  original: Instruction = null;
  shareRegister: ShareRegister = null;
  settleDateShareRegister: ShareRegister = null;
  editMode = false;

  isCreate = false;
  isCorrection = false;

  get InstructionState() {
    return InstructionState;
  }

  async created() {
    await this.loadData();
  }

  // @vue3 @Watch($route) körs även vid navigering från sidan, vilket inte sker i vue 2
  @Watch('$route')
  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }

    const { id } = this.$route.params;
    const isCreate = id === 'new';

    const { instruction, shareRegister } = isCreate
      ? await makeNewInstruction(store.newModelProps)
      : await findInstructionAndShareRegister(id);

    const settleDateShareRegister = await this.loadShareRegisterForSettleDate(instruction);
    instruction.entity && (await findEntity(instruction.entity.id));
    if (instruction.isKapsureEvent) {
      await setActiveNavigationModel(getInsuranceOwner(instruction));
    } else {
      await setActiveNavigationModel(instruction.entity);
    }

    Object.assign(this, {
      shareRegister,
      instruction,
      original: instruction,
      editMode: isCreate,
      isCreate,
      settleDateShareRegister,
    });
  }

  async changeEntity() {
    const { shareRegister, instruction } = await makeNewInstruction(this.instruction);
    Object.assign(this, { shareRegister, instruction });
  }

  @Watch('instruction.state')
  async onInstructionStateChange() {
    this.settleDateShareRegister = await this.loadShareRegisterForSettleDate();
  }

  @Watch('instruction.settleDate')
  async changeSettleDate(settleDate, oldDate) {
    if (oldDate != null && !isSameDay(settleDate, oldDate)) {
      await this.loadShareRegister();
      this.settleDateShareRegister = await this.loadShareRegisterForSettleDate();
    }
  }

  @Watch('instruction.corporateEvent.recordDate')
  async changeRecordDate(recordDate, oldDate) {
    if (oldDate != null && !isSameDay(recordDate, oldDate)) {
      await this.loadShareRegister();
      this.settleDateShareRegister = await this.loadShareRegisterForSettleDate();
    }
  }

  async correctInstruction() {
    this.isCorrection = true;
  }

  async loadShareRegister() {
    this.shareRegister = await loadShareRegisterForInstruction(this.instruction);
  }

  async loadShareRegisterForSettleDate(instruction = this.instruction) {
    if (
      [TransactionType.ISSUE_SHARE, TransactionType.RIGHTS_ISSUE, TransactionType.EXERCISE_WARRANT].includes(
        instruction?.type,
      )
    ) {
      return loadShareRegisterForSettleDate(instruction);
    }
    return null;
  }

  async confirmAndSaveInstruction() {
    const insuranceParties = findNewInsuranceOwnersWithNoInvestor(this.instruction);
    if (insuranceParties.length > 0) {
      await this.useConfirmModal({
        header: 'Spara ägarbyte',
        text: 'Det finns ägare som är försäkringsbolag, men som saknar investerare / försäkringsnummer. Är det korrekt?',
      });
    }

    return await this.saveInstruction();
  }

  async saveAndExecuteCorrection() {
    const currentState = this.instruction.state;
    this.instruction.state = InstructionState.CORRECTED;
    const result = await this.save();

    if (!result) {
      this.instruction.state = currentState;
    } else {
      this.isCorrection = false;
      this.$router.push({ path: result.viewUrl });
    }
  }

  async saveAndExecute() {
    const currentState = this.instruction.state;
    this.instruction.state = InstructionState.EXECUTED;
    const result = await this.save();
    if (!result) {
      this.instruction.state = currentState;
    } else {
      this.confirmationModal();
    }
  }

  async save() {
    const { model } = await this.submitForm(async () => {
      return this.confirmAndSaveInstruction();
    });
    if (model) {
      this.original = this.instruction = model;
      this.editMode = false;
      if (this.isCreate) {
        this.$router.push({ path: model.viewUrl });
      }
    }
    return model;
  }

  confirmationModal() {
    this.$q.dialog({
      title: `Klart! ${this.header} är genomförd!`,
      noRouteDismiss: true,
    });
  }

  async cancel() {
    this.editMode = false;
    this.instruction = this.original;
    this.errorMessage = null;
  }

  async deleteInstruction() {
    this.$q
      .dialog({
        title: 'Är du säker?',
        message: `Vill du ta bort instruktion?`,
        ok: 'Ta bort',
        cancel: 'Avbryt',
      })
      .onOk(async () => {
        await destroyInstruction(this.instruction);
        await this.useConfirmModal({
          text: 'Instruktionen är raderad',
          useConfirm: false,
        });
      });
  }

  get ready() {
    return this.instruction != null;
  }

  get validator() {
    return this.shareRegister && makeInstructionValidator(this.instruction, this.shareRegister);
  }

  get header() {
    return toTransactionTypeLabel(this.instruction.type);
  }

  get shareRegisterLocked() {
    return this.instruction.entity?.issuerData.locked;
  }

  get isExecuted() {
    return this.instruction.id && this.instruction.state === InstructionState.EXECUTED;
  }

  get isNew() {
    return this.instruction.state === InstructionState.NEW;
  }

  get validForSupplement() {
    return [
      TransactionType.ISSUE_BOND,
      TransactionType.ISSUE_CONVERTIBLE,
      TransactionType.ISSUE_DEBENTURE,
      TransactionType.ISSUE_WARRANT,
      TransactionType.SUPPLEMENT,
    ].includes(this.instruction.type);
  }

  get actionButtons() {
    const initialExecuteButton =
      this.instruction.isInterim && this.instruction.entity?.issuerData.hasShareRegister
        ? actionButtons.EXECUTED_INTERIM
        : actionButtons.EXECUTED;

    const state = this.instruction.state;
    if (state === InstructionState.NEW || state === InstructionState.SUBMITTED) {
      return [actionButtons.REVIEW];
    }
    if (state === InstructionState.REVIEW) {
      return [actionButtons.REQUEST_CHANGE, initialExecuteButton];
    }
    if (state === InstructionState.REQUEST_CHANGE || state === InstructionState.REVERTED) {
      return [initialExecuteButton];
    }
    if (state === InstructionState.EXECUTED_INTERIM && this.instruction.isInterim) {
      return [actionButtons.INTERIM_EXECUTED, actionButtons.REVERTED];
    }
    if (state === InstructionState.EXECUTED) {
      return [actionButtons.REVERTED];
    }
    return [];
  }

  async saveInstruction(state = false) {
    try {
      if (state) {
        return await saveInstruction(this.instruction, { state });
      }

      return await saveInstruction(this.instruction);
    } catch (e) {
      this.errorMessage = e;
    }
  }

  async updateState(state) {
    if (state === InstructionState.EXECUTED || state === InstructionState.REVERTED) {
      const message =
        state === InstructionState.EXECUTED
          ? 'Vill du verkligen genomföra?'
          : 'Vill du verkligen genomföra makulering?';
      this.$q
        .dialog({
          title: 'Är du säker?',
          message,
          ok: 'Ja, genomför',
          cancel: 'Nej, avbryt',
        })
        .onOk(async () => {
          return await this.saveInstruction(state);
        });
    } else if (state === InstructionState.REQUEST_CHANGE) {
      this.$q
        .dialog({
          title: 'Kommentar till företaget',
          ok: 'Begär komplettering',
          cancel: 'Avbryt',
          prompt: {
            model: this.instruction.requestedChangeMessage,
            type: 'text',
            isValid: val => !!val,
          },
        })
        .onOk(async value => {
          return await saveInstruction(this.instruction, { state, requestedChangeMessage: value });
        });
    } else {
      return await this.saveInstruction(state);
    }
  }

  get correctButtonLabel() {
    return this.instruction.type === TransactionType.KAPSURE_SETTLEMENT ? 'Korrigera utbokning' : 'Korrigera inbokning';
  }

  edit() {
    this.editMode = true;
    this.original = this.instruction;
    this.instruction = this.instruction.copy();
  }

  initSupplementInstruction() {
    const { entity, corporateEvent } = this.instruction;
    const instrument = corporateEvent.instrumentSources[0].instrument;
    openNewInstruction({ entity, type: TransactionType.SUPPLEMENT, instrument });
  }
}
</script>
