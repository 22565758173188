<template>
  <q-page class="PositionListPage">
    <h1>Innehav</h1>
    <PositionSearchWithResult />
  </q-page>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import PositionSearchWithResult from '@/views/positionList/PositionSearchWithResult.vue';

@Component({
  components: { PositionSearchWithResult },
})
export default class PositionListPage extends Vue {}
</script>
