import axios from 'axios';
import FileUpload from '@/models/investmentOffer/FileUpload';

export async function signFile(fileUpload, model) {
  const data = {
    filename: fileUpload.getName(),
    filetype: fileUpload.file.type,
    filesize: fileUpload.file.size,
    model: model.id,
    parentType: fileUpload.constructor.NAME,
  };
  const response = await axios.post('/api/investment-offer/signupload', data);
  return response.data;
}

export function uploadToAmazon(signedUrl, fileUpload: FileUpload) {
  return new Promise(function (resolve, reject) {
    const xhr = new XMLHttpRequest();

    xhr.open('PUT', signedUrl);
    xhr.setRequestHeader('x-amz-acl', 'private');
    xhr.onload = function () {
      if (xhr.status === 200) {
        resolve(null);
      }
    };
    xhr.onerror = function () {
      console.error('Could not upload file');
      reject();
    };

    xhr.send(fileUpload.file);
  });
}
