<template>
  <q-page class="TransactionListPage">
    <h1>Transaktioner</h1>
    <TransactionSearchWithResult />
  </q-page>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import TransactionSearchWithResult from '@/views/transaction/TransactionSearchWithResult.vue';

@Component({
  components: {
    TransactionSearchWithResult,
  },
})
export default class TransactionListPage extends Vue {}
</script>
