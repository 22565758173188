<template>
  <q-dialog ref="dialog">
    <div class="q-dialog-plugin CreateEntityModal">
      <template v-if="entity.isPerson == null">
        <q-btn label="Skapa Person" v-bind="$defs.btn" @click="entity.isPerson = true" />
        <q-btn label="Skapa Juridisk Person" v-bind="$defs.btn" @click="entity.isPerson = false" />
      </template>
      <template v-else>
        <p class="p2">Skapa {{ labels.title }}</p>
        <q-form @submit="search" class="q-gutter-md validation-form q-mt-xs" ref="form">
          <div>
            <q-radio
              v-model="entity.countryCode"
              @update:modelValue="clearError"
              val="SE"
              :label="labels.swedishEntity"
            />
            <q-radio
              v-model="entity.countryCode"
              @update:modelValue="clearError"
              :val="null"
              :label="labels.foreignEntity"
            />
          </div>
          <InputNationalId
            v-bind="$defs.input"
            :placeholder="labels.placeholder"
            class="q-mt-xs"
            :rules="nationalIdRules"
            v-model="entity.nationalId"
            :entity="entity"
            reactive-rules
            @focus="clearError"
            v-if="isSE"
          />
          <q-banner rounded class="bg-orange text-white" v-if="noPersonFound">
            Det gick inte att hitta en svensk person med angivet personnummer. Vill du ändå skapa en person utan att
            läsa in data från roaring?
          </q-banner>
          <q-banner rounded class="bg-red text-white" v-else-if="errorMessage">
            {{ getErrorText(errorMessage) }}
          </q-banner>
        </q-form>
        <q-card-actions align="right" class="q-px-none">
          <q-btn v-bind="$defs.btn" flat @click="cancel">Avbryt</q-btn>
          <q-btn
            label="Fortsätt skapa manuellt"
            type="submit"
            v-bind="$defs.btn"
            :loading="loading"
            @click="continueCreatePerson"
            v-if="noPersonFound"
          />
          <q-btn label="Fortsätt" type="submit" v-bind="$defs.btn" :loading="loading" @click="search" v-else />
        </q-card-actions>
      </template>
    </div>
  </q-dialog>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { DialogComponent, FormComponent } from '@shared/mixins';
import { validation } from '@shared/common';
import { lookupEntity } from '@/models/entity/entityService';
import { InputNationalId } from '@/components';
@Component({
  components: { InputNationalId },
  mixins: [DialogComponent, FormComponent],
})
export default class CreateEntityModal extends Vue {
  @Prop({ default: () => null }) isPerson: boolean;

  entity = null;
  nationalId: string;
  noPersonFound = false;

  created() {
    this.entity = {
      isPerson: this.isPerson,
      nationalId: null,
      countryCode: 'SE',
    };
  }

  clearError() {
    this.errorMessage = null;
  }
  get isSE() {
    return this.entity.countryCode === 'SE';
  }

  get labels() {
    const isPerson = this.entity.isPerson;
    return {
      title: isPerson ? 'fysisk person' : 'juridisk person',
      swedishEntity: isPerson ? 'Svensk person' : 'Svenskt bolag',
      foreignEntity: isPerson ? 'Utländsk person' : 'Utländskt bolag',
      placeholder: isPerson ? 'Personnummer' : 'Organisationsnummer',
    };
  }

  get nationalIdRules() {
    return validation.nationalId;
  }

  async continueCreatePerson() {
    this.$router.push({
      name: 'PersonPage',
      params: { id: 'new' },
      query: { nationalId: this.entity.nationalId, countryCode: 'SE' },
    });
  }

  async search() {
    let existingEntity;
    await this.submitForm(async () => {
      if (this.isSE) {
        try {
          existingEntity = await lookupEntity(this.entity);
        } catch (error) {
          if (error.key === 'roaring.noPersonFound') {
            this.noPersonFound = true;
          } else {
            throw error;
          }
        }
      }
    });
    if (this.noPersonFound) {
      return;
    }
    this.$router.push({
      name: this.entity.isPerson ? 'PersonPage' : 'LegalEntityPage',
      params: { id: this.isSE ? existingEntity.id : 'new' },
    });
  }
}
</script>
<style lang="scss">
.CreateEntityModal {
  .q-btn {
    display: block;
    margin: 10px;
  }
}
</style>
