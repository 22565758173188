<template>
  <tr :class="['DestinationRow', supplementRowClass]" v-if="!(isExecutedRightsIssue && !destination.quantity)">
    <td></td>
    <td class="text-left" v-if="columns.instrument">
      <router-link :to="destination.instrument.viewUrl" v-if="destination.instrument">{{
        destination.instrument.viewName
      }}</router-link>
    </td>
    <td class="text-left" v-if="columns.owner">
      <a-link :target="destination.owner" />

      <div v-if="totals.error" class="warning-error-message">{{ getErrorText(totals.error) }}</div>
    </td>

    <td class="text-left">
      <a-link :target="destination.investor" />
    </td>

    <td class="text-left">
      <InsuranceLink :instructionParty="destination" />
    </td>
    <td class="text-right" v-if="columns.ownedQuantity">
      {{ formatNumber(ownedQuantity) }}
    </td>
    <td class="text-right new-share" v-if="columns.allocatedQuantity">
      {{ formatNumber(allocatedQuantity) }}
    </td>
    <td class="text-right" v-if="columns.quantity">
      <a-input-number
        v-bind="$defs.input"
        input-class="text-right destination-issued"
        v-model="quantity"
        :readonly="readonly"
        :fractionDigits="0"
        @change="$emit('change')"
      />
    </td>
    <td class="text-right" v-if="columns.amount">
      <a-input-number
        :disable="!amountIsEditable"
        v-model="destination.amount"
        v-bind="$defs.input"
        input-class="text-right"
        @change="$emit('change')"
      />
    </td>
    <td class="q-table--col-auto-width" v-if="!readonly">
      <q-btn
        flat
        round
        color="primary"
        icon="more_vert"
        size="sm"
        id="more-menu"
        class="`test-action-button-${destination.id}`"
      >
        <q-menu>
          <q-list>
            <q-item clickable v-close-popup @click="edit" class="`test-action-button-edit`">
              <q-item-section>Editera</q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="remove">
              <q-item-section>Ta bort</q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
    </td>
  </tr>
</template>
<script lang="ts">
// @ts-nocheck
import { Instruction, InstructionParty, InstructionState, ShareRegister, TransactionType } from '@/models';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { validation } from '@shared/common';
import TableSettings from '@shared/web/models/shareRegister/TableSettings';
import EditPositionModal from '@shared/views/editPositionModal/EditPositionModal.vue';
import InsuranceLink from '@/views/insurance/InsuranceLink.vue';
import { removeDestination } from '@shared/web/models/instruction/instructionService';

@Component({
  components: { InsuranceLink },
})
export default class DestinationRow extends Vue {
  @Prop() destination: InstructionParty;
  @Prop() shareRegister: ShareRegister;
  @Prop() tableSettings: TableSettings;
  @Prop() instruction: Instruction;
  @Prop() readonly: boolean;
  @Prop() validator;
  @Prop() clickedButtons;
  @Prop() columns;

  get supplementRowClass(): string {
    return this.instruction.rowIsModified(this.destination)
      ? 'row-is-modified'
      : this.instruction.newRowAdded(this.shareRegister, this.destination)
      ? 'new-row-added'
      : '';
  }

  get quantity() {
    return this.destination.quantity;
  }

  set quantity(value) {
    this.destination.quantity = value;
    if (this.isAmountDistributedConvertible) {
      this.destination.amount = value * this.instruction.corporateEvent.rightsData.nominalAmountPerPiece;
    }
  }

  get isValueDistribution() {
    return this.destination.instrument?.isValueDistribution;
  }

  get totals() {
    return {};
  }

  get isConvertible() {
    return this.instruction.type === TransactionType.ISSUE_CONVERTIBLE;
  }
  get isBond() {
    return this.instruction.type === TransactionType.ISSUE_BOND;
  }

  get isShareholderContribution() {
    return this.instruction.type === TransactionType.ISSUE_SHAREHOLDER_CONTRIBUTION;
  }

  get isIssueDebenture() {
    return this.instruction.type === TransactionType.ISSUE_DEBENTURE;
  }

  get amountIsEditable() {
    if (this.readonly) {
      return false;
    }
    return this.instruction?.corporateEvent?.rightsData?.distributeByValue === true;
  }

  get isAmountDistributedConvertible() {
    return this.isConvertible && this.instruction?.corporateEvent?.rightsData?.distributeByValue === false;
  }

  get defaultOwners() {
    return this.shareRegister.getUniqueOwners();
  }
  get validation() {
    return validation;
  }
  get totalAmount() {
    return this.destination.quantity * this.instruction.price;
  }
  get isInsuranceCompanyOwner() {
    return this.destination.owner?.isInsuranceCompany;
  }
  get isOwnerLegalEntity() {
    return !this.destination.owner?.isPerson;
  }

  get partyValidation() {
    return this.validator.forParty(this.destination);
  }

  get ownedQuantity() {
    return this.partyValidation.totalQuantity;
  }

  get allocatedQuantity() {
    if (this.instruction.type === TransactionType.EXERCISE_WARRANT) {
      return this.destination.allocatedQuantity;
    }

    if (this.sourceValidation.ratio == null) {
      return null;
    }

    if (this.sourceValidation.ratio === 0) {
      return 0;
    }

    return this.ownedQuantity / this.sourceValidation.ratio;
  }

  get sourceValidation() {
    return this.validator.forInstrumentSource(this.destination.instrument);
  }

  get isExecutedRightsIssue() {
    return (
      this.instruction.type === TransactionType.RIGHTS_ISSUE &&
      [InstructionState.EXECUTED, InstructionState.EXECUTED_INTERIM].includes(this.instruction.state)
    );
  }

  changeOwner() {
    if (!this.isOwnerLegalEntity) {
      this.destination.investor = null;
    }
    if (!this.isInsuranceCompanyOwner) {
      this.destination.insuranceNumber = null;
    }
  }

  edit() {
    this.$q.dialog({
      component: EditPositionModal,
      componentProps: {
        positionClass: InstructionParty,
        value: this.destination,
        instruction: this.instruction,
        edit: true,
        label: 'deltagare',
        tradeEventForm: true,
        shareRegister: this.shareRegister,
      },
    });
  }

  remove() {
    removeDestination(this.instruction, this.destination);
  }
}
</script>
<style lang="scss">
tr.DestinationRow {
  &.row-is-modified {
    background-color: #ffc795;
  }
  &.new-row-added {
    background-color: #ccffaf;
  }
}
</style>
