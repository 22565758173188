<template>
  <q-header v-bind:class="[profile && !profile.loggedIn ? 'public-header' : 'bg-primary-5']">
    <q-toolbar class="Navbar">
      <q-btn padding="none" class="logo" flat unelevated to="/">
        <q-avatar :square="true">
          <svg>
            <use xlink:href="/kapclear-logo.svg#logo-avatar"></use>
          </svg>
        </q-avatar>
        <q-toolbar-title :shrink="true">
          <svg>
            <use xlink:href="/kapclear-logo.svg#logo-title"></use>
          </svg>
        </q-toolbar-title>
      </q-btn>
      <q-space />
      <NavSearch />
      <q-space />
      <q-btn
        flat
        round
        dense
        :icon="menuVisible ? 'close' : 'menu'"
        class="q-mr-sm menu-btn"
        :class="{ 'close-btn': menuVisible }"
        @click="menuVisible = !menuVisible"
      />

      <div class="menu" :class="{ active: menuVisible }">
        <template v-if="profile && profile.loggedIn">
          <q-btn round color="accent" unelevated text-color="primary-5" class="profile-btn q-mr-md" to="/">{{
            initialName
          }}</q-btn>
        </template>
      </div>
      <q-btn flat round icon="more_vert" class="q-px-sm">
        <q-menu anchor="bottom right" self="top right">
          <q-list>
            <q-item v-for="item in items" :href="item.url" :key="item.title">
              <q-item-section v-if="item.icon" avatar>
                <q-icon :name="item.icon" />
              </q-item-section>
              <q-item-section>{{ item.title }}</q-item-section>
            </q-item>

            <q-item>
              <div class="toggleIconMargins">
                <q-toggle
                  :false-value="appModes.DEFAULT"
                  :true-value="appModes.KAPSURE"
                  v-model="appMode"
                  @update:model-value="updateAppMode"
                />
              </div>
              <q-item-section>Kapsure mode</q-item-section>
            </q-item>
            <q-item clickable @click="logout">
              <q-item-section avatar>
                <q-icon name="logout" />
              </q-item-section>
              <q-item-section>Logga ut</q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
    </q-toolbar>
  </q-header>
</template>
<script lang="ts">
import store from '@/store';
import { logout } from '@/models/profileService';
import { Component, Vue, Watch } from 'vue-facing-decorator';
import nameInitials from 'name-initials';
import NavSearch from '@/components/NavSearch.vue';
import { getAppMode, setAppMode, AppModes } from '@/appMode';

@Component({
  components: { NavSearch },
})
export default class Navbar extends Vue {
  leftDrawerOpen = false;
  menuAnimation = false;
  menuVisible = false;
  appModes = AppModes;

  @Watch('$route')
  onRouteChange() {
    this.menuVisible = false;
  }

  get profile() {
    return store.profile;
  }

  async logout() {
    await logout();
    this.$router.push({ path: '/' });
  }

  get name() {
    return this.profile.user.name || this.profile.user.username;
  }

  get appMode() {
    return getAppMode();
  }

  updateAppMode(value) {
    setAppMode(value);
  }

  get initialName() {
    if (this.name != null) {
      return nameInitials(this.name);
    }
    return '';
  }

  get items() {
    return [
      {
        title: 'Dashboard',
        url: '/dashboard',
        icon: 'home',
      },

      {
        title: 'Sök',
        url: '/search/legal-entities',
        icon: 'search',
      },
      {
        title: 'Investerings Erbjudande',
        url: '/investment-offer',
        icon: 'local_offer',
      },
      {
        title: 'Valutakurser',
        url: '/valutakurser',
        icon: 'euro',
      },
      {
        title: 'System',
        url: '/system',
        icon: 'settings',
      },
    ];
  }
}
</script>
<style lang="scss">
.Navbar {
  height: 72px;
  max-width: 1360px;
  margin: 0 auto;

  .menu-btn {
    display: none;
  }
  .menu:not(.active) {
    display: flex;
    justify-content: space-between;
    justify-self: stretch;
  }
  .menu {
    height: 100%;
  }
  .q-tabs {
    flex: 0 1 auto;
    height: 100%;
    position: static;
    margin: 0 20px 0 auto;

    .q-tab {
      text-transform: none;
      margin-right: 3px;
      .q-tab__content {
        min-width: unset;
      }
      .q-tab__label {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }

  .logo {
    height: 100%;
    .q-btn__content {
      padding: 3px 10px 0;
      width: 220px;
    }
    .q-avatar {
      font-size: 54px;
    }
    svg {
      height: 54px;
      width: 127px;
    }
  }
  &.q-toolbar {
    padding-left: 7px;
  }
  .q-toolbar__title {
    overflow: visible;
    max-width: fit-content;
    .q-btn {
      height: 100%;
    }
    svg {
      fill: #fff;
    }
  }

  .profile-btn {
    font-size: 14px;
    margin: 0 12px 0 0;
    height: 32px;
    align-self: center;

    .q-btn__wrapper {
      min-width: 32px;
      min-height: 32px;
    }
  }
  .logout-btn {
    font-size: 12px;

    .q-btn__wrapper {
      white-space: nowrap;
    }
  }
}
.public-header {
  background-color: transparent !important;
  .Navbar {
    color: #000;
    .logo .q-toolbar__title svg {
      fill: #000;
    }
    .q-btn-group {
      height: 100%;
      margin-right: 10px;
      .q-btn__wrapper {
        padding: 0 10px;
        min-height: auto;
      }
      .q-btn {
        background-color: $dark;
        font-size: 12px;
        line-height: 28px;
        width: 80px;
        height: 28px;
        border-right: 1px solid #fff;
        align-self: center;
        + .q-btn {
          border-left: 1px solid #fff;
        }
      }
    }

    @media (max-width: 950px) {
      width: 100%;
      margin: 0;
      padding: 0;

      .menu-btn {
        display: block;
        z-index: 2;
        position: relative;
        &.close-btn {
          position: fixed;
          right: 10px;
        }
      }
      .menu {
        width: 100%;
        overflow: hidden;
        padding-left: calc(100% - 272px);
        background-color: transparent;
        position: absolute;
        right: 0;
        top: 0;
        padding-bottom: 40px;
        visibility: hidden;
        height: 100vh;
        transition: background-color 0.5s cubic-bezier(0.82, 0.085, 0.395, 0.895);
        .q-tabs {
          right: -272px;
          transition: right 0.5s cubic-bezier(0.82, 0.085, 0.395, 0.895);
        }
        .q-btn-group {
          left: 272px;
          margin-right: 0;
          transition: left 0.5s cubic-bezier(0.82, 0.085, 0.395, 0.895);
        }

        &.active {
          visibility: visible;
          position: fixed;
          background-color: rgba(0, 0, 0, 0.5);
          transition: background-color 0.5s cubic-bezier(0.82, 0.085, 0.395, 0.895);

          .q-tabs {
            right: 0;
            transition: right 0.5s cubic-bezier(0.82, 0.085, 0.395, 0.895);
          }
          .q-btn-group {
            margin-top: 0;
            position: relative;
            top: -50px;
            left: 32px;
            right: auto;
            height: auto;
            transition: left 0.5s cubic-bezier(0.82, 0.085, 0.395, 0.895);
          }
        }
      }

      .q-tabs {
        background-color: white;
        width: 272px;
        padding: 50px 0;
        margin: 0;
        list-style: none;
        display: block !important;
        position: relative;
        right: 0;
        height: 100vh;

        .q-tab {
          -webkit-flex-direction: column !important;
          flex-direction: column !important;
          float: left !important;
          text-transform: none;
          width: 100%;
          padding-left: 32px;
        }
        .q-tab__content {
          align-items: flex-start;
          text-align: left !important;
        }
        .q-tabs__content {
          display: block !important;
        }
      }
      .q-btn-group {
        margin-top: 20px;
      }
    }
  }
}
.toggleIconMargins {
  margin-left: -15px;
  margin-right: 15px;
}
</style>
