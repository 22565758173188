import { Instrument, Position } from '@/models';
import { uniq } from 'lodash';

export type PositionAndAccumulatedValues = {
  quantity: number;
  currentValue?: number;
  instrument: Instrument | null;
};

export function buildPositionRows(positions: Position[]): PositionAndAccumulatedValues[] {
  const instruments: Instrument[] = uniq(positions.map(position => position.instrument));

  const instrumentRows = instruments.flatMap(instrument => {
    const instrumentPositions = positions.filter(pos => pos.instrument === instrument);

    return [mapInstrument(instrument, instrumentPositions), ...instrumentPositions];
  });

  return instrumentRows;
}

function mapInstrument(instrument: Instrument, instrumentPositions: Position[]) {
  const accumulatedValues = instrumentPositions.reduce(
    (acc, position) => {
      return {
        quantity: acc.quantity + position.quantity,
        currentValue: acc.currentValue + position.currentValue,
        acquisitionValue: isNaN(position.acquisitionValue) ? null : acc.acquisitionValue + position.acquisitionValue,
      };
    },
    {
      currentValue: 0,
      quantity: 0,
      acquisitionValue: 0,
    },
  );

  return {
    ...accumulatedValues,
    instrument,
  };
}
