<template>
  <q-dialog ref="dialog" persistent>
    <div class="q-dialog-plugin MergeEntityModal">
      <h4 class="table-header">
        Personen som du kryssar i här försvinner och slås ihop med den personen du valde i tidigare vy:
        <div class="targeted-entity">
          <div>{{ formatNationalId(targetedEntity) }}</div>
          <div>{{ entityName }}</div>
          <div>{{ types }}</div>
          <div>{{ countryCode }}</div>
        </div>
      </h4>
      <MergePersonSearch @search="onSearch" />
      <q-table
        :rows="filteredPersons"
        :columns="columns"
        :pagination="pagination"
        row-key="id"
        flat
        hide-no-data
        hideSelectedBanner
        class="standard-table"
        selection="single"
        v-model:selected="selected"
        :onSelection="onSelection"
      >
        <template v-slot:body-cell-insuranceNumber="props">
          <q-td :props="props">
            <router-link :to="props.row.viewUrl">{{ props.value }}</router-link>
          </q-td>
        </template>
        <template v-slot:bottom-row>
          <q-tr>
            <q-td colspan="100%" align="right">
              <q-btn size="md" v-bind="$defs.btn" flat @click="closeModal" label="Avbryt" />
              <q-btn
                flat
                color="grey-2"
                textColor="primary"
                label="Slå ihop person"
                size="md"
                @click="merge"
                :disabled="selected.length === 0"
              />
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
  </q-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-facing-decorator';
import { Entity } from '@shared/web/models';
import MergePersonSearch from '@/views/personList/MergePersonSearch.vue';
import { QTableProps } from 'quasar';
import { formatNationalId, formatTypeList } from '@shared/common';
import { MergeItems } from '@shared/models/types';
import { mergeEntities } from '@/models/entity/entityService';

@Component({
  components: {
    MergePersonSearch,
  },
})
export default class MergeEntityModal extends Vue {
  @Prop() targetedEntity: Entity;
  @Prop() previousSearchList: Entity[];
  @Prop() header: string;
  @Prop() onMerge: Function;
  allPersons: Entity[] = [];
  searchResults: Entity[] = [];
  selectedEntity: Entity | null = null;

  selected = [];
  selectedObject = null;

  get filteredPersons(): Entity[] {
    if (this.searchResults) {
      return this.searchResults.filter(result => result.id !== this.entityId);
    }

    return this.previousSearchList;
  }

  onSearch(results: Entity[]) {
    this.searchResults = results;
    this.selectedEntity = null;
  }

  onSelection(selectedItem) {
    if (selectedItem && selectedItem.rows.length > 0) {
      this.selectedEntity = selectedItem.rows[0];
    } else {
      this.selectedEntity = null;
    }
  }

  async merge() {
    if (this.selectedEntity !== null) {
      const _mergeEntities: MergeItems = { targetedId: this.entityId, selectedId: this.selectedEntity.id };
      await mergeEntities(_mergeEntities);
      this.onMerge && this.onMerge();
    }
    this.closeModal();
  }

  get entityId() {
    return this.targetedEntity.id;
  }

  get entityName() {
    return this.targetedEntity.viewName;
  }

  get countryCode() {
    return this.targetedEntity.countryCode;
  }

  get types() {
    return formatTypeList(this.targetedEntity.types);
  }

  @Watch('$route')
  closeModal() {
    this.$emit('hide');
  }

  pagination = {
    rowsPerPage: 10,
    sortBy: 'viewName',
  };

  columns: QTableProps['columns'] = [
    {
      field: person => formatNationalId(person),
      label: 'Person/bolagsnr',
      name: 'nationalId',
      align: 'left',
      classes: 'q-table--col-auto-width',
    },
    {
      name: 'viewName',
      field: 'viewName',
      label: 'Namn',
      align: 'left',
    },
    {
      name: 'types',
      field: row => row.types,
      label: 'Typ',
      align: 'left',
      format: types => {
        return formatTypeList(types);
      },
      sortable: true,
    },
    {
      name: 'countryCode',
      field: 'countryCode',
      label: 'Land',
      align: 'left',
    },
    {
      name: 'menu',
      label: '',
      field: null,
      classes: 'q-table--col-auto-width',
    },
  ];
}
</script>

<style lang="scss">
.MergeEntityModal {
  min-width: 1000px;

  .table-header {
    display: grid;
    gap: 0.5rem;
  }
  .targeted-entity {
    display: flex;

    background: #2c612c;
    max-width: fit-content;
    gap: 1rem;
    padding: 0.5rem 1rem;
    color: #f7f8f7;
    font-weight: 600;
    span {
      letter-spacing: 1px;
    }
  }
  .search {
    min-height: 200px;
  }
}
</style>
