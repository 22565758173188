<template>
  <q-markup-table flat class="standard-table InterimDestinationsTable" separator="horizontal">
    <thead>
      <tr>
        <th class="text-left holder-name-th"></th>
        <SortableTH property="instrument" :tableSettings="tableSettings" v-if="columns.instrument">Aktie</SortableTH>
        <SortableTH property="owner" :tableSettings="tableSettings" v-if="columns.owner">Ägare</SortableTH>

        <SortableTH property="investor" :tableSettings="tableSettings">Försäkringsägare</SortableTH>

        <th class="text-left">Förs.nr</th>
        <th class="text-right number">Aktienummer</th>
        <th class="text-right number">Antal</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="{ holder, positions } in positionGroups" :key="holder?.id">
        <HolderRow :holder="holder" v-if="holder" />

        <template v-for="destination in positions" :key="destination.id">
          <InterimDestinationRow
            :destination="destination"
            :instruction="instruction"
            :tableSettings="tableSettings"
            :columns="columns"
            @change="$emit('change')"
          />
        </template>
      </template>
    </tbody>
  </q-markup-table>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';

import { Instruction } from '@/models';
import { getPositionGroups } from '@shared/shareRegister/utils';

import InterimDestinationRow from './InterimDestinationRow.vue';
import HolderRow from './HolderRow.vue';
import SortableTH from './SortableTH.vue';
import TableSettings from '@shared/web/models/shareRegister/TableSettings';
import { isSameModel } from '@shared/utils';

@Component({
  components: { HolderRow, InterimDestinationRow, SortableTH },
})
export default class InterimDestinationsTable extends Vue {
  @Prop() tableSettings: TableSettings;
  @Prop() instruction: Instruction;
  @Prop() disableAdd: boolean;

  get positionGroups() {
    const positions = this.instruction.interimPositions || [];
    const instruments = [];
    positions.forEach(position => {
      if (!instruments.find(instrument => isSameModel(instrument, position.instrument))) {
        instruments.push(position.instrument);
      }
    });
    return getPositionGroups(positions, instruments, this.tableSettings);
  }

  get columns() {
    const { groupProperty, isInvestorRegister } = this.tableSettings;
    return {
      owner: groupProperty !== 'owner' || isInvestorRegister,
      instrument: groupProperty !== 'instrument',
    };
  }
}
</script>
<style lang="scss">
.InterimDestinationsTable {
  .q-input.number {
    width: 80px;
    margin-left: auto;
  }
  .holder-row {
    background: #f0f0f0;
  }
  .holder-name-th {
    width: 100px;
  }
}
</style>
