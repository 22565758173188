<template>
  <q-dialog ref="dialog">
    <div class="q-dialog-plugin EditAdministratorModal">
      <h4 class="table-header">{{ isCreate ? 'Lägg till' : 'Editera' }} administratör</h4>
      <q-banner rounded class="bg-red text-white q-mb-md" v-if="errorMessage">
        {{ getErrorText(errorMessage) }}
      </q-banner>
      <q-form @submit="done">
        <EntityAdminForm
          :is-create="isCreate"
          :entity="entity"
          :entity-admin="admin"
          v-model:personal-number="personalNumber"
          v-model:phone="phone"
          v-model:email="email"
          v-model:role="currentRoleValue"
          v-model:can-login-with-email="canLoginWithEmail"
          v-model:access="access"
        />
        <q-card-actions align="right">
          <q-btn :flat="true" @click="cancel">Avbryt</q-btn>
          <q-btn type="submit">Spara</q-btn>
        </q-card-actions>
      </q-form>
    </div>
  </q-dialog>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { Entity, EntityAdmin } from '@/models';
import { DialogComponent, FormComponent } from '@shared/mixins';
import { validation } from '@shared/common';
import { EntityAdminRole, EntityType } from '@shared/models/types';
import { toEntityAdminRoleLabel } from '@shared/common';
import { lookupUser, save as saveAdmin } from '@/models/entityAdmin/entityAdminService';
import EntityAdminForm from '@shared/views/entityAdmin/EntityAdminForm.vue';

@Component({
  components: { EntityAdminForm },
  mixins: [DialogComponent, FormComponent],
})
export default class AdministratorFormModal extends Vue {
  @Prop({ default: () => new EntityAdmin() }) admin?: EntityAdmin;
  @Prop({ default: null }) entity: Entity;

  personalNumber: string = '';
  currentRoleValue: EntityAdminRole = null;
  currentRoleLabel: string = null;
  email: string = '';
  phone: string = '';
  canLoginWithEmail = false;
  access: 'limited' | 'full';

  mounted() {
    this.personalNumber = this.admin?.user?.personalNumber;
    this.email = this.admin?.email;
    this.phone = this.admin?.phone;
    this.canLoginWithEmail = this.admin?.user?.canLoginWithEmail;
    this.updateRole(this.admin?.role);
    this.email = this.admin.email;
    this.phone = this.admin.phone;
    this.access = this.admin.limitedAccess ? 'limited' : 'full';
  }

  get isCreate(): boolean {
    return !this.admin.id;
  }

  get validation() {
    return validation;
  }

  get entityAdminRoles() {
    let roles: EntityAdminRole[] = [];
    if (this.entity.isPerson) {
      roles = [EntityAdminRole.Unspecified, EntityAdminRole.Accountant];
    } else {
      const isCustodian = this.entity.types.includes(EntityType.participant);
      roles = isCustodian
        ? Object.values(EntityAdminRole)
        : Object.values(EntityAdminRole).filter(role => role !== EntityAdminRole.CustodianAdmin);
    }

    return roles.map(role => ({
      value: role,
      label: toEntityAdminRoleLabel(role),
    }));
  }

  updateRole(roleValue: EntityAdminRole) {
    this.currentRoleValue = roleValue;
    this.currentRoleLabel = toEntityAdminRoleLabel(this.currentRoleValue);
    this.admin.role = roleValue;
  }

  handleRoleChange({ value }: { value: EntityAdminRole }) {
    this.updateRole(value);
  }

  toEntityAdminRoleLabel(role) {
    return toEntityAdminRoleLabel(role);
  }

  async done() {
    if (this.personalNumber) {
      this.admin.user = await lookupUser(this.personalNumber);
    }
    this.admin.phone = this.phone;
    this.admin.email = this.email;

    this.admin.canLoginWithEmail = this.canLoginWithEmail;
    this.admin.limitedAccess = this.access === 'limited';
    this.admin.role = this.currentRoleValue;
    this.admin.entity = this.entity;
    await saveAdmin(this.admin);
    this.$emit('ok', { admin: this.admin });
    this.hide();
  }
}
</script>
