import axios from 'axios';
import axiosRetry from 'axios-retry';

import { getToken } from '@/authentication';
import { acquireToken } from './models/auth/msalProvider';

axiosRetry(axios, {
  retries: 1,
  retryCondition(error) {
    return error.response.status === 401;
  },
  onRetry: async (retryCount, error, requestConfig) => {
    console.log('retry ' + requestConfig.url);
    await acquireToken();
  },
});

axios.defaults.baseURL = import.meta.env.VITE_APP_API_BASE_URL;

axios.interceptors.request.use(
  config => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return Promise.resolve(config);
  },
  error => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  res => res,
  err => {
    const status = err.response?.status;
    if (status === 400) {
      const data = err.response.data;

      if (data && data.text) {
        throw data;
      }
    }
    if (status === 401) {
      throw { text: 'Du har blivit utloggad', ...err };
    }
    console.error(err);
    throw { text: 'Ett fel uppstod', ...err };
  },
);
