<template>
  <q-markup-table flat class="standard-table" separator="horizontal">
    <thead>
      <tr>
        <th class="text-left">Ägare</th>
        <th class="text-left">Investerare</th>
        <th class="text-left">Förbehåll</th>
        <th class="text-right">Aktienummer</th>
        <th class="text-right number">Innehav</th>
        <th class="text-right">{{ isDistributedByValue ? 'Nominellt belopp' : 'Antal som säljs' }}</th>
        <th class="text-right">{{ isDistributedByValue ? 'Pris' : 'Pris per värdepapper' }}</th>
        <th class="text-right number">Total likvid</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="text-left source-owner">
          <a-link :target="source.owner" v-if="readonly" />
          <SelectEntity
            v-model="source.owner"
            v-bind="$defs.input"
            :rules="validation.required('Ägare')"
            :hideCreate="true"
            :defaultOwners="defaultOwners"
            @update:modelValue="changeOwner"
            v-else
          />
        </td>
        <td class="text-left">
          <a-link :target="source.investor" v-if="readonly" />
          <SelectEntity
            v-model="source.investor"
            v-bind="$defs.input"
            :hideCreate="true"
            :defaultOwners="possibleInvestors"
            @update:modelValue="changeInvestor"
            v-else-if="possibleInvestors.length > 1"
          />
        </td>
        <td class="text-left"></td>
        <td class="text-left"></td>
        <td class="text-right">
          <span v-if="isDistributedByValue">{{ formatNumber(available, 2, 2) }}</span>
          <span v-else>{{ formatNumber(available) }}</span>
        </td>

        <td class="text-right" v-if="isDistributedByValue">
          {{ formatNumber(source.amount, 2, 2) }}
        </td>
        <td class="text-right" v-if="!isDistributedByValue">{{ formatNumber(source.quantity) }}</td>

        <td class="text-right">
          <a-input-number
            v-bind="$defs.input"
            input-class="text-right price"
            v-model="instruction.price"
            :readonly="readonly"
            :fractionDigits="2"
          />
        </td>
        <td class="text-right">
          {{ formatNumber(totalAmount, 2, 2) }}
        </td>
      </tr>
      <tr v-for="sourcePosition in tradeEvent.sourcePositions" :key="sourcePosition.position.id">
        <td></td>
        <td>
          {{ sourcePosition.position.investor && sourcePosition.position.investor.viewName }}
        </td>
        <td class="text-left reservations">
          <div v-for="reservation in sourcePosition.position.reservations" :key="reservation">
            {{ toReservationLabel(reservation) }}
          </div>
        </td>
        <td class="text-right">
          {{ shareNumbers(sourcePosition.position) }}

          <p class="nationalid-small" v-if="sourcePosition.position.pledgeOwner">Aktiepost är pantsatt</p>
          <p class="nationalid-small" v-if="sourcePosition.position.certificateIssued">Aktiebrev är utfärdat</p>
        </td>
        <td class="text-right">{{ formatNumber(sourcePosition.position.quantity) }}</td>
        <td class="text-right">
          <a-input-number
            :minimumFractionDigits="0"
            :maximumFractionDigits="0"
            v-if="!isDistributedByValue"
            v-model="sourcePosition.quantity"
            input-class="text-right test-source-row-quantity-sold"
            v-bind="$defs.input"
            :readonly="readonly"
            @change="changeQuantity"
          />
          <a-input-number
            :minimumFractionDigits="2"
            :maximumFractionDigits="2"
            v-if="isDistributedByValue"
            v-model="sourcePosition.amount"
            :rules="[
              () =>
                sourcePosition.amount <= sourcePosition.position.amount ||
                `Kan inte vara större än ${sourcePosition.position.amount}`,
            ]"
            input-class="text-right test-source-row-amount-sold"
            v-bind="$defs.input"
            :readonly="readonly"
            @change="changeAmount"
          />
        </td>
        <td colspan="10"></td>
      </tr>
    </tbody>
  </q-markup-table>
</template>
<script lang="ts">
import { Instruction, ShareRegister, Position } from '@/models';
import { SourcePosition } from '@shared/web/models/instruction/TradeEvent';
import { getInvestorsByOwner } from '@shared/shareRegister/utils';
import { filterPositions, getUniqueOwners } from '@shared/shareRegister/utils';
import SelectEntity from '@/views/entity/SelectEntity.vue';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { validation, toReservationLabel } from '@shared/common';
import { formatNumber } from '@/utils';
import * as _ from 'lodash';
@Component({
  components: { SelectEntity },
})
export default class SourceRow extends Vue {
  @Prop() shareRegister: ShareRegister;
  @Prop() instruction: Instruction;
  @Prop() readonly: boolean;
  @Prop() validator;

  toReservationLabel(val) {
    return toReservationLabel(val);
  }

  shareNumbers(position: Position) {
    const { shareNumberFrom, shareNumberTo } = position;
    if (shareNumberFrom && shareNumberTo) {
      return `${formatNumber(shareNumberFrom)}-${formatNumber(shareNumberTo)}`;
    }
    return null;
  }

  get tradeEvent() {
    return this.instruction.tradeEvent;
  }

  get source() {
    return this.instruction.tradeEvent.source;
  }

  get defaultOwners() {
    return getUniqueOwners(this.shareRegister.positions, this.source.instrument);
  }

  get isDistributedByValue() {
    return this.instruction?.tradeEvent?.source?.instrument?.rightsData?.isValueDistribution();
  }

  get available() {
    if (this.isDistributedByValue) {
      return _.sumBy(this.tradeEvent.sourcePositions, sourcePosition => sourcePosition.position.amount);
    }
    return _.sumBy(this.tradeEvent.sourcePositions, sourcePosition => sourcePosition.position.quantity);
  }

  get validation() {
    return validation;
  }
  get totalAmount() {
    if (this.isDistributedByValue) {
      return this.instruction.price || this.source.quantity;
    }
    return this.source.quantity * this.instruction.price;
  }
  get possibleInvestors() {
    return getInvestorsByOwner(this.shareRegister.positions, this.source.owner);
  }

  get isInsuranceCompanyOwner() {
    return this.source.owner?.isInsuranceCompany;
  }

  getMatchingPositionsForCustodian() {
    const { owner, investor, instrument } = this.source;
    return this.validator.getPartlyMatchingPositions({ owner, investor, instrument });
  }

  changeOwner() {
    Object.assign(this.source, {
      investor: null,
      quantity: null,
    });
    this.changeInvestor();
  }
  changeInvestor() {
    const { instrument, owner, investor } = this.source;
    const positions = filterPositions(this.shareRegister.positions, { instrument, owner, investor });
    this.tradeEvent.sourcePositions = positions.map(position => SourcePosition.newModel({ position }));
  }

  changeQuantity() {
    this.source.quantity = _.sumBy(this.tradeEvent.sourcePositions, sourcePosition => sourcePosition.quantity);
  }

  changeAmount() {
    this.source.amount = _.sumBy(this.tradeEvent.sourcePositions, sourcePosition => sourcePosition.amount);
    this.$emit('change');
  }
}
</script>
