<template>
  <div class="TradeEventContent">
    <a-page-section class="padded">
      <div class="row q-col-gutter-x-xl">
        <q-list separator class="standard-list col-md-6">
          <a-list-item label="Emittent">
            <a-link :target="instruction.entity" v-if="readonly" />
            <SelectEntity
              v-model="instruction.entity"
              entityType="issuer"
              v-bind="$defs.input"
              :readonly="instruction.entity != null"
              :hideCreate="true"
              @update:modelValue="$emit('changeEntity')"
              v-else
            />
          </a-list-item>
          <a-list-item label="Aktie">
            <a-link :target="tradeEvent.source.instrument" v-if="readonly" />
            <a-select
              v-model="tradeEvent.source.instrument"
              v-bind="$defs.input"
              :readonly="readonly"
              :options="shares"
              map-options
              option-label="viewName"
              :rules="validation.required('Aktie')"
              v-else
            />
          </a-list-item>
          <a-list-item label="Transaktionstyp">
            <a-select
              v-bind="$defs.input"
              v-model="instruction.type"
              :options="typeOptions"
              :readonly="readonly"
              :rules="validation.required('Transaktionstyp')"
              emit-value
              map-options
            ></a-select>
          </a-list-item>
          <a-list-item class="notes" label="Anteckningar">
            <q-input v-model="instruction.notes" autogrow filled :readonly="readonly" />
          </a-list-item>
        </q-list>
        <q-list separator class="standard-list col-md-6">
          <a-list-item label="Affärsdag">
            <a-input-date
              v-model="tradeEvent.tradeDate"
              v-bind="$defs.input"
              :readonly="readonly"
              :rules="validation.required('Affärsdag')"
            />
          </a-list-item>
          <a-list-item label="Införd i aktieboken">
            <a-input-date v-model="instruction.settleDate" v-bind="$defs.input" :readonly="readonly" />
          </a-list-item>
          <a-list-item label="Registreringsdag">
            <a-input-date v-model="instruction.registrationDate" v-bind="$defs.input" :readonly="true" />
          </a-list-item>
          <a-list-item label="Status">
            {{ toInstructionStateLabel(instruction.calculatedState) }}
          </a-list-item>
          <p v-if="instruction.warning">
            Det finns pågående nyemission - med avstämningsdag före datum för införande i aktieboken.
          </p>
          <LastActivity :model="instruction" />
        </q-list>
      </div>
    </a-page-section>
    <a-page-section header="Från" v-if="shareRegister">
      <SourceRow
        :shareRegister="shareRegister"
        :instruction="instruction"
        :readonly="readonly"
        :validator="validator"
      />
    </a-page-section>
    <a-page-section header="Till" v-if="shareRegister">
      <q-markup-table flat class="standard-table" separator="horizontal">
        <thead>
          <tr>
            <th class="text-left">Ägare</th>
            <th class="text-left">Investerare</th>
            <th class="text-left">Förs.nr</th>
            <th class="text-left">Förvar</th>
            <th class="text-left">Depånummer</th>
            <th class="text-right number">{{ isValueDistributed ? 'Nominellt belopp' : 'Antal' }}</th>
            <th class="text-right number">Pris</th>
            <th class="text-right number">Likvid</th>
            <th v-if="!readonly"></th>
          </tr>
        </thead>
        <tbody>
          <DestinationRow
            v-for="(destination, index) in instruction.destinations"
            :key="index"
            :destination="destination"
            :instruction="instruction"
            :shareRegister="shareRegister"
            :readonly="readonly"
          />
          <a-table-row-btn @click="addDestination()" label="Lägg till köpare" v-if="!readonly" />
        </tbody>
      </q-markup-table>
    </a-page-section>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';
import SourceRow from './SourceRow.vue';
import DestinationRow from './DestinationRow.vue';
import { Instruction, Instrument, ShareRegister, InstructionParty } from '@/models';
import { options, toInstructionStateLabel } from '@shared/common';
import SelectEntity from '@/views/entity/SelectEntity.vue';
import { validation } from '@shared/common';
import LastActivity from '@/views/activity/LastActivity.vue';
import EditPositionModal from '@shared/views/editPositionModal/EditPositionModal.vue';
@Component({
  components: {
    SourceRow,
    DestinationRow,
    SelectEntity,
    LastActivity,
  },
})
export default class TradeEventContent extends Vue {
  @Prop() instruction: Instruction;
  @Prop() shareRegister: ShareRegister;
  @Prop({ default: true }) readonly: boolean;
  @Prop() validator;

  toInstructionStateLabel(val) {
    return toInstructionStateLabel(val);
  }

  get shares(): Array<Instrument> {
    return this.shareRegister && this.shareRegister.instruments;
  }

  get isValueDistributed() {
    return this.instruction?.tradeEvent?.source?.instrument?.rightsData?.isValueDistribution();
  }

  addDestination() {
    const data = { instrument: this.instruction.tradeEvent.source.instrument, entity: this.shareRegister.entity };
    this.$q.dialog({
      component: EditPositionModal,
      componentProps: {
        value: data,
        positionClass: InstructionParty,
        label: 'köpare',
        tradeEventForm: true,
        instruction: this.instruction,
        shareRegister: this.shareRegister,
      },
    });
  }

  get options() {
    return options;
  }

  get tradeEvent() {
    return this.instruction.tradeEvent;
  }

  get typeOptions() {
    return options.instructionTypeOptions;
  }

  get validation() {
    return validation;
  }

  @Watch('instruction.tradeEvent.source.instrument')
  changeInstrument(instrument) {
    this.tradeEvent.source.instrument = instrument;
    this.instruction.destinations.forEach(dest => (dest.instrument = instrument));
  }
}
</script>
<style lang="scss">
.TradeEventContent {
  .q-markup-table {
    overflow: initial;
  }
  th.number {
    width: 12%;
  }
  .notes {
    > .q-item__section {
      margin-right: -9px;
    }
    .q-field__control {
      margin-right: 8px;
      height: auto;
    }
    .q-field {
      height: auto;
    }
  }
}
</style>
