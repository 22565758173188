<template>
  <a-page-section class="InstrumentBasics padded">
    <div class="row q-col-gutter-x-xl">
      <q-list :separator="true" class="standard-list col-md-6">
        <a-list-item label="Namn">
          <q-input
            v-bind="inputAttributes"
            v-model="instrument.name"
            :rules="validation.name"
            :readonly="readonlyShareData"
        /></a-list-item>
        <a-list-item label="Kortnamn">
          <q-input v-bind="inputAttributes" v-model="instrument.shortName" />
        </a-list-item>

        <a-list-item label="Valuta">
          <SelectCurrency
            v-model="instrument.currencyCode"
            v-bind="inputAttributes"
            :rules="validation.required('Valuta')"
            :readonly="readonlyShareData"
          />
        </a-list-item>

        <template v-if="instrument.rightsData && instrument.rightsData.share">
          <a-list-item label="Aktie">
            <router-link :to="instrument.rightsData.share.viewUrl">{{ instrument.rightsData.share.name }} </router-link>
          </a-list-item>
        </template>

        <a-list-item :label="idType.label" v-for="idType in activeIdTypes" :key="idType.value">
          <a-blur-input
            v-bind="inputAttributes"
            v-model="instrument[idType.value]"
            lazy-rules
            :readonly="idType.readonly"
            :rules="idType.value === 'ISIN' ? validation.ISIN : null"
            :data-testid="`instrument-type-${idType.value}`"
          />
        </a-list-item>

        <AddInstrumentId :instrument="instrument" v-if="!readonly" @add="addId" />

        <a-list-item label="Värderat pris">
          <template v-if="instrument.isValueDistribution"
            >{{ formatNumber(latestValuation?.pricePercent, 0, 4) }} %</template
          >
          <template v-else>{{ formatNumber(latestValuation?.price, 0, 4) }}</template>
        </a-list-item>
        <a-list-item
          label="Värderat pris SEK"
          v-if="instrument.currencyCode !== 'SEK' && !instrument.isValueDistribution"
          >{{ formatNumber(latestValuation?.priceSEK, 0, 4) }}
        </a-list-item>
        <a-list-item label="Prisdatum">{{ formatDay(latestValuation?.date) }} </a-list-item>
      </q-list>

      <q-list :separator="true" class="standard-list col-md-6">
        <a-list-item label="Emittent">
          <SelectIssuer
            v-model="instrument.entity"
            v-bind="inputAttributes"
            :rules="validation.required('Emittent')"
            v-if="!readonlyShareData"
          />
          <template v-else>
            <router-link :to="instrument.entity.viewUrl" class="q-pt-sm">{{ instrument.entity.viewName }}</router-link>
            <div class="row issuer-info q-pt-sm q-pb-sm" v-if="issuerData">
              <div>
                <label>Avstämningsbolag</label>
                <span>{{ toBooleanLabel(issuerData.isRecordCompany) }}</span>
              </div>
              <div>
                <label>Land</label>
                <span>{{ instrument.entity.countryCode }}</span>
              </div>
            </div>
          </template>
        </a-list-item>
        <a-list-item label="Värdepapperskategori">
          <q-select
            v-model="instrument.category"
            v-bind="inputAttributes"
            :options="options.instrumentCategoryOptions"
            emit-value
            map-options
            :readonly="readonlyShareData"
            :rules="validation.required('Värdepapperskategori')"
            dropdown-icon="expand_more"
          />
        </a-list-item>
        <a-list-item label="Värdepappersgrupp">
          <q-select
            v-model="instrument.group"
            v-bind="inputAttributes"
            :options="instrumentGroupOptions"
            :readonly="readonlyShareData"
            emit-value
            map-options
            :rules="validation.required('Värdepappersgrupp')"
            dropdown-icon="expand_more"
          />
        </a-list-item>
        <a-list-item label="Prisrapportering till depåinstitut">
          <q-select
            v-model="instrument.reportCustodians"
            v-bind="inputAttributes"
            emit-value
            map-options
            multiple
            option-label="viewName"
            :options="custodians"
            dropdown-icon="expand_more"
            class="height-auto"
          />
        </a-list-item>
        <a-list-item label="Produkttyper">
          <a-checkbox-group
            v-model="instrument.products"
            :options="productOptions"
            :readonly="readonly"
            class="products-options height-auto"
          />
        </a-list-item>
      </q-list>
    </div>
  </a-page-section>
</template>
<script lang="ts">
// @ts-nocheck
import { Component, Prop, Vue } from 'vue-facing-decorator';
import * as _ from 'lodash';
import { validation, options } from '@shared/common';
import { Entity, Instrument } from '@/models';
import SelectCurrency from '@shared/components/SelectCurrency.vue';
import SelectIssuer from '@/views/entity/SelectIssuer.vue';
import AddInstrumentId from './AddInstrumentId.vue';
import { Products } from '@shared/index';
import { findCustodians } from '@/models/entity/entityService';
@Component({
  components: { SelectIssuer, SelectCurrency, AddInstrumentId },
})
export default class InstrumentBasics extends Vue {
  @Prop() instrument: Instrument;
  @Prop() readonly: boolean;
  @Prop() readonlyShareData: boolean;

  custodians: Entity[];

  async mounted() {
    this.custodians = await findCustodians();
  }

  get productOptions() {
    return Object.entries(Products).map(([label, value]) => {
      return { label: `${label.charAt(0)}${label.substring(1).toLowerCase()}`, value };
    });
  }
  get shareData() {
    return this.instrument.shareData;
  }
  get issuerData() {
    return this.instrument.entity && this.instrument.entity.issuerData;
  }
  get reservationsOptions() {
    return options.reservationsOptions;
  }
  get instrumentGroupOptions() {
    return options.instrumentGroupOptions.filter(option => {
      return option.category === this.instrument.category;
    });
  }

  get latestValuation() {
    return this.instrument.valuation;
  }

  addId({ type, value }) {
    this.instrument[type] = value;
  }

  get activeIdTypes() {
    return this.instrument.activeIdTypes;
  }

  get options() {
    return options;
  }

  get inputAttributes() {
    return {
      dense: true,
      filled: true,
      square: true,
      readonly: this.readonly,
    };
  }

  get validation() {
    return validation;
  }
}
</script>
<style lang="scss">
.InstrumentBasics {
  .issuer-info {
    label {
      margin-right: 10px;
    }
    span {
      margin-right: 20px;
    }
  }
  .height-auto .q-field__control {
    height: auto;
  }
}
</style>
