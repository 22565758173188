<template>
  <q-page class="LegalEntityValuationsListPage">
    <div class="row q-gutter-lg q-mt-sm q-mb-md">
      <div class="col">
        <h1>Värderingar</h1>
      </div>
      <q-btn-group unelevated class="col col-md-auto action-buttons">
        <q-btn label="Värdera" icon="money" v-bind="$defs.btn" @click="valuate" />
      </q-btn-group>
    </div>
    <div v-for="valuation in valuatedInstruments" :key="valuation.id">
      <q-card flat class="bg-white q-pa-sm q-mb-sm" bordered>
        <article>
          <div class="row items-center" style="margin-bottom: -10px">
            <div class="custom-heading q-pt-xs col-6">
              <h3>
                {{ valuation.commentTitle }}
              </h3>
            </div>
            <div class="col-6 buttons" align="right">
              <p align="right">{{ formattedDate(valuation.createdAt) }}</p>
              <q-btn-group stretch unelevated>
                <q-btn flat v-bind="$defs.btn" label="Editera" @click="edit(valuation)" />
                <q-btn flat color="red" label="Ta bort" @click="deleteValuation(valuation)" />
              </q-btn-group>
            </div>
          </div>
          <q-separator color="secondary" />
          <div class="q-px-md">
            <div v-if="valuation.comment" v-html="valuation.comment"></div>
            <div v-else>-</div>
          </div>
        </article>

        <q-table
          :rows="valuation.instrumentValuations"
          :columns="columns"
          row-key="id"
          :hide-pagination="true"
          flat
          class="standard-table q-pt-md col-12"
        >
          <template v-slot:header="props">
            <q-tr class="">
              <q-th style="width: 30%" :props="props" key="viewName">Värdepapper</q-th>
              <q-th style="width: 10%" :props="props" key="isin">Id</q-th>
              <q-th style="width: 15%" :props="props" key="price">Pris</q-th>
              <q-th style="width: 10%" :props="props" key="companyValue">Bolagsvärde</q-th>
              <q-th style="width: 10%" :props="props" key="date">Prisdatum</q-th>
              <q-th style="width: 10%" :props="props" key="method">Värderingsmetod</q-th>
              <q-th style="width: 15%" :props="props" key="period">Värderingsperiod</q-th>
            </q-tr>
          </template>
          <template v-slot:body-cell-viewName="props">
            <q-td :props="props">
              <router-link v-if="props.value" :to="props.row.instrument.viewUrl">{{ props.value }}</router-link>
            </q-td>
          </template>
          <template v-slot:body-cell-price="props">
            <q-td :props="props">
              {{ formatNumber(props.value, 0, 4) + ' ' + props.row.instrument.priceSuffix }}
            </q-td>
          </template>
        </q-table>
      </q-card>
    </div>
  </q-page>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import { Entity, EntityValuation } from '@/models';
import { deleteValuation, loadValuations } from '@/models/valuation/valuationService';
import { QTableProps } from 'quasar';
import EntityValuationModal from '../legalEntity/LegalEntityValuationModal.vue';
import { formatDay, formatNumber } from '@shared/utils';
import { findEntity } from '@/models/entity/entityService';
import { format } from 'date-fns';
import { ConfirmModal } from '@/components';
import { setActiveNavigationModel } from '@/utils';
@Component
export default class EntityValuationsListPage extends Vue {
  valuations: EntityValuation[];
  entity: Entity;
  latestValuation: EntityValuation = null;

  async mounted() {
    const entity = await findEntity(this.$route.params.id);
    this.entity = entity;
    setActiveNavigationModel(entity);
  }

  async created() {
    this.load();
  }

  async load() {
    this.valuations = await loadValuations(this.$route.params.id);
  }

  edit(valuation) {
    this.$q
      .dialog({
        component: EntityValuationModal,
        componentProps: {
          instruments: this.entity.instruments,
          entity: this.entity,
          existingEntityValuation: valuation,
        },
      })
      .onOk(async () => {
        this.load();
      });
  }

  valuate() {
    this.$q
      .dialog({
        component: EntityValuationModal,
        componentProps: {
          instruments: this.entity.instruments,
          entity: this.entity,
          latestValuation: this.latestValuation,
        },
      })
      .onOk(async () => {
        this.load();
      });
  }

  get valuatedInstruments() {
    if (this.valuations) {
      this.latestValuation = this.valuations[0];
    }
    return this.valuations;
  }

  async deleteValuation(valuation: EntityValuation) {
    this.$q.dialog({
      component: ConfirmModal,
      componentProps: {
        header: 'Radera värdering',
        text: 'Detta kommer att radera värderingen',
        onConfirm: async () => {
          try {
            await deleteValuation(valuation);
            this.load();
          } catch (error) {
            this.errorMessage = error;
          }
        },
      },
    });
  }

  get columns(): QTableProps['columns'] {
    return [
      {
        name: 'viewName',
        field: row => row.instrument.name,
        label: 'Värdepapper',
        align: 'left',
      },
      {
        name: 'isin',
        field: row => row.instrument.instrumentId,
        label: 'Id',
        align: 'left',
      },
      {
        name: 'price',
        field: 'pricePercent',
        label: 'Pris',
        align: 'left',
      },
      {
        name: 'companyValue',
        field: row => formatNumber(row.companyValue),
        label: 'Bolagsvärde',
        align: 'left',
      },
      {
        name: 'date',
        field: row => formatDay(row.date),
        label: 'Prisdatum',
        align: 'left',
      },
      {
        name: 'method',
        field: 'method',
        label: 'Värderingsmetod',
        align: 'left',
      },
      {
        name: 'period',
        field: 'period',
        label: 'Värderingsperiod',
        align: 'left',
      },
    ];
  }
  formattedDate(date: Date): string {
    return format(date, 'yyyy-MM-dd HH:mm');
  }
}
</script>
<style lang="scss">
.LegalEntityValuationsListPage {
  .custom-heading {
    padding-left: 16px;
    color: $grey-5;
    font-size: 13px;
    font-weight: 700;
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
  }
}
</style>
