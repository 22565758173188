<template>
  <div>
    <div class="flex q-mt-xl q-mb-sm justify-between items-center">
      <h4 class="text-weight-regular q-mr-sm">Aktier</h4>
      <div>
        <q-btn
          :disable="!!entity?.issuerData?.locked"
          @click="updateFromRoaring"
          flat
          dense
          icon="refresh"
          label="Hämta senaste information från roaring"
        />
      </div>
    </div>
    <q-card-section class="SharesTable q-pa-none">
      <q-table
        :rows="rows"
        :columns="columns"
        :pagination="pagination"
        row-key="id"
        flat
        hide-pagination
        hide-no-data
        class="standard-table"
      >
        <template v-slot:body-cell-viewName="props">
          <q-td :props="props">
            <router-link v-if="props.value" :to="props.row.viewUrl">{{ props.value }}</router-link>
          </q-td>
        </template>
        <template v-slot:body-cell-status="props">
          <q-td :props="props" class="validation" :class="{ valid: props.value }">
            <template v-if="props.value">Klar</template>
            <template v-else-if="isMissing(props.row)">Saknas</template>
            <template v-else>
              <template v-if="entity.issuerData.locked">Felaktig</template>
              <template v-else>Preliminär</template>
            </template>
          </q-td>
        </template>
        <template v-slot:body-cell-actions="props">
          <q-td key="actions">
            <q-btn
              v-if="shares.length > 1"
              flat
              round
              color="grey-4"
              icon="more_vert"
              size="sm"
              class="test-share-row-more-dropdown"
            >
              <q-menu self="center left" anchor="center left" :offset="[70, 0]" content-class="a-tooltip shadow-1">
                <q-list>
                  <q-item v-if="shares.length > 1" clickable v-close-popup @click="mergeInsurance(props.row)">
                    <q-item-section>Slå ihop värdepapper</q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
          </q-td>
        </template>
        <template v-slot:bottom-row>
          <q-tr>
            <q-td colspan="4"> Totalt</q-td>
            <q-td class="text-right validation" :class="{ valid: totals.valid }">
              <template v-if="!totals.valid">{{ formatNumber(totals.totalQuantity) }} av</template>
              {{ formatNumber(issuerData.totalQuantity) }}
            </q-td>
            <q-td class="text-right validation" :class="{ valid: totals.valid }">
              <template>{{ formatPercent(1) }}</template>
            </q-td>
            <q-td class="text-right validation" :class="{ valid: totals.valid }">
              <template v-if="!totals.valid">{{ formatNumber(totals.totalCapital) }} av</template>
              {{ formatNumber(issuerData.totalCapital) }}
            </q-td>

            <q-td colspan="2"></q-td>
          </q-tr>
          <a-table-row-btn @click="addInstrument" label="Lägg till aktie" v-if="!issuerData.locked" />
        </template>
      </q-table>
    </q-card-section>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { Instrument, Entity } from '@/models';
import { toShareClassTypeLabel, toInstrumentGroupLabel } from '@shared/common';
import shareRegisterValidator from '@shared/models/instructionValidator/shareRegisterValidator';
import { formatPercent, formatNumber } from '@/utils';
import { openNewInstrument } from '@/actions';
import ShareData from '@shared/web/models/instrument/ShareData';
import { QTableProps } from 'quasar';
import { refreshSharesInfo } from '@/models/entity/entityService';
import { MergeItems } from '@shared/index';
import { mergeInstrument } from '@/models/instrument/instrumentService';
import MergeInstrumentModal from '../instrument/MergeInstrumentModal.vue';
@Component
export default class SharesTable extends Vue {
  @Prop() entity: Entity;
  @Prop() performSave;
  @Prop({ default: false }) showMissing;

  get totals() {
    const issuerTotals = this.shareRegisterValidator.forEntity();

    return issuerTotals;
  }

  get instrumentValidationMap() {
    return this.shareRegisterValidator.getInstrumentMap();
  }

  get shareRegisterValidator() {
    return shareRegisterValidator(this.entity, this.entity.instruments, []);
  }

  get shares(): Array<Instrument> {
    return this.entity.shares;
  }

  get rows() {
    if (this.showMissing) {
      return [...this.shares, ...this.entity.missingInstruments];
    }
    return this.shares;
  }

  get issuerData() {
    return this.entity.issuerData;
  }

  isMissing(share: { shareData: ShareData }) {
    return !!this.entity.missingInstruments.find(missingShare => {
      return (
        missingShare.shareData.classType === share.shareData.classType &&
        missingShare.shareData.totalCapital === share.shareData.totalCapital &&
        missingShare.shareData.totalQuantity === share.shareData.totalQuantity
      );
    });
  }

  addInstrument() {
    openNewInstrument({
      entity: this.entity,
      category: 'SHA',
    });
  }

  updateFromRoaring() {
    this.$q
      .dialog({
        title: 'Är du säker?',
        message: `Vill du uppdatera informationen om detta värdepapper?`,
        ok: 'Ja, hämta senaste info',
        cancel: 'Avbryt',
      })
      .onOk(async () => {
        await refreshSharesInfo(this.entity);
        this.$emit('refresh');
      });
  }

  mergeInsurance(instrument: Instrument) {
    const filteredInstruments = this.shares.filter(_instrument => _instrument !== instrument);

    this.$q.dialog({
      component: MergeInstrumentModal,
      componentProps: {
        instrument,
        instrumentValidationMap: this.instrumentValidationMap,
        instruments: filteredInstruments,
        onMerge: async (selectedId: string) => {
          const _mergeInstruments: MergeItems = { targetedId: instrument.id, selectedId };

          await mergeInstrument(_mergeInstruments);
          this.$emit('refresh');
        },
      },
    });
  }

  get columns(): QTableProps['columns'] {
    return [
      {
        name: 'viewName',
        field: 'viewName',
        label: 'Namn',
        align: 'left',
      },
      {
        field: instrument => instrument.shareData.classType,
        name: 'classType',
        label: 'Aktieslag',
        align: 'right',
        format: value => toShareClassTypeLabel(value),
      },
      {
        field: instrument => instrument.group,
        name: 'group',
        label: 'Grupp',
        align: 'right',
        format: (value, row) => toInstrumentGroupLabel(value || row.shareData?.group),
      },
      {
        field: instrument => instrument.shareData?.votingPower,
        name: 'votingPower',
        format: value => formatNumber(value, 2, 20),
        label: 'Röstandel',
        align: 'right',
      },
      {
        field: instrument => instrument.shareData.totalQuantity,
        name: 'totalQuantity',
        format: value => formatNumber(value),
        label: 'Antal Aktier',
        align: 'right',
      },
      {
        field: instrument => {
          return this.instrumentValidationMap.get(instrument)?.votingPowerQuota;
        },
        name: 'votingPowerQuota',
        format: value => formatPercent(value),
        label: 'Röstandel',
        align: 'right',
      },
      {
        field: instrument => instrument.shareData.totalCapital,
        name: 'totalCapital',
        format: value => formatNumber(value),
        label: 'Aktiekapital',
        align: 'right',
      },
      {
        field: instrument => instrument.valuation?.priceSEK,
        name: 'valuationPrice',
        format: value => formatNumber(value),
        label: 'Värdering',
        align: 'right',
      },
      {
        name: 'status',
        field: instrument => instrument.shareData.positionsValid,

        label: 'Status',
        align: 'right',
      },
      {
        name: 'actions',
        field: null,
        label: '',
      },
    ].filter(col => {
      return this.entity.isKapclearIssuer || col.name != 'status';
    }) as QTableProps['columns'];
  }
  pagination = {
    rowsPerPage: 0,
    sortBy: 'viewName',
  };
}
</script>
