<template>
  <q-card flat class="InstrumentList standard-table">
    <q-table :rows="list" :columns="columns" row-key="id" :pagination="pagination" flat hide-no-data>
      <template v-slot:bottom-row>
        <a-table-row-btn @click="openCreateInstrument" label="Nytt värdepapper" />
      </template>
      <template v-slot:body-cell-viewName="props">
        <q-td :props="props">
          <router-link :to="props.row.viewUrl">{{ props.value }}</router-link>
        </q-td>
      </template>
      <template v-slot:body-cell-entity="props">
        <q-td :props="props">
          <router-link :to="props.row.entity.viewUrl">{{ props.value }}</router-link>
        </q-td>
      </template>
      <template v-slot:body-cell-menu="props">
        <q-td :props="props">
          <q-btn flat round color="primary" icon="more_vert" size="sm">
            <q-menu>
              <q-list>
                <q-item clickable v-close-popup :to="props.row.positionsUrl">
                  <q-item-section>Visa innehav</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </q-td>
      </template>
    </q-table>
  </q-card>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { toInstrumentGroupLabel, toInstrumentCategoryLabel } from '@shared/common';
import { openNewInstrument } from '@/actions';
import { QTableProps } from 'quasar';
@Component
export default class InstrumentList extends Vue {
  @Prop() list;

  openCreateInstrument() {
    openNewInstrument({});
  }

  pagination = {
    rowsPerPage: 20,
    sortBy: 'viewName',
  };
  columns: QTableProps['columns'] = [
    {
      name: 'viewName',
      field: 'viewName',
      label: 'Namn',
      align: 'left',
    },
    {
      field: row => row.entity.viewName,
      name: 'entity',
      label: 'Emittent',
      align: 'left',
    },
    {
      field: 'category',
      name: 'category',
      label: 'Kategori',
      align: 'left',
      format: value => {
        return toInstrumentCategoryLabel(value);
      },
    },
    {
      field: 'group',
      name: 'group',
      label: 'Grupp',
      align: 'left',
      format: value => {
        return toInstrumentGroupLabel(value);
      },
    },
    {
      field: 'currencyCode',
      name: 'currencyCode',
      label: 'Valuta',
      align: 'left',
    },
    {
      field: 'products',
      name: 'products',
      label: 'Produkt',
      align: 'left',
      format: value => {
        return value.map(v => `${v.charAt(0)}${v.slice(1).toLowerCase()}`).join(', ');
      },
    },
    {
      name: 'menu',
      field: null,
      label: '',
      classes: 'q-table--col-auto-width',
    },
  ];
}
</script>
