<template>
  <q-page class="ViewLegalEntityPage" v-if="ready">
    <div class="row q-gutter-lg q-mt-sm">
      <div class="col">
        <h1 v-if="isCreate">Skapa juridisk person</h1>
        <h1 v-else>{{ entity.viewName }}</h1>
      </div>
      <q-btn-group unelevated class="col col-md-auto action-buttons">
        <template v-if="editMode">
          <q-btn v-bind="$defs.btn" color="red-5" @click="deleteEntity" v-if="!isCreate">Radera</q-btn>
          <q-btn label="Avbryt" flat v-bind="$defs.btn" @click="cancel" />
          <q-btn label="Spara" v-bind="$defs.btn" @click="save" />
        </template>
        <template v-else>
          <q-btn label="Värdera" icon="money" v-bind="$defs.btn" @click="valuate" />
          <q-btn label="Editera" icon="edit" v-bind="$defs.btn" @click="edit" />
        </template>
      </q-btn-group>
    </div>
    <q-form ref="form">
      <q-banner id="error-text" rounded class="bg-red-5 text-white q-mt-md" v-if="errorMessage">
        {{ getErrorText(errorMessage) }}
      </q-banner>

      <LegalEntityBasics :entity="entity" :readonly="!editMode" />

      <template v-if="entity.isIssuer">
        <IssuerData :entity="entity" :readonly="!editMode" @refersh="loadData" />

        <template v-if="!editMode">
          <SharesTable
            :show-missing="true"
            :entity="entity"
            v-if="entity.instruments || entity.missingInstruments.length"
            @refresh="loadData"
          />
          <InstructionList
            :list="instructions"
            header="Pågående ägarbyten"
            v-if="instructions && instructions.length"
            :hidePagination="true"
          />
          <CorporateEventList
            :list="corporateEvents"
            header="Bolagshändelser"
            v-if="corporateEvents && corporateEvents.length"
            :hidePagination="true"
            :entity="entity"
            :readonly="true"
          />
          <InstrumentsTable :entity="entity" v-if="entity.instruments" />
        </template>
      </template>

      <q-card flat class="q-pa-none">
        <div class="flex items-end justify-between">
          <h4 class="table-header">Adresser</h4>
          <div class="items-center q-mb-sm">
            <q-btn flat dense @click="refreshLegalEntityInfo" label="Hämta från roaring" icon="refresh" />
          </div>
        </div>
        <AddressTable :entity="entity" :performSave="!editMode" />
      </q-card>

      <q-card flat class="q-pa-none">
        <div class="flex items-end justify-between">
          <h4 class="table-header">Roller</h4>
          <div class="items-center q-mb-sm">
            <q-btn flat dense @click="refreshRoles" label="Hämta från roaring" icon="refresh" />
          </div>
        </div>
        <RolesTable :show-header="false" :entity="entity" :performSave="!editMode" />
      </q-card>

      <q-card flat class="q-pa-none" v-if="!isCreate">
        <h4 class="table-header">Administratörer</h4>
        <AdministratorTable :entity="entity" />
      </q-card>
      <q-card flat class="q-pa-none" v-if="!isCreate">
        <h4 class="table-header">Kontaktpersoner</h4>
        <ContactPersonTable :entity="entity" />
      </q-card>
      <q-card flat class="q-pa-none" v-if="!isCreate">
        <InsurancesTable :investor="entity" />
      </q-card>
    </q-form>
  </q-page>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator';
import {
  loadEntity,
  save,
  initTypeData,
  destroyEntity,
  updateRolesFromRoaring,
  updateLegalEntityFromRoaring,
} from '@/models/entity/entityService';
import { findActiveInstructions } from '@/models/instruction/instructionService';
import { Entity, EntityAdmin, Instruction, InstructionMainType, Instrument } from '@/models';
import { FormComponent } from '@shared/mixins';
import AddressTable from '../common/AddressTable.vue';
import RolesTable from '@shared/views/rolesAdmin/RolesTable.vue';
import { ConfirmModal } from '@/components';
import IssuerData from './IssuerData.vue';
import InstrumentsTable from './InstrumentsTable.vue';
import SharesTable from './SharesTable.vue';
import LegalEntityBasics from './LegalEntityBasics.vue';
import InstructionList from '@/views/instructionList/InstructionList.vue';
import CorporateEventList from '@/views/instructionList/CorporateEventList.vue';
import AdministratorTable from './AdministratorTable.vue';
import InsurancesTable from '../insurance/InsurancesTable.vue';
import EntityValuationModal from './LegalEntityValuationModal.vue';
import { findEntityAdminsForEntity } from '@/models/entityAdmin/entityAdminService';
import { isToNewPage, setActiveNavigationModel } from '@/utils';
import ContactPersonTable from '../person/ContactPersonTable.vue';

@Component({
  components: {
    AddressTable,
    RolesTable,
    IssuerData,
    InstrumentsTable,
    SharesTable,
    LegalEntityBasics,
    InstructionList,
    CorporateEventList,
    AdministratorTable,
    InsurancesTable,
    ContactPersonTable,
  },
  mixins: [FormComponent],
})
export default class ViewLegalEntityPage extends Vue {
  entity: Entity = null;
  original: Entity = null;
  editMode = false;
  isCreate = false;

  instructions: Array<Instruction>;
  instruments: Array<Instrument>;
  corporateEvents: Array<Instruction>;
  entityAdmins: Array<EntityAdmin> = null;
  active: true;

  async created() {
    await this.loadData();
  }

  @Watch('$route')
  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }
    const { id } = this.$route.params;
    const isCreate = id === 'new';

    const entity = isCreate
      ? Entity.newModel({ isPerson: false, active: true, legalEntity: {} })
      : await loadEntity(id as string);

    setActiveNavigationModel(entity);

    if (!isCreate) {
      await this.loadInstructions(entity);
      await this.loadEntityAdmin(id);
    } else {
      initTypeData(entity);
    }

    Object.assign(this, { entity, original: entity, editMode: isCreate, isCreate });
  }

  async loadEntityAdmin(id) {
    const entityAdmins = await findEntityAdminsForEntity(id);
    Object.assign(this, { entityAdmins });
  }

  @Watch('entity.types')
  changeTypes() {
    initTypeData(this.entity);
  }

  async loadInstructions(entity) {
    this.instructions =
      entity.isKapclearIssuer && (await findActiveInstructions(entity, InstructionMainType.TRADE_EVENT));
    this.corporateEvents =
      entity.isKapclearIssuer && (await findActiveInstructions(entity, InstructionMainType.CORPORATE_EVENT, null));
  }

  get ready() {
    return this.entity != null;
  }
  edit() {
    this.editMode = true;
    this.original = this.entity;
    this.entity = this.entity.copy();
  }

  async save() {
    const model = await this.submitForm(async () => {
      return save(this.entity);
    });
    if (model) {
      this.original = this.entity = model;
      this.editMode = false;
      if (this.isCreate) {
        this.$router.push({ path: model.viewUrl });
      } else {
        await loadEntity(this.entity.id);
      }
    }
  }

  async cancel() {
    this.editMode = false;
    this.entity = this.original;
    this.errorMessage = null;
  }

  async deleteEntity() {
    this.$q.dialog({
      component: ConfirmModal,
      componentProps: {
        header: 'Radera bolag',
        text: 'Detta kommer radera detta bolag',
        onConfirm: async () => {
          try {
            await destroyEntity(this.entity);
            this.$router.push({ path: '/legal-entity' });
          } catch (error) {
            this.errorMessage = error;
          }
        },
      },
    });
  }

  async refreshLegalEntityInfo() {
    await updateLegalEntityFromRoaring(this.entity);
    this.$q.dialog({
      title: 'Klart! Nu är det uppdaterat!',
      message: 'Kontakta IT om du behöver hjälp',
    });
  }

  async refreshRoles() {
    await updateRolesFromRoaring(this.entity);
    this.$q.dialog({
      title: 'Klart! Nu är det uppdaterat!',
      message: 'Kontakta IT om du behöver hjälp',
    });
  }

  valuate() {
    this.$q.dialog({
      component: EntityValuationModal,
      componentProps: {
        instruments: this.entity.instruments,
        entity: this.entity,
      },
    });
  }
}
</script>
