<template>
  <div class="SupplementContent">
    <q-card class="q-my-lg" flat>
      <div class="row">
        <span class="box green-box"></span>
        <p>Ny position tillaggd</p>
        <span class="box orange-box"></span>
        <p>"Antal / Belopp" ändrad</p>
      </div>
    </q-card>
    <DestinationsTableCard
      :instruction="instruction"
      :entity="entity"
      :shareRegister="shareRegister"
      :readonly="readonly"
      :disable-add="readonly"
    />
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';

import { Entity, Instruction, ShareRegister } from '@/models';
import DestinationsTableCard from '@/views/instruction/corporateEvent/DestinationsTableCard.vue';

@Component({
  components: { DestinationsTableCard },
})
export default class SupplementContent extends Vue {
  @Prop() instruction: Instruction;
  @Prop() entity: Entity;
  @Prop({ default: true }) readonly: boolean;
  @Prop() shareRegister: ShareRegister;
}
</script>

<style lang="scss">
.SupplementContent {
  .orange-box {
    background-color: #ffc795;
  }
  .green-box {
    background-color: #ccffaf;
  }
  .row {
    display: flex;
    align-items: center;
  }

  .box {
    display: inline-block;
    border-radius: 50%;
    border: 1px solid #9f9f9f72;
    margin: 0 10px; // Ändrat från margin: 20px 10px 0
    width: 20px;
    height: 14px;
  }

  // Ta bort eventuell standardmarginal från p-taggen
  p {
    margin: 0;
  }
}
</style>
