<template>
  <div>
    {{ title }}
    <q-list class="standard-list" :separator="true">
      <a-list-item class="text-right" :label="label">
        {{ formatNationalId(entityInfo) }}
      </a-list-item>
      <a-list-item class="text-right" label="Namn">
        {{ entityInfo.viewName }}
      </a-list-item>
      <a-list-item class="text-right" label="Adress">
        <div>{{ contacts ? contacts.address1 : null }}</div>
        <div>{{ contacts ? contacts.zip : null }}</div>
        <div>{{ contacts ? contacts.city : null }}</div>
        <div>{{ contacts ? contacts.countryCode : null }}</div>
      </a-list-item>
      <a-list-item class="text-right" label="Mail">
        {{ ownerContactInfo ? ownerContactInfo.email : null }}
      </a-list-item>
      <a-list-item class="text-right" label="Telefon">
        {{ ownerContactInfo ? ownerContactInfo.phone : null }}
      </a-list-item>
    </q-list>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { Entity, IssuerInfo } from '@shared/web/models';

@Component({})
export default class ViewEntityInfo extends Vue {
  @Prop() entityInfo: Entity;
  @Prop() issuerInfo: IssuerInfo;
  @Prop() title: string;
  @Prop() label: string;

  get contacts() {
    return this.entityInfo?.addresses[0];
  }

  get ownerContactInfo() {
    return this.issuerInfo?.getContactInfo(this.entityInfo);
  }
}
</script>
<style lang="scss"></style>
