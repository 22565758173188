<template>
  <q-page class="DashboardPage">
    <div class="header">
      <h1>Dashboard</h1>
      <q-btn @click="load" :loading="loading"> Uppdatera sidan </q-btn>
    </div>
    <h2 class="q-mt-lg q-pb-sm">Ogranskade och preliminära</h2>
    <q-tabs v-model="reviewsActiveTab" align="left" v-if="!loading" class="bg-primary text-white">
      <q-tab v-for="tab in reviewsTabs" :key="tab.name" :name="tab.name" :label="tab.label">
        <q-badge v-if="!!tab.count" color="red" floating>{{ tab.count }}</q-badge>
      </q-tab>
    </q-tabs>
    <q-spinner size="24" v-if="loading" />
    <ShareRegisterList
      v-if="!loading && reviewsActiveTab === 'shareRegister'"
      :list="shareRegisterInstructions"
      no-data-label="Inga inskickade aktieböcker hittades"
    />
    <InstructionList
      :show-entity="true"
      v-if="!loading && reviewsActiveTab === 'tradeEvents'"
      :list="tradeEventInstructions"
      :hide-pagination="true"
      :rows-per-page="1000"
      sortBy="submittedAt"
      :descending="true"
      no-data-label="Inga inskickade ägarbyten hittades"
    />
    <CorporateEventList
      v-if="!loading && reviewsActiveTab === 'corporateEvents'"
      :displayIssuer="true"
      :list="corporateEventInstructions"
      sortBy="submittedAt"
      :descending="true"
      :hide-pagination="true"
      :rows-per-page="1000"
      no-data-label="Inga inskickade bolagshändelser hittades"
    />

    <div class="q-mt-xl test-unsynced-entites">
      <h2 class="q-pb-sm">Osynkade aktieböcker</h2>
      <q-tabs v-model="unsyncedActiveTab" align="left" v-if="!loading" class="bg-primary text-white">
        <q-tab v-for="tab in unsyncedTabs" :key="tab.name" :name="tab.name" :label="tab.label">
          <q-badge v-if="!!tab.count" color="red" floating>{{ tab.count }}</q-badge>
        </q-tab>
      </q-tabs>
      <q-spinner size="24" v-if="loading" />
      <LegalEntityList
        v-if="!loading && unsyncedActiveTab === 'unsynced'"
        :list="activeUnsyncedEntities"
        :displayNewButton="false"
        :hidePagination="true"
      />
      <LegalEntityList
        v-if="!loading && unsyncedActiveTab === 'toSync'"
        :list="entitiesToSync"
        :displayNewButton="false"
        :hidePagination="true"
      />
    </div>

    <div class="q-mt-xl">
      <h2 class="q-pb-sm">Ogranskade försäkringar</h2>

      <q-spinner size="24" v-if="loading" />
      <InsuranceList :list="insuranceList" />
    </div>
  </q-page>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import ShareRegisterList from './ShareRegisterList.vue';
import InstructionList from '@/views/instructionList/InstructionList.vue';
import CorporateEventList from '@/views/instructionList/CorporateEventList.vue';
import LegalEntityList from '@/views/legalEntityList/LegalEntityList.vue';
import InsuranceList from './InsuranceList.vue';
import { Entity, Instruction, InstructionState, Ownership, TransactionType } from '@/models';
import { search as searchInstruction } from '@/models/instruction/instructionService';
import { search as searchEntity } from '@/models/entity/entityService';
import SearchQuery from '@shared/web/models/SearchQuery';
import { tradeEventTypes } from '@shared/instruction/utils';
import { orderBy } from 'lodash';
import InstructionSearchQuery from '@shared/web/models/instruction/InstructionSearchQuery';
import { setActiveNavigationModel } from '@/utils';
import { OwnershipState } from '@shared/index';
import { findOwnerships } from '@/models/ownership/ownershipService';

@Component({
  components: { ShareRegisterList, InstructionList, CorporateEventList, LegalEntityList, InsuranceList },
})
export default class DashboardPage extends Vue {
  shareRegisterInstructions: Instruction[] = [];
  tradeEventInstructions: Instruction[] = [];
  corporateEventInstructions: Instruction[] = [];
  unsyncedEntities: Array<Entity> = [];
  entitiesToSync: Entity[] = [];
  insuranceList: Ownership[] = [];
  loading = false;
  reviewsActiveTab = 'shareRegister';
  unsyncedActiveTab = 'unsynced';

  async mounted() {
    setActiveNavigationModel(null);
    await this.load();
  }

  get activeUnsyncedEntities() {
    return this.unsyncedEntities.filter(entity => entity.activeTypes.issuer !== false);
  }

  async load() {
    this.loading = true;
    this.shareRegisterInstructions = orderBy(
      await searchInstruction(
        InstructionSearchQuery.newModel({
          type: TransactionType.SHAREREGISTER_CREATED,
          state: InstructionState.SUBMITTED,
        }),
      ),
      ['updatedAt'],
      ['desc'],
    );
    this.tradeEventInstructions = orderBy(
      await searchInstruction(
        InstructionSearchQuery.newModel({
          type: tradeEventTypes,
          state: InstructionState.SUBMITTED,
        }),
      ),
      ['updatedAt'],
      ['desc'],
    );
    this.corporateEventInstructions = orderBy(
      await searchInstruction(
        InstructionSearchQuery.newModel({
          type: this.corporateEventsTypes,
          state: InstructionState.SUBMITTED,
        }),
      ),
      ['updatedAt'],
      ['desc'],
    );
    this.unsyncedEntities = await searchEntity(SearchQuery.newModel({ unSyncedData: true }));
    this.entitiesToSync = await searchEntity(SearchQuery.newModel({ toSync: true }));
    this.insuranceList = await findOwnerships({ state: OwnershipState.REVIEW });
    this.loading = false;
  }

  get corporateEventsTypes() {
    return [
      TransactionType.RIGHTS_ISSUE,
      TransactionType.ISSUE_WARRANT,
      TransactionType.ISSUE_SHARE,
      TransactionType.EXERCISE_WARRANT,
      TransactionType.ISSUE_CONVERTIBLE,
      TransactionType.SPLIT,
      TransactionType.ISSUE_CONVERTIBLE,
      TransactionType.EXERCISE_CONVERTIBLE,
      TransactionType.ISSUE_BOND,
      TransactionType.EXERCISE_BOND,
      TransactionType.ISSUE_DEBENTURE,
      TransactionType.EXERCISE_DEBENTURE,
      TransactionType.ISSUE_SHAREHOLDER_CONTRIBUTION,
      TransactionType.SUPPLEMENT,
    ];
  }

  get reviewsTabs() {
    return [
      { name: 'shareRegister', label: 'Aktieböcker', count: this.shareRegisterInstructions.length },
      { name: 'tradeEvents', label: 'Ägarbyten', count: this.tradeEventInstructions.length },
      { name: 'corporateEvents', label: 'Bolagshändelser', count: this.corporateEventInstructions.length },
    ];
  }

  get unsyncedTabs() {
    return [
      { name: 'unsynced', label: 'Diff mot bolagsverket', count: this.activeUnsyncedEntities.length },
      { name: 'toSync', label: 'BTA:er att konvertera', count: this.entitiesToSync.length },
    ];
  }
}
</script>
<style lang="scss">
.DashboardPage {
  .PageSection {
    margin: 0;
  }
  .q-tab--inactive {
    opacity: 0.7;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
  .spinner {
    position: relative;
    visibility: hidden;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    border-radius: 50%;
    border-top: solid 2px rgba(255, 255, 255, 0.75);
    border-left: solid 2px rgba(255, 255, 255, 0.75);
    border-bottom: solid 2px rgba(255, 255, 255, 0.25);
    border-right: solid 2px rgba(255, 255, 255, 0.25);
    &.spin {
      visibility: visible;
      animation: spin 2s linear infinite;
    }
  }
  .header {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
  }
  .p2 {
    font-size: 18px;
    line-height: 1.33;
    margin: 8px 0;
    font-weight: 400;
  }
}
</style>
