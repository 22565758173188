<template>
  <span class="entity-link">
    <RouterLink :to="entity.viewUrl">{{ entity.viewName }}</RouterLink>
    <RouterLink v-if="showCommentsLink" :to="`/legal-entity/comments/${entity.id}`" class="btn-link">
      <q-icon size="18px" name="comment" :color="entity.lastCommentAt ? 'primary' : 'grey'" />
    </RouterLink>
  </span>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { Entity } from '@shared/web/models';

@Component
export default class EntityLink extends Vue {
  @Prop({ required: true }) entity: Entity;
  @Prop({ default: false }) showCommentsLink: boolean;
}
</script>
<style lang="scss">
.entity-link {
  .btn-link {
    text-decoration: none;
    padding: 0 5px;
  }
}
</style>
