<template>
  <tr class="transaction-row">
    <td></td>
    <td>{{ formatDay(transaction.settleDate) }}</td>
    <td>{{ formatDay(transaction.tradeDate) }}</td>
    <td>
      <router-link v-if="eventLink" :to="eventLink">{{ typeLabel }}</router-link>
      <span v-else>{{ typeLabel }}</span>
    </td>
    <td class="text-left">
      <router-link v-if="transaction.previousOwner" :to="transaction.previousOwner.viewUrl">{{
        transaction.previousOwner.viewName
      }}</router-link>
    </td>
    <td class="text-left">
      <router-link v-if="transaction.custodian" :to="transaction.custodian.viewUrl">{{
        transaction.custodian.viewName
      }}</router-link>
    </td>
    <td class="text-left">
      <div v-if="transaction.investor" class="q-py-sm">
        <div>{{ transaction.owner.viewName }}</div>
        <div><a-link :target="transaction.investor" /></div>
      </div>
      <a-link :target="transaction.owner" v-else />
    </td>
    <td class="text-right">
      {{ formatNumber(transaction.quantity) }}
    </td>
    <td class="text-right">{{ formatNumber(transaction.amount) }}</td>
    <td class="text-right">{{ formatNumber(transaction.price) }}</td>
    <td class="text-right">{{ transaction.verificationNumber }}</td>
  </tr>
</template>
<script lang="ts">
// @ts-nocheck
import { Transaction } from '@/models';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { toTransactionTypeLabel } from '@shared/common';
import { TransactionType } from '@shared/models/types';
import { isTradeEvent } from '@shared/instruction/utils';

@Component({})
export default class TransactionRow extends Vue {
  @Prop() transaction: Transaction;
  get typeLabel() {
    if (this.transaction.transactionType === TransactionType.TRADE) {
      return 'Ägarbyte';
    }
    return toTransactionTypeLabel(this.transaction.transactionType, this.transaction.debit);
  }

  get eventLink() {
    const { transactionType, instruction } = this.transaction;
    const instructionId = instruction?.id || instruction;
    const instructionRoute = isTradeEvent(this.transaction.transactionType) ? `/instruction/view` : `/corporate-event`;
    return transactionType === TransactionType.SHAREREGISTER_CREATED || instructionId == null
      ? null
      : `${instructionRoute}/${instructionId}`;
  }
}
</script>
