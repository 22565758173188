import { TransactionsData } from '@/models';
import TransactionSearchQuery from '@shared/web/models/transaction/TransactionSearchQuery';
import axios from 'axios';

const baseUrl = '/api/transaction';

export async function search(searchQuery: TransactionSearchQuery): Promise<TransactionsData> {
  const query = searchQuery.serialize();
  const { data } = await axios.post(baseUrl + '/find', query);
  return TransactionsData.newModel(data, true);
}
