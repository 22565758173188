<template>
  <q-page>
    <h1 class="q-mb-lg">
      <RouterLink v-if="entity" :to="entity?.viewUrl">{{ entity?.viewName }}</RouterLink> - systemloggar
    </h1>
    <div class="row">
      <div class="col-12 col-md-6 q-mb-md">
        <EventLogTypesSelect v-model="selectedTypes" />
      </div>
    </div>
    <SystemLogsTable :users="usersIds" :types="selectedTypes" />
  </q-page>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import { findEntity } from '@/models/entity/entityService';
import { setActiveNavigationModel } from '@/utils';
import { Entity, EntityAdmin } from '@shared/web/models';
import { findEntityAdminsForEntity } from '@/models/entityAdmin/entityAdminService';
import SystemLogsTable from '@/views/system/SystemLogsTable.vue';
import EventLogTypesSelect from '@/components/EventLogTypesSelect.vue';

@Component({
  components: { EventLogTypesSelect, SystemLogsTable },
})
export default class EntitySystemLogsPage extends Vue {
  selectedTypes = null;
  entity: Entity;
  entityAdmins: EntityAdmin[] = [];

  get usersIds() {
    return this.entityAdmins.map(entityAdmin => entityAdmin.user.id);
  }

  async mounted() {
    this.entity = await findEntity(this.$route.params.id);
    setActiveNavigationModel(this.entity);
    this.entityAdmins = await findEntityAdminsForEntity(this.entity);
  }
}
</script>
