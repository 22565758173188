<template>
  <div class="PersonList standard-table">
    <q-table :rows="list" :columns="columns" row-key="id" :pagination="pagination" flat hide-no-data>
      <template v-slot:bottom-row>
        <a-table-row-btn @click="openCreatePerson" label="Ny Fysisk Person" />
      </template>
      <template v-slot:body-cell-viewName="props">
        <q-td :props="props">
          <router-link :to="props.row.viewUrl">{{ props.value }}</router-link>
        </q-td>
      </template>
      <template v-slot:body-cell-menu="props">
        <q-td :props="props">
          <q-btn flat round color="primary" icon="more_vert" size="sm">
            <q-menu>
              <q-list>
                <q-item clickable v-close-popup @click="mergeEntity(props.row)">
                  <q-item-section>Slå ihop person</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </q-td>
      </template>
    </q-table>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { formatNationalId } from '@shared/common';
import CreateEntityModal from '@/views/entity/CreateEntityModal.vue';
import MergeEntityModal from '@/views/entity/MergeEntityModal.vue';
import { QTableProps } from 'quasar';
import { Entity } from '@shared/web/models';

@Component
export default class PersonList extends Vue {
  @Prop() list;

  pagination = {
    rowsPerPage: 20,
    sortBy: 'viewName',
  };
  columns: QTableProps['columns'] = [
    {
      field: person => formatNationalId(person),
      label: 'Personnummer',
      name: 'nationalId',
      align: 'left',
      classes: 'q-table--col-auto-width',
    },
    {
      name: 'viewName',
      field: 'viewName',
      label: 'Namn',
      align: 'left',
    },

    {
      name: 'countryCode',
      field: 'countryCode',
      label: 'Land',
      align: 'left',
    },
    {
      name: 'menu',
      label: '',
      field: null,
      classes: 'q-table--col-auto-width',
    },
  ];

  mergeEntity(entity: Entity) {
    this.$q.dialog({
      component: MergeEntityModal,
      componentProps: {
        targetedEntity: entity,
        previousSearchList: this.list,
        onMerge() {
          this.$emit('reload');
        },
      },
    });
  }
  openCreatePerson() {
    this.$q.dialog({
      component: CreateEntityModal,
      componentProps: {
        isPerson: true,
      },
    });
  }
}
</script>
