<template>
  <div class="SectionFooter row justify-end align-center">
    <slot />
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';

@Component
export default class SectionFooter extends Vue {}
</script>
<style lang="scss">
.SectionFooter {
  align-items: center;
  margin-bottom: -10px;
  a {
    text-transform: initial;
    margin-left: 5px;
    text-decoration: none;
    font-weight: 500;
  }
  a,
  .text {
    line-height: 20px;
  }
}
</style>
