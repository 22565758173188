import { Instrument, Entity } from '@/models';
import axios from 'axios';
import { modelHandler, Update, serialize } from '../base';
import { addById, removeById } from '@/utils';
import InstrumentSearchQuery from '@/models/instrument/InstrumentSearchQuery';

import { getModel } from '@/store';
import { MergeItems } from '@shared/index';
const baseUrl = '/api/instrument';
const { findModel, saveModel, destroyModel, loadModel } = modelHandler(Instrument, baseUrl);

export async function save(instrument: Instrument): Promise<Update<Instrument>> {
  const update = await saveModel(instrument);

  postUpdate(update);

  return update;
}

function postUpdate(update: Update<Instrument>) {
  const { model, changes, backup } = update;
  if (changes.entity) {
    addById(model.entity.instruments, model);
    const oldEntity = getModel(backup.entity, Entity);
    oldEntity && removeById(oldEntity.instruments, model);
  }
}

export async function destroyInstrument(instrument: Instrument) {
  const update = await destroyModel(instrument);
  postUpdate(update);
}

export async function mergeInstrument(mergeInstrument: MergeItems) {
  await axios.post(baseUrl + `/merge`, mergeInstrument);
}

export async function loadInstrument(id) {
  return loadModel(id);
}

export async function findInstrument(id) {
  return findModel(id);
}

export async function search(query: InstrumentSearchQuery): Promise<Array<Instrument>> {
  const { data } = await axios.post(baseUrl + '/search', serialize(query));

  Entity.newModels(data.models.Entity, true);
  return Instrument.newModels(data.result, true);
}

export const searchInstruments = search;
