<template>
  <q-page class="ViewInstrumentPage" v-if="ready">
    <div class="row q-gutter-lg q-mt-sm">
      <div class="col">
        <h1 v-if="isCreate">Skapa värdepapper</h1>
        <h1 v-else>{{ instrument.viewName }}</h1>
      </div>
      <q-btn-group unelevated class="col col-md-auto action-buttons">
        <template v-if="editMode">
          <q-btn
            v-bind="$defs.btn"
            color="red-5"
            class="margin-right-auto"
            @click="deleteInstrument"
            v-if="!isCreate && !isLockedForEditingIssuerData"
            >Radera</q-btn
          >
          <q-btn label="Avbryt" flat v-bind="$defs.btn" @click="cancel" />
          <q-btn label="Spara" v-bind="$defs.btn" @click="save" />
        </template>
        <template v-else>
          <q-btn label="Editera" icon="edit" v-bind="$defs.btn" @click="edit" />
        </template>
      </q-btn-group>
    </div>
    <q-form ref="form">
      <q-banner id="error-text" rounded class="bg-red-5 text-white q-mt-md" v-if="errorMessage">
        {{ getErrorText(errorMessage) }}
      </q-banner>
      <InstrumentContent
        v-if="instrument"
        :instrument="instrument"
        :readonly="!editMode"
        :readonlyShareData="readonlyShareData"
        @reload="reload"
      />
    </q-form>

    <InstrumentEventList :list="corporateEvents" :instrument="instrument" />
  </q-page>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator';
import { FormComponent } from '@shared/mixins';
import { loadInstrument, save, destroyInstrument } from '@/models/instrument/instrumentService';

import { Instrument, InstructionMainType, Instruction } from '@/models';
import { findActiveInstructions } from '@/models/instruction/instructionService';
import InstrumentContent from './InstrumentContent.vue';
import InstrumentEventList from '@/views/instructionList/InstrumentEventList.vue';
import store from '@/store';
import { ConfirmModal } from '@/components';
import { isToNewPage, setActiveNavigationModel } from '@/utils';
import { findCustodians } from '@/models/entity/entityService';

@Component({
  components: { InstrumentContent, InstrumentEventList },
  mixins: [FormComponent],
})
export default class ViewInstrumentPage extends Vue {
  instrument: Instrument = null;
  original: Instrument = null;

  corporateEvents: Instruction[] = [];
  editMode = false;
  isCreate = false;

  async created() {
    await this.loadData();
  }

  @Watch('$route')
  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }
    const { id } = this.$route.params;
    const isCreate = id === 'new';
    // load custodians so the store is populated with all custodians. That way, the array reportCustodians will be correctly populated.
    await findCustodians();
    const instrument = isCreate
      ? Instrument.newModel(store.newModelProps).initCategoryData()
      : await loadInstrument(id);

    if (instrument.entity) {
      setActiveNavigationModel(instrument);
    }

    Object.assign(this, { instrument, original: instrument, editMode: isCreate, isCreate });
    if (!isCreate) {
      this.loadCorporateEvents();
    }
  }

  @Watch('instrument.entity')
  initCurrency() {
    const { entity } = this.instrument;
    if (this.instrument.currencyCode == null && entity && entity.issuerData) {
      this.instrument.currencyCode = entity.issuerData.currencyCode;
    }
  }

  @Watch('instrument.category')
  changeTypes() {
    this.instrument.initCategoryData();
  }

  get ready() {
    return this.instrument != null;
  }

  async loadCorporateEvents() {
    const entity = this.instrument.entity;
    if (!entity.isKapclearIssuer) {
      return;
    }
    const corporateEvents = await findActiveInstructions(entity, InstructionMainType.CORPORATE_EVENT, null);

    this.corporateEvents = corporateEvents.filter((instruction: Instruction) =>
      instruction.corporateEvent.instrumentSources.find(
        source => source.instrument === this.instrument && source.quantity > 0,
      ),
    );
  }

  get readonlyShareData() {
    if (this.isCreate) {
      return false;
    }
    return !this.editMode;
  }

  edit() {
    this.editMode = true;
    this.original = this.instrument;
    this.instrument = this.instrument.copy();
  }

  async save() {
    const { model } = await this.submitForm(async () => {
      return save(this.instrument);
    });
    if (model) {
      this.original = this.instrument = model;
      this.editMode = false;
      if (this.isCreate) {
        this.$router.push({ path: model.viewUrl });
      }
    }
  }

  async cancel() {
    this.editMode = false;
    this.instrument = this.original;
    this.errorMessage = null;
  }

  get isLockedForEditingIssuerData() {
    return this.instrument.entity?.issuerData?.locked;
  }

  async deleteInstrument() {
    this.$q.dialog({
      component: ConfirmModal,
      componentProps: {
        header: 'Radera värdepapper',
        text: 'Detta kommer att radera värdepappret',
        onConfirm: async () => {
          try {
            await destroyInstrument(this.instrument);
            await this.useConfirmModal({
              text: 'Värdepappret är raderat',
              useConfirm: false,
            });
          } catch (error) {
            this.errorMessage = error;
          }
        },
      },
    });
  }
  async reload() {
    this.instrument = await loadInstrument(this.original.id);
  }
}
</script>
