import SearchQuery from '@shared/web/models/SearchQuery';
import { stringToBoolean } from '@/utils';
export default class LESearchQuery extends SearchQuery {
  constructor(attr) {
    super(attr);
    Object.assign(this, attr);
    this.active = stringToBoolean(this.active);
  }
  active?: boolean | null = null;
  status?: string | null = null;
  field?: string | null = null;
  type?: string | null = null;
}
