<template>
  <div>
    <h2 class="q-mb-md">Ny kommentar</h2>
    <EntityCommentForm @savedComment="loadComments" :entity="entity" />
    <h2 class="q-mt-xl q-mb-md">Alla kommentarer</h2>
    <div>
      <q-skeleton v-if="loading" height="100px" />
      <div v-else>
        <div v-for="comment in comments" v-bind:key="comment.id">
          <EntityCommentCard :comment="comment" @removed="comment => removeComment(comment)" />
        </div>
        <div v-if="comments.length === 0">Inga kommentarer hittades...</div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';
import axios from 'axios';
import Comment from '@shared/web/models/entity/Comment';
import EntityCommentCard from '@/views/comment/CommentCard.vue';
import EntityCommentForm from '@/views/comment/EntityCommentForm.vue';
import { Entity } from '@shared/web/models';
import { remove } from '@/utils';

@Component({
  components: { EntityCommentForm, EntityCommentCard },
})
export default class EntityCommentsView extends Vue {
  @Prop() entity: Entity | undefined;
  comments = [];
  loadingComments = false;
  mounted() {
    this.loadComments();
  }
  get loading() {
    return this.loadingComments || !this.entity;
  }
  async loadComments() {
    if (!this.entity) {
      return;
    }
    this.loadingComments = true;
    this.comments = Comment.newModels((await axios.get('/api/comment/for-entity/' + this.entity.id)).data);
    this.loadingComments = false;
  }
  async removeComment(comment) {
    await remove(this.comments, comment);
  }
  @Watch('entity')
  handleEntityChange() {
    this.loadComments();
  }
}
</script>
