<template>
  <div v-if="ready" class="LegalEntityList standard-table">
    <q-table
      :rows="list"
      :columns="columns"
      row-key="id"
      :pagination="pagination"
      :hide-pagination="true"
      :no-data-label="noDataLabel"
      flat
      :hide-no-data="!noDataLabel"
    >
      <template v-slot:body-cell-viewName="props">
        <q-td :props="props">
          <router-link :to="`${baseUrl + props.row.entity.id}`">{{ props.value }}</router-link>
        </q-td>
      </template>
    </q-table>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { formatNationalId, toShareRegisterStatusText } from '@shared/common';
import { formatDateTime } from '@/utils';
import { QTableProps } from 'quasar';
import { Instruction } from '@/models';
@Component
export default class ShareRegisterList extends Vue {
  @Prop() list: Instruction[];
  @Prop({ default: null }) noDataLabel: 'Inga inskickade aktieböcker hittades';

  get baseUrl() {
    return '/legal-entity/share-register/';
  }

  get ready() {
    return this.list != null;
  }

  get pagination() {
    return {
      rowsPerPage: 1000,
      sortBy: 'submittedAt',
      descending: true,
    };
  }

  get columns(): QTableProps['columns'] {
    return [
      {
        name: 'nationalId',
        field: instruction => formatNationalId(instruction.entity),
        label: 'Nationellt bolagsnr',
        align: 'left',
        classes: 'q-table--col-auto-width',
        sortable: true,
      },
      {
        name: 'viewName',
        field: instruction => instruction.entity.viewName,
        label: 'Namn',
        align: 'left',
        sortable: true,
      },
      {
        name: 'status',
        field: row => row,
        label: 'Aktiebokstatus',
        align: 'left',
        format: instruction => toShareRegisterStatusText(instruction.entity),
        sortable: true,
      },
      {
        name: 'countryCode',
        field: instruction => instruction.entity.countryCode,
        label: 'Land',
        align: 'left',
        sortable: true,
      },
      {
        name: 'submittedAt',
        field: row => row?.submittedAt,
        label: 'Inskickat',
        align: 'left',
        format: val => (val ? formatDateTime(new Date(val)) : ''),
        sortable: true,
      },
      {
        name: 'updatedAt',
        field: row => row.updatedAt,
        label: 'Senaste uppdatering',
        align: 'left',
        format: formatDateTime,
        sortable: true,
      },
    ];
  }
}
</script>
