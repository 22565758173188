<template>
  <a-page-section class="InsuranceBasics padded">
    <div class="row q-col-gutter-x-xl">
      <q-list :separator="true" class="standard-list col-md-6">
        <a-list-item label="Försäkringsägare">
          {{ ownership.investor?.viewName }}
        </a-list-item>
        <a-list-item label="Försäkringsbolag">
          <a-select
            v-model="ownership.owner"
            :options="insuranceCompanies"
            v-bind="inputAttributes"
            :rules="validation.required('Försäkringsbolag')"
            map-options
            option-label="viewName"
          />
        </a-list-item>
        <a-list-item label="Försäkringsnummer">
          <q-input v-bind="inputAttributes" v-model="ownership.insuranceNumber"
        /></a-list-item>
        <a-list-item label="Depåinstitut">
          <SelectCustodian v-model="ownership.custodian" v-bind="inputAttributes" :owner="ownership.owner" />
        </a-list-item>
        <a-list-item label="Depånummer">
          <q-input v-bind="inputAttributes" v-model="ownership.custodianAccountNumber"
        /></a-list-item>
        <a-list-item label="Partnerkund">
          <q-checkbox v-model="ownership.isPartnerClient" :disable="inputAttributes.readonly"></q-checkbox>
        </a-list-item>
        <a-list-item label="Förmedlare" v-if="ownership.isPartnerClient">
          <SelectEntity
            class="q-mt-sm"
            v-model="partnerContact.entity"
            v-bind="inputAttributes"
            :entityType="entityType"
            placeholder="Bolag"
            :rules="validation.required('Bolag')"
          >
          </SelectEntity>
          <div class="row q-mt-sm">
            <div class="col-8">
              <q-input
                placeholder="Namn"
                v-model="partnerContact.firstName"
                v-bind="inputAttributes"
                :rules="validation.required('Namn')"
              ></q-input>
            </div>
            <div class="col-4">
              <q-input
                placeholder="Telefon"
                v-model="partnerContact.phone"
                v-bind="inputAttributes"
              ></q-input>
            </div>
          </div>
          <div class="row q-mt-sm">
            <div class="col">
              <q-input
                placeholder="Email"
                v-model="partnerContact.email"
                v-bind="inputAttributes"
              ></q-input>
            </div>
          </div>
        </a-list-item>
      </q-list>

      <q-list :separator="true" class="standard-list col-md-6">
        <a-list-item label="Startdatum">
          <a-input-date v-model="ownership.startDate" v-bind="inputAttributes" />
        </a-list-item>
        <a-list-item label="Slutdatum">
          <a-input-date
            v-model="ownership.endDate"
            v-bind="inputAttributes"
            :readonly="!ownership.endDate || inputAttributes.readonly"
          />
        </a-list-item>
        <a-list-item label="Status">
          <p>{{ ownership.status }}</p>
        </a-list-item>
        <a-list-item label="Status för granskning">
          <p>
            <span> {{ ownership.stateText }}</span>

            <q-btn
              v-bind="$defs.btn"
              color="secondary"
              dense
              rounded
              flat
              class="review-btn"
              v-if="needReview"
              @click="$emit('setConfirmed')"
              >Sätt som granskad</q-btn
            >
          </p>
        </a-list-item>

        <a-list-item label="Avgift">
          <q-input v-model="ownership.insuranceFee" v-bind="inputAttributes" />
        </a-list-item>
        <a-list-item label="Totalt marknadsvärde">
          <p>{{ formatNumber(totalMarketValue) }}</p>
        </a-list-item>
      </q-list>
    </div>
  </a-page-section>
</template>
<script lang="ts">
// @ts-nocheck
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { validation } from '@shared/common';
import { Ownership, ContactInfo, Position } from '@/models';
import SelectEntity from '@/views/entity/SelectEntity.vue';
import SelectCustodian from '@/views/entity/SelectCustodian.vue';
import { OwnershipState, EntityType } from '@shared/index';
import { findInsuranceCompanies } from '@/models/entity/entityService';
import { findPositions } from '@/models/position/positionService';
import { sumBy } from 'lodash';

const NORDNET_NATIONALID = '5164060286';

@Component({
  components: { SelectEntity, SelectCustodian },
})
export default class InsuranceBasics extends Vue {
  @Prop() ownership: Ownership;
  @Prop() readonly: boolean;

  insuranceCompanies = null;
  positions: Array<Position> = [];

  async mounted() {
    this.insuranceCompanies = await findInsuranceCompanies();
    if (this.ownership.id) {
      this.positions = await findPositions({ ownership: this.ownership });
    }
  }

  get totalMarketValue() {
    return sumBy(this.positions, positions => positions.currentValue);
  }

  get inputAttributes() {
    return {
      dense: true,
      filled: true,
      square: true,
      readonly: this.readonly,
    };
  }

  get validation() {
    return validation;
  }

  get needReview() {
    return this.ownership.state === OwnershipState.REVIEW;
  }

  get partnerContact() {
    if (!this.ownership.partnerContact) {
      this.ownership.partnerContact = new ContactInfo();
    }
    return this.ownership.partnerContact;
  }

  get entityType() {
    return [
      EntityType.issuer,
      EntityType.kapsureIssuer,
      EntityType.insuranceCompany,
      EntityType.participant,
      EntityType.parentCompany,
    ];
  }
}
</script>
<style lang="scss">
.InsuranceBasics {
  .review-btn {
    margin-left: 10px;
    font-size: 14px;
  }
}
</style>
