<template>
  <div class="relative-position">
    <pre v-if="stringify" :class="preClasses">{{ JSON.stringify(data, undefined, 1) }}</pre>
    <pre v-else :class="preClasses">{{ data }}</pre>
    <div class="code-box-cover" v-if="!open" @click="open = true"></div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
@Component({})
export default class FoldableCodeBox extends Vue {
  @Prop({ default: true }) stringify;
  @Prop({ default: undefined }) data;
  open = false;
  get preClasses() {
    return `code-box ${this.open ? 'open' : 'closed'}`;
  }
}
</script>
<style lang="scss">
pre.code-box {
  width: 100%;
  margin: 0;
  resize: both;
  font-size: 0.6rem;
  &.closed {
    overflow: hidden;
    height: 70px;
    padding: 0.1rem;
    background-color: #fff;
  }
  &.open {
    overflow: scroll;
    height: 100px;
    padding: 0.5rem;
    background-color: #f5f5f5;
  }
}
.code-box-cover {
  inset: 0;
  position: absolute;
  cursor: pointer;
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}
</style>
