<template>
  <div v-if="ready">
    <div v-if="header" class="row">
      <div class="col">
        <h1 v-if="header">{{ header }}</h1>
      </div>
      <div v-if="showCreateButton" class="col col-auto">
        <NewInstructionButton :main-type="mainType" :entity="searchQuery.entity" />
      </div>
    </div>
    <div class="row q-col-gutter-md instruction-search-row">
      <div class="col col-12 col-md-8 col-lg-9">
        <InstructionSearch @search="search" :searchQuery="searchQuery" />
      </div>
      <div v-if="!header && showCreateButton" class="col col-12 col-md-4 col-lg-3 action-btn-col">
        <NewInstructionButton :main-type="mainType" :entity="searchQuery.entity" />
      </div>
    </div>
    <InstructionList :list="instructions" class="q-mt-lg" />
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import SearchQuery from '@shared/web/models/instruction/InstructionSearchQuery';
import * as _ from 'lodash';
import { findEntity } from '@/models/entity/entityService';
import { setActiveNavigationModel } from '@/utils';
import { findInstrument } from '@/models/instrument/instrumentService';
import InstructionList from '@/views/instructionList/InstructionList.vue';
import InstructionSearch from '@/views/instructionList/InstructionSearch.vue';
import NewInstructionButton from '@/views/instructionList/NewInstructionButton.vue';

@Component({
  components: { NewInstructionButton, InstructionSearch, InstructionList },
})
export default class InstructionSearchWithResult extends Vue {
  @Prop({ default: 'Instruktioner' }) header;
  @Prop({ default: true }) showCreateButton;
  @Prop({ required: true }) mainType;
  instructions = [];
  searchQuery = null;

  async created() {
    await this.loadData();
  }

  async loadData() {
    const searchQuery = SearchQuery.newModel(this.$route.query);

    if (searchQuery.entity && _.isString(searchQuery.entity)) {
      searchQuery.entity = await findEntity(searchQuery.entity);
      setActiveNavigationModel(searchQuery.entity);
    }
    if (searchQuery.instrument && _.isString(searchQuery.instrument)) {
      searchQuery.instrument = await findInstrument(searchQuery.instrument);
    }
    searchQuery.mainType = this.mainType;

    Object.assign(this, { searchQuery });
  }

  search(searchResult) {
    this.instructions = searchResult;
  }

  get ready() {
    return this.searchQuery != null;
  }
}
</script>
<style lang="scss">
@media only screen and (min-width: $breakpoint-md-min) {
  .instruction-search-row {
    .action-btn-col button {
      width: 100%;
    }
  }
}
</style>
