<template>
  <q-markup-table flat class="standard-table InstrumentSourceTable" separator="horizontal">
    <thead>
      <tr class="additional-head">
        <th></th>
        <th colspan="3" class="marked-bg"><span class="text">Innan Split</span></th>
        <th colspan="1"><span class="text">Splitvillkor</span></th>
        <th colspan="10"><span class="text">Efter Split</span></th>
      </tr>
      <tr>
        <th class="text-left">Aktie</th>
        <th class="text-right marked-bg">Antal aktier</th>
        <th class="text-right marked-bg">Kvotvärde</th>
        <th class="text-right marked-bg">Aktiekapital</th>

        <th class="text-right">
          <div class="splitvillkor">
            <span>Nya</span>
            <span>Befintliga</span>
          </div>
        </th>
        <th class="text-right number">Antal aktier</th>
        <th class="text-right number">Kvotvärde</th>
        <th class="text-right number">Aktiekapital</th>
      </tr>
    </thead>
    <tbody>
      <InstrumentSourceRow
        v-for="source in instruction.corporateEvent.instrumentSources"
        :source="source"
        :shareRegister="shareRegister"
        :instruction="instruction"
        :readonly="readonly"
        :validator="validator"
        :key="source.instrument.id"
      />
    </tbody>
  </q-markup-table>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';

import { ShareRegister, Instruction } from '@/models';
import InstrumentSourceRow from './InstrumentSourceRow.vue';

@Component({
  components: { InstrumentSourceRow },
})
export default class InstrumentSourceTable extends Vue {
  @Prop() shareRegister: ShareRegister;
  @Prop() instruction: Instruction;
  @Prop() readonly: boolean;
  @Prop() validator;

  fillDestinations() {
    const { validator } = this;
    this.instruction.destinations.forEach(destination => {
      const partyValidation = validator.forParty(destination);
      const sourceValidation = validator.forInstrumentSource(destination.instrument);
      const ratio = sourceValidation.ratio;
      if (ratio == null) {
        return;
      }

      destination.quantity = Math.floor(partyValidation.totalQuantity / ratio);
    });
  }
}
</script>
<style lang="scss">
.InstrumentSourceTable {
  thead tr.additional-head {
    height: 24px;

    th {
      height: 24px;
      border: none;
      font-weight: 400;
    }
    .text {
      position: relative;
      top: 8px;
    }
  }

  .marked-bg {
    background: #f0f0f0;
  }
  th {
    .splitvillkor {
      display: flex;
      align-items: baseline;
      justify-content: flex-end;
      gap: 0.5em;
      span {
        flex-basis: 50%;
      }
    }
  }
}
</style>
