import { Entity, Instruction, ShareRegister, Instrument, InstructionParty } from '@/models';
import InstructionSearchQuery from '@shared/web/models/instruction/InstructionSearchQuery';
import instructionValidator from '@shared/models/instructionValidator/instructionValidator';
import { findShareRegister } from '@/models/shareRegister/shareRegisterService';
import { initModel, modelHandler, serialize, serializedChanges } from '../base';
import { getModel } from '@/store';
import * as _ from 'lodash';

import { InstructionState, TransactionType } from '@shared/models/types';
import axios from 'axios';
const baseUrl = '/api/instruction';
const { saveModel, searchModels, findModel, updateWithChanges, destroyModel } = modelHandler(Instruction, baseUrl);
import { asModelId } from '@/utils';
import { isEmpty } from 'lodash';

export async function saveInstruction(instruction: Instruction, update?) {
  return update != null ? await updateWithChanges(instruction, update) : await saveModel(instruction);
}

export async function destroyInstruction(instruction) {
  return destroyModel(instruction);
}

export function findNewInsuranceOwnersWithNoInvestor(instruction: Instruction) {
  const parties = instruction.destinations?.filter(party => party.owner?.isInsuranceCompany && party.investor == null);
  if (isEmpty(parties)) {
    return [];
  }
  const changes = getSerializedChanges(instruction) as any;

  const isChanged = party => {
    const match = _.find(changes.destinations, dest => dest.owner === party.owner.id);
    return match != null;
  };

  return parties.filter(party => isChanged(party));
}

function getSerializedChanges(instruction: Instruction) {
  if (instruction.id == null) {
    return serialize(instruction);
  }
  return serializedChanges(instruction, getModel(instruction.id, Instruction));
}

export async function findInstruction(id) {
  return findModel(id);
}

export async function issueWarrant(instrument) {
  const { data } = await axios.post(baseUrl + '/issuewarrant', { instrument: instrument.id });
  return Instrument.newModel(data, true);
}

export async function findInstructionAndShareRegister(id) {
  const { data } = await axios.get(baseUrl + '/withshareregister/' + id);
  return {
    shareRegister: ShareRegister.newModel(data.shareRegister, true),
    instruction: Instruction.newModel(data.instruction, true),
  };
}

export async function search(query: InstructionSearchQuery): Promise<Array<Instruction>> {
  const result = await searchModels(query);
  return result;
}

export async function findActiveInstructions(
  entity: Entity,
  mainType,
  state = InstructionState.IN_PROGRESS,
): Promise<Array<Instruction>> {
  if (!entity.isKapclearIssuer) {
    return [];
  }
  return search(new InstructionSearchQuery({ entity, mainType, state }));
}

export async function findShareRegisterCreatedInstruction(entity: Entity): Promise<Instruction | undefined> {
  const result = await search(
    new InstructionSearchQuery({ entity, state: null, type: TransactionType.SHAREREGISTER_CREATED }),
  );
  if (result.length > 0) {
    return result[0];
  }
  return undefined;
}

function isSameModel(a, b) {
  return (a == null && b == null) || a === b;
}

export function makeInstructionValidator(instruction: Instruction, shareRegister: ShareRegister) {
  return instructionValidator({
    instruction,
    entity: shareRegister.entity,
    instruments: shareRegister.instruments,
    positions: shareRegister.positions,
    isSameModel,
    settleDate: shareRegister.settleDate || instruction.settleDate,
    activeInstructions: shareRegister.activeInstructions,
  });
}

export async function loadShareRegisterForInstruction(instruction: Instruction) {
  let { settleDate } = instruction;
  const { entity, tradeEvent, corporateEvent } = instruction;
  if (corporateEvent && corporateEvent.recordDate) {
    settleDate = corporateEvent.recordDate;
  }
  return findShareRegister({ entity, settleDate, instrument: tradeEvent?.source.instrument });
}

export async function loadShareRegisterForSettleDate(instruction: Instruction) {
  const { entity, tradeEvent, settleDate } = instruction;

  return findShareRegister({ entity, settleDate, instrument: tradeEvent?.source.instrument });
}

export async function makeNewInstruction({
  type,
  entity,
  rootInstruction,
  destinations,
  sources,
  instrument,
}: {
  type: TransactionType;
  entity: Entity;
  rootInstruction?: Instruction;
  destinations: Array<InstructionParty>;
  sources: Array<InstructionParty>;
  instrument?: Instrument;
}) {
  const { data } = await axios.post(baseUrl + '/new', {
    type,
    entity: asModelId(entity),
    rootInstruction: asModelId(rootInstruction),
    destinations,
    sources,
    instrument: asModelId(instrument),
  });
  const instruction = await Instruction.newModel(data, true);

  const shareRegister = await loadShareRegisterForInstruction(instruction);

  return { instruction, shareRegister };
}

export function getInterimPositions(instruction: Instruction, shareRegister: ShareRegister) {
  if (instruction.state === InstructionState.EXECUTED_INTERIM) {
    const { corporateEvent } = instruction;
    const interimInstruments = corporateEvent.instrumentSources.map(source => source.interimShare);
    const interimPositions = shareRegister.positions.filter(position => {
      return interimInstruments.find(instrument => isSameModel(instrument, position.instrument));
    });
    return interimPositions;
  } else if ((instruction.state = InstructionState.EXECUTED)) {
    return instruction.interimPositions || [];
  }
  return [];
}
