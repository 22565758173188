<template>
  <q-dialog ref="dialog" persistent>
    <div class="q-dialog-plugin MergeOwnershipModal">
      <h4 class="table-header">Försäkringen du kryssar i slås ihop med den du valt i tabellen</h4>
      <q-table
        :rows="ownerships"
        :columns="columns"
        :pagination="pagination"
        row-key="id"
        flat
        hide-pagination
        hide-no-data
        hideSelectedBanner
        class="standard-table"
        selection="single"
        v-model:selected="selected"
        :onSelection="onSelection"
      >
        <template v-slot:body-cell-insuranceNumber="props">
          <q-td :props="props">
            <router-link :to="props.row.viewUrl">{{ props.value }}</router-link>
          </q-td>
        </template>
        <template v-slot:bottom-row>
          <q-tr>
            <q-td colspan="100%" align="right">
              <q-btn size="small" v-bind="$defs.btn" flat @click="closeModal" label="Avbryt" />
              <q-btn
                flat
                color="grey-2"
                textColor="primary"
                label="Slå ihop försäkring"
                size="small"
                @click="onMergeOwnership"
                :disabled="selected.length === 0"
              />
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
  </q-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-facing-decorator';
import { QTableProps } from 'quasar';
import { Ownership } from '@/models';

type ExtendedOwnership = {
  selected: boolean;
} & Ownership;
type SelectedItemType = {
  rows: readonly ExtendedOwnership[];
  keys: readonly string[];
  added: boolean;
  evt: Event;
};
@Component({})
export default class MergeOwnershipList extends Vue {
  @Prop() ownerships: Ownership[];
  @Prop() targetedOwnershipId: string;
  @Prop() onMerge: Function;

  selected = [];
  selectedObject = null;

  onSelection(selectedItem: SelectedItemType) {
    selectedItem.rows[0].selected = selectedItem.added;
    if (selectedItem.rows[0].selected) {
      this.selectedObject = selectedItem.rows[0];
    }
  }

  async onMergeOwnership() {
    if (this.selectedObject !== null) {
      await this.onMerge(this.targetedOwnershipId, this.selectedObject.id);
      this.closeModal();
    }
  }

  @Watch('$route')
  closeModal() {
    this.$emit('hide');
  }

  columns: QTableProps['columns'] = [
    {
      name: 'owner',
      field: 'owner',
      label: 'Försäkringsbolag',
      align: 'left',
      format: owner => owner?.viewName,
      classes: 'insurance-row-test-class',
    },
    {
      field: 'insuranceNumber',
      name: 'insuranceNumber',
      label: 'Försäkringsnummer',
      align: 'left',
    },
    {
      name: 'custodian',
      field: 'custodian',
      label: 'Depåinstitut',
      align: 'left',
      format: custodian => custodian?.viewName,
    },
    {
      field: 'custodianAccountNumber',
      name: 'custodianAccountNumber',
      label: 'Depånummer',
      align: 'left',
    },
    {
      field: insurance => insurance.status,
      name: 'status',
      label: 'Status',
      align: 'left',
    },
    {
      name: 'actions',
      field: null,
      label: '',
    },
  ];

  pagination = {
    rowsPerPage: 0,
    sortBy: 'owner',
  };
}
</script>

<style lang="scss">
.MergeOwnershipModal {
  min-width: 1000px;
}
</style>
