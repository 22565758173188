<template>
  <q-dialog ref="dialog" persistent>
    <div class="q-dialog-plugin ConfirmModal">
      <a-page-section :header="header" class="padded">
        <q-banner rounded class="bg-red text-white q-mb-md" v-if="errorMessage">
          {{ getErrorText(errorMessage) }}
        </q-banner>

        <template v-if="valid">
          <div class="q-pb-md">
            {{ confirmText }}
            <q-spinner size="12" v-if="finalizing" />
          </div>
          <div>
            <a-input-date :disable="finalizing" v-model="settleDate" label="Införd i aktieboken" v-bind="$defs.input" />
          </div>
          <div class="q-pt-lg">
            <div class="q-py-sm">Meddela via e-post:</div>
            <div>
              <q-checkbox v-model="notifyAdmins" :val="true" label="Administratörer" />
            </div>
            <div>
              <q-checkbox v-model="notifyRoles" :val="true" label="Bolagsföreträdare" />
            </div>
            <div>
              <q-checkbox v-model="notifyInvestors" :val="true" label="Investerare" />
            </div>
          </div>
        </template>
        <template v-else> Aktieboken har felaktigheter och kan därför inte skapas </template>
      </a-page-section>

      <q-card-actions align="right" class="q-pa-md">
        <q-btn v-bind="$defs.btn" flat @click="cancel" :disable="finalizing">Avbryt</q-btn>
        <q-btn type="submit" v-bind="$defs.btn" :disable="finalizing" @click="confirm"> Fortsätt </q-btn>
      </q-card-actions>
    </div>
  </q-dialog>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { DialogComponent } from '@shared/mixins';
import { ShareRegister, InstructionState, Instruction } from '@shared/web/models';
import { makeShareRegisterValidation } from '@/models/shareRegister/shareRegisterService';
import { setShareRegisterState } from '@/models/shareRegister/shareRegisterService';

@Component({
  mixins: [DialogComponent],
})
export default class LockShareRegisterModal extends Vue {
  @Prop() onConfirm: Function;
  @Prop() onReject: Function;
  @Prop() shareRegister: ShareRegister;
  @Prop() instruction: Instruction;
  @Prop() skipIssuerDataValidation: boolean;

  errorMessage: string = null;
  text: string = null;
  valid = false;
  header = 'Skapa aktiebok';
  finalizing = false;
  _settleDate = null;
  notifyAdmins = true;
  notifyRoles = true;
  notifyInvestors = true;

  mounted() {
    const totals = makeShareRegisterValidation(
      this.shareRegister,
      this.shareRegister.positions,
      this.skipIssuerDataValidation,
    );
    this.valid = totals.isValid();
    this.settleDate = this.instruction?.settleDate || this.shareRegister.settleDate || new Date();
  }

  get settleDate() {
    return this._settleDate;
  }

  set settleDate(date) {
    if (this.instruction) {
      this.instruction.settleDate = date;
    }
    this._settleDate = date;
  }

  get confirmText() {
    if (this.finalizing) {
      return 'Verifierar aktieboken...';
    }
    if (this.hasInsuranceOwnersWithNoInvestor()) {
      return 'Det finns ägare som är försäkringsbolag, men som saknar investerare / försäkringsnummer. Är det korrekt? Det här kommer skapa och låsa aktieboken.';
    }
    return 'Det här kommer skapa och låsa aktieboken.';
  }

  hasInsuranceOwnersWithNoInvestor() {
    const positions = this.shareRegister.positions.filter(
      party => party.owner.isInsuranceCompany && party.investor == null,
    );
    return positions.length > 0;
  }

  cancel() {
    this.$emit('hide');
  }

  async confirm() {
    try {
      this.finalizing = true;
      if (this.valid) {
        await setShareRegisterState(this.shareRegister, {
          settleDate: this.settleDate,
          state: InstructionState.EXECUTED,
          skipIssuerDataValidation: this.skipIssuerDataValidation,
          notifyAdmins: this.notifyAdmins,
          notifyRoles: this.notifyRoles,
          notifyInvestors: this.notifyInvestors,
        });
      }
      this.done();
    } catch (error) {
      this.errorMessage = error;
    } finally {
      this.finalizing = false;
    }
  }
}
</script>
