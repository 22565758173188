<template>
  <q-card flat class="q-pa-none PageSection">
    <h4 class="table-header" v-if="header">{{ header }}</h4>
    <q-card-section class="q-pa-none">
      <slot />
    </q-card-section>
  </q-card>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
@Component({
  components: {},
})
export default class Card extends Vue {
  @Prop() header;
}
</script>
<style lang="scss">
.PageSection {
  margin: 24px 0;
  &.padded .q-card__section--vert {
    padding: 16px;
  }
}

.q-dialog-plugin .PageSection {
  border-radius: 0;
}
</style>
