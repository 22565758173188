import store from './store';

export function getAppMode() {
  return store.appMode || setAppModeInStore(localStorage.getItem('appMode')) || setAppMode(AppModes.DEFAULT);
}

export function setAppMode(mode: string) {
  setAppModInLocalStorage(mode);
  return setAppModeInStore(mode);
}

function setAppModeInStore(mode: string) {
  store.appMode = mode;
  return store.appMode;
}

function setAppModInLocalStorage(mode: string) {
  localStorage.setItem('appMode', mode);
}

export function isKapsureMode() {
  return getAppMode() == AppModes.KAPSURE;
}

export enum AppModes {
  DEFAULT = 'default',
  KAPSURE = 'kapsure',
}
