import SearchQuery from '@shared/web/models/SearchQuery';
import { EntitySearchOptions } from '@shared/entity/EntitySearchOptions';

export class EntitySearchQuery extends SearchQuery {
  constructor(attr = {}) {
    super(attr);
    Object.assign(this, attr); // @vue3 likanande som ContactInfo
  }
  options: EntitySearchOptions;
}
