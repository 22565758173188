<template>
  <div class="q-px-md">
    <a-table-row-btn label="Lägg till dokument" @click="pickUploadFiles" />
    <input ref="fileInput" type="file" multiple id="file-input" style="display: none" @change="onChange" />
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Ref } from 'vue-facing-decorator';

@Component({
  components: {},
})
export default class InputFileGeneral extends Vue {
  @Prop() model!: any;
  @Prop() uploadFile!: any;
  @Prop() FileUpload!: any;
  @Ref('fileInput') readonly fileInput!: HTMLInputElement;

  pickUploadFiles() {
    this.$nextTick(() => {
      this.fileInput.click();
    });
  }

  onChange(event: Event) {
    const files = Array.from((event.target as HTMLInputElement).files || []);
    const fileUploads = files.map(file => new this.FileUpload(file, this.model));
    fileUploads.forEach(fileUpload => this.uploadFile(fileUpload, this.model));
  }
}
</script>
