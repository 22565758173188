<template>
  <div class="ExerciseWarrantContent">
    <a-page-section class="padded">
      <div class="row q-col-gutter-x-xl">
        <q-list separator class="standard-list col-md-6">
          <a-list-item label="Emittent">
            <a-link :target="instruction.entity" />
          </a-list-item>
        </q-list>
        <q-list separator class="standard-list col-md-6">
          <a-list-item label="Registreringsdag BTA">
            <a-input-date
              v-model="instruction.corporateEvent.interimRegistrationDate"
              v-bind="$defs.input"
              :readonly="true"
            />
          </a-list-item>
          <a-list-item label="Registreringsdag">
            <a-input-date v-model="instruction.registrationDate" v-bind="$defs.input" :readonly="true" />
          </a-list-item>
          <a-list-item label="Införd i aktieboken lös option">
            <a-input-date v-model="instruction.settleDate" v-bind="$defs.input" :readonly="readonly" />
          </a-list-item>
        </q-list>
      </div>
    </a-page-section>
    <WarrantData
      :readonly="readonly"
      :warrant="warrant"
      :rightsData="instruction.corporateEvent.rightsData"
      :entity="shareRegister.entity"
      :instruction="instruction"
      v-if="shareRegister && warrant"
    />

    <template v-if="showInterimDestinations">
      <h4 class="text-h4 text-weight-regular q-mb-md q-mt-xl">Innehav BTA</h4>
      <InterimDestinationsTableCard
        :shareRegister="settleDateShareRegister"
        :instruction="instruction"
        :validator="validator"
        :readonly="readonly || isInterim"
      />
    </template>

    <template v-if="shareRegister">
      <h4 class="text-h4 text-weight-regular q-mb-md q-mt-xl">Teckningslista Nyemission</h4>
      <DestinationsTableCard
        :shareRegister="shareRegister"
        v-if="shareRegister"
        :instruction="instruction"
        :validator="validator"
        :readonly="readonly || isInterim"
        :disableAdd="true"
      />
    </template>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';

import { Instruction, InstructionState, ShareRegister } from '@/models';
import WarrantData from '@/views/instrument/WarrantData.vue';
import TableSettings from '@shared/web/models/shareRegister/TableSettings';

import DestinationsTableCard from '@/views/instruction/corporateEvent/DestinationsTableCard.vue';
import InterimDestinationsTableCard from '@/views/instruction/corporateEvent/InterimDestinationsTableCard.vue';
import { getInterimPositions } from '@/models/instruction/instructionService';

@Component({
  components: {
    DestinationsTableCard,
    InterimDestinationsTableCard,
    WarrantData,
  },
})
export default class ExerciseWarrantContent extends Vue {
  @Prop() instruction: Instruction;
  @Prop() shareRegister: ShareRegister;
  @Prop() settleDateShareRegister: ShareRegister;
  @Prop({ default: true }) readonly: boolean;
  @Prop() validator;
  @Prop() disableAdd: boolean;
  tableSettings = new TableSettings();

  mounted() {
    this.onSettleDateShareRegisterChange();
  }

  @Watch('settleDateShareRegister')
  @Watch('instruction.state')
  @Watch('readonly')
  onSettleDateShareRegisterChange() {
    if (this.instruction.state === InstructionState.EXECUTED_INTERIM) {
      this.instruction.interimPositions = this.interimPositions;
    }
  }

  get interimPositions() {
    return getInterimPositions(this.instruction, this.settleDateShareRegister);
  }

  get isInterim() {
    return this.instruction.state === InstructionState.EXECUTED_INTERIM;
  }

  get showInterimDestinations() {
    return (
      this.settleDateShareRegister &&
      [InstructionState.EXECUTED_INTERIM, InstructionState.EXECUTED].includes(this.instruction.state)
    );
  }

  get warrant() {
    return this.instruction.corporateEvent.instrumentSources[0].originInstrument;
  }
}
</script>
<style lang="scss">
.ExerciseWarrantContent {
  .q-markup-table {
    overflow: initial;
  }
  th.number {
    width: 12%;
  }
  .w50 {
    width: 50%;
  }
}
</style>
