<template>
  <div class="IssueBondContent">
    <q-card class="q-my-lg" flat>
      <div class="q-py-md">
        <div class="row q-mb-md">
          <SelectEntity
            v-model="instruction.entity"
            entityType="issuer"
            :hideCreate="true"
            @update:modelValue="$emit('changeEntity')"
            :readonly="instruction.entity != null"
            v-bind="$defs.input"
          />
        </div>
        <BondDetails
          :instruction="instruction"
          :shareRegister="shareRegister"
          :readonly="readonly"
          @change="handleDetailsChange"
        />
      </div>
    </q-card>
    <DestinationsTableCard
      :instruction="instruction"
      :entity="entity"
      :shareRegister="shareRegister"
      :readonly="readonly"
      :disable-add="readonly"
      @change="handleDestinationsChange"
    />
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';

import { Entity, Instruction, ShareRegister } from '@/models';
import DestinationsTableCard from '@/views/instruction/corporateEvent/DestinationsTableCard.vue';
import SelectEntity from '@/views/entity/SelectEntity.vue';
import BondDetails from '@shared/views/bond/BondsDetails.vue';
import { distributedTotalsInDebtInstruments } from '@shared/common';

@Component({
  components: { BondDetails, SelectEntity, DestinationsTableCard },
})
export default class IssueBondContent extends Vue {
  @Prop() instruction: Instruction;
  @Prop() entity: Entity;
  @Prop({ default: true }) readonly: boolean;
  @Prop() shareRegister: ShareRegister;

  handleDetailsChange() {
    this.recalculateDestinationsAmounts();
  }

  handleDestinationsChange() {
    this.recalculateDestinationsAmounts();
  }

  recalculateDestinationsAmounts() {
    distributedTotalsInDebtInstruments(this.instruction);
  }
}
</script>
