<template>
  <tr>
    <td colspan="3">
      <router-link :to="instrument.viewUrl" class="standard-text">{{ instrument.viewName }}</router-link>
    </td>
    <td v-if="tableSettings.isInvestorRegister"></td>
    <td colspan="2" v-if="columns.shareNumbers"></td>

    <td></td>
    <template v-if="instrument.isShare">
      <q-td class="text-right validation" :class="{ valid: totals.positionsValid }">
        <template v-if="!totals.valid">{{ formatNumber(totals.totalQuantity) }} av</template>
        {{ formatNumber(shareData.totalQuantity) }}
      </q-td>
      <td class="text-right">{{ formatPercent(totals.totalShare, 2) }}</td>
      <td class="text-right">{{ formatPercent(totals.votingPowerQuota, 2) }}</td>
    </template>

    <td colspan="5"></td>
  </tr>
</template>
<script lang="ts">
// @ts-nocheck
// @TODO kolla formatPercent(val, 2). Ska 2 vara där? Det fanns inget andra argument i det existerande filtret
import { Instrument, ShareRegister } from '@/models';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { makeShareRegisterValidation } from '@/models/shareRegister/shareRegisterService';
import TableSettings from '@shared/web/models/shareRegister/TableSettings';
@Component({})
export default class InstrumentRow extends Vue {
  @Prop() instrument: Instrument;
  @Prop() shareRegister: ShareRegister;
  @Prop() tableSettings: TableSettings;
  @Prop() columns;
  get issuerData() {
    return this.shareRegister.entity.issuerData;
  }

  get shareData() {
    return this.instrument.shareData.getHistoricVersion(this.shareRegister.settleDate);
  }

  get totals() {
    const totals = makeShareRegisterValidation(this.shareRegister);
    return totals.forInstrument(this.instrument);
  }
}
</script>
