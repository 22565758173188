import { createApp } from 'vue';
import { Quasar } from 'quasar';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

import App from './App.vue';
import router from './router';
import './axios.ts';
import initComponents from './components';
import * as defaultStyle from './defaultStyle';
import { setUserDataToSentry } from './models/sentry/sentryService';
import { getEnv } from './utils';
import quasarConfig from './quasar';
import errorHandler from './errorHandler';

const app = createApp(App);

app.config.globalProperties.$defs = defaultStyle;
app.config.errorHandler = errorHandler;
app.use(Quasar, quasarConfig);
initComponents(app);

const sentryDsn = import.meta.env.VITE_APP_SENTRY_DSN;

if (sentryDsn) {
  Sentry.init({
    app,
    dsn: sentryDsn,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['localhost', 'bo-dev.kapclear.se', 'bo-staging.kapclear.se', 'bo.kapclear.se', /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    trackComponents: true,
    environment: getEnv() || import.meta.env.MODE,
    release: import.meta.env.npm_package_version,
  });

  setUserDataToSentry();
}

app.use(router);
app.mount('#app');
