<template>
  <div class="kapclear-editor q-pa-sm">
    <div class="buttons" v-if="editor">
      <button
        type="button"
        @click="editor.chain().focus().toggleBold().run()"
        :disabled="!editor.can().chain().focus().toggleBold().run()"
        :class="{ 'is-active': editor.isActive('bold') }"
        :tabindex="tabIndex"
      >
        <q-icon name="format_bold" />
      </button>
      <button
        type="button"
        @click="editor.chain().focus().toggleItalic().run()"
        :disabled="!editor.can().chain().focus().toggleItalic().run()"
        :class="{ 'is-active': editor.isActive('italic') }"
        :tabindex="tabIndex"
      >
        <q-icon name="format_italic" />
      </button>
      <button
        type="button"
        @click="editor.chain().focus().toggleStrike().run()"
        :disabled="!editor.can().chain().focus().toggleStrike().run()"
        :class="{ 'is-active': editor.isActive('strike') }"
        :tabindex="tabIndex"
      >
        <q-icon name="format_strikethrough" />
      </button>
      <button
        type="button"
        @click="editor.chain().focus().setParagraph().run()"
        :class="{ 'is-active': editor.isActive('paragraph') }"
        :tabindex="tabIndex"
      >
        <q-icon name="view_headline" />
      </button>
      <button
        type="button"
        @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
        :tabindex="tabIndex"
      >
        <span>h1</span>
      </button>
      <button
        type="button"
        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
        :tabindex="tabIndex"
      >
        <span>h2</span>
      </button>
      <button
        type="button"
        @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
        :tabindex="tabIndex"
      >
        <span>h3</span>
      </button>
      <button
        type="button"
        @click="editor.chain().focus().toggleBulletList().run()"
        :class="{ 'is-active': editor.isActive('bulletList') }"
        :tabindex="tabIndex"
      >
        <q-icon name="format_list_bulleted" />
      </button>
      <button
        type="button"
        @click="editor.chain().focus().toggleOrderedList().run()"
        :class="{ 'is-active': editor.isActive('orderedList') }"
        :tabindex="tabIndex"
      >
        <q-icon name="format_list_numbered" />
      </button>
      <button
        type="button"
        @click="editor.chain().focus().toggleBlockquote().run()"
        :class="{ 'is-active': editor.isActive('blockquote') }"
        :tabindex="tabIndex"
      >
        <q-icon name="format_quote" />
      </button>
      <button type="button" @click="editor.chain().focus().setHorizontalRule().run()" :tabindex="tabIndex">
        <q-icon name="horizontal_rule" />
      </button>
      <button
        type="button"
        @click="editor.chain().focus().undo().run()"
        :disabled="!editor.can().chain().focus().undo().run()"
        :tabindex="tabIndex"
      >
        <q-icon name="undo" />
      </button>
      <button
        type="button"
        @click="editor.chain().focus().redo().run()"
        :disabled="!editor.can().chain().focus().redo().run()"
        :tabindex="tabIndex"
      >
        <q-icon name="redo" />
      </button>
    </div>
    <div class="editor-content-wrapper">
      <editor-content :editor="editor" />
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';
import { AnyExtension, Editor, EditorContent } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import { Placeholder } from '@tiptap/extension-placeholder';

@Component({
  components: { EditorContent },
})
export default class CommentEditor extends Vue {
  @Prop() modelValue;
  editor;
  mounted() {
    this.editor = new Editor({
      content: this.modelValue,
      extensions: [
        StarterKit as unknown as AnyExtension,
        Placeholder.configure({
          placeholder: 'Skriv din kommentar här...',
        }),
      ],
      onUpdate: ({ editor }) => {
        this.$emit('update:modelValue', editor.getHTML());
      },
    });
  }

  get tabIndex() {
    return -1;
  }
  async unmount() {
    this.editor.destroy();
  }
  @Watch('modelValue')
  handleModelValueChange(modelValue) {
    const html = this.editor.getHTML();
    if (html !== modelValue) {
      this.editor.commands.setContent(modelValue, false);
    }
  }
}
</script>
<style lang="scss">
.kapclear-editor {
  background: #fff;

  & > .buttons > button {
    border: 0;
    padding: 10px;
    background: none;
    font-size: 1.2rem;
    color: #777;
    cursor: pointer;
    &.is-active {
      color: #000;
    }
  }
  .ProseMirror {
    outline: none;
    padding: 14px;
  }
  .ProseMirror p.is-editor-empty:first-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
}
</style>
