<template>
  <tr class="position-row" :data-testid="`position-${position.id}`">
    <td>
      <q-btn
        size="sm"
        color="primary"
        round
        flat
        dense
        class="icon-position test-toggle-expand"
        :id="position.id"
        @click="toggleExpandButton"
        :icon="clickedButtons[position.id] ? 'remove' : 'add'"
      ></q-btn>
    </td>
    <td class="text-left" v-if="columns.absoluteInvestorManager">
      <router-link :to="position.absoluteInvestorManager.viewUrl" v-if="position.absoluteInvestorManager">{{
        position.absoluteInvestorManager.viewName
      }}</router-link>
    </td>
    <td class="text-left" v-if="columns.instrument">
      <router-link :to="position.instrument.viewUrl" v-if="position.instrument">{{
        position.instrument.viewName
      }}</router-link>
    </td>
    <td class="text-left" v-if="columns.owner">
      <router-link :to="position.owner.viewUrl" v-if="position.owner">{{ position.owner.viewName }}</router-link>
      <div v-if="totals.error" class="warning-error-message">{{ getErrorText(totals.error) }}</div>
    </td>

    <td class="text-left">
      <router-link :to="position.investor.viewUrl" v-if="position.investor">{{
        position.investor.viewName
      }}</router-link>
    </td>

    <template v-if="columns.shareNumbers">
      <template v-if="isShare">
        <td class="text-right" :data-testid="`share-number-from-${position.id}`">
          <div>{{ formatNumber(position.shareNumberFrom) }}</div>
          <div v-if="totals.numberSerieError" class="warning-error-message">
            {{ getErrorText(totals.numberSerieError) }}
          </div>
        </td>
        <td class="text-right" :data-testid="`share-number-to-${position.id}`">
          <div>{{ formatNumber(position.shareNumberTo) }}</div>
        </td>
      </template>
      <td colspan="2" v-else></td>
    </template>
    <td class="text-right" :data-testid="`reservations-${position.id}`" v-if="columns.reservations">
      <template v-if="isShare">{{ toBooleanLabel(hasReservations) }} </template>
    </td>
    <td class="text-right" v-if="columns.custodian">
      <template v-if="isShare"> {{ position.custodian && position.custodian.viewName }} </template>
    </td>
    <td class="text-right test-quantity" :class="{ paddingForPledgeText: position.pledgeOwner }">
      <template v-if="position.instrument.isValueDistribution">{{ formatNumber(position.amount) }}</template>
      <template v-else>{{ formatNumber(position.quantity) }}</template>

      <p v-if="position.pledgeOwner" class="ispledge-small">Aktiepost är pantsatt</p>
    </td>
    <td class="text-right">
      <template v-if="isShare">{{ formatPercent(totals.quantityQuota) }}</template>
    </td>
    <td class="text-right">
      <template v-if="isShare">{{ formatPercent(totals.votingPowerQuota) }} </template>
    </td>
    <td class="q-table--col-auto-width">
      <q-btn
        flat
        round
        color="primary"
        icon="more_vert"
        size="sm"
        id="more-menu"
        :data-testid="`action-button-${position.id}`"
      >
        <q-menu>
          <q-list>
            <q-item clickable v-close-popup @click="edit" :data-testid="`action-button-edit-${position.id}`">
              <q-item-section>Editera position </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="viewHistory">
              <q-item-section>Visa historik </q-item-section>
            </q-item>
            <q-item clickable v-close-popup :to="position.transactionsUrl">
              <q-item-section>Visa transaktioner</q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="registerInstruction">
              <q-item-section>Registrera ägarbyte</q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="removePosition" v-if="!isLocked">
              <q-item-section icon="delete">Ta bort</q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
    </td>
  </tr>
</template>
<script lang="ts">
import * as _ from 'lodash';
import { Position, ShareRegister, TransactionType } from '@/models';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { makeShareRegisterValidation } from '@/models/shareRegister/shareRegisterService';
import EditPositionModal from '@shared/views/editPositionModal/EditPositionModal.vue';
import EditPositionHistoryModal from '@shared/views/shareRegister/EditPositionHistoryModal.vue';
import RemovePositionModal from '@shared/views/editPositionModal/RemovePositionModal.vue';
import TableSettings from '@shared/web/models/shareRegister/TableSettings';
import { openNewInstruction } from '@/actions';

@Component
export default class PositionRow extends Vue {
  @Prop() position: Position;
  @Prop() shareRegister: ShareRegister;
  @Prop() tableSettings: TableSettings;
  @Prop() clickedButtons;
  @Prop() columns;
  get issuerData() {
    return this.shareRegister.entity.issuerData;
  }

  get isShare() {
    return this.position.instrument.isShare;
  }

  get isLocked() {
    return this.shareRegister.entity.issuerData.locked;
  }

  get hasReservations() {
    return !_.isEmpty(this.position.reservations);
  }

  get totals() {
    const totals = makeShareRegisterValidation(this.shareRegister);
    return totals.forPosition(this.position);
  }

  toggleExpandButton(event) {
    const id = event.currentTarget.id;
    return this.$emit('expand', { show: !this.clickedButtons[id], id: id });
  }

  edit() {
    this.$q.dialog({
      component: EditPositionModal,
      componentProps: {
        value: this.position,
        edit: true,
        shareRegister: this.shareRegister,
        readonly: this.issuerData.locked,
      },
    });
  }

  removePosition() {
    this.$q.dialog({
      component: RemovePositionModal,
      componentProps: {
        positionClass: Position,
        value: this.position,
        shareRegister: this.shareRegister,
      },
    });
  }

  viewHistory() {
    this.$q.dialog({
      component: EditPositionHistoryModal,
      componentProps: {
        value: this.position,
      },
    });
  }

  registerInstruction() {
    const { quantity, owner, investor, instrument, insuranceNumber, custodian, custodianAccountNumber } = this.position;
    const data = {
      tradeEvent: {
        source: { owner, investor, quantity, insuranceNumber, instrument, custodian, custodianAccountNumber },
      },
      type: TransactionType.TRADE,
      entity: instrument.entity,
      destinations: [{ instrument }],
    };
    openNewInstruction(data);
  }
}
</script>
