<template>
  <div class="SplitContent">
    <a-page-section class="padded">
      <div class="row q-col-gutter-x-xl">
        <q-list separator class="standard-list col-md-6">
          <a-list-item label="Emittent">
            <a-link :target="instruction.entity" v-if="readonly" />
            <SelectEntity
              v-model="instruction.entity"
              entityType="issuer"
              v-bind="$defs.input"
              :readonly="instruction.entity != null"
              :hideCreate="true"
              @update:modelValue="$emit('changeEntity')"
              v-else
            />
          </a-list-item>
          <template v-if="shareRegister">
            <a-list-item label="Kvotvärde">
              {{ formatNumber(issuerData.quotaValue, 2) }}
            </a-list-item>
            <a-list-item label="Totalt antal aktier">
              {{ formatNumber(issuerData.totalQuantity) }}
            </a-list-item>
            <a-list-item label="Totalt aktiekapital">
              {{ formatNumber(issuerData.totalCapital, 2) }}
            </a-list-item>
            <a-list-item label="Avstämningsbolag">
              {{ toBooleanLabel(issuerData.isRecordCompany) }}
            </a-list-item>
            <a-list-item label="Status">
              {{ toInstructionStateLabel(instruction.calculatedState) }}
            </a-list-item>
          </template>
          <p v-if="instruction.warning" class="text-small">
            Det finns pågående ägarbyten med datum för införande i aktieboken efter avstämningsdagen.
          </p>
        </q-list>
        <q-list separator class="standard-list col-md-6">
          <a-list-item label="Splitvillkor">
            <div class="splitvillkor">
              <a-input-number
                v-bind="$defs.input"
                input-class="text-right test-split-factor"
                v-model="instruction.corporateEvent.splitFactor"
                :readonly="readonly"
                :rules="[v => !isNaN(v) || 'Måste vara ett nummer']"
                @change="changeSplitFactor"
              />
              :
              <q-input v-bind="$defs.input" input-class="text-right " value="1" :readonly="true" />
            </div>
          </a-list-item>
          <a-list-item label="Avstämningsdag">
            <a-input-date v-model="instruction.corporateEvent.recordDate" v-bind="$defs.input" :readonly="readonly" />
          </a-list-item>
          <a-list-item label="Införd i aktieboken">
            <a-input-date v-model="instruction.settleDate" v-bind="$defs.input" :readonly="readonly" />
          </a-list-item>

          <a-list-item label="Registreringsdag">
            <a-input-date v-model="instruction.registrationDate" v-bind="$defs.input" :readonly="true" />
          </a-list-item>

          <LastActivity :model="instruction" />
        </q-list>
      </div>
    </a-page-section>
    <a-page-section header="Aktier" v-if="shareRegister">
      <InstrumentSourceTable
        :shareRegister="shareRegister"
        :readonly="readonly"
        :instruction="instruction"
        :validator="validator"
      />
    </a-page-section>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import InstrumentSourceTable from './InstrumentSourceTable.vue';
import { Instruction, Instrument, ShareRegister } from '@/models';
import { options, toInstructionStateLabel } from '@shared/common';
import SelectEntity from '@/views/entity/SelectEntity.vue';
import { validation } from '@shared/common';
import { requiredNumber } from '@shared/utils/validation';
import LastActivity from '@/views/activity/LastActivity.vue';
import { sumBy } from 'lodash';
@Component({
  components: {
    SelectEntity,
    InstrumentSourceTable,
    LastActivity,
  },
})
export default class SplitContent extends Vue {
  @Prop() instruction: Instruction;
  @Prop() shareRegister: ShareRegister;
  @Prop({ default: true }) readonly: boolean;
  @Prop() validator;

  toInstructionStateLabel(val) {
    return toInstructionStateLabel(val);
  }

  get shares(): Array<Instrument> {
    return this.shareRegister && this.shareRegister.instruments;
  }

  changeSplitFactor() {
    const { corporateEvent } = this.instruction;

    corporateEvent.instrumentSources.forEach(instrumentSource => {
      const sourceValidation = this.validator.forSplitInstrumentSource(instrumentSource.instrument);
      instrumentSource.quantity = sourceValidation.addedQuantity;
    });
    this.instruction.quantity = sumBy(corporateEvent.instrumentSources, instrumentSource => instrumentSource.quantity);
    this.$emit('change');
  }

  get entityValidation() {
    return this.validator.forEntity();
  }

  get issuerData() {
    return this.shareRegister && this.shareRegister.getHistoricIssuerData();
  }

  get priceRules() {
    return [requiredNumber];
  }

  get options() {
    return options;
  }

  get validation() {
    return validation;
  }
}
</script>
<style lang="scss">
.SplitContent {
  .q-markup-table {
    overflow: initial;
  }
  th.number {
    width: 12%;
  }
  .w50 {
    width: 50%;
  }
  .splitvillkor {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    gap: 0.5em;
    input {
      [readonly] {
        color: #b3afad !important;
      }
    }
  }
}
</style>
