<template>
  <SelectEntity
    :modelValue="modelValue"
    @change="$emit($event)"
    :entityType="entityType"
    createLabel="Skapa ny Emittent"
    v-bind="$attrs"
  >
  </SelectEntity>
</template>
<script lang="ts">
import { Entity } from '@/models';
import SelectEntity from './SelectEntity.vue';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { EntityType } from '@shared/index';

@Component({
  components: { SelectEntity },
})
export default class SelectIssuer extends Vue {
  @Prop() modelValue: Entity;

  get entityType() {
    return [EntityType.issuer, EntityType.kapsureIssuer];
  }
}
</script>
