<template>
  <div v-if="ready" class="LegalEntityList standard-table">
    <q-table
      :rows="list"
      :columns="columns"
      row-key="id"
      :pagination="pagination"
      :hide-pagination="hidePagination"
      flat
      hide-no-data
    >
      <template v-slot:bottom-row v-if="displayNewButton">
        <a-table-row-btn @click="openCreateLE" label="Ny Juridisk Person" />
      </template>

      <template v-slot:body-cell-viewName="props">
        <q-td :props="props">
          <EntityLink :entity="props.row" show-comments-link="1" />
        </q-td>
      </template>
      <template v-slot:body-cell-menu="props">
        <q-td :props="props">
          <q-btn flat round color="primary" icon="more_vert" size="sm">
            <q-menu>
              <q-list>
                <q-item clickable v-close-popup :to="'/position?entity=' + props.key" v-if="props.row.isKapclearIssuer">
                  <q-item-section>Visa innehav</q-item-section>
                </q-item>
                <q-item clickable v-close-popup @click="mergeEntity(props.row)">
                  <q-item-section>Slå ihop person</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </q-td>
      </template>
    </q-table>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { formatTypeList, formatNationalId, toShareRegisterStatusText } from '@shared/common';
import CreateEntityModal from '@/views/entity/CreateEntityModal.vue';
import { formatISO9075 } from 'date-fns';
import { QTableProps } from 'quasar';
import EntityLink from '@/components/EntityLink.vue';
import { Entity } from '@shared/web/models';
import MergeEntityModal from '@/views/entity/MergeEntityModal.vue';

@Component({
  components: { EntityLink },
})
export default class LegalEntityList extends Vue {
  @Prop() list;
  @Prop({ default: true }) displayNewButton;
  @Prop({ default: false }) hidePagination;

  openCreateLE() {
    this.$q.dialog({
      component: CreateEntityModal,
      componentProps: {
        isPerson: false,
      },
    });
  }

  get baseUrl() {
    return '/legal-entity/share-register/';
  }

  get ready() {
    return this.list != null;
  }

  created() {
    if (!this.hidePagination) {
      this.pagination.rowsPerPage = 20;
    }
  }

  pagination = {
    rowsPerPage: 0,
    sortBy: 'viewName',
  };
  columns: QTableProps['columns'] = [
    {
      name: 'nationalId',
      field: entity => formatNationalId(entity),
      label: 'Nationellt bolagsnr',
      align: 'left',
      classes: 'q-table--col-auto-width',
      sortable: true,
    },
    {
      name: 'viewName',
      sortable: true,
      field: 'viewName',
      label: 'Namn',
      align: 'left',
    },

    {
      name: 'types',
      field: row => row.types,
      label: 'Typ',
      align: 'left',
      format: types => {
        return formatTypeList(types);
      },
      sortable: true,
    },
    {
      name: 'status',
      field: row => row,
      label: 'Aktiebokstatus',
      align: 'left',
      format: legalEntity => toShareRegisterStatusText(legalEntity),
      sortable: true,
    },
    {
      name: 'countryCode',
      field: 'countryCode',
      label: 'Land',
      align: 'left',
      sortable: true,
    },
    {
      name: 'createdAt',
      field: 'createdAt',
      label: 'Registrerad',
      format: createdAt => (createdAt ? formatISO9075(new Date(createdAt)) : '-'),
      sortable: true,
    },
    {
      name: 'menu',
      label: '',
      field: null,
      classes: 'q-table--col-auto-width',
    },
  ];

  mergeEntity(entity: Entity) {
    this.$q.dialog({
      component: MergeEntityModal,
      componentProps: {
        targetedEntity: entity,
        previousSearchList: this.list,
        onMerge() {
          this.$emit('reload');
        },
      },
    });
  }
}
</script>
