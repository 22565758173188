<template>
  <div class="PositionSearch">
    <h4 class="table-header">Sök Innehav</h4>
    <q-form @submit="search" class="search-form">
      <div class="row q-col-gutter-md">
        <div class="col col-8 col-lg-6">
          <SelectEntity
            v-model="searchQuery.entity"
            entityType="issuer"
            v-bind="$defs.input"
            :hideCreate="true"
            label="Emittent"
          />
        </div>
        <div class="col-4 col-lg-2 flex">
          <q-btn
            label="Sök"
            type="submit"
            v-bind="$defs.btn"
            class="full-width"
            :loading="loading"
            :disable="searchQuery.entity == null"
          />
        </div>
      </div>

      <div class="row q-col-gutter-md">
        <div class="col col-12 col-md-6 col-lg-4">
          <a-select
            v-bind="$defs.input"
            v-model="searchQuery.instrument"
            :options="instruments"
            map-options
            option-label="viewName"
            null-label="Alla"
            label="Värdepapper"
            :disable="searchQuery.entity == null"
          />
        </div>
        <div class="col col-12 col-md-6 col-lg-4">
          <a-select
            v-bind="$defs.input"
            v-model="searchQuery.ownerOrInvestor"
            :options="ownerOrInvestors"
            map-options
            option-label="viewName"
            null-label="Alla"
            label="Ägare/Försäkringsägare"
            :disable="searchQuery.entity == null"
          />
        </div>
        <div class="col col-12 col-md-3 col-lg-2">
          <a-input-date v-model="searchQuery.settleDate" v-bind="$defs.input" label="Per införd i aktieboken" />
        </div>
        <div class="col col-12 col-md-3 col-lg-2">
          <SelectCustodian
            v-model="searchQuery.custodian"
            v-bind="$defs.input"
            null-label="Alla"
            label="Depåinstitut"
          />
        </div>
      </div>
      <div>
        <div class="col col-12 col-md-3 col-lg-2">
          <q-checkbox v-model="searchQuery.onlyInsurance" label="Endast försäkringsägande"></q-checkbox>
        </div>
      </div>
    </q-form>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-facing-decorator';
import { search } from '@/models/position/positionService';
import SearchQuery from '@/models/position/PositionSearchQuery';
import { search as searchInstruments } from '@/models/instrument/instrumentService';
import { options } from '@shared/common';
import SelectEntity from '@/views/entity/SelectEntity.vue';
import SelectCustodian from '@/views/entity/SelectCustodian.vue';
import InstrumentSearchQuery from '@/models/instrument/InstrumentSearchQuery';
@Component({
  components: { SelectEntity, SelectCustodian },
})
export default class PositionSearch extends Vue {
  loading = false;
  instruments = null;
  @Prop() ownerOrInvestors;
  @Prop() searchQuery: SearchQuery;

  get options() {
    return options;
  }

  async mounted() {
    if (!this.searchQuery.isEmpty()) {
      await this.issuerChange(this.searchQuery.entity);
      await this.search();
    }
  }

  get positionsValidOptions() {
    return [
      { value: null, label: 'Alla' },
      { value: true, label: 'Klara' },
      { value: false, label: 'Preliminära/Felaktiga' },
    ];
  }

  get dateTypes() {
    return [
      { value: null, label: 'Ingen vald' },
      { value: 'registrationDate', label: 'Registreringsdag' },
      { value: 'settleDate', label: 'Införd i aktieboken' },
      { value: 'tradeDate', label: 'Affärsdag' },
    ];
  }

  @Watch('searchQuery.entity')
  async issuerChange(entity) {
    if (entity == null) {
      this.instruments = null;
      this.$emit('reset');
      return;
    }
    this.instruments = await searchInstruments(
      new InstrumentSearchQuery({ entity: entity.id, settleDate: this.searchQuery.settleDate }),
    );
    this.$emit('instruments', this.instruments);
  }

  @Watch('searchQuery.settleDate')
  async dateChange(settleDate) {
    if (this.searchQuery.entity) {
      this.instruments = await searchInstruments(
        new InstrumentSearchQuery({ entity: this.searchQuery.entity, settleDate }),
      );
      this.$emit('instruments', this.instruments);
    }
  }

  async search() {
    this.loading = true;

    this.$router.replace({
      query: this.searchQuery.asRequestParams(),
    });

    try {
      const positions = await search(this.searchQuery);
      this.$emit('search', positions);
    } finally {
      this.loading = false;
    }
  }
}
</script>
