<template>
  <q-page class="ViewPersonPage" v-if="ready">
    <div class="row q-gutter-lg q-mt-sm">
      <div class="col">
        <h1 v-if="isCreate">Skapa person</h1>
        <h1 v-else>{{ entity.viewName }}</h1>
      </div>
      <q-btn-group unelevated class="col col-md-auto action-buttons">
        <template v-if="editMode">
          <q-btn v-bind="$defs.btn" color="red-5" class="margin-right-auto" @click="deletePerson" v-if="!isCreate"
            >Radera</q-btn
          >
          <q-btn label="Avbryt" flat v-bind="$defs.btn" @click="cancel" />
          <q-btn label="Spara" v-bind="$defs.btn" @click="save" />
        </template>
        <template v-else>
          <q-btn label="Hämta från roaring" icon="refresh" @click="refreshPersonInfo" />
          <q-btn label="Editera" icon="edit" v-bind="$defs.btn" @click="edit" />
        </template>
      </q-btn-group>
    </div>
    <q-form ref="form">
      <q-banner id="error-text" rounded class="bg-red-5 text-white q-mt-md" v-if="errorMessage">
        {{ getErrorText(errorMessage) }}
      </q-banner>
      <PersonBasics :entity="entity" :readonly="!editMode" />

      <q-card flat class="q-pa-none" v-if="!isCreate">
        <InsurancesTable :investor="entity" />
      </q-card>
      <q-card v-if="!isKapsureMode" flat>
        <h4 class="table-header">Användare</h4>
        <AdministratorTable :entity="entity" />
      </q-card>

      <q-card flat class="q-pa-none" v-if="!isCreate && !isKapsureMode">
        <h4 class="table-header">Roller</h4>
        <PersonRolesTable :entity="entity" v-if="!editMode" />
      </q-card>
    </q-form>
  </q-page>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator';
import { loadEntity, save, destroyEntity } from '@/models/entity/entityService';
import { Entity } from '@/models';
import AddressTable from '../common/AddressTable.vue';
import PersonRolesTable from './PersonRolesTable.vue';
import PersonBasics from './PersonBasics.vue';
import { FormComponent } from '@shared/mixins';
import { ConfirmModal } from '@/components';
import { isToNewPage, setActiveNavigationModel } from '@/utils';
import AdministratorTable from '@/views/legalEntity/AdministratorTable.vue';
import InsurancesTable from '../insurance/InsurancesTable.vue';
import { isKapsureMode } from '@/appMode';
import { updatePersonFromRoaring } from '@/models/entity/entityService';
@Component({
  components: {
    AdministratorTable,
    AddressTable,
    PersonRolesTable,
    PersonBasics,
    InsurancesTable,
  },
  mixins: [FormComponent],
})
export default class ViewPersonPage extends Vue {
  entity: Entity = null;
  original: Entity = null;
  editMode = false;
  isCreate = false;
  active: true;
  async created() {
    await this.loadData();
  }

  @Watch('$route')
  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }
    const { id } = this.$route.params;
    const isCreate = id === 'new';
    const { nationalId, countryCode } = this.$route.query;
    const entity = isCreate
      ? Entity.newModel({ isPerson: true, active: true, person: {}, countryCode, nationalId })
      : await loadEntity(id as string);

    setActiveNavigationModel(entity);

    Object.assign(this, { entity, original: entity, editMode: isCreate, isCreate });
  }

  get ready() {
    return this.entity != null;
  }

  edit() {
    this.editMode = true;
    this.original = this.entity;
    this.entity = this.entity.copy();
  }

  async save() {
    const model = await this.submitForm(async () => {
      return save(this.entity);
    });
    if (model) {
      this.original = this.entity = model;
      this.editMode = false;
      if (this.isCreate) {
        this.$router.push({ path: model.viewUrl });
      }
    }
  }

  async deletePerson() {
    this.$q.dialog({
      component: ConfirmModal,
      componentProps: {
        header: 'Rensa Pesonen',
        text: 'Detta kommer rensa detta personen i aktiedata',
        onConfirm: async () => {
          try {
            await destroyEntity(this.entity);
            this.$router.push({ path: '/person' });
          } catch (error) {
            this.errorMessage = error;
          }
        },
      },
    });
  }

  async cancel() {
    this.editMode = false;
    this.entity = this.original;
    this.errorMessage = null;
  }

  get isKapsureMode() {
    return isKapsureMode();
  }

  async refreshPersonInfo() {
    await updatePersonFromRoaring(this.entity);
    this.$q.dialog({
      title: 'Klart! Nu är det uppdaterat!',
      message: 'Kontakta IT om du behöver hjälp',
    });
    this.loadData();
  }
}
</script>
