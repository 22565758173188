<template>
  <q-card flat class="q-pa-none PageSection">
    <div class="table-tabs">
      <q-tabs v-model="viewType" narrow-indicator class="">
        <q-tab name="shareRegister" label="Aktiebok" />
        <q-tab name="investorRegister" label="Investerarbok" />
      </q-tabs>

      <q-tabs v-model="groupProperty" narrow-indicator class="margin-left-auto">
        <q-tab name="instrument" label="Värdepapper" />
        <q-tab name="owner" label="Ägare" v-if="viewType === 'shareRegister' || viewType === 'publicRegister'" />
        <q-tab name="absoluteInvestor" label="Investeringsansvarig" v-if="viewType === 'investorRegister'" />
      </q-tabs>
    </div>

    <q-card-section class="q-pa-none">
      <PositionsTable :shareRegister="shareRegister" :tableSettings="tableSettings" />
    </q-card-section>
  </q-card>
</template>

<script lang="ts">
// @ts-nocheck
import { Component, Vue, Prop } from 'vue-facing-decorator';

import { ShareRegister } from '@/models';

import PositionsTable from './PositionsTable.vue';
import TableSettings from '@shared/web/models/shareRegister/TableSettings';
@Component({
  components: { PositionsTable },
})
export default class PositionsTableCard extends Vue {
  @Prop() shareRegister: ShareRegister;
  tableSettings = new TableSettings();
  get viewType() {
    return this.tableSettings.viewType;
  }
  set viewType(val) {
    if (val === 'investorRegister') {
      this.groupProperty = 'absoluteInvestor';
    } else {
      this.groupProperty = 'instrument';
    }
    this.tableSettings.viewType = val;
  }
  get groupProperty() {
    return this.tableSettings.groupProperty;
  }
  set groupProperty(val) {
    this.tableSettings.groupProperty = val;
    if (val === 'instrument') {
      this.tableSettings.sortProperty = 'owner';
    }
    if (val === 'absoluteInvestor' || val === 'owner') {
      this.tableSettings.sortProperty = 'quantity';
      this.tableSettings.sortDesc = true;
    }
  }
}
</script>
<style lang="scss"></style>
