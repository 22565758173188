<template>
  <q-input v-bind="$attrs" :modelValue="modelValue" @update:modelValue="onInput" :mask="mask" unmasked-value />
</template>
<script lang="ts">
import { Entity } from '@/models';
import { Component, Vue, Prop } from 'vue-facing-decorator';

function prefixYear(val) {
  if (val.length > 1) {
    const start = val.substring(0, 2);
    if (start !== '19' && start !== '20') {
      val = '19' + val;
    }
  }
  return val;
}
@Component
export default class InputNationalId extends Vue {
  @Prop()
  modelValue: string;

  @Prop()
  entity: Entity;

  get mask() {
    if (this.entity.isSE && /^\d+$/.test(this.modelValue)) {
      return this.entity.isPerson ? '########-####' : '######-####';
    }
    return '';
  }

  onInput(val = '') {
    if (this.entity.isSE && this.entity.isPerson) {
      val = prefixYear(val);
    }
    this.$emit('update:modelValue', val);
  }
}
</script>
