<template>
  <q-dialog ref="dialog"
    ><div class="q-dialog-plugin">
      <h4 class="table-header">Kontaktperson</h4>
      <q-form @submit="done">
        <q-input
          label="Namn"
          v-model="contactPerson.firstName"
          :rules="validation.required('Namn')"
          square
          bg-color="white"
        />
        <q-input label="E-post" v-model="contactPerson.email" :rules="validation.email" square bg-color="white" />
        <q-input label="Telefon" v-model="contactPerson.phone" :rules="validation.phone" square bg-color="white" />
        <q-select label="Roll" v-model="contactPerson.role" :options="contactPersonRoles" />
        <q-card-actions class="q-mt-md" align="right">
          <q-btn :flat="true" @click="cancel">Avbryt</q-btn>
          <q-btn type="submit">Spara</q-btn>
        </q-card-actions>
      </q-form>
    </div></q-dialog
  >
</template>
<script lang="ts">
import { DialogComponent } from '@shared/mixins';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { validation } from '@shared/common';
import { ContactInfo } from '@/models';

@Component({
  components: {},
  mixins: [DialogComponent],
})
export default class ContactPersonModal extends Vue {
  @Prop({ required: false }) input: ContactInfo;
  contactPerson: ContactInfo;

  mounted() {
    this.contactPerson = this.input;
  }
  done() {
    this.$emit('ok', this.contactPerson);
    this.hide();
  }

  get validation() {
    return validation;
  }

  get contactPersonRoles() {
    return ['Bolagsföreträdare', 'Kund'];
  }
}
</script>
