<template>
  <li class="tree-structure-list">
    <div v-if="link" class="group-name">
      <router-link :to="link">{{ title }}</router-link>
    </div>
    <div v-else class="group-name">
      {{ title }}
    </div>
    <ul v-if="children?.length">
      <TreeStructureListItem
        v-for="{ id, title, children: itemsChildren } in children"
        :id="id"
        :key="title"
        :title="title"
        :children="itemsChildren"
      />
    </ul>
  </li>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { Structure } from '@shared/views/common/treeStructureList/treeStructureListTypes';
import { isBackOffice } from '@shared/web/utils';

@Component({
  components: { TreeStructureListItem: this },
})
export default class TreeStructureListItem extends Vue {
  @Prop() id: string;
  @Prop() title: string;
  @Prop() children?: Structure[];

  get link() {
    if (isBackOffice() && this.id) {
      return `/legal-entity/view/${this.id}`;
    }
    return null;
  }
}
</script>
