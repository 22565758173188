<template>
  <div class="tree-structure-list">
    <div v-if="!hasStructure">Ingen koncernstruktur</div>
    <ul v-if="hasStructure" class="top">
      <TreeStructureListItem :id="structure?.id" :title="structure.title" :children="structure.children" />
    </ul>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { Structure } from '@shared/views/common/treeStructureList/treeStructureListTypes';
import TreeStructureListItem from '@shared/views/common/treeStructureList/TreeStructureListItem.vue';
import { Entity } from '@shared/web/models';

@Component({
  components: { TreeStructureListItem },
})
export default class TreeStructureList extends Vue {
  @Prop({ default: null }) entity: Entity;

  get structure(): Structure {
    return this.entity.structure;
  }
  get hasStructure() {
    return this.entity.legalEntity.parentCompany || this.entity.legalEntity.cachedStructure?.children?.length;
  }
}
</script>
<style lang="scss">
.tree-structure-list {
  ul:not(.top) {
    margin-left: 10px;
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: -5px;
      bottom: 10px;
      border-left: 1px solid rgba(0, 0, 0, 0.3);
    }
    ul {
      margin-left: 30px;
    }
    .group-name::before {
      content: '';
      display: inline-block;
      border-top: 1px solid rgba(0, 0, 0, 0.3);
      width: 10px;
      margin-bottom: 4px;
      margin-right: 5px;
    }
  }
}
</style>
