import InvestmentOffer from '@shared/web/models/investmentOffer/InvestorOffer';
import { modelHandler } from '../base';

const baseUrl = '/api/investment-offer';
const { createModel, saveModel, destroyModel } = modelHandler(InvestmentOffer, baseUrl);

export async function createInvestmentOffer(investmentOffer: {
  offerCompanyName?: string;
  title: string;
  text: string;
  archived: boolean;
  draft: boolean;
}): Promise<InvestmentOffer> {
  const { model } = await createModel(investmentOffer);
  return model;
}

export async function saveInvestmentOffer(investmentOffer: InvestmentOffer): Promise<InvestmentOffer> {
  const { model } = await saveModel(investmentOffer);
  return model;
}

export function removeInvestmentOffer(investmentOffer: InvestmentOffer) {
  destroyModel(investmentOffer);
}
