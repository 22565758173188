<template>
  <div>
    <InstrumentBasics :instrument="instrument" :readonly="readonly" :readonlyShareData="readonlyShareData" />
    <ShareData
      :instrument="instrument"
      :readonly="readonly"
      v-if="instrument.isShare"
      :readonlyShareData="readonlyShareData"
      @reload="$emit('reload')"
    />
    <WarrantData
      v-if="instrument.isRight"
      :readonly="readonly"
      :warrant="instrument"
      :entity="instrument.entity"
      :rightsData="instrument.rightsData"
    />

    <div v-if="instrument.isConvertible" class="q-my-xl">
      <ConvertibleDetails :instrument="instrument" :readonly="readonly" :entity="instrument.entity" />
    </div>
    <div v-if="instrument.isBond" class="q-my-xl">
      <BondDetails :instrument="instrument" :readonly="readonly" />
    </div>
    <div v-if="instrument.isBond || instrument.isConvertible" class="q-my-xl">
      <AccruedInterest :instrument="instrument" :readonly="readonly" />
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';

import { Instrument } from '@/models';

import InstrumentBasics from './InstrumentBasics.vue';
import ShareData from './ShareData.vue';
import WarrantData from './WarrantData.vue';
import BondDetails from '@shared/views/bond/BondsDetails.vue';
import ConvertibleDetails from '@shared/views/convertible/ConvertibleDetails.vue';
import NumberInput from '@shared/components/NumberInput.vue';
import AccruedInterest from './AccruedInterest.vue';

@Component({
  components: {
    BondDetails,
    ConvertibleDetails,
    ShareData,
    InstrumentBasics,
    WarrantData,
    NumberInput,
    AccruedInterest,
  },
})
export default class InstrumentContent extends Vue {
  @Prop() instrument: Instrument;
  @Prop({ default: true }) readonly: boolean;
  @Prop() readonlyShareData: boolean;
}
</script>
