<template>
  <a-page-section class="ShareData padded" v-if="shareData && issuerData" header="Aktiedata">
    <div class="row q-col-gutter-x-xl">
      <div class="col-md-7">
        <q-markup-table flat class="standard-table test-sharedata-table" separator="horizontal">
          <thead>
            <tr>
              <th class="text-left"></th>
              <th class="text-right">Aktien</th>
              <th class="text-right">Bolagsverket</th>
              <th class="text-right">Bolaget</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-left bold-label">Antal Utgivna</td>
              <td class="text-right">
                <a-input-number
                  v-bind="inputAttributes"
                  input-class="text-right totalQuantity"
                  v-model="shareData.totalQuantity"
                  :rules="quantityValidation"
                />
              </td>
              <td class="text-right test-sharedata-roaring-quantity">
                <FormattedNumber
                  v-if="roaringTotalQuantity"
                  :number="roaringTotalQuantity"
                  :compare-with="shareData.totalQuantity"
                />
                <div v-if="instrument.roaringQuantityDiff" class="text-small">
                  diffar med:
                  <FormattedNumber v-if="instrument.roaringQuantityDiff" :number="instrument.roaringQuantityDiff" />
                </div>
              </td>
              <td class="text-right">{{ formatNumber(issuerData.totalQuantity) }}</td>
            </tr>
            <tr>
              <td class="text-left bold-label">Kvotvärde</td>
              <td class="text-right">
                <FormattedNumber :number="instrument.shareData.quotaValue" />
              </td>
              <td class="text-right">
                <FormattedNumber
                  v-if="instrument.quotaValueFromRoaring"
                  :number="instrument.quotaValueFromRoaring"
                  :compare-with="instrument.shareData.quotaValue"
                />
                <div v-if="instrument.quotaValueDiff" class="text-small">
                  diffar med:
                  <FormattedNumber :number="instrument.quotaValueDiff" />
                </div>
              </td>
              <td class="text-right">{{ formatNumber(issuerData.quotaValue, 2, 20) }}</td>
            </tr>
            <tr>
              <td class="text-left bold-label">Aktiekapital</td>
              <td class="text-right">
                <FormattedNumber
                  v-if="readonlyShareData"
                  :number="shareData.totalCapital"
                  :min-decimals="0"
                  :max-decimals="4"
                />
                <a-input-number
                  v-if="!readonlyShareData"
                  v-bind="inputAttributes"
                  input-class="text-right totalCapital"
                  v-model="shareData.totalCapital"
                  :readonly="readonlyShareData"
                  :rules="capitalValidation"
                  :minimumFractionDigits="2"
                  :maximumFractionDigits="20"
                />
              </td>
              <td class="text-right test-sharedata-roaring-capital">
                <FormattedNumber
                  v-if="roaringTotalCapital"
                  :number="roaringTotalCapital"
                  :compare-with="shareData.totalCapital"
                />
                <div v-if="instrument.roaringCapitalDiff" class="text-small">
                  diffar med
                  <FormattedNumber v-if="instrument.roaringCapitalDiff" :number="instrument.roaringCapitalDiff" />
                </div>
              </td>
              <td class="text-right">{{ formatNumber(issuerData.totalCapital) }}</td>
            </tr>
            <tr v-if="hasRoaringDiff">
              <td></td>
              <td colspan="99">
                <q-btn
                  class="full-width"
                  color="white"
                  text-color="black"
                  dense
                  label="Synka aktiekapitalet från bolagsverket"
                  :loading="syncInProgress"
                  :disable="syncTooltipDisabledMessages.length > 0"
                  @click="syncTotalCapital"
                >
                  <q-tooltip v-if="syncTooltipDisabledMessages.length">
                    <span v-html="syncTooltipDisabledMessages.join('<br />')" />
                  </q-tooltip>
                </q-btn>
              </td>
            </tr>
          </tbody>
        </q-markup-table>
        <div class="row q-mt-md">
          <div class="col standard-list">
            <a-list-item label="Aktieslag">
              <a-select
                v-model="shareData.classType"
                v-bind="inputAttributes"
                :options="options.shareClassTypeOptions"
                :readonly="readonlyShareData"
                emit-value
                map-options
                class="q-pb-none"
              />
            </a-list-item>
          </div>
          <div class="col standard-list">
            <a-list-item label="Röstandel">
              <a-input-number
                v-bind="inputAttributes"
                :minimumFractionDigits="2"
                :maximumFractionDigits="20"
                v-model="shareData.votingPower"
                :rules="instrument.isKapclearProduct && validation.requiredNumber"
                class="q-pb-none"
                :readonly="readonlyShareData"
              />
            </a-list-item>
            <q-item v-if="hasRoaringDiff" class="test-sharedata-roaring-votingpower">
              ({{ formatNumber(roaringVotingPower, 2) }} enligt bolagsverket)
            </q-item>
          </div>
        </div>
        <div class="row q-mt-md">
          <div class="col"></div>
        </div>
      </div>
      <div class="col-md-5">
        <h4 class="table-header">Förbehåll</h4>
        <a-checkbox-group
          v-model="shareData.reservations"
          :options="options.reservationsOptions"
          :readonly="readonly"
          class="reservations-options"
        />
      </div>
    </div>
    <div class="row link-row" v-if="readonly">
      <router-link :to="instrument.positionsUrl">Visa Innehav</router-link>
      <router-link :to="instrument.transactionsUrl">Visa Transaktioner</router-link>
    </div>
  </a-page-section>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import * as _ from 'lodash';
import { options, validation } from '@shared/common';
import { requiredInteger, requiredNumber } from '@shared/utils/validation';
import { Instrument, InstrumentGroup } from '@/models';
import { formatNumber, toNumber } from '@/utils';
import FormattedNumber from '@shared/components/FormattedNumber.vue';
import { save } from '@/models/instrument/instrumentService';

@Component({
  components: { FormattedNumber },
})
export default class ShareData extends Vue {
  @Prop() instrument: Instrument;
  @Prop() readonly: boolean;
  @Prop() readonlyShareData: boolean;
  syncInProgress = false;

  get shareData() {
    return this.instrument.shareData;
  }

  get issuerData() {
    return _.get(this.instrument, 'entity.issuerData');
  }

  get syncTooltipDisabledMessages() {
    const messages: string[] = [];
    if (this.shareData.totalQuantity !== this.roaringTotalQuantity) {
      messages.push('Antalet aktier på värdepappret måste vara samma som hos bolagsverket');
    }
    if (this.instrument.group !== InstrumentGroup.SHA) {
      messages.push('Synkningen kan göras endast på stam-aktier');
    }
    return messages;
  }

  get hasRoaringDiff() {
    return this.instrument.hasRoaringDiff;
  }

  get roaringTotalQuantity(): number {
    return this.instrument.shareDataTotalQuantityFromRoaring;
  }

  get roaringTotalCapital(): number {
    return this.instrument.shareDataTotalCapitalFromRoaring;
  }

  get roaringVotingPower(): number {
    return this.instrument.shareDataVotingPowerFromRoaring;
  }

  get options() {
    return options;
  }

  get quantityValidation() {
    if (!this.instrument.isKapclearProduct) {
      return [];
    }
    return [
      val => requiredInteger(val),
      val => {
        const { totalQuantity } = this.issuerData;
        return toNumber(val) <= totalQuantity || 'Antal aktier överstiger det för emittenten';
      },
    ];
  }

  get capitalValidation() {
    if (!this.instrument.isKapclearProduct) {
      return [];
    }
    return [
      val => requiredNumber(val),
      val => {
        const { quotaValue } = this.issuerData;
        const { totalQuantity } = this.shareData;
        return totalQuantity * quotaValue == toNumber(val) || 'Kvotvärde gånger antal utgivna aktier stämmer ej';
      },
    ];
  }

  get inputAttributes() {
    return {
      dense: true,
      filled: true,
      square: true,
      readonly: this.issuerData.locked || this.readonly,
    };
  }

  get validation() {
    return validation;
  }

  async syncTotalCapital() {
    this.syncInProgress = true;
    this.instrument.shareData.totalCapital = this.instrument.shareDataTotalCapitalFromRoaring;
    await save(this.instrument);
    this.syncInProgress = false;
    this.$emit('reload');
    this.$q.notify({
      message: 'Klart!',
    });
  }

  formatNumber(number: any, min?: number, max?: number) {
    return formatNumber(number, min, max);
  }
}
</script>
<style lang="scss">
.ShareData {
  .q-markup-table td {
    width: 33%;
  }

  .standard-table {
    overflow: visible;
  }
  .link-row {
    justify-content: flex-end;
  }
  .link-row a {
    padding-left: 20px;
  }
  .bold-label {
    font-weight: 700;
    font-size: 13px !important;
  }
}
</style>
