<template>
  <q-banner class="bg-red text-white MessageBanner rounded-borders q-ma-md print-hide" v-if="globalError">
    {{ globalError.text }}
    <template v-slot:action>
      <q-btn flat color="white" label="Stäng" @click="close" />
    </template>
  </q-banner>
</template>
<script lang="ts">
import store from '@/store';
import { Component, Vue } from 'vue-facing-decorator';

@Component
export default class MessageBanner extends Vue {
  get globalError() {
    return store.globalError;
  }
  close() {
    store.globalError = null;
  }
}
</script>
<style lang="scss">
.MessageBanner {
  position: fixed;
  z-index: 10000;
  bottom: 0;
  right: 0;
}
</style>
