import SearchQuery from '@shared/web/models/SearchQuery';
import { stringToBoolean } from '@/utils';
import { Entity } from '..';
import { Products } from '@shared/index';
export default class InstrumentSearchQuery extends SearchQuery {
  constructor(attr) {
    super(attr);
    Object.assign(this, attr);
    this.positionsValid = stringToBoolean(this.positionsValid);
  }

  positionsValid?: boolean | null = null;
  category?: string | null = null;
  entity?: Entity = null;
  product?: Products = null;
  settleDate?: Date = null;

  static define = () => ({
    entity: {
      model: Entity,
    },
  });
}
