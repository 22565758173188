<template>
  <div class="InstructionSearch">
    <h4 class="table-header">{{ labels.title }}</h4>
    <q-form @submit="search" class="q-gutter-x-md search-form">
      <div class="row">
        <SelectEntity
          v-model="searchQuery.entity"
          entityType="issuer"
          v-bind="$defs.input"
          :hideCreate="true"
          label="Emittent"
        />

        <q-btn label="Sök" type="submit" v-bind="$defs.btn" :loading="loading" />
      </div>

      <div class="row">
        <a-select
          v-model="searchQuery.state"
          v-bind="$defs.input"
          :options="withAllOption(options.instructionStateOptions)"
          emit-value
          map-options
          label="Status"
        />
        <a-select
          v-bind="$defs.input"
          v-model="searchQuery.instrument"
          :options="instruments"
          map-options
          null-label="Alla"
          :disable="searchQuery.entity == null"
          option-label="viewName"
          label="Värdepapper"
        ></a-select>
      </div>
      <div class="row">
        <a-select
          v-model="searchQuery.dateType"
          v-bind="$defs.input"
          :options="dateTypes"
          emit-value
          map-options
          class="fill-width"
          label="Datumperiod"
        />
        <a-input-date v-model="searchQuery.from" v-bind="$defs.input" :disable="!searchQuery.dateType" label="Från" />
        <a-input-date v-model="searchQuery.to" v-bind="$defs.input" :disable="!searchQuery.dateType" label="Till" />
      </div>
    </q-form>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-facing-decorator';
import { search } from '@/models/instruction/instructionService';
import SearchQuery from '@shared/web/models/instruction/InstructionSearchQuery';
import { search as searchInstruments } from '@/models/instrument/instrumentService';
import { options } from '@shared/common';
import SelectEntity from '@/views/entity/SelectEntity.vue';
import { InstructionMainType } from '@/models';
import InstrumentSearchQuery from '@/models/instrument/InstrumentSearchQuery';
@Component({
  components: { SelectEntity },
})
export default class InstructionSearch extends Vue {
  loading = false;
  instruments = null;
  @Prop() searchQuery: SearchQuery;

  get options() {
    return options;
  }

  mounted() {
    if (!this.searchQuery.isEmpty()) {
      this.search();
    }
  }

  get labels() {
    return {
      title:
        this.searchQuery.mainType === InstructionMainType.CORPORATE_EVENT ? 'Sök Bolagshändelser' : 'Sök Ägarbyten',
    };
  }
  get positionsValidOptions() {
    return [
      { value: null, label: 'Alla' },
      { value: true, label: 'Klara' },
      { value: false, label: 'Preliminära/Felaktiga' },
    ];
  }

  get dateTypes() {
    return [
      { value: null, label: 'Ingen vald' },
      { value: 'registrationDate', label: 'Registreringsdag' },
      { value: 'settleDate', label: 'Införd i aktieboken' },
      { value: 'tradeDate', label: 'Affärsdag' },
    ];
  }

  @Watch('searchQuery.entity', { immediate: true })
  async issuerChange(entity) {
    if (entity == null) {
      this.instruments = null;
      return;
    }

    this.instruments = await searchInstruments(new InstrumentSearchQuery({ entity }));
  }

  async search() {
    this.loading = true;

    this.$router.replace({
      query: this.searchQuery.asRequestParams(),
    });

    try {
      const searchResult = await search(this.searchQuery);
      this.$emit('search', searchResult);
    } finally {
      this.loading = false;
    }
  }
}
</script>
