<template>
  <a-page-section header="Övriga Värdepapper" class="InstrumentTable">
    <q-table
      :rows="instruments"
      :columns="columns"
      :pagination="pagination"
      row-key="id"
      flat
      hide-pagination
      hide-no-data
      class="standard-table"
    >
      <template v-slot:body-cell-viewName="props">
        <q-td :props="props">
          <router-link :to="props.row.viewUrl">{{ props.value }}</router-link>
        </q-td>
      </template>
      <template v-slot:bottom-row>
        <a-table-row-btn @click="addInstrument" label="Lägg till värdepapper" />
      </template>
    </q-table>
  </a-page-section>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { Entity } from '@/models';
import { toInstrumentGroupLabel, toInstrumentCategoryLabel } from '@shared/common';
import { openNewInstrument } from '@/actions';
import { QTableProps } from 'quasar';
import { formatNumber } from '@/utils';
@Component
export default class InstrumentsTable extends Vue {
  @Prop() entity: Entity;
  @Prop() performSave;

  get instruments() {
    return this.entity.instruments.filter(instrument => !instrument.isShare);
  }

  addInstrument() {
    openNewInstrument({
      entity: this.entity,
    });
  }

  columns: QTableProps['columns'] = [
    {
      name: 'viewName',
      field: 'viewName',
      label: 'Namn',
      align: 'left',
    },
    {
      field: 'category',
      name: 'category',
      label: 'Kategori',
      align: 'left',
      format: value => {
        return toInstrumentCategoryLabel(value);
      },
    },
    {
      field: 'group',
      name: 'group',
      label: 'Grupp',
      align: 'left',
      format: value => {
        return toInstrumentGroupLabel(value);
      },
    },
    {
      field: 'currencyCode',
      name: 'currencyCode',
      label: 'Valuta',
      align: 'left',
    },
    {
      field: instrument => instrument.valuation?.priceSEK,
      name: 'valuationPrice',
      format: value => formatNumber(value),
      label: 'Värdering',
      align: 'left',
    },
  ];
  pagination = {
    rowsPerPage: 0,
    sortBy: 'viewName',
  };
}
</script>
