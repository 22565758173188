<template>
  <q-page v-if="ready" class="RightsRegisterPage">
    <div class="row justify-between">
      <h1>Optionsbok</h1>
    </div>
    <RightsInfo :shareRegister="shareRegister" />
    <RightsPositionTable :shareRegister="shareRegister" :readonly="true" />
  </q-page>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator';
import { InstrumentCategory, ShareRegister } from '@shared/web/models';
import RightsInfo from '@shared/views/rightsRegister/RightsInfo.vue';
import RightsPositionTable from '@shared/views/rightsRegister/RightsPositionTable.vue';
import LastEntityUpdate from '@shared/components/LastEntityUpdate.vue';

import { findShareRegister } from '@shared/web/models/shareRegister/shareRegisterService';
import { findEntity } from '@/models/entity/entityService';
import { isToNewPage, setActiveNavigationModel } from '@/utils';
@Component({
  components: { RightsInfo, RightsPositionTable, LastEntityUpdate },
})
export default class RightsRegisterPage extends Vue {
  shareRegister: ShareRegister = null;

  async created() {
    await this.loadData();
  }

  @Watch('$route')
  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }
    const entity = await findEntity(this.$route.params.id);
    this.shareRegister = await findShareRegister({
      entity,
      instrumentCategory: InstrumentCategory.RIGHTS,
    });
    setActiveNavigationModel(entity);
  }

  get ready() {
    return this.shareRegister != null;
  }
}
</script>
