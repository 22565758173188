export enum EventLogType {
  EMAIL = 'email',
  LOGIN = 'login',
  UPDATE = 'update',
  CREATE = 'create',
  DELETE = 'delete',
}

export const EventLogTypeLabels = {
  [EventLogType.EMAIL]: 'utgående e-post',
  [EventLogType.LOGIN]: 'inloggning',
  [EventLogType.UPDATE]: 'uppdatering',
  [EventLogType.CREATE]: 'skapande',
  [EventLogType.DELETE]: 'borttagning',
};
