import { Model } from '../base';
import { IEntityValuation } from '@shared/models/modelTypes';
import { InstrumentValuation } from '@shared/web/models';

export default class EntityValuation extends Model implements IEntityValuation {
  entity: string;
  comment?: string;
  commentTitle?: string;
  instrumentValuations: InstrumentValuation[];
  createdAt: Date;

  static define = () => ({
    instrumentValuations: {
      embeddedModel: [InstrumentValuation],
      serializeChanges: false,
    },
    createdAt: {
      type: 'date',
    },
  });
}
