<template>
  <div v-if="ready">
    <PositionSearch
      class="col"
      @reset="reset"
      @search="search"
      @instruments="setInstruments"
      :searchQuery="searchQuery"
      :ownerOrInvestors="ownerOrInvestors"
    />
    <div class="row">
      <div class="col-12 col-sm-6 col-md-4">
        <q-list bordered v-if="entity">
          <q-item v-if="entity">
            <q-item-section><b>Avstämningsbolag</b></q-item-section>
            <q-item-section>{{ entity.issuerData.isRecordCompany ? 'Ja' : 'Nej' }}</q-item-section>
          </q-item>
          <q-item v-if="shareData && shareData.hasReservations">
            <q-item-section><b>Förbehåll finns</b></q-item-section>
            <q-item-section>Ja</q-item-section>
          </q-item>
          <q-item v-if="shareRegister">
            <q-item-section><b>Totalt utgivna</b></q-item-section>
            <q-item-section>{{ formatNumber((shareData || issuerData).totalQuantity) }}</q-item-section>
          </q-item>
        </q-list>
      </div>
    </div>
    <PositionsTableCard :shareRegister="shareRegister" v-if="shareRegister" class="q-mt-lg" />
    <div v-if="shareRegister">
      <ExportPositionExcel :shareRegister="shareRegister" />
      <PdfDownload v-bind="pdfConfig"> PDF </PdfDownload>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator';
import ExportPositionExcel from './ExportPositionExcel.vue';
import PositionSearchQuery from '@/models/position/PositionSearchQuery';
import PositionSearch from './PositionSearch.vue';
import PositionsTableCard from '@/views/shareRegister/PositionsTableCard.vue';

import { findEntity } from '@/models/entity/entityService';
import { findInstrument } from '@/models/instrument/instrumentService';
import * as _ from 'lodash';
import { ShareRegister, Instrument, Position, Entity } from '@/models';
import PdfDownload from '@shared/components/PdfDownload.vue';
import { formatNationalId } from '@shared/common';
import formatDate from 'date-fns/format';
import { formatNumber, setActiveNavigationModel } from '@/utils';
import { isKapsureMode } from '@/appMode';
import { IEntity } from '@shared/models/modelTypes';
import { fetchEntityOwners } from '@shared/web/entity/entityService';

@Component({
  components: {
    PositionSearch,
    ExportPositionExcel,
    PdfDownload,
    PositionsTableCard,
  },
})
export default class PositionSearchWithResult extends Vue {
  loadingDownload = false;
  shareRegister: ShareRegister = null;
  searchQuery: PositionSearchQuery = null;
  instruments: Array<Instrument>;
  ownerOrInvestors: Array<IEntity> = null;

  async created() {
    await this.loadData();
  }
  formatNumber(v) {
    return formatNumber(v);
  }

  async loadData() {
    const searchQuery = PositionSearchQuery.newModel(this.$route.query);
    searchQuery.onlyInsurance = isKapsureMode();
    if (searchQuery.entity) {
      if (_.isString(searchQuery.entity)) {
        searchQuery.entity = await findEntity(searchQuery.entity);
      }
      setActiveNavigationModel(searchQuery.entity);
    }
    if (searchQuery.instrument && _.isString(searchQuery.instrument)) {
      searchQuery.instrument = await findInstrument(searchQuery.instrument);
    }

    Object.assign(this, { searchQuery });
  }
  @Watch('searchQuery.entity')
  async entityOwners() {
    return (this.ownerOrInvestors = await fetchEntityOwners(this.searchQuery.entity));
  }

  search(positions: Array<Position>) {
    const { entity, instrument } = this.searchQuery;
    const instruments = instrument ? [instrument] : this.instruments;
    this.shareRegister = ShareRegister.newModel({
      positions,
      entity,
      instruments,
      settleDate: this.searchQuery.settleDate,
    });
  }

  get entity() {
    return this.searchQuery.entity;
  }

  get instrument() {
    return this.searchQuery.instrument;
  }

  get issuerData() {
    return this.shareRegister && this.shareRegister.getHistoricIssuerData();
  }

  get shareData() {
    return (
      this.shareRegister &&
      this.instrument &&
      this.instrument.shareData?.getHistoricVersion(this.shareRegister.settleDate)
    );
  }

  setInstruments(instruments) {
    this.instruments = instruments;
  }

  get ready() {
    return this.searchQuery != null;
  }

  reset() {
    this.shareRegister = null;
  }

  get pdfConfig() {
    if (this.searchQuery.entity) {
      const { entity, instrument, custodian, settleDate = new Date(), ownerOrInvestor } = this.searchQuery;

      const filename = `${formatNationalId(entity, false)} ${formatDate(settleDate || new Date(), 'yyyyMMdd')}`;
      return {
        filename,
        entity: entity,
        instrument: instrument,
        custodian: custodian,
        owner: ownerOrInvestor,
        settleDate: settleDate,
        category: null,
      };
    }
    return { disabled: true };
  }
}
</script>
