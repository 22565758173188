import { PublicClientApplication, AuthenticationResult, Configuration, AuthError } from '@azure/msal-browser';
import axios from 'axios';
import { setToken } from '@/authentication';
import { deleteUserDataForSentry, setUserDataToSentry } from '../sentry/sentryService';

const msalConfig: Configuration = {
  auth: {
    clientId: import.meta.env.VITE_APP_MSAL_CLIENTID,
    authority: `https://login.microsoftonline.com/${import.meta.env.VITE_APP_MSAL_TENANTID}`,
    redirectUri: window.location.origin + '/dashboard',
  },
  cache: {
    cacheLocation: 'localStorage',
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

async function init() {
  await msalInstance.initialize();
  await msalInstance.handleRedirectPromise().then(handleResponse);
}

function handleResponse(loginResponse: AuthenticationResult | null) {
  if (loginResponse?.idToken) {
    setToken(loginResponse.idToken);
    setUserDataToSentry();
  }
}

export async function acquireToken() {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length === 0) {
    return { loggedIn: false };
  }

  const account = accounts[0];
  const tokenRequest = {
    account: account,
    scopes: ['user.read'],
  };
  try {
    console.log('acquireToken');
    const response = await msalInstance.acquireTokenSilent(tokenRequest);
    setToken(response.idToken);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export default {
  login: async () => {
    const loginRequest = {
      scopes: ['openid', 'profile', 'user.read'],
    };
    msalInstance.loginRedirect(loginRequest);
  },

  fetchProfile: async () => {
    await init();

    try {
      await acquireToken();

      setUserDataToSentry();
      const { data } = await axios.get('/api/auth/profile');
      return { ...data, loggedIn: true };
    } catch (error) {
      console.error('Error acquiring token silently:', error);
      return { loggedIn: false };
    }
  },

  logout: async () => {
    const logoutRequest = {
      postLogoutRedirectUri: window.location.origin + '/',
    };
    msalInstance.logoutRedirect(logoutRequest);
    deleteUserDataForSentry();
  },
};
