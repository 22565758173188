<template>
  <q-dialog ref="dialog">
    <div class="q-dialog-plugin AllActivityModal" v-if="activityList">
      <a-page-section header="Historik" class="padded">
        <q-table :rows="activityList" :columns="columns" row-key="id">
          <template v-slot:body="props">
            <ActivityTableRow :activity="props['row']" />
          </template>
        </q-table>
      </a-page-section>

      <q-card-actions align="right" class="q-pa-md">
        <q-btn v-bind="$defs.btn" flat @click="cancel">Stäng</q-btn>
      </q-card-actions>
    </div>
  </q-dialog>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { DialogComponent } from '@shared/mixins';
import { Activity } from '@/models';
import { ActivityHolder } from '@shared/web/models/activity/ActivityHolder';
import { findActivity } from '@/models/activity/activityService';
import ActivityTableRow from './ActivityTableRow.vue';
import { QTableProps } from 'quasar';
@Component({
  mixins: [DialogComponent],
  components: { ActivityTableRow },
})
export default class ConfirmModal extends Vue {
  @Prop() header: string;
  @Prop() text: string;

  @Prop() model: ActivityHolder;
  activityList: Array<Activity> = null;

  columns: QTableProps['columns'] = [
    { name: 'desc', label: 'Description', field: 'desc', align: 'left', sortable: true },
    { name: 'date', label: 'Date', field: 'date', align: 'left', sortable: true },
    { name: 'details', label: 'Detaljer', field: 'details', align: 'center', sortable: true },
  ];

  async mounted() {
    this.activityList = await findActivity(this.model);
  }
}
</script>
<style lang="scss">
.AllActivityModal {
  &:not(.step1) {
    width: 80vw;
    max-width: 1024px;
    height: 80%;
  }
  pre {
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    overflow-x: auto;
  }
  .standard-list .q-item {
    min-height: 30px;
  }
}
</style>
