<template>
  <q-page class="CurrencyRatesPage" v-if="ready">
    <div class="row">
      <h1>Valutakurser</h1>
    </div>
    <q-separator />
    <div class="flex q-mt-xl q-mb-sm justify-between items-center">
      <h4 class="text-weight-regular q-mr-sm">{{ formatDay(new Date(currencyRates.date)) }}</h4>
      <div>
        <q-btn flat dense icon="refresh" label="Uppdatera dagens kurs" @click="updateRates" />
      </div>
    </div>

    <div class="q-pa-md">
      <q-card-section class="CurrencyTable q-pa-none">
        <q-table
          :rows="rows"
          :columns="columns"
          row-key="name"
          flat
          hide-pagination
          :pagination="pagination"
          hide-no-data
          class="standard-table"
        />
      </q-card-section>
    </div>
  </q-page>
</template>
<script lang="ts">
import { CurrencyRates } from '@/models';
import { updateCurrencyRates, getCurrencyRates } from '@/models/rates/currencyRatesService';
import { ICurrencies } from '@shared/models/modelTypes';
import { formatNumber } from '@shared/utils/utils';
import { QTableProps } from 'quasar';
import { Component, Vue } from 'vue-facing-decorator';

@Component({})
export default class CurrencyRatesPage extends Vue {
  currencyRates: CurrencyRates = null;

  mounted() {
    this.getRates();
  }
  async getRates() {
    this.currencyRates = await getCurrencyRates();
  }
  async updateRates() {
    this.currencyRates = await updateCurrencyRates();
  }

  get ready() {
    return this.currencyRates != null;
  }

  get rows() {
    const currencyNames = Object.keys(this.currencyRates.rates) as Partial<ICurrencies[]>;

    return currencyNames.map(currencyName => {
      return { name: currencyName, rate: formatNumber(this.currencyRates?.rates[currencyName]) };
    });
  }

  columns: QTableProps['columns'] = [
    {
      name: 'name',
      required: true,
      label: 'Valuta',
      align: 'left',
      field: row => row.name,
      sortable: true,
    },
    { name: 'rate', align: 'right', label: 'Kurs', field: 'rate', sortable: true },
  ];

  pagination = {
    rowsPerPage: 0,
    sortBy: 'currency',
  };
}
</script>
<style type="scss">
.CurrencyTable {
  max-width: 25rem;
}
</style>
