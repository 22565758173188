<template>
  <q-page padding class="flex flex-center Home">
    <q-form @submit="login" class="q-gutter-y-md" v-if="!profile.loggedIn">
      <q-input v-model="credentials.ssn" label="Personnummer" v-if="!isMSAL" v-bind="$defs.input" />

      <q-btn unelevated label="Logga in" type="submit" color="primary" />
    </q-form>
  </q-page>
</template>

<script>
import { login } from '@/models/profileService';
import store from '@/store';
import router from '@/router';
export default {
  name: 'Home',
  data() {
    return {
      credentials: {
        ssn: null,
      },
    };
  },
  mounted() {
    if (this.profile.loggedIn) {
      router.push('/dashboard');
    }
  },
  methods: {
    async login() {
      await login(this.credentials);
      this.$router.push({ path: '/dashboard' });
    },
  },
  computed: {
    isMSAL() {
      return import.meta.env.VITE_APP_AUTH_PROVIDER === 'MSAL';
    },
    profile() {
      return store.profile;
    },
  },
};
</script>
<style lang="scss">
.q-page.Home {
  .q-form {
    max-width: 230px;
    width: 100%;
  }
  button {
    width: 100%;
  }
}
</style>
