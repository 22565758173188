<template>
  <tr v-if="!destination.deleted">
    <td class="text-left test-destination-owner">
      <a-link :target="destination.owner" />
    </td>
    <td class="text-left test-destination-investor">
      <a-link :target="destination.investor" />
    </td>
    <td class="text-left">
      <InsuranceLink :instructionParty="destination" />
    </td>
    <td class="text-left">
      <a-link :target="destination.custodian" />
    </td>
    <td class="text-left">
      {{ destination.custodianAccountNumber }}
    </td>
    <td class="text-right" :class="{ paddingForPledgeText: destination.pledgeOwner }">
      <a-input-number
        v-bind="$defs.input"
        input-class="text-right quantity"
        v-model="destination.quantity"
        :readonly="readonly"
        :rules="validation.requiredLargerThanZero"
        v-if="!isValueDistributed"
        :fractionDigits="0"
      />
      <a-input-number
        v-else
        :disable="!isValueDistributed || readonly"
        input-class="text-right amount"
        v-model="destination.amount"
        @update:modelValue="$emit('change')"
        v-bind="$defs.input"
      />
      <p class="ispledge-small" v-if="destination.pledgeOwner">Aktiepost är pantsatt</p>
    </td>
    <td class="text-right">{{ formatNumber(instruction.price, 2, 2) }}</td>
    <td class="text-right">{{ formatNumber(totalAmount, 2, 2) }}</td>
    <td class="q-table--col-auto-width" v-if="!readonly">
      <q-btn
        flat
        round
        color="primary"
        icon="more_vert"
        size="sm"
        id="more-menu"
        class="`test-action-button-${destination.id}`"
      >
        <q-menu>
          <q-list>
            <q-item clickable v-close-popup @click="edit" class="`test-action-button-edit`">
              <q-item-section>Editera</q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="remove">
              <q-item-section>Ta bort</q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
    </td>
  </tr>
</template>
<script lang="ts">
import { Instruction, InstructionParty, ShareRegister, TransactionType } from '@/models';

import SelectCustodian from '@/views/entity/SelectCustodian.vue';
import EditPositionModal from '@shared/views/editPositionModal/EditPositionModal.vue';
import InsuranceLink from '@/views/insurance/InsuranceLink.vue';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { validation } from '@shared/common';
import { removeDestination } from '@shared/web/models/instruction/instructionService';
@Component({
  components: { SelectCustodian, InsuranceLink },
})
export default class DestinationRow extends Vue {
  @Prop() destination: InstructionParty;
  @Prop() shareRegister: ShareRegister;
  @Prop() instruction: Instruction;
  @Prop() readonly: boolean;

  get defaultOwners() {
    return this.shareRegister.getUniqueOwners();
  }
  get validation() {
    return validation;
  }
  get totalAmount() {
    if (this.isValueDistributed) {
      return (this.instruction.price / this.instruction.tradeEvent.source.amount) * this.destination.amount;
    }
    return this.destination.quantity * this.instruction.price;
  }

  get isValueDistributed() {
    if (this.instruction.type === TransactionType.TRADE) {
      return this.instruction?.tradeEvent?.source?.instrument?.rightsData?.isValueDistribution();
    }
    return this.instruction?.corporateEvent?.rightsData?.isValueDistribution();
  }

  get isInsuranceCompanyOwner() {
    return this.destination.owner?.isInsuranceCompany;
  }
  get isOwnerLegalEntity() {
    return !this.destination.owner?.isPerson;
  }

  edit() {
    this.$q.dialog({
      component: EditPositionModal,
      componentProps: {
        positionClass: InstructionParty,
        value: this.destination,
        label: 'köpare',
        tradeEventForm: true,
        instruction: this.instruction,
        shareRegister: this.shareRegister,
      },
    });
  }

  remove() {
    removeDestination(this.instruction, this.destination);
  }

  changeOwner() {
    if (!this.isOwnerLegalEntity) {
      this.destination.investor = null;
    }
    if (!this.isInsuranceCompanyOwner) {
      this.destination.insuranceNumber = null;
    }
  }
}
</script>
