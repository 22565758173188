<template>
  <q-table
    :rows="positions"
    :columns="columns"
    :pagination="pagination"
    row-key="id"
    flat
    hide-pagination
    hide-no-data
    class="standard-table InstrumentPositionsTable"
  >
    <template v-slot:body-cell-ownership="props">
      <q-td :props="props">
        <InsuranceLink :instructionParty="props.row" />
      </q-td>
    </template>
    <template v-slot:body-cell="props">
      <q-td :props="props">
        <template v-if="props.value?.viewUrl">
          <router-link :to="props.value.viewUrl"> {{ props.value.viewName }}</router-link>
        </template>
        <template v-else>{{ props.value }}</template>
      </q-td>
    </template>
  </q-table>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { Instrument, Position } from '@/models';
import InsuranceLink from '@/views/insurance/InsuranceLink.vue';
import { QTableProps } from 'quasar';
import { formatNumber } from '@shared/utils';

@Component({
  components: {
    InsuranceLink,
  },
})
export default class InstrumentPositionsTable extends Vue {
  @Prop() positions: Array<Position>;
  @Prop() instrument: Instrument;

  get columns(): QTableProps['columns'] {
    return [
      {
        name: 'owner',
        field: 'owner',
        label: 'Ägare',
        align: 'left',
      },
      {
        field: 'investor',
        name: 'investor',
        label: 'Försäkringsägare',
        align: 'left',
      },
      {
        field: 'ownership',
        name: 'ownership',
        label: 'Försäkring',
        align: 'left',
      },
      {
        field: 'custodian',
        name: 'custodian',
        label: 'Depåinstitut',
        align: 'left',
        sortable: true,
        sort: (a, b) => a?.viewName.localeCompare(b?.viewName),
      },
      {
        field: row => (this.instrument.isValueDistribution ? row.amount : row.quantity),
        name: 'quantity',
        label: this.instrument.isValueDistribution ? 'Belopp' : 'Antal',
        format: val => formatNumber(val),
        align: 'right',
      },
      {
        field: position => position.currentValue,
        format: value => formatNumber(value),
        name: 'currentValue',
        label: 'Värde',
        align: 'right',
      },
      {
        field: 'acquisitionValue',
        format: value => formatNumber(value),
        name: 'acquisitionValue',
        label: 'Anskaffningsvärde',
        align: 'right',
      },
      {
        name: 'menu',
        field: null,
        label: '',
        classes: 'q-table--col-auto-width',
      },
    ];
  }

  pagination = {
    rowsPerPage: 0,
    sortBy: 'owner',
  };
}
</script>
