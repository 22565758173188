<template>
  <a-page-section header="Innehav" class="KapsurePositionTable">
    <q-table
      :rows="positionsAndAccValues"
      :columns="columns"
      :pagination="pagination"
      row-key="id"
      flat
      hide-pagination
      hide-no-data
      class="standard-table"
    >
      <template v-slot:body="props">
        <q-tr :props="props" :class="{ 'holder-row': !props.row.id }">
          <q-td v-for="col in props.cols" :key="col.name" :props="props">
            <template v-if="props.row[col.name]?.viewUrl">
              <router-link :to="props.row[col.name].viewUrl">
                {{ !props.row.id ? props.row[col.name].viewName : null }}
              </router-link>
            </template>

            <template v-else-if="col.name === 'date'">
              {{ formatDay(props.row.instrument.valuation?.date) }}
            </template>

            <template v-else-if="col.name === 'valuationPrice' && !props.row.id">
              {{ formatNumber(props.row.instrument.valuation?.priceSEK) }}
            </template>

            <template v-else>{{ formatNumber(props.row[col.name]) }}</template>

            <template v-if="col.name === 'menu' && !props.row.instrument.isKapclearProduct && props.row.id">
              <q-btn flat round color="primary" icon="more_vert" size="sm" id="test-position-row-more-dropdown">
                <q-menu>
                  <q-list>
                    <q-item clickable v-close-popup @click="registerSettlement(props.row)">
                      <q-item-section>Boka ut</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>
            </template>
          </q-td>
        </q-tr>
      </template>
      <template v-slot:bottom-row>
        <a-table-row-btn @click="registerInstruction" label="Registrera inbokning" />
      </template>
    </q-table>
  </a-page-section>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { Ownership, Position } from '@/models';
import { TransactionType } from '@/models';
import { QTableProps } from 'quasar';
import { openNewInstruction } from '@/actions';
import { findPositions } from '@/models/position/positionService';
import { buildPositionRows, PositionAndAccumulatedValues } from './buildPositionRows';

@Component
export default class KapsurePositionTable extends Vue {
  @Prop() ownership: Ownership;
  @Prop() performSave;

  positions: Array<Position> = [];
  positionsAndAccValues: PositionAndAccumulatedValues[] = [];

  async mounted() {
    if (!this.ownership.id) {
      return;
    }
    this.positions = await findPositions({ ownership: this.ownership });
    this.positionsAndAccValues = buildPositionRows(this.positions);
  }

  registerInstruction() {
    const data = {
      destinations: [{ ownership: this.ownership.id }],
      type: TransactionType.KAPSURE_BOOKING,
    };
    openNewInstruction(data);
  }

  registerSettlement(position) {
    const data = {
      type: TransactionType.KAPSURE_SETTLEMENT,
      sources: [{ origin: position.id }],
    };
    openNewInstruction(data);
  }

  columns: QTableProps['columns'] = [
    {
      name: 'instrument',
      field: 'instrument',
      label: 'Värdepapper',
      align: 'left',
    },
    {
      field: row => (row.instrument.isValueDistribution ? row.amount : row.quantity),
      name: 'quantity',
      label: 'Antal',

      align: 'left',
      classes: 'test-position-row-quantity',
    },
    {
      field: instrument => instrument.valuation?.price,
      name: 'valuationPrice',
      label: 'Senaste värdering',
      align: 'left',
    },
    {
      field: 'currentValue',
      name: 'currentValue',
      label: 'Värde',
      align: 'left',
    },
    {
      field: instrument => instrument.valuation?.date,
      name: 'date',
      label: 'Värderingsdag',
      align: 'left',
    },
    {
      field: 'acquisitionValue',
      name: 'acquisitionValue',
      label: 'Anskaffningsvärde',
    },
    {
      name: 'menu',
      field: null,
      label: '',
      classes: 'q-table--col-auto-width',
    },
  ];

  pagination = {
    rowsPerPage: 0,
    sortBy: 'owner',
  };
}
</script>

<style lang="scss">
.KapsurePositionTable {
  .holder-row {
    background: #f0f0f0;
  }
}
</style>
