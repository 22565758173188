import * as _ from 'lodash';
import FileUpload from '@/models/investmentOffer/FileUpload';
import { signFile, uploadToAmazon } from './uploadutils';

export async function uploadFile(fileUpload: FileUpload, model: any) {
  const { signedUrl, file } = await signFile(fileUpload, model);

  await uploadToAmazon(signedUrl, fileUpload);
  fileUpload.model.files.push(file);
}
