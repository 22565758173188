<template>
  <q-layout view="hHh Lpr lfr" v-if="profile" class="layout-standard">
    <Navbar v-if="loggedIn" />
    <LeftPanel v-if="loggedIn" />

    <q-page-container>
      <router-view />
    </q-page-container>

    <MessageBanner />
  </q-layout>
</template>

<script lang="ts">
import store from '@/store';
import Navbar from '@/components/Navbar.vue';

import MessageBanner from '@/components/MessageBanner.vue';
import LeftPanel from '@/components/LeftPanel.vue';

import { Component, Vue } from 'vue-facing-decorator';

@Component({
  components: {
    Navbar,
    LeftPanel,
    MessageBanner,
  },
})
export default class App extends Vue {
  get loggedIn() {
    return store.profile.loggedIn;
  }
  get profile() {
    return store.profile;
  }
}
</script>
<style lang="scss">
.layout-standard {
  background: $grey-1;

  .q-drawer--left {
    background: $grey-0;
  }
  .q-footer {
    z-index: 0;
  }
  @media (min-width: 1360px) {
    .q-page-container,
    .q-drawer--left,
    .q-footer {
      margin-left: calc(50vw - 680px);
    }
    .q-page-container {
      max-width: 1360px;
    }
    .q-footer {
      max-width: 1160px;
    }
  }
}
.layout-public {
  @media (min-width: 1360px) {
    .q-footer {
      .q-toolbar {
        max-width: 1160px;
        margin: 0 auto;
      }
    }
  }
}

.layout-onboarding {
  background: black;
}
</style>
