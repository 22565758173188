<template>
  <q-page class="OwnerPositionsPage" v-if="ready">
    <div class="row q-gutter-lg q-mt-sm">
      <div class="col">
        <h1>Innehav för {{ owner.viewName }}</h1>
      </div>
    </div>
    <q-card flat class="q-pa-none PageSection">
      <q-card-section class="q-pa-none">
        <OwnerPositionsTable :positions="positions" />
      </q-card-section>
    </q-card>
  </q-page>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator';

import { Entity, Position } from '@/models';
import OwnerPositionsTable from './OwnerPositionsTable.vue';
import { search } from '@/models/position/positionService';

import { isToNewPage, setActiveNavigationModel } from '@/utils';
import PositionSearchQuery from '@/models/position/PositionSearchQuery';
import { loadEntity } from '@/models/entity/entityService';
@Component({
  components: { OwnerPositionsTable },
  mixins: [],
})
export default class OwnerPositionsPage extends Vue {
  owner: Entity;
  positions: Array<Position>;
  async created() {
    await this.loadData();
  }

  @Watch('$route')
  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }
    const { id } = this.$route.params;

    this.owner = await loadEntity(id as string);
    setActiveNavigationModel(this.owner);
    this.positions = await this.loadPositions();
  }

  get ready() {
    return this.positions != null;
  }

  async loadPositions() {
    const { owner } = this;

    return await search(
      PositionSearchQuery.newModel({
        ownerOrInvestor: owner,
      }),
    );
  }
}
</script>
