<template>
  <q-field
    class="type-field CheckboxGroup"
    flat
    :modelValue="modelValue"
    :rules="rules"
    :readonly="readonly"
    ref="input"
  >
    <q-option-group
      type="checkbox"
      emit-value
      map-options
      :options="options"
      @update:modelValue="$emit('update:modelValue', $event)"
      :modelValue="modelValue"
      :disable="readonly"
    />
  </q-field>
</template>

<script lang="ts">
import { Component, Vue, Prop, Ref } from 'vue-facing-decorator';

@Component
export default class CheckboxGroup extends Vue {
  @Prop() label: string;
  @Prop() options;
  @Prop() modelValue;
  @Prop() rules;
  @Prop() readonly;
  @Ref() readonly input;
  validate() {
    return this.input.validate();
  }
}
</script>
<style lang="scss">
.CheckboxGroup {
  .q-field__control {
    height: auto;
  }
  .disabled,
  .disabled * {
    opacity: 1 !important;
    cursor: default !important;
  }

  .q-field__control:before {
    border-color: rgba(0, 0, 0, 0) !important;
  }
  .q-field__control:after {
    height: 0;
  }
  .q-checkbox__label {
    color: $text-color;
  }
}
</style>
