<template>
  <q-page class="InstructionListPage">
    <InstructionSearchWithResult header="Ägarbyten" :mainType="mainType" :showCreateButton="true" />
  </q-page>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import { InstructionMainType } from '@/models';
import InstructionSearchWithResult from '@/views/instructionList/InstructionSearchWithResult.vue';
@Component({
  components: {
    InstructionSearchWithResult,
  },
})
export default class InstructionListPage extends Vue {
  get mainType() {
    return InstructionMainType.TRADE_EVENT;
  }
}
</script>
