<template>
  <span>
    <span>
      <span v-if="compareWith">
        <span v-for="({ value, diff }, index) in formattedNumberWithDiff" :key="index">
          <span :class="diff ? 'text-red' : ''">{{ value }}</span>
        </span>
      </span>
      <span v-else>{{ formattedNumber }}</span>
    </span>
    <template v-if="expandable && croppedPart.length">
      <q-btn class="expand-btn" @click="expanded = true" flat dense label="...">
        <q-tooltip>Visa resten av decimalerna</q-tooltip>
      </q-btn>
    </template>
  </span>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { formatNumber } from '@shared/utils';

const maxDecimals = 20;

@Component({})
export default class FormattedNumber extends Vue {
  @Prop({ required: true }) number;
  @Prop({ default: 0 }) minDecimals;
  @Prop({ default: 4 }) maxDecimals;
  @Prop() compareWith;
  @Prop({ default: true }) expandable;
  expanded = false;

  get formattedNumberWithDiff() {
    const diffStartIndex = this.diffStartIndex(
      this.formattedNumber.toString(),
      formatNumber(this.compareWith, null, maxDecimals),
    );
    return this.formattedNumber
      .toString()
      .split('')
      .map((char, index) => {
        return {
          value: char,
          diff: this.number !== this.compareWith && (diffStartIndex === -1 || index >= diffStartIndex),
        };
      });
  }

  diffStartIndex(a: string, b: string): number {
    if (this.compareWith) {
      return a.split('').findIndex((char, index) => {
        const charToCompare = b?.[index];
        return char !== charToCompare;
      });
    }
    return null;
  }

  get croppedPart() {
    if (Number.isNaN(this.number) || this.number == null) {
      return '';
    }
    const full = formatNumber(this.number, this.minDecimals, maxDecimals);
    return full.substring(this.formattedNumber.length);
  }

  get formattedNumber() {
    if (this.number === null) {
      return '';
    }
    return formatNumber(this.number, this.minDecimals, this.expanded ? maxDecimals : this.maxDecimals);
  }
}
</script>
<style>
.expand-btn .q-btn__wrapper {
  padding: 0;
  min-height: 5px;
}
</style>
