<template>
  <a-label-item v-if="!isReadonly" label="Lägg till depåinstitut">
    <SelectEntity
      :disable="isReadonly"
      type="legalEntity"
      entityType="participant"
      @update:modelValue="entity => onSelectConnection(entity, EntityConnectionType.AsParticipant)"
    />
  </a-label-item>
  <a-label-item :label="label">
    <div v-if="!entity.legalEntity.entityConnections?.length">Inget anslutet</div>
    <div
      class="row no-wrap justify-between items-center q-pt-xs"
      :key="connection.id"
      v-for="connection in entity.legalEntity.entityConnections"
    >
      <router-link :to="`/legal-entity/view/${connection.entity.id}`">{{
        connection.entity.legalEntity?.name
      }}</router-link>
      <q-btn
        v-if="!isReadonly"
        class="q-px-sm q-py-xs"
        flat
        color="primary"
        size="sm"
        icon="delete"
        @click="onDisconnectEntity(connection)"
      />
    </div>
  </a-label-item>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { Entity, EntityConnection } from '@shared/web/models';
import { EntityConnectionType, EntityType, asModelId } from '@shared/index';
import SelectEntity from '@/views/entity/SelectEntity.vue';

@Component({
  components: { SelectEntity },
})
export default class EntityConnections extends Vue {
  @Prop() entity: Entity;
  @Prop() performSave;
  @Prop() readonly;

  EntityConnectionType = EntityConnectionType;

  get label() {
    if (this.isInsuranceCompany) return 'Anslutna depåinstitut';
    if (this.isParticipant) return 'Anslutna försäkringsbolag';
    return 'Anslutna bolag';
  }

  get isReadonly() {
    return this.readonly || !this.isInsuranceCompany;
  }

  get isInsuranceCompany() {
    return this.entity.types.includes(EntityType.insuranceCompany);
  }

  get isParticipant() {
    return this.entity.types.includes(EntityType.participant);
  }

  get connections() {
    const connections = this.entity.legalEntity.entityConnections || [];
    if (this.isInsuranceCompany) {
      return connections.filter(connection => connection.type === EntityConnectionType.AsParticipant);
    } else if (this.isParticipant) {
      return connections.filter(connection => connection.type === EntityConnectionType.AsInsuranceCompany);
    }
    return [];
  }

  onSelectConnection(entity, type: EntityConnectionType) {
    if (entity) {
      this.entity.legalEntity.entityConnections = this.entity.legalEntity.entityConnections || [];
      if (
        !this.entity.legalEntity.entityConnections.find(
          connection => connection.type === type && asModelId(connection.entity) === asModelId(entity),
        )
      ) {
        const connection = EntityConnection.newModel({ type, entity }, false);
        this.entity.legalEntity.entityConnections.push(connection);
      }
    }
  }

  onDisconnectEntity(connectionToDelete) {
    const index = this.entity.legalEntity.entityConnections.findIndex(connection => {
      return connection === connectionToDelete;
    });
    this.entity.legalEntity.entityConnections.splice(index, 1);
  }
}
</script>
<style lang="scss"></style>
