<template>
  <a-page-section header="Emittent" class="IssuerData padded" v-if="issuerData">
    <div class="row q-col-gutter-x-xl">
      <q-list :separator="true" class="standard-list col-md-7">
        <IssuerSharesInfo :entity="entity" />
        <a-label-item v-if="entity.isKapsureIssuer" label="Bolagsvärde">
          <q-item>
            <q-item-section>
              <a-input-number
                allowOnlyNumber
                :minimumFractionDigits="2"
                :maximumFractionDigits="20"
                v-bind="inputAttributes"
                :readonly="readonly"
                v-model="issuerData.companyValue"
              />
            </q-item-section>
          </q-item>
        </a-label-item>
      </q-list>

      <q-list :separator="true" class="standard-list col-md-5">
        <a-label-item label="Valuta">
          <SelectCurrency
            v-model="issuerData.currencyCode"
            v-bind="inputAttributes"
            :rules="validation.required('Valuta')"
            :readonly="readonlyShareData"
          />
        </a-label-item>
        <a-label-item label="Aktiebokstatus" v-if="entity.isKapclearIssuer">
          {{ shareRegisterStatusText }}
        </a-label-item>
        <a-label-item label="Avstämningsbolag">
          <template v-if="inputAttributes.readonly"> {{ toBooleanLabel(issuerData.isRecordCompany) }}</template>
          <q-checkbox v-else v-model="issuerData.isRecordCompany" class="q-pt-xs" />
        </a-label-item>
        <a-label-item label="Enbart TO-kund">
          <template v-if="inputAttributes.readonly"> {{ toBooleanLabel(issuerData.onlyWarrantCompany) }}</template>
          <q-checkbox v-else v-model="issuerData.onlyWarrantCompany" class="q-pt-xs" />
        </a-label-item>
      </q-list>
    </div>
  </a-page-section>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import SelectCurrency from '@shared/components/SelectCurrency.vue';
import { validation, options, toShareRegisterStatusText } from '@shared/common';
import FormattedNumber from '@shared/components/FormattedNumber.vue';
import { Entity } from '@/models';
import store from '@/store';
import { Profile } from '@/models';
import { formatNumber } from 'shared/src';
import IssuerSharesInfo from '@/components/IssuerSharesInfo.vue';

@Component({
  components: { SelectCurrency, FormattedNumber, IssuerSharesInfo },
})
export default class IssuerData extends Vue {
  @Prop() entity: Entity;
  @Prop() readonly: boolean;

  get issuerData() {
    return this.entity.issuerData;
  }

  get hasQuantityDiff() {
    return this.entity.roaringQuantityDiff > 0;
  }

  get hasCapitalDiff() {
    return this.entity.roaringCapitalDiff > 0;
  }

  get roaringTotalQuantity(): number {
    return this.entity.issuerDataTotalQuantityFromRoaring;
  }

  get roaringTotalCapital(): number {
    return this.entity.issuerDataTotalCapitalFromRoaring;
  }

  get roaringQuoteValue(): number | null {
    if (this.roaringTotalCapital && this.roaringTotalQuantity) {
      return this.roaringTotalCapital / this.roaringTotalQuantity;
    }
    return null;
  }

  get profile(): Profile {
    return store.profile;
  }

  get shareRegisterStatusText() {
    return toShareRegisterStatusText(this.entity);
  }

  get validation() {
    return validation;
  }
  get options() {
    return options;
  }

  get readonlyShareData() {
    return this.readonly || this.issuerData.locked || (this.entity.isSE && !this.profile.isAdmin);
  }

  get inputAttributes() {
    return {
      dense: true,
      filled: true,
      square: true,
      readonly: this.readonly || this.issuerData.locked,
    };
  }

  formatNumber(number: any, min?: number, max?: number) {
    return formatNumber(number, min, max);
  }
}
</script>
<style lang="scss">
.IssuerData {
  .link-row {
    justify-content: flex-end;
  }
  .SectionFooter a {
    padding-left: 16px;

    &.q-btn {
      margin-right: auto;
      padding-left: 0;
    }
  }
}
</style>
