<template>
  <div>
    <q-table
      :rows="rows"
      :columns="columns"
      row-key="id"
      flat
      hide-pagination
      hide-no-data
      :pagination="pagination"
      class="standard-table"
    >
      <template v-slot:body-cell-nationalId="props">
        <q-td :props="props">
          <template>{{ props.value }} </template>
        </q-td>
      </template>
      <template v-slot:body-cell-actions="props">
        <q-td key="actions">
          <q-btn flat round color="grey-4" icon="more_vert" size="sm">
            <q-menu self="center left" anchor="center left" :offset="[70, 0]" content-class="a-tooltip shadow-1">
              <q-list>
                <q-item clickable v-close-popup @click="editAdmin(props.row)">
                  <q-item-section>Ändra</q-item-section>
                </q-item>
                <q-item clickable v-close-popup @click="login(props.row)">
                  <q-item-section>Logga in</q-item-section>
                </q-item>
                <q-item clickable v-close-popup @click="handleDeleteAdminClick(props.row)">
                  <q-item-section>Ta bort</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </q-td>
      </template>
      <template v-slot:bottom-row>
        <a-table-row-btn @click="addAdmin" label="Lägg till administratör" />
      </template>
    </q-table>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { Entity, EntityAdmin } from '@/models';
import { toEntityAdminRoleLabel } from '@shared/common';
import AdministratorFormModal from '@/views/legalEntity/AdministratorFormModal.vue';
import axios from 'axios';
import { QTableProps } from 'quasar';
import { deleteAdmin, findEntityAdminsForEntity } from '@/models/entityAdmin/entityAdminService';

@Component({
  components: {},
})
export default class AdministratorTable extends Vue {
  @Prop() entityAdmins: Array<EntityAdmin>;
  @Prop({ required: true, default: null }) entity: Entity;
  rows;

  async created() {
    await this.loadAdmins();
  }

  async loadAdmins() {
    if (this.entityAdmins) {
      this.rows = this.entityAdmins;
    } else if (this.entity?.id) {
      this.rows = await findEntityAdminsForEntity(this.entity);
    }
  }

  pagination = {
    rowsPerPage: 0,
    sortBy: 'type',
  };

  columns: QTableProps['columns'] = [
    {
      field: admin => admin.user?.name,
      name: 'name',
      label: 'Namn',
      align: 'left',
    },
    {
      field: admin => admin.user?.personalNumber,
      name: 'personalNumber',
      label: 'Personnummer',
      align: 'left',
    },
    {
      field: admin => admin.email,
      name: 'email',
      label: 'E-post',
      align: 'left',
    },
    {
      field: admin => admin.phone,
      name: 'phone',
      label: 'Telefon',
      align: 'left',
    },
    {
      field: admin => toEntityAdminRoleLabel(admin.role),
      name: 'role',
      label: 'Roll',
      align: 'left',
    },
    {
      name: 'actions',
      field: null,
      label: '',
    },
  ];

  addAdmin() {
    this.$q
      .dialog({
        component: AdministratorFormModal,
        componentProps: {
          entity: this.entity,
        },
      })
      .onOk(async () => {
        this.$emit('refreshAdmins');
        await this.loadAdmins();
      });
  }

  editAdmin(admin) {
    this.$q
      .dialog({
        component: AdministratorFormModal,
        componentProps: {
          admin: EntityAdmin.newModel({ ...admin }),
          entity: this.entity,
        },
      })
      .onOk(async () => {
        this.$emit('refreshAdmins');
        await this.loadAdmins();
      });
  }

  async login(admin: EntityAdmin) {
    const { data } = await axios.get('/api/user/create-token/' + admin.user.id);
    window.open(data.link, '_blank');
  }

  async handleDeleteAdminClick(admin: EntityAdmin) {
    this.$q
      .dialog({
        title: 'Är du säker?',
        message: `Vill du ta bort användare ${admin.user.name}?`,
        ok: 'Ta bort',
        cancel: 'Avbryt',
      })
      .onOk(async () => {
        await deleteAdmin(admin);
        this.entityAdmins = this.entityAdmins.filter(a => a !== admin);
      });
  }
}
</script>
<style lang="scss"></style>
