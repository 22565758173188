<template>
  <div>
    <q-card flat class="bg-white q-pa-md q-mb-sm">
      <div class="row">
        <div v-html="comment.text"></div>
      </div>
      <div class="row">
        <div class="col-4 text-small self-center">
          <span class="text-bold">Skapad:</span> {{ formatDateTime(comment.createdAt) }} &dash;
          {{ comment.author.viewName }}
        </div>
        <div class="col-6 text-small self-center">
          <div v-if="comment.lastActivity">
            <span class="text-bold">Senast uppdaterad:</span> {{ formatDateTime(comment.lastActivity.at) }} &dash;
            {{ comment.lastActivity.by.username }}
          </div>
        </div>
        <div class="col-2" align="right">
          <q-btn dense flat @click="edit">
            <q-icon name="edit" />
          </q-btn>
          <q-btn dense flat @click="remove">
            <q-icon name="delete" />
          </q-btn>
        </div>
      </div>
    </q-card>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import Comment from '@shared/web/models/entity/Comment';
import { formatDateTime } from '@shared/utils';
import CommentEditorModal from '@/views/comment/CommentEditorModal.vue';
import { removeComment } from '@/models/comment/commentService';

@Component
export default class CommentCard extends Vue {
  @Prop({ required: true }) comment: Comment;
  formatDateTime(datetime: string | Date) {
    return formatDateTime(new Date(datetime));
  }
  edit() {
    this.$q.dialog({
      component: CommentEditorModal,
      componentProps: {
        update: this.comment,
      },
    });
  }
  async remove() {
    await removeComment(this.comment);
    this.$emit('removed', this.comment);
  }
}
</script>
