import { Entity } from '@/models';
import axios from 'axios';
import { initModel, modelHandler, serialize, Update } from '../base';
import { EntitySearchQuery } from '@shared/web/models/entity/EntitySearchQuery';
import LESearchQuery from './LESearchQuery';
import { addModels } from '@/store';
import { MergeItems } from '@shared/models/types';

const baseUrl = '/api/entity';
const { searchModels, createModel, updateModel, findModel, saveModel, destroyModel } = modelHandler(Entity, baseUrl);

export async function search(query: LESearchQuery, defaults = {}): Promise<Array<Entity>> {
  const result = await searchModels({ ...defaults, ...query });
  return result;
}

export const searchEntities = search;

export async function searchEntity(searchQuery: EntitySearchQuery) {
  const { data } = await axios.post('/api/entity/search-autocomplete', serialize(searchQuery));
  return Entity.newModels(data, false);
}

export async function create(entity: Entity): Promise<Entity> {
  const { model } = await createModel(entity);
  return model;
}

export async function update(entity: Entity): Promise<Update<Entity>> {
  return updateModel(entity);
}

export async function save(entity: Entity): Promise<Entity> {
  const { model } = await saveModel(entity);
  return model;
}

export async function lookupEntity({ nationalId, countryCode, isPerson }): Promise<Entity> {
  const { data } = await axios.post(baseUrl + '/lookup', { nationalId, countryCode, isPerson });
  return Entity.newModel(data, true);
}

export async function loadEntity(id: Entity | string) {
  return findModel(id, true);
}

export async function findEntity(id) {
  return findModel(id);
}

export async function mergeEntities(mergeEntities: MergeItems) {
  const { data } = await axios.post(baseUrl + `/merge-entities`, mergeEntities);
  return data;
}

export async function enableAutoupdate(entity: Entity) {
  const { data } = await axios.put(baseUrl + '/autoupdate', { id: entity.id });

  Object.assign(entity, data);
  return initModel(entity, true);
}

export async function destroyEntity(entity: Entity) {
  await destroyModel(entity);
}

async function fillIssuerData(entity: Entity) {
  const { nationalId, countryCode } = entity;
  const { data } = await axios.post(baseUrl + '/lookup-issuerdata', { nationalId, countryCode });
  entity.issuerData = data;
  initModel(entity);
}

export async function initTypeData(entity: Entity) {
  if (entity.isIssuer) {
    entity.issuerData == null && (await fillIssuerData(entity));
  } else {
    entity.issuerData = null;
  }
}

let custodians = null;
export async function findCustodians() {
  if (custodians == null) {
    custodians = await searchEntity(new EntitySearchQuery({ options: { entityType: 'participant' }, query: '' }));
    custodians = custodians.sort((a, b) => (a.viewName > b.viewName ? 1 : -1));
    addModels(custodians);
  }
  return custodians;
}

let insuranceCompanies = null;
export async function findInsuranceCompanies() {
  if (insuranceCompanies == null) {
    insuranceCompanies = await searchEntity(
      new EntitySearchQuery({ options: { entityType: 'insuranceCompany' }, query: '' }),
    );
    insuranceCompanies = insuranceCompanies.sort((a, b) => (a.viewName > b.viewName ? 1 : -1));
    addModels(insuranceCompanies);
  }
  return insuranceCompanies;
}

export async function updateLegalEntityFromRoaring(entity: Entity) {
  const { data } = await axios.get(`${baseUrl}/${entity.id}/update-legal-entity-from-roaring`);
  Entity.newModel(data, true);
}

export async function updatePersonFromRoaring(entity: Entity) {
  return axios.get(`${baseUrl}/${entity.id}/update-person-from-roaring`);
}

export async function updateRolesFromRoaring(entity: Entity) {
  return axios.get(`${baseUrl}/${entity.id}/update-roles-from-roaring`);
}

export async function refreshSharesInfo(entity: Entity) {
  return axios.post(`${baseUrl}/${entity.id}/refresh-shares-info`);
}
