<template>
  <div v-if="ready" class="InsuranceList standard-table">
    <q-table
      :rows="list"
      :columns="columns"
      row-key="id"
      :pagination="pagination"
      :hide-pagination="true"
      flat
      hide-no-data
    >
      <template v-slot:body-cell-insuranceNumber="props">
        <q-td :props="props">
          <router-link :to="'/insurance/view/' + props.row.id">{{
            props.row.insuranceNumber || 'Försäkringsnummer saknas'
          }}</router-link>
        </q-td>
      </template>
    </q-table>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { formatISO9075 } from 'date-fns';
import { QTableProps } from 'quasar';

@Component({
  components: {},
})
export default class InsuranceList extends Vue {
  @Prop() list;

  @Prop({ default: false }) hidePagination;

  get ready() {
    return this.list != null;
  }

  pagination = {
    rowsPerPage: 0,
    sortBy: 'updatedAt',
  };
  columns: QTableProps['columns'] = [
    {
      name: 'insuranceNumber',
      sortable: true,
      field: 'insuranceNumber',
      label: 'Försäkringsnummer',

      align: 'left',
    },
    {
      name: 'investor',
      sortable: true,
      field: 'investor',
      label: 'Försäkringsägare',
      format: entity => entity?.viewName,
      align: 'left',
    },
    {
      name: 'owner',
      sortable: true,
      field: 'owner',
      label: 'Försäkringsbolag',
      format: entity => entity?.viewName,
      align: 'left',
    },
    {
      name: 'custodian',
      sortable: true,
      field: 'custodian',
      label: 'Depåinstitut',
      format: entity => entity?.viewName,
      align: 'left',
    },

    {
      name: 'updatedAt',
      field: 'updatedAt',
      label: 'Uppdaterad',
      format: updatedAt => updatedAt && formatISO9075(new Date(updatedAt)),
      sortable: true,
    },
  ];
}
</script>
