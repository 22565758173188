import { Position, ShareRegister } from '@/models';
import PositionSearchQuery from './PositionSearchQuery';
import axios from 'axios';
import { modelHandler, serializedChanges } from '../base';
import { removeById } from '@/utils';

const baseUrl = '/api/position';
const { searchModels, saveModel, destroyModel } = modelHandler(Position, baseUrl);

export async function findPositions(query) {
  return search(new PositionSearchQuery(query));
}

export async function search(query: PositionSearchQuery): Promise<Array<Position>> {
  const result = await searchModels(query);
  return result;
}

export async function deletePosition(position: Position, shareRegister: ShareRegister) {
  await destroyModel(position);
  removeById(shareRegister.positions, position);
}

export async function savePosition(position: Position) {
  return saveModel(position);
}

export async function updateWithSettlePosition(position: Position, settleDate: Date) {
  const update = serializedChanges(position);

  const { data } = await axios.put(baseUrl + '/update-with-settle-position', { settleDate, update });

  return Position.newModel(data, true);
}
