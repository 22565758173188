<template>
  <q-input
    v-bind="$attrs"
    :modelValue="val"
    @update:modelValue="onInput"
    @blur="onBlur"
    @keyup.enter="$event.target.blur()"
    lazy-rules
    ref="input"
  />
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch, Ref } from 'vue-facing-decorator';

@Component
export default class BlurInput extends Vue {
  @Prop() modelValue;
  val;

  @Ref() readonly input;

  created() {
    this.val = this.modelValue;
  }

  validate() {
    return this.input.validate();
  }

  @Watch('value')
  valueChange(value) {
    this.val = value;
  }

  onInput(val) {
    this.val = val;
  }
  onBlur() {
    this.$emit('update:modelValue', this.val);
  }
}
</script>
