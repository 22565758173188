<template>
  <a-page-section class="padded" header="Teckningsoption">
    <div class="row q-col-gutter-x-xl">
      <q-list separator class="standard-list col-md-6">
        <a-list-item label="Underliggande aktie">
          <a-link :target="rightsData.underlyingInstrument" v-if="readonlyWarrant" />

          <a-select
            v-model="rightsData.underlyingInstrument"
            v-bind="$defs.input"
            :readonly="readonlyWarrant"
            :options="shares"
            map-options
            option-label="viewName"
            :rules="validation.required('Aktie')"
            v-else
          />
        </a-list-item>
        <a-list-item label="Namn">
          <q-input v-model="warrantName" :readonly="true" v-bind="$defs.input" />
        </a-list-item>
        <a-list-item label="Antal utgivna">
          <a-input-number
            v-model="rightsData.totalQuantity"
            v-bind="$defs.input"
            :readonly="readonlyWarrant"
            :fractionDigits="0"
            @change="updateTotalQuantity"
          />
        </a-list-item>
        <a-list-item label="Underliggande per option">
          <a-input-number
            v-model="rightsData.contractSize"
            v-bind="$defs.input"
            :readonly="readonlyWarrant"
            :maximumFractionDigits="6"
          />
        </a-list-item>
        <a-list-item label="Premie">
          <a-input-number
            v-model="rightsData.premium"
            v-bind="$defs.input"
            :readonly="readonlyWarrant"
            :fractionDigits="2"
          />
        </a-list-item>
        <a-list-item label="KapID för BTA" v-if="kapIdForBTA">{{ kapIdForBTA }} </a-list-item>
      </q-list>
      <q-list separator class="standard-list col-md-6">
        <a-list-item label="Utgivningsår">
          <a-select
            v-bind="$defs.input"
            placeholder="yyyy"
            emit-value
            map-options
            class="fill-width"
            v-model="rightsData.issueDate"
            :readonly="readonlyWarrant"
            :options="yearOptions"
          />
        </a-list-item>

        <a-list-item label="Teckningsperiod från">
          <a-input-date v-model="rightsData.exercisePeriodFrom" v-bind="$defs.input" :readonly="readonlyWarrant" />
        </a-list-item>
        <a-list-item label="Teckningsperiod till">
          <a-input-date v-model="rightsData.exercisePeriodTo" v-bind="$defs.input" :readonly="readonlyWarrant" />
        </a-list-item>
        <a-list-item label="Slutdag">
          <a-input-date v-model="rightsData.dueDate" v-bind="$defs.input" :readonly="readonlyWarrant" />
        </a-list-item>
        <a-list-item label="Teckningskurs">
          <a-input-number
            v-model="rightsData.exercisePrice"
            v-bind="$defs.input"
            :readonly="readonlyWarrant"
            :fractionDigits="2"
          />
        </a-list-item>
      </q-list>
    </div>
  </a-page-section>
</template>

<script lang="ts">
import { range } from 'lodash';
import { Component, Vue, Prop, Watch } from 'vue-facing-decorator';
import { Instrument, RightsData, Instruction, Entity } from '@/models';
import { validation } from '@shared/common';
import { createWarrantName } from '@shared/rightsIssue/utils';

@Component({
  components: {},
})
export default class WarrantsData extends Vue {
  @Prop() entity: Entity;
  @Prop() readonly: boolean;
  @Prop() rightsData: RightsData;
  @Prop() instruction: Instruction;
  @Prop() warrant: Instrument;

  get shares(): Array<Instrument> {
    return this.entity.shares;
  }

  updateTotalQuantity() {
    if (this.warrant?.shareData) {
      this.warrant.shareData.totalQuantity = this.warrant.rightsData.totalQuantity;
    }
  }

  get validation() {
    return validation;
  }

  get yearOptions() {
    const baseYear = this.rightsData.issueDate?.getFullYear() || new Date().getFullYear();
    const years = range(baseYear - 5, baseYear + 5);
    return years.map(year => ({
      label: `${year}`,
      value: new Date(`${year}-01-01T00:00:00.000Z`),
    }));
  }

  get readonlyWarrant() {
    return this.readonly || this.rightsData.issued;
  }

  get kapIdForBTA() {
    return this.instruction?.corporateEvent.instrumentSources[0]?.interimShareKapID;
  }

  get warrantName() {
    if (this.warrant) {
      return this.warrant.name;
    }
    return createWarrantName(this.rightsData, this.entity.instruments);
  }
  @Watch('rightsData.exercisePeriodTo')
  setDefaultDueDate(date) {
    if (!this.rightsData.dueDate) {
      this.rightsData.dueDate = new Date(date.toISOString());
    }
  }
}
</script>
