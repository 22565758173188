<template>
  <q-markup-table flat class="standard-table DestinationsTable" separator="horizontal">
    <thead>
      <tr>
        <th class="text-left holder-name-th"></th>
        <SortableTH property="instrument" :tableSettings="tableSettings" v-if="columns.instrument">Aktie</SortableTH>
        <SortableTH property="owner" :tableSettings="tableSettings" v-if="columns.owner">Ägare</SortableTH>

        <SortableTH property="investor" :tableSettings="tableSettings">Försäkringsägare</SortableTH>

        <th class="text-left">Förs.nr</th>
        <th class="text-right number" v-if="columns.ownedQuantity">Antal</th>
        <th class="text-right number" v-if="columns.allocatedQuantity">Nya aktier, pro-rata</th>
        <th class="text-right number" v-if="columns.quantity">
          {{ isConvertible ? 'Antal Konvertibler' : 'Tecknade' }}
        </th>
        <th class="text-right number" v-if="columns.amount">Nom. belopp</th>
        <th v-if="!readonly"></th>
      </tr>
    </thead>
    <tbody>
      <template v-for="{ holder, positions } in positionGroups" :key="holder?.id">
        <HolderRow :holder="holder" v-if="holder" />

        <template v-for="destination in positions" :key="destination.id">
          <DestinationRow
            :clickedButtons="clickedButtons"
            @expand="expand"
            :destination="destination"
            :instruction="instruction"
            :validator="validator"
            :shareRegister="shareRegister"
            :tableSettings="tableSettings"
            :columns="columns"
            :readonly="readonly"
            @change="$emit('change')"
          />
        </template>
        <a-table-row-btn
          :key="'footer' + (holder && holder.id)"
          v-if="!readonly && !disableAdd"
          @click="addPosition(holder)"
          label="Lägg till position"
        />
      </template>
    </tbody>
  </q-markup-table>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';

import { Instruction, InstructionParty, ShareRegister, TransactionType } from '@/models';
import { getPositionGroups } from '@shared/shareRegister/utils';

import DestinationRow from './DestinationRow.vue';
import HolderRow from './HolderRow.vue';
import SortableTH from './SortableTH.vue';
import EditPositionModal from '@shared/views/editPositionModal/EditPositionModal.vue';
import TableSettings from '@shared/web/models/shareRegister/TableSettings';

@Component({
  components: { HolderRow, DestinationRow, SortableTH },
})
export default class DestinationsTable extends Vue {
  @Prop() shareRegister: ShareRegister;
  @Prop() tableSettings: TableSettings;
  @Prop() instruction: Instruction;
  @Prop() readonly: boolean;

  @Prop() disableAdd: boolean;
  @Prop() validator;
  clickedButtons = {};

  expand(toggle) {
    const { id, show } = toggle;
    this.clickedButtons[id] = show;
  }

  isOpen(position) {
    return this.clickedButtons[position.id];
  }

  @Watch('$route')
  onUrlChange() {
    this.clickedButtons = {};
  }

  get isConvertible() {
    return this.instruction.type === TransactionType.ISSUE_CONVERTIBLE;
  }

  get isShareholderContribution() {
    return this.instruction.type === TransactionType.ISSUE_SHAREHOLDER_CONTRIBUTION;
  }

  get isBond() {
    return this.instruction.type === TransactionType.ISSUE_BOND;
  }

  get isIssueDebenture() {
    return this.instruction.type === TransactionType.ISSUE_DEBENTURE;
  }

  get defaultOwners() {
    return this.shareRegister.getUniqueOwners();
  }
  get issuerData() {
    return this.shareRegister.entity.issuerData;
  }

  addPosition(holder) {
    let data = this.instrumentMode ? { instrument: holder } : { owner: holder };

    this.$q
      .dialog({
        component: EditPositionModal,
        componentProps: {
          positionClass: InstructionParty,
          value: data,
          label: 'deltagare',
          tradeEventForm: true,
          instruction: this.instruction,
          shareRegister: this.shareRegister,
        },
      })
      .onOk(() => {
        this.$emit('change');
      });
  }

  get positionGroups() {
    const { destinations, corporateEvent } = this.instruction;
    const instruments = corporateEvent.instrumentSources.map(source => source.instrument);
    return getPositionGroups(destinations, instruments, this.tableSettings);
  }

  get instrumentMode() {
    return this.tableSettings.groupProperty === 'instrument';
  }

  get columns() {
    const { type } = this.instruction;
    const { groupProperty, isInvestorRegister } = this.tableSettings;
    const distributeByValue = !!this.instruction?.corporateEvent?.rightsData?.distributeByValue;
    return {
      owner: groupProperty !== 'owner' || isInvestorRegister,
      instrument: groupProperty !== 'instrument',
      allocatedQuantity: type === TransactionType.RIGHTS_ISSUE || type === TransactionType.EXERCISE_WARRANT,
      quantity: !distributeByValue,
      ownedQuantity: type === TransactionType.RIGHTS_ISSUE,
      amount: distributeByValue,
    };
  }
}
</script>
<style lang="scss">
.DestinationsTable {
  .q-input.number {
    width: 80px;
    margin-left: auto;
  }
  .holder-row {
    background: #f0f0f0;
  }
  .holder-name-th {
    width: 100px;
  }
}
</style>
