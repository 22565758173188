import { Ownership, Entity } from '@/models';
import axios from 'axios';
import { modelHandler, Update } from '../base';
import { asModelId } from '@/utils';
import { MergeItems } from '@shared/models/types';

const baseUrl = '/api/ownership';
const { findModel, saveModel, destroyModel } = modelHandler(Ownership, baseUrl);

export async function save(ownership: Ownership): Promise<Update<Ownership>> {
  return await saveModel(ownership);
}

export async function destroyOwnership(ownership: Ownership) {
  return await destroyModel(ownership);
}

export async function mergeOwnership(mergeOwnership: MergeItems) {
  const { data } = await axios.post(baseUrl + `/merge`, mergeOwnership);
  return data;
}

export async function findOwnership(id) {
  return findModel(id);
}

export async function findOwnerships(query) {
  const { data } = await axios.post(baseUrl + `/search`, query);

  return Ownership.newModels(data, true);
}

export async function findOwnershipsByInvestor(entity: Entity): Promise<Array<Ownership>> {
  const { data } = await axios.post(baseUrl + `/findbyinvestor/${asModelId(entity)}`);

  return Ownership.newModels(data, true);
}
