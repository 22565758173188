<template>
  <q-page>
    <h1 class="q-mb-xl">
      <span v-if="entity">{{ entity.viewName }} -</span>
      kommentarer
    </h1>
    <EntityCommentsView :entity="entity" />
  </q-page>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import { findEntity } from '@/models/entity/entityService';
import EntityCommentsView from '@/views/comment/EntityCommentsView.vue';
import { Entity } from '@shared/web/models';
import { setActiveNavigationModel } from '@/utils';

@Component({
  components: { EntityCommentsView },
})
export default class EntityCommentsPage extends Vue {
  editor;
  entity: Entity;

  async mounted() {
    const entity = await findEntity(this.$route.params.id);
    setActiveNavigationModel(entity);
    this.entity = entity;
  }
}
</script>
