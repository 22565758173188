<template>
  <q-page class="InvestmentOfferAddEdit">
    <div class="row">
      <h1>{{ pageTitle }} investeringserbjudande</h1>
    </div>
    <q-separator />
    <q-form v-if="investmentOffer" class="q-mt-lg" @submit.prevent="updateInvestmentOffer()">
      <div class="form q-mb-lg">
        <q-input
          class="q-pa-md"
          v-model="investmentOffer.offerCompanyName"
          label="Bolagets namn"
          @focus="resetErrorOnFocus"
          autofocus
        />
        <q-input
          class="q-pa-md"
          input-class="test-investment-offer-title-input"
          v-model="investmentOffer.title"
          label="Rubrik"
          :error="Boolean(titleError)"
          :error-message="titleError"
          @focus="resetErrorOnFocus"
        />
        <InvestmentOfferEditor class="editor-text" v-if="!disabled" v-model="investmentOffer.text" />
        <FileList
          :model="investmentOffer"
          :uploadFile="importedUploadFile"
          :FileUpload="importedFileUpload"
          :isEdit="isEdit"
        />
        <q-checkbox
          class="q-px-xs test-investment-offer-arcive-checkbox"
          v-model="investmentOffer.archived"
          label="Arkiverad"
          color="red"
        />
        <div class="row justify-end">
          <q-btn color="red-5" class="q-mt-sm q-mx-md q-my-md" @click="remove">Radera</q-btn>
          <q-btn flat color="primary" class="q-mt-sm q-mx-md q-my-md" @click="onCancel">Avbryt</q-btn>
          <q-btn type="submit" data-cy="investmentOfferSaveButton" class="q-mt-sm q-mx-md q-my-md">Spara</q-btn>
        </div>
      </div>
    </q-form>
    <div v-if="!investmentOffer">
      <p>Investeringserbjudandet kunde inte hittas</p>
    </div>
  </q-page>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import InvestmentOfferEditor from '@/components/InvestmentOfferEditor.vue';
import { fetchInvestmentOfferById } from '@shared/web/models/investmentOffer/investmentOffersService';
import {
  saveInvestmentOffer,
  removeInvestmentOffer,
  createInvestmentOffer,
} from '@/models/investmentOffer/investmentOfferService';
import FileList from '@/components/FileList.vue';
import axios from 'axios';
import { uploadFile } from '@/models/investmentOffer/fileHandler';
import FileUpload from '@/models/investmentOffer/FileUpload';
@Component({
  components: { InvestmentOfferEditor, FileList },
})
export default class InvestmentOfferAddEdit extends Vue {
  pageTitle: string;
  investmentOffer;
  hasErrors: boolean = false;
  titleError: string = '';
  importedUploadFile: any = uploadFile;
  importedFileUpload: any = FileUpload;

  async mounted(): Promise<void> {
    const paramOfferId = this.$route.params.id;

    if (paramOfferId) {
      this.pageTitle = 'Redigera';
      this.investmentOffer = await fetchInvestmentOfferById(paramOfferId.toString());
      this.investmentOffer.files = (await axios.get(`/api/investment-offer/list/${this.investmentOffer.id}`)).data;
    } else {
      this.pageTitle = 'Skapa';
    }
  }

  get disabled(): boolean {
    return !this.investmentOffer.text;
  }
  get isEdit() {
    return this.$route.params.id ? true : false;
  }
  async updateInvestmentOffer(): Promise<void> {
    this.validateTitle();
    if (this.hasErrors) {
      return;
    }
    this.investmentOffer.draft = false;
    await saveInvestmentOffer(this.investmentOffer);
    this.$q.notify({ type: 'positive', message: 'Erbjudandet uppdaterat' });
    this.$router.push('/investment-offer');
  }

  onCancel(): void {
    this.$router.push('/investment-offer');
  }

  async onRemove(): Promise<void> {
    await removeInvestmentOffer(this.investmentOffer);
    this.$router.push('/investment-offer');
  }

  async remove(): Promise<void> {
    this.$q
      .dialog({
        title: 'Radera erbjudandet',
        message: `Är du säker på att du vill radera erbjudandet?`,
        ok: 'Ja',
        cancel: 'Nej',
      })
      .onOk(async () => {
        await this.onRemove();
      });
  }
  async onSubmit(): Promise<void> {
    this.validateTitle();
    if (this.hasErrors) {
      return;
    }
    await createInvestmentOffer({
      title: this.investmentOffer.title,
      text: this.investmentOffer.text,
      archived: this.investmentOffer.archived,
      draft: this.investmentOffer.draft,
    });
    this.$q.notify({ type: 'positive', message: 'Erbjudandet tillagt.' });
    this.$router.push('/investment-offer');
  }
  validateTitle(): void {
    this.hasErrors = !this.investmentOffer.title;
    this.titleError = this.hasErrors ? 'Rubrik saknas' : '';
  }
  resetErrorOnFocus() {
    this.hasErrors = false;
    this.titleError = '';
  }
}
</script>
<style type="scss">
.InvestmentOfferAddEdit {
  .form {
    background: white;
  }
}
</style>
