import { Entity, IssuerInfo } from '@/models';

import { modelHandler } from '../base';
import axios from 'axios';
const baseUrl = '/api/issuer-info';
const { saveModel } = modelHandler(IssuerInfo, baseUrl);

export async function findIssuerInfo(entity: Entity) {
  const { data } = await axios.get(baseUrl + '/entity/' + entity.id);
  return IssuerInfo.newModel(data, true);
}

export async function saveIssuerInfo(info: IssuerInfo): Promise<IssuerInfo> {
  const { model } = await saveModel(info);

  return model;
}
