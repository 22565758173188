<template>
  <tr>
    <td colspan="3">
      {{ owner.viewName }}
    </td>
    <td colspan="2" v-if="columns.shareNumbers"></td>

    <td></td>
    <q-td class="text-right">
      {{ formatNumber(totals.totalQuantity) }}
    </q-td>

    <td class="text-right">{{ formatPercent(totals.totalShare, 2) }}</td>
    <td class="text-right">{{ formatPercent(totals.votingPower, 2) }}</td>
    <td colspan="5"></td>
  </tr>
</template>
<script lang="ts">
// @ts-nocheck
// @TODO kolla formatPercent(val, 2). Ska 2 vara där? Det fanns inget andra argument i det existerande filtret
import { Entity, ShareRegister } from '@/models';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { makeShareRegisterValidation } from '@/models/shareRegister/shareRegisterService';
import TableSettings from '@shared/web/models/shareRegister/TableSettings';
@Component({})
export default class OwnerRow extends Vue {
  @Prop() owner: Entity;
  @Prop() shareRegister: ShareRegister;
  @Prop() tableSettings: TableSettings;
  @Prop() columns;

  get issuerData() {
    return this.shareRegister.entity.issuerData;
  }

  get totals() {
    const totals = makeShareRegisterValidation(this.shareRegister);
    return totals.forOwner(this.owner);
  }
}
</script>
