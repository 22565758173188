import * as _ from 'lodash';
import ListItem from '@shared/components/ListItem.vue';
import BlurInput from './BlurInput.vue';
import PageSection from './PageSection.vue';
import CheckboxGroup from './CheckboxGroup.vue';
import InputFormatNumber from '@shared/components/NumberInput.vue';
import InputDate from '@shared/components/InputDate.vue';
import Select from './Select.vue';
import SectionFooter from './SectionFooter.vue';
import ConfirmModal from './ConfirmModal.vue';
import Link from './Link.vue';
import TableRowBtn from '@shared/components/TableRowButton.vue';
import aBtnLink from '@shared/components/ABtnLink.vue';
import aInfoBtn from '@shared/components/aInfoBtn.vue';
import { formatPercent, formatNumber, formatDay } from '@/utils';
import { formatNationalId } from '@shared/common';
import { QDialogOptions } from 'quasar';

let _app;

export default function init(app) {
  _app = app;
  app.component('a-page-section', PageSection);
  app.component('a-section-footer', SectionFooter);
  app.component('a-list-item', ListItem);
  app.component('a-blur-input', BlurInput);
  app.component('a-label-item', ListItem);
  app.component('a-checkbox-group', CheckboxGroup);
  app.component('a-input-number', InputFormatNumber);
  app.component('a-input-date', InputDate);
  app.component('a-select', Select);
  app.component('a-link', Link);
  app.component('a-table-row-btn', TableRowBtn);
  app.component('a-btn-link', aBtnLink);
  app.component('a-info-btn', aInfoBtn);

  Object.assign(app.config.globalProperties, {
    toBooleanLabel: value => {
      return value ? 'Ja' : 'Nej';
    },
    formatPercent: number => {
      return formatPercent(number);
    },

    isEmpty: value => {
      return _.isEmpty(value);
    },

    isNonEmpty: value => {
      return !_.isEmpty(value);
    },

    formatNumber: (number, min = 0, max = 2) => {
      return formatNumber(number, min, max);
    },
    formatDay: date => {
      return formatDay(date);
    },

    getErrorText: error => {
      return error.text || 'Ett fel uppstod';
    },

    withAllOption: options => {
      return [{ value: null, label: 'Alla' }, ...options];
    },
    formatNationalId: entity => {
      return formatNationalId(entity);
    },

    onlyProps: attrs => {
      // @vue3 när v-bind="$attrs" används följer även event med, exempelvis change. Det var inte fallet i vue 2, då kom endast attrs med, ej event. Genom att använda denna metod filtreras event bort
      return _.pickBy(attrs, attr => !_.isFunction(attr));
    },
    useConfirmModal({ header, text, useConfirm }: { header?: string; text: string; useConfirm?: boolean }) {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        this.$q.dialog({
          component: ConfirmModal,
          componentProps: {
            header,
            text,
            useConfirm,
            onConfirm: async () => {
              resolve(true);
            },
            onReject: async () => {
              reject(false);
            },
          },
        });
      });
    },
  });
}

export const openDialog = (props: QDialogOptions) => {
  return _app.config.globalProperties.$q.dialog(props);
};

export { default as SelectCountry } from '@shared/components/SelectCountry.vue';
export { default as InputNationalId } from './InputNationalId.vue';
export { default as ConfirmModal } from './ConfirmModal.vue';
