<template>
  <a-page-section header="Transaktioner" class="KapsureTransactionTable">
    <q-table
      :rows="transactions"
      :columns="columns"
      :pagination="pagination"
      row-key="id"
      flat
      hide-pagination
      hide-no-data
      class="standard-table"
    >
      <template v-slot:body-cell-instrument="props">
        <q-td :props="props">
          <template v-if="props.value?.viewUrl">
            <router-link :to="props.value.viewUrl"> {{ props.value.viewName }}</router-link>
          </template>
          <template v-else>{{ props.value }}</template>
        </q-td>
      </template>
      <template v-slot:body-cell-transactionType="props">
        <q-td :props="props">
          <template v-if="props.row.instruction">
            <router-link :to="`/instruction/view/${props.row.instruction.id ?? props.row.instruction}`">
              {{ props.value }}</router-link
            >
          </template>
          <template>{{ props.value }}</template>
        </q-td>
      </template>
    </q-table>
  </a-page-section>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { Ownership, Transaction } from '@/models';
import { QTableProps } from 'quasar';
import { search as searchTransactions } from '@/models/transaction/transactionService';
import { formatDay, formatNumber } from '@shared/utils';
import TransactionSearchQuery from '@shared/web/models/transaction/TransactionSearchQuery';
import { toTransactionTypeLabel } from '@shared/common';

@Component
export default class KapsureTransactionTable extends Vue {
  @Prop() ownership: Ownership;

  transactions: Array<Transaction>;

  async mounted() {
    const { transactions } = await searchTransactions(
      new TransactionSearchQuery({ ownership: this.ownership, includeCounterparts: false }),
    );
    this.transactions = transactions;
  }

  columns: QTableProps['columns'] = [
    {
      field: 'settleDate',
      name: 'settleDate',
      label: 'Införd i aktieboken',
      align: 'left',
      format: value => formatDay(value),
    },
    {
      field: 'transactionType',
      name: 'transactionTyp',
      label: 'Transaktionstyp',
      align: 'left',
      format: value => toTransactionTypeLabel(value),
    },
    {
      name: 'instrument',
      field: 'instrument',
      label: 'Värdepapper',
      align: 'left',
    },
    {
      field: 'quantity',
      format: value => value && formatNumber(value),
      name: 'quantity',
      label: 'Antal',
      align: 'right',
    },
    {
      field: 'amount',
      format: value => value && formatNumber(value),
      name: 'amount',
      label: 'Belopp',
      align: 'right',
    },
    {
      field: 'price',
      format: value => value && formatNumber(value),
      name: 'price',
      label: 'Pris',
      align: 'right',
    },
  ];
  pagination = {
    rowsPerPage: 0,
  };
}
</script>
