<template>
  <div class="KapsureEventContent">
    <a-page-section class="padded">
      <KapsureEventBasics
        :instruction="instruction"
        :destination="destination"
        :readonly="readonly"
        :isKapsureSettlement="isKapsureSettlement"
      />
    </a-page-section>
    <a-page-section header="Försäkring" v-if="ownership">
      <q-markup-table flat class="standard-table" separator="horizontal">
        <thead>
          <tr>
            <th class="text-left">Försäkringbolag</th>
            <th class="text-left">Försäkringsägare</th>
            <th class="text-left">Försäkringsnummer</th>
            <th class="text-left">Depåinstitut</th>

            <th v-if="isKapsureSettlement" class="text-right">Initialt antal</th>
            <th class="text-right">
              {{ isDistributedByValue ? 'Nominellt belopp' : 'Antal' }}
            </th>
            <th class="text-right">{{ isDistributedByValue ? 'Pris' : 'Pris per värdepapper' }}</th>
            <th class="text-right number">Total likvid</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-left source-owner">
              <a-link :target="ownership.owner" />
            </td>
            <td class="text-left">
              <a-link :target="ownership.investor" />
            </td>
            <td class="text-left"><InsuranceLink :instructionParty="destination" /></td>
            <td class="text-left"><a-link :target="ownership.custodian" /></td>
            <td v-if="isKapsureSettlement" class="text-right">{{ initialQuantity }}</td>

            <td class="text-right">
              <a-input-number
                v-bind="$defs.input"
                input-class="text-right price test-quantity-input"
                v-model="destination.quantity"
                :rules="validation.requiredLargerThanZero"
                :readonly="readonly"
                :fractionDigits="isDistributedByValue ? 2 : 0"
              />
            </td>

            <td class="text-right">
              <a-input-number
                v-bind="$defs.input"
                input-class="text-right price test-price-input"
                v-model="instruction.price"
                :readonly="readonly"
                :fractionDigits="2"
              />
            </td>
            <td class="text-right">
              {{ formatNumber(totalAmount, 2, 2) }}
            </td>
          </tr>
        </tbody>
      </q-markup-table>
    </a-page-section>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { validation } from '@shared/common';
import SourceRow from './SourceRow.vue';
import DestinationRow from './DestinationRow.vue';
import KapsureEventBasics from './KapsureEventBasics.vue';
import { Instruction } from '@/models';
import LastActivity from '@/views/activity/LastActivity.vue';
import InsuranceLink from '@/views/insurance/InsuranceLink.vue';
import { isKapsureSettlement } from '@shared/instruction/utils';

@Component({
  components: {
    SourceRow,
    DestinationRow,
    LastActivity,
    KapsureEventBasics,
    InsuranceLink,
  },
})
export default class KapsureEventContent extends Vue {
  @Prop() instruction: Instruction;

  @Prop({ default: true }) readonly: boolean;

  get ownership() {
    return this.destination?.ownership;
  }

  get destination() {
    return this.isKapsureSettlement ? this.instruction.sources[0] : this.instruction.destinations[0];
  }

  get validation() {
    return validation;
  }

  get totalAmount() {
    if (!this.destination) {
      return null;
    }
    if (this.isDistributedByValue) {
      return this.destination.amount;
    }
    return this.destination.quantity * this.instruction.price;
  }

  get isDistributedByValue() {
    return this.destination?.instrument?.rightsData?.isValueDistribution();
  }

  get initialQuantity() {
    return this.destination.origin.quantity;
  }
  get isKapsureSettlement() {
    return isKapsureSettlement(this.instruction.type);
  }
}
</script>
