<template>
  <q-page class="InstrumentListPage">
    <h1>Värdepapper</h1>
    <InstrumentSearch class="col" @search="search" />
    <InstrumentList :list="instruments" class="q-mt-lg" />
  </q-page>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import InstrumentSearch from './InstrumentSearch.vue';
import InstrumentList from './InstrumentList.vue';
@Component({
  components: {
    InstrumentList,
    InstrumentSearch,
  },
})
export default class InstrumentListPage extends Vue {
  instruments = [];

  search(searchResult) {
    this.instruments = searchResult;
  }
}
</script>
