<template>
  <q-markup-table flat class="standard-table InstrumentSourceTable" separator="horizontal">
    <thead>
      <tr class="additional-head" v-if="columns.preTotalCapital">
        <th></th>
        <th colspan="2" class="marked-bg"><span class="text">Innan emission</span></th>
        <th colspan="10"></th>
      </tr>
      <tr>
        <th class="text-left">Aktie</th>
        <th class="text-right marked-bg" v-if="columns.preTotalCapital">Antal aktier</th>
        <th class="text-right marked-bg" v-if="columns.preTotalCapital">Aktiekapital</th>
        <th class="text-right number">Nya aktier</th>
        <th class="text-right" v-if="columns.ratio">Ratio</th>
        <th class="text-right number" v-if="columns.subscribedQuantity">Tecknade</th>
        <th class="text-right number">Tillfört aktiekapital</th>
        <th class="text-right number" v-if="columns.totalCapitalDifference">Överkursfond</th>
      </tr>
    </thead>
    <tbody>
      <InstrumentSourceRow
        v-for="source in instruction.corporateEvent.instrumentSources"
        :source="source"
        :shareRegister="shareRegister"
        :instruction="instruction"
        :readonly="readonly"
        :validator="validator"
        :key="source.instrument.id"
        :columns="columns"
      />
      <tr v-if="!readonly">
        <td colspan="10" class="text-right">
          <q-btn label="Fördela aktier på pro-rata" type="a" color="primary" flat @click="fillDestinations()" />
        </td>
      </tr>
    </tbody>
  </q-markup-table>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';

import { ShareRegister, Instruction } from '@/models';
import InstrumentSourceRow from './InstrumentSourceRow.vue';

@Component({
  components: { InstrumentSourceRow },
})
export default class InstrumentSourceTable extends Vue {
  @Prop() shareRegister: ShareRegister;
  @Prop() instruction: Instruction;
  @Prop() readonly: boolean;
  @Prop() validator;

  get columns() {
    const isRightsIssue = this.instruction.isRightsIssue;
    return {
      preTotalQuantity: isRightsIssue,
      preTotalCapital: isRightsIssue,
      ratio: isRightsIssue,
      totalCapitalDifference: isRightsIssue,
      subscribedQuantity: isRightsIssue,
    };
  }

  fillDestinations() {
    const { validator } = this;
    this.instruction.destinations.forEach(destination => {
      const partyValidation = validator.forParty(destination);
      const sourceValidation = validator.forInstrumentSource(destination.instrument);
      const ratio = sourceValidation.ratio;
      if (ratio == null) {
        return;
      }

      destination.quantity = Math.floor(partyValidation.totalQuantity / ratio);
    });
  }
}
</script>
<style lang="scss">
.InstrumentSourceTable {
  thead tr.additional-head {
    height: 24px;

    th {
      height: 24px;
      border: none;
      font-weight: 400;
    }
    .text {
      position: relative;
      top: 8px;
    }
  }

  .marked-bg {
    background: #f0f0f0;
  }
}
</style>
