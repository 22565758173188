import ConfirmWithDateModal from '@/components/ConfirmWithDateModal.vue';
import { save } from '@/models/ownership/ownershipService';
import { openDialog } from '@/components';
import { Ownership } from '@/models';
import { OwnershipState } from '@shared/models/types';

export default (insurance: Ownership) => {
  return openDialog({
    component: ConfirmWithDateModal,
    componentProps: {
      confirmText: 'Ange datum när försäkringen stängts eller ska stängas.',
      dateLabel: 'Slutdatum',
      onConfirm: endDate => {
        insurance.endDate = endDate;
        insurance.state = OwnershipState.CLOSED;
        save(insurance);
      },
      onReject: () => {},
    },
  });
};
