<template>
  <div>
    <q-table
      :rows="roles"
      :columns="columns"
      row-key="id"
      flat
      hide-pagination
      :pagination="{ rowsPerPage: 0 }"
      hide-no-data
      class="standard-table"
    >
      <template v-slot:body-cell-edit="props">
        <q-td :props="props">
          <q-btn flat round color="primary" icon="edit" size="sm" />
        </q-td>
      </template>
      <template v-slot:body-cell-viewName="props">
        <q-td :props="props">
          <router-link :to="props.row.legalEntity.viewUrl" v-if="props.row.legalEntity.viewUrl">{{
            props.value
          }}</router-link>
          <template v-else>{{ props.value }} </template>
        </q-td>
      </template>
    </q-table>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { toRoleTypeLabel, formatNationalId } from '@shared/common';
import { QTableProps } from 'quasar';
@Component({})
export default class PersonRolesTable extends Vue {
  @Prop() entity;

  get roles() {
    return this.entity.person.roles;
  }

  columns: QTableProps['columns'] = [
    {
      field: 'type',
      name: 'type',
      label: 'Roll',
      align: 'left',
      format: role => {
        return toRoleTypeLabel(role);
      },
    },
    {
      name: 'viewName',
      field: role => role.legalEntity.viewName,
      label: 'Juridisk person',
      align: 'left',
    },
    {
      name: 'nationalId',
      field: role => formatNationalId(role.legalEntity),
      label: 'Nationellt org.nummer',
      align: 'left',
    },
    {
      field: role => role.legalEntity.countryCode,
      label: 'Land',
      name: 'land',
      align: 'left',
    },
  ];
}
</script>
