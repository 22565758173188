import store from '@/store';
import Profile from './Profile';
import msalProvider from './auth/msalProvider';
import jwtProvider from './auth/jwtProvider';

const provider = import.meta.env.VITE_APP_AUTH_PROVIDER === 'MSAL' ? msalProvider : jwtProvider;

export async function login(credentials) {
  await provider.login(credentials);

  await loadProfile();
}

export async function findProfile(): Promise<Profile> {
  if (store.profile) {
    return store.profile;
  }
  return loadProfile();
}

export async function loadProfile() {
  const data = await provider.fetchProfile();
  store.profile = Profile.newModel(data);
  return store.profile;
}

export async function logout() {
  await provider.logout();

  store.profile = Profile.newModel({ loggedIn: false });
}
