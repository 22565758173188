<template>
  <div class="CloseInstrumentContent">
    <div class="row q-col-gutter-x-xl">
      <q-list v-if="shareRegister" separator class="standard-list col-md-6">
        <a-list-item label="Värdepapper">
          <q-select
            label="Välj värdepapper"
            v-model="instruction.instrument"
            :options="instrumentList"
            option-label="viewName"
            :disable="readonly"
            :readonly="readonly"
          />
        </a-list-item>

        <a-list-item label="Datum">
          <a-input-date
            v-model="instruction.settleDate"
            v-bind="$defs.input"
            :disable="readonly"
            :readonly="readonly"
          />
        </a-list-item>
        <LastActivity :model="instruction" />
      </q-list>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';

import { Instruction, ShareRegister } from '@shared/web/models';

import SelectEntity from '@/views/entity/SelectEntity.vue';

import LastActivity from '@/views/activity/LastActivity.vue';
import { uniqBy } from 'lodash';

@Component({
  components: {
    SelectEntity,
    LastActivity,
  },
})
export default class CloseInstrumentContent extends Vue {
  @Prop() instruction: Instruction;
  @Prop() shareRegister: ShareRegister;
  @Prop({ default: true }) readonly: boolean;

  get instrumentList() {
    return uniqBy(this.shareRegister.instruments, instrument => instrument.viewName).filter(
      instrument =>
        instrument.settleTo == null && (instrument.isBond || instrument.isConvertible || instrument.isDebenture),
    );
  }
}
</script>
<style lang="scss">
.CloseInstrumentContent {
  .q-markup-table {
    overflow: initial;
  }
  th.number {
    width: 12%;
  }
  .w50 {
    width: 50%;
  }
}
</style>
