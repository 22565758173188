<template>
  <q-dialog ref="dialog">
    <div class="q-dialog-plugin LookupEntityModal">
      <p class="p2">Skapa Fysisk / Juridisk Person</p>
      <q-form @submit="search" class="q-gutter-md validation-form q-mt-xs" ref="form">
        <InputNationalId
          v-bind="$defs.input"
          placeholder="Person- / Organisationsnummer"
          class="q-mt-xs"
          :rules="nationalIdRules"
          v-model="nationalId"
          :entity="entity"
          reactive-rules
          @focus="clearError"
        />
        <q-banner rounded class="bg-red text-white" v-if="errorMessage">
          {{ getErrorText(errorMessage) }}
        </q-banner>
      </q-form>
      <q-card-actions align="right" class="q-px-none">
        <q-btn v-bind="$defs.btn" flat @click="cancel">Avbryt</q-btn>
        <q-btn label="Fortsätt" type="submit" v-bind="$defs.btn" :loading="loading" @click="search" />
      </q-card-actions>
    </div>
  </q-dialog>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import { DialogComponent, FormComponent } from '@shared/mixins';
import { isValidNationalId, isValidPersonalNumber, validation } from '@shared/common';
import { lookupEntity } from '@/models/entity/entityService';
import { InputNationalId } from '@/components';
@Component({
  components: { InputNationalId },
  mixins: [DialogComponent, FormComponent],
})
export default class CreateEntityModal extends Vue {
  entity = null;
  nationalId: string = null;

  created() {
    this.entity = {
      nationalId: null,
      countryCode: 'SE',
    };
  }

  clearError() {
    this.errorMessage = null;
  }

  get nationalIdRules() {
    return validation.nationalId;
  }

  async search() {
    await this.submitForm(async () => {
      const { nationalId } = this;
      if (!isValidNationalId(nationalId)) {
        this.errorMessage = { text: 'Ogiltigt person / organisationsnummer' };
        return;
      }

      const entity = await lookupEntity({
        nationalId,
        countryCode: 'SE',
        isPerson: isValidPersonalNumber(nationalId),
      });
      this.done(entity);
    });
  }
}
</script>
<style lang="scss">
.LookupEntityModal {
  .q-btn {
    display: block;
    margin: 10px;
  }
}
</style>
