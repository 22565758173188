import SearchQuery from '@shared/web/models/SearchQuery';

import { Entity, Instrument, Ownership } from '@/models';

export default class PositionSearchQuery extends SearchQuery {
  constructor(attr) {
    super(attr);
    Object.assign(this, attr);
  }
  entity?: Entity = null;
  ownership?: Ownership;
  instrument?: Instrument = null;
  settleDate?: Date = null;
  custodian?: Entity = null;
  ownerOrInvestor?: Entity = null;
  onlyInsurance?: boolean;

  isEmpty() {
    return this.entity == null;
  }

  static define = () => ({
    entity: {
      model: Entity,
    },
    instrument: {
      model: Instrument,
    },
    ownership: {
      model: Ownership,
    },
    settleDate: {
      type: 'date',
    },
    custodian: {
      model: Entity,
    },
    ownerOrInvestor: {
      model: Entity,
    },
  });
}
