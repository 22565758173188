import { ICurrencies, ICurrencyRates, IRates } from '@shared/models/modelTypes';
import { Model } from './base';

export default class CurrencyRates extends Model implements ICurrencyRates {
  rates: IRates;
  date: Date;

  getRate(currency: ICurrencies) {
    if (currency === 'SEK') {
      return 1;
    }
    return this.rates[currency];
  }
}
