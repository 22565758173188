<template>
  <a-page-section :header="header" class="CorporateEventList">
    <q-table
      :rows="list"
      :columns="columns"
      row-key="id"
      :pagination="pagination"
      :hide-pagination="hidePagination"
      :no-data-label="noDataLabel"
      :hide-no-data="!noDataLabel"
      flat
      class="standard-table"
    >
      <template v-slot:body-cell-type="props">
        <q-td :props="props">
          <router-link :to="props.row.viewUrl">{{ props.value }}</router-link>
        </q-td>
      </template>
      <template v-slot:body-cell-menu="props">
        <q-td :props="props">
          <q-btn flat round color="primary" icon="more_vert" size="sm">
            <q-menu>
              <q-list>
                <q-item clickable v-close-popup :to="'/corporate-event/' + props.row.id">
                  <q-item-section>Visa bolagshändelse</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </q-td>
      </template>
      <template v-slot:bottom-row>
        <a-table-row-btn @click="newIssuerInstruction" label="Ny bolagshändelse" v-if="readonly" />
      </template>
    </q-table>
  </a-page-section>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { toTransactionTypeLabel, toInstructionStateLabel } from '@shared/common';
import { formatDateTime, formatNumber } from '@/utils';
import * as _ from 'lodash';
import { formatDay } from '@/utils';
import { Entity, TransactionType } from '@/models';
import { openNewInstruction } from '@/actions';
import { QTableProps } from 'quasar';
@Component
export default class CorporateEventList extends Vue {
  @Prop() list;
  @Prop() header;
  @Prop() hidePagination;
  @Prop({ default: 0 }) rowsPerPage;
  @Prop({ default: null }) noDataLabel;
  @Prop() entity: Entity;
  @Prop() readonly: boolean;
  @Prop({ default: 'settleDate' }) sortBy;
  @Prop({ default: false }) descending;
  @Prop() displayIssuer;

  get pagination() {
    return {
      rowsPerPage: this.rowsPerPage,
      sortBy: this.sortBy,
      descending: this.descending,
    };
  }

  newIssuerInstruction() {
    openNewInstruction({ entity: this.entity, type: TransactionType.RIGHTS_ISSUE });
  }

  get columns(): QTableProps['columns'] {
    return [
      {
        name: 'entity',
        field: 'entity',
        label: 'Emittent',
        align: 'left',
        format: value => {
          return value.viewName;
        },
        sortable: true,
      },
      {
        name: 'settleDate',
        field: 'settleDate',
        label: 'Datum',
        align: 'left',
        format: value => {
          return value && formatDay(value);
        },
        sortable: true,
      },
      {
        field: row => row.type,
        name: 'type',
        label: 'Händelse',
        align: 'left',
        format: value => {
          return toTransactionTypeLabel(value);
        },
        sortable: true,
      },
      {
        name: 'price',
        field: 'price',
        label: 'Kvotvärde / Pris',
        align: 'left',
        format: value => {
          return formatNumber(value, 2);
        },
        sortable: true,
      },
      {
        name: 'amount',
        field: row => {
          switch (row.type) {
            case TransactionType.ISSUE_SHARE:
              return row.corporateEvent.issueShareData.totalQuantity;

            case TransactionType.ISSUE_WARRANT:
            case TransactionType.ISSUE_CONVERTIBLE:
              return row.corporateEvent.rightsData.totalQuantity;

            case TransactionType.ISSUE_BOND:
            case TransactionType.ISSUE_DEBENTURE:
              return row.corporateEvent.rightsData.nominalAmountTotal;

            default:
              const sources = row.corporateEvent?.instrumentSources as Array<any>;
              return _.sumBy(sources, source => source.quantity);
          }
        },
        label: 'Antal nya värdepapper / Nominellt belopp',
        align: 'left',
        format: value => {
          return formatNumber(value);
        },
        sortable: true,
      },
      {
        name: 'status',
        field: 'calculatedState',
        label: 'Status',
        align: 'left',
        format: value => {
          return toInstructionStateLabel(value);
        },
        sortable: true,
      },
      {
        name: 'submittedAt',
        field: row => row?.submittedAt,
        label: 'Inskickat',
        align: 'left',
        format: val => (val ? formatDateTime(new Date(val)) : ''),
        sortable: true,
      },
      {
        name: 'updatedAt',
        field: row => row.updatedAt,
        label: 'Uppdateringsdatum',
        align: 'left',
        format: formatDateTime,
        sortable: true,
      },
      {
        name: 'menu',
        field: 'menu',
        label: '',
        classes: 'q-table--col-auto-width',
      },
    ].filter(col => {
      return this.displayIssuer || col.field != 'entity';
    }) as QTableProps['columns'];
  }
}
</script>
