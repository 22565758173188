export class FileUpload {
  file;
  model;
  filename;
  constructor(file, fileModel) {
    this.file = file;
    this.model = fileModel;
    this.filename = file.name;
  }

  getName() {
    return this.filename || this.file.name || 'Image';
  }

  getBlob() {
    return this.file.slice ? this.file.slice() : this.file;
  }

  isImage() {
    return this.file.type.indexOf('image') > -1;
  }
}

export default FileUpload;
