<template>
  <template v-if="displayLink">
    <a-link :to="link">{{ text }}</a-link>
  </template>
</template>
<script lang="ts">
import { InstructionParty } from '@/models';
import { asModelId } from '@shared/utils';
import { Component, Vue, Prop } from 'vue-facing-decorator';

@Component({})
export default class InsuranceLink extends Vue {
  @Prop() instructionParty: InstructionParty;

  get link() {
    const { ownership } = this.instructionParty;

    return ownership && `/insurance/view/${asModelId(ownership)}`;
  }

  get displayLink() {
    return this.instructionParty.owner?.isInsuranceCompany || this.instructionParty.ownership;
  }

  get text() {
    const { insuranceNumber, ownership } = this.instructionParty;
    if (insuranceNumber) {
      return insuranceNumber;
    }
    if (ownership) {
      return 'Försäkring';
    }
    return null;
  }
}
</script>
