<template>
  <q-markup-table flat class="standard-table IssuerSharesInfo" bordered separator="horizontal">
    <thead>
      <tr>
        <th class="text-left"></th>
        <th class="text-right">Kapclear</th>
        <th class="text-right">Bolagsverket</th>
        <th class="text-right">Differens</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="text-left">Totalt antal aktier</td>
        <td class="text-right">
          {{ formatNumber(issuerData.totalQuantity) }}
        </td>
        <td class="text-right">
          {{ formatNumber(issuerRoaringTotalQuantity) }}
        </td>
        <td class="text-right">
          {{ formatNumber(entity?.roaringQuantityDiff) }}
        </td>
      </tr>
      <tr>
        <td class="text-left">Totalt aktiekapital</td>
        <td class="text-right">
          {{ formatNumber(issuerData.totalCapital) }}
        </td>
        <td class="text-right">
          {{ formatNumber(issuerRoaringTotalCapital) }}
        </td>
        <td class="text-right">
          {{ formatNumber(entity?.roaringCapitalDiff) }}
        </td>
      </tr>
    </tbody>
  </q-markup-table>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';

import '@shared/views/investmentOffer/investment-offer-style.scss';
import { Entity } from '@/models';

@Component({
  components: {},
})
export default class IssuerSharesInfo extends Vue {
  @Prop() entity: Entity;

  get issuerData() {
    return this.entity.issuerData;
  }

  get issuerRoaringTotalQuantity(): number {
    return this.entity.issuerDataTotalQuantityFromRoaring;
  }

  get issuerRoaringTotalCapital(): number {
    return this.entity.issuerDataTotalCapitalFromRoaring;
  }
}
</script>
