import { format, isToday } from 'date-fns';
import { Activity, ActivityType } from '@/models';
import { toInstructionStateLabel } from '@shared/common';

export function activityMadeByAuthorText(activity, displayDate = true) {
  if (activity.type === ActivityType.CREATED) {
    return `Skapad av ${byAndAt(activity, displayDate)} `;
  }
  if (activity.type === ActivityType.UPDATED) {
    return `Uppdaterad av ${byAndAt(activity, displayDate)}  ${showState(activity.state)}`;
  }
  if (activity.type === ActivityType.DELETED) {
    return `Raderad av ${byAndAt(activity, displayDate)} `;
  }
  return '-';
}

function byAndAt(activity: Activity, displayDate) {
  const { at, by } = activity;
  const byText = by && (by.username || by.name);
  if (displayDate) {
    return `${byText}  ${dateText(at)}`;
  } else {
    return `${byText}`;
  }
}

function dateText(date) {
  if (isToday(date)) {
    return format(date, 'HH:mm');
  }
  return format(date, 'yyyy-MM-dd');
}

function showState(state) {
  if (state) {
    return toInstructionStateLabel(state);
  }
  return '';
}
