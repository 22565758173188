<template>
  <form v-on:submit.prevent="submit" ref="form">
    <input
      class="test-file-upload"
      type="file"
      ref="file"
      :id="`file_instrument_${instrumentId}`"
      v-on:change="submit()"
      style="display: none"
    />
    <q-btn
      padding="xs md"
      size="md"
      @click="$refs.file.click()"
      :loading="loading"
      :disable="loading"
      label="Ladda upp excel"
    />
  </form>
</template>
<script lang="ts">
// @ts-nocheck
import { ApplicationError } from '@shared/models/types';
import axios from 'axios';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import UploadErrorRows from './UploadErrorRows.vue';

@Component
export default class UploadExcel extends Vue {
  @Prop({ type: String })
  instrumentId!: string;

  public loading = false;

  public async submit(): Promise<void> {
    this.loading = true;
    let formData = new FormData();
    formData.append('file', (this.$refs.file as HTMLInputElement).files[0]);
    formData.append('instrument', this.instrumentId.toString());

    try {
      await axios.post('/api/position/bulk', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      if (error?.response?.data?.data && error.response.data.data instanceof Array) {
        this.showRowErrors(error.response.data.data);
      } else {
        throw error;
      }
    } finally {
      this.loading = false;

      // Resets form to reset reactivity to hidden file input (makes it possible to upload the same file again)
      (this.$refs.form as HTMLFormElement).reset();
    }

    this.$emit('uploaded');
  }

  private showRowErrors(rows: ApplicationError[]) {
    this.$q.dialog({
      component: UploadErrorRows,
      componentProps: {
        rows,
      },
    });
  }
}
</script>
