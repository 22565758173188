<template>
  <q-page class="InsurancePage" v-if="ready">
    <div class="row q-gutter-lg q-mt-sm">
      <div class="col">
        <h1>Försäkring</h1>

        <h3 v-if="!isCreate">{{ ownership.investor?.viewName }}</h3>
      </div>
      <q-btn-group unelevated class="col col-md-auto action-buttons">
        <template v-if="editMode">
          <q-btn v-bind="$defs.btn" color="red-5" class="margin-right-auto" @click="deleteInsurance" v-if="!isCreate"
            >Radera</q-btn
          >
          <q-btn
            label="Stäng försäkring"
            v-if="!ownership.endDate && !isCreate"
            color="red-5"
            flat
            v-bind="$defs.btn"
            @click="closeInsurance()"
          />
          <q-btn label="Avbryt" flat v-bind="$defs.btn" @click="cancel" />
          <q-btn label="Spara" v-bind="$defs.btn" @click="save" />
        </template>
        <template v-else>
          <q-btn label="Editera" icon="edit" v-bind="$defs.btn" @click="edit" />
        </template>
      </q-btn-group>
    </div>
    <q-form ref="form">
      <q-banner id="error-text" rounded class="bg-red-5 text-white q-mt-md" v-if="errorMessage">
        {{ getErrorText(errorMessage) }}
      </q-banner>
      <InsuranceBasics :ownership="ownership" :readonly="!editMode" @setConfirmed="setConfirmed" />
    </q-form>
    <q-card flat class="q-pa-none" v-if="!isCreate">
      <KapsurePositionTable :ownership="ownership" />
    </q-card>

    <q-card flat class="q-pa-none" v-if="!isCreate">
      <q-btn v-bind="$defs.btn" flat @click="toggleShowTransactions">{{ showTransactionText }}</q-btn>
      <KapsureTransactionTable :ownership="ownership" v-if="showTransactions" />
    </q-card>
  </q-page>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator';
import { FormComponent } from '@shared/mixins';
import { findOwnership, save, destroyOwnership } from '@/models/ownership/ownershipService';
import { Ownership } from '@/models';
import InsuranceBasics from './InsuranceBasics.vue';
import store from '@/store';
import { ConfirmModal } from '@/components';
import { isToNewPage, setActiveNavigationModel } from '@/utils';
import KapsurePositionTable from './KapsurePositionTable.vue';
import KapsureTransactionTable from './KapsureTransactionTable.vue';
import closeInsuranceModal from './closeInsurance';
import { OwnershipState } from '@shared/index';

@Component({
  components: { KapsurePositionTable, InsuranceBasics, KapsureTransactionTable },
  mixins: [FormComponent],
})
export default class ViewInsurancePage extends Vue {
  ownership: Ownership = null;
  original: Ownership = null;

  editMode = false;
  isCreate = false;
  showTransactions = false;

  async created() {
    await this.loadData();
  }

  get showTransactionText() {
    return this.showTransactions ? 'Dölj transaktioner' : 'Visa transaktioner';
  }

  toggleShowTransactions() {
    return (this.showTransactions = !this.showTransactions);
  }

  @Watch('$route')
  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }
    const { id } = this.$route.params;
    const isCreate = id === 'new';

    const ownership = isCreate ? Ownership.newModel(store.newModelProps) : await findOwnership(id);

    setActiveNavigationModel(ownership.investor);
    Object.assign(this, { ownership, original: ownership, editMode: isCreate, isCreate });
  }

  get ready() {
    return this.ownership != null;
  }

  edit() {
    this.editMode = true;
    this.original = this.ownership;
    this.ownership = this.ownership.copy();
  }

  async setConfirmed() {
    this.ownership.state = OwnershipState.CONFIRMED;
    this.save();
  }

  async save() {
    if (!this.ownership.isPartnerClient) {
      delete this.ownership.partnerContact;
    }
    const { model } = await this.submitForm(async () => {
      return save(this.ownership);
    });
    if (model) {
      this.original = this.ownership = model;
      this.editMode = false;
      if (this.isCreate) {
        this.$router.push({ path: model.viewUrl });
      }
    }
  }

  async cancel() {
    this.editMode = false;
    this.ownership = this.original;
    this.errorMessage = null;
  }

  async deleteInsurance() {
    this.$q.dialog({
      component: ConfirmModal,
      componentProps: {
        header: 'Radera försäkring',
        text: 'Detta kommer att radera försäkringen',
        onConfirm: async () => {
          try {
            await destroyOwnership(this.ownership);
            await this.useConfirmModal({
              text: 'Försäkringen är raderad',
              useConfirm: false,
            });
          } catch (error) {
            this.errorMessage = error;
          }
        },
      },
    });
  }

  closeInsurance() {
    closeInsuranceModal(this.ownership);
  }
}
</script>

<style lang="scss">
.InsurancePage {
  h3 {
    margin-top: 5px;
  }
}
</style>
