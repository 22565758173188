import { Model } from '../base';

import { Entity, Instrument, Transaction } from '@shared/web/models';
import { mergeTransactions, ExtendedTransaction } from '@shared/web/models/transaction/utils';
import { getInstrumentTransactionGroups, getUniqueOwners } from './utils';
import { findById } from '@shared/utils';
import * as _ from 'lodash';
export default class TransactionsData extends Model {
  transactions: Array<Transaction> = [];
  entity: Entity;
  instruments: Array<Instrument> = [];
  sortProperty = null;
  sortDesc = false;
  groupProperty = 'instrument';
  filterInstrument: Instrument = null;
  filterOwner: Entity = null;
  static define = () => ({
    entity: {
      model: Entity,
    },
    instruments: {
      model: [Instrument],
    },
    transactions: {
      embeddedModel: [Transaction],
      serializeChanges: true,
    },
  });
  static storeModel = false;

  setFilterInstrumentId(instrumentId: string) {
    this.filterInstrument = instrumentId ? findById(this.instruments, instrumentId) : null;
  }

  setFilterOwnerId(ownerId: string) {
    const owners = this.getUniqueOwners();
    this.filterOwner = ownerId ? _.find(owners, owner => owner.id === ownerId) : null;
  }

  getFilters() {
    const filters = [];
    if (this.filterInstrument != null) {
      filters.push((transaction: ExtendedTransaction) => transaction.instrument === this.filterInstrument);
    }
    if (this.filterOwner != null) {
      filters.push((transaction: ExtendedTransaction) =>
        [transaction.owner, transaction.previousOwner].includes(this.filterOwner),
      );
    }
    return filters;
  }

  getGroups() {
    let { transactions } = this;
    transactions = mergeTransactions(transactions);
    const filters = this.getFilters();
    filters.forEach(filter => {
      transactions = transactions.filter(filter);
    });

    if (this.groupProperty === 'instrument') {
      return getInstrumentTransactionGroups(transactions, this.sortProperty, this.sortDesc);
    }

    throw new Error('groupProperty is not defined');
  }

  getUniqueOwners() {
    return getUniqueOwners(this.transactions);
  }
}
