<template>
  <q-page class="LegalEntityListPage">
    <h1>Juridiska Personer</h1>
    <LegalEntitySearch class="col" @search="search" />
    <LegalEntityList :list="legalEntities" class="q-mt-lg" />
  </q-page>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import LegalEntitySearch from './LegalEntitySearch.vue';
import LegalEntityList from './LegalEntityList.vue';
@Component({
  components: {
    LegalEntitySearch,
    LegalEntityList,
  },
})
export default class LegalEntityPage extends Vue {
  legalEntities = [];

  search(searchResult) {
    this.legalEntities = searchResult;
  }
}
</script>
<style lang="scss"></style>
