<template>
  <q-page class="InvestmentOffersViewPage">
    <div class="row justify-end">
      <q-btn v-if="isValidInvestmentOffer" class="q-mx-lg justify-end" label="Editera" icon="edit" @click="edit" />
    </div>
    <InvestmentOfferDetails class="investment_offer-text-editor_style" :id="$route.params.id" />
  </q-page>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import InvestmentOfferDetails from '@shared/views/investmentOffer/InvestmentOfferDetails.vue';
import { fetchInvestmentOfferById } from '@shared/web/models/investmentOffer/investmentOffersService';
@Component({
  components: { InvestmentOfferDetails },
})
export default class InvestmentOffersView extends Vue {
  isValidInvestmentOffer = null;
  async mounted() {
    this.isValidInvestmentOffer = Boolean((await fetchInvestmentOfferById(`${this.$route.params.id}`)) != null);
  }

  edit() {
    fetchInvestmentOfferById(`${this.$route.params.id}`);
    const routeLocation = {
      name: 'InvestmentOfferEdit',
      params: { id: `${this.$route.params.id}` },
    };
    const resolvedRoute = this.$router.resolve(routeLocation);
    this.$router.push(resolvedRoute);
  }
}
</script>
