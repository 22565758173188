<template>
  <q-markup-table flat class="standard-table PositionsTable" separator="horizontal">
    <thead>
      <tr>
        <th class="text-left holder-name-th"></th>
        <SortableTH
          property="absoluteInvestorManager"
          :tableSettings="tableSettings"
          v-if="columns.absoluteInvestorManager"
          >Investeringsansvarig</SortableTH
        >
        <SortableTH property="instrument" :tableSettings="tableSettings" v-if="columns.instrument">Aktie</SortableTH>
        <SortableTH property="owner" :tableSettings="tableSettings" v-if="columns.owner">Ägare</SortableTH>

        <SortableTH property="investor" :tableSettings="tableSettings">Försäkringsägare</SortableTH>

        <template v-if="columns.shareNumbers">
          <SortableTH :alignRight="true" property="shareNumberFrom" :tableSettings="tableSettings"
            >Nummer från</SortableTH
          >
          <SortableTH :alignRight="true" property="shareNumberFrom" :tableSettings="tableSettings"
            >Nummer till</SortableTH
          >
        </template>

        <th class="text-right" v-if="columns.reservations">Förbehåll</th>
        <th class="text-right" v-if="columns.custodian">Förvar</th>
        <SortableTH :alignRight="true" property="quantity" :tableSettings="tableSettings">Antal / Belopp</SortableTH>

        <th class="text-right">Kapitalandel</th>
        <th class="text-right">Röstandel</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <template v-for="{ holder, positions } in positionGroups" :key="holder?.id">
        <InstrumentRow
          :instrument="holder"
          :shareRegister="shareRegister"
          :tableSettings="tableSettings"
          :columns="columns"
          v-if="holder && tableSettings.groupProperty === 'instrument'"
          class="holder-row"
        />

        <OwnerRow
          :shareRegister="shareRegister"
          :owner="holder"
          :tableSettings="tableSettings"
          :columns="columns"
          class="holder-row"
          v-if="holder && tableSettings.groupProperty === 'owner'"
        />
        <InvestorRow
          :shareRegister="shareRegister"
          :investor="holder"
          :key="holder.id"
          :tableSettings="tableSettings"
          :columns="columns"
          class="holder-row"
          v-if="holder && tableSettings.groupProperty === 'absoluteInvestor'"
        />
        <template v-for="position in positions" :key="position.id">
          <PositionRow
            :clickedButtons="clickedButtons"
            @expand="expand"
            :position="position"
            :shareRegister="shareRegister"
            :tableSettings="tableSettings"
            :columns="columns"
          />
          <ExpandingPositionRow :issuerInfo="issuerInfo" :position="position" v-if="isOpen(position)" />
        </template>
        <a-table-row-btn
          v-if="displayAddPosition"
          @click="addPosition(holder)"
          label="Lägg till position"
          :data-testid="`add-position-${holder?.id}`"
          :key="'footer' + holder?.id"
        />
      </template>
    </tbody>
  </q-markup-table>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-facing-decorator';
import { ShareRegister, IssuerInfo, Position } from '@/models';
import EditPositionModal from '@shared/views/editPositionModal/EditPositionModal.vue';
import InstrumentRow from './InstrumentRow.vue';
import OwnerRow from './OwnerRow.vue';
import PositionRow from './PositionRow.vue';
import InvestorRow from './InvestorRow.vue';
import SortableTH from '@/views/instruction/corporateEvent/SortableTH.vue';
import ExpandingPositionRow from './ExpandingPositionRow.vue';
import TableSettings from '@shared/web/models/shareRegister/TableSettings';
import { findIssuerInfo } from '@/models/entity/issuerInfoService';

@Component({
  components: { InstrumentRow, PositionRow, OwnerRow, InvestorRow, SortableTH, ExpandingPositionRow },
})
export default class PositionsTable extends Vue {
  @Prop() shareRegister: ShareRegister;
  @Prop() tableSettings: TableSettings;
  @Prop() displayAddPosition = false;
  issuerInfo: IssuerInfo = null;
  clickedButtons = {};

  async created() {
    this.issuerInfo = await findIssuerInfo(this.shareRegister.entity);
  }

  expand(toggle) {
    const { id, show } = toggle;
    this.clickedButtons[id] = show;
  }

  isOpen(position) {
    return this.clickedButtons[position.id];
  }

  @Watch('$route')
  onUrlChange() {
    this.clickedButtons = {};
  }

  get defaultOwners() {
    return this.shareRegister.getUniqueOwners();
  }
  get issuerData() {
    return this.shareRegister.entity.issuerData;
  }

  addPosition(holder) {
    const entity = this.shareRegister.entity;
    const data = this.instrumentMode ? { instrument: holder, entity } : { owner: holder, entity };

    this.$q.dialog({
      component: EditPositionModal,
      componentProps: {
        positionClass: Position,
        value: data,
        shareRegister: this.shareRegister,
      },
    });
  }

  get positionGroups() {
    return this.shareRegister.getPositionGroups(this.tableSettings);
  }

  get instrumentMode() {
    return this.tableSettings.groupProperty === 'instrument';
  }

  get columns() {
    const { groupProperty, isInvestorRegister } = this.tableSettings;
    const { hasShareNumbers } = this.issuerData;
    return {
      owner: groupProperty !== 'owner' || isInvestorRegister,
      instrument: groupProperty !== 'instrument',
      absoluteInvestorManager: groupProperty !== 'absoluteInvestor' && isInvestorRegister,
      reservations: !isInvestorRegister,
      shareNumbers: hasShareNumbers && !isInvestorRegister,
      custodian: isInvestorRegister,
    };
  }
}
</script>
<style lang="scss">
.PositionsTable {
  .q-input.number {
    width: 80px;
    margin-left: auto;
  }
  .holder-row {
    background: #f0f0f0;
  }
  .holder-name-th {
    width: 100px;
  }
}
</style>
