<template>
  <q-page class="InstrumentTransactionsPage" v-if="ready">
    <div class="row q-gutter-lg q-mt-sm">
      <div class="col">
        <h1>Transaktioner i {{ instrument.viewName }}</h1>
      </div>
    </div>
    <q-card flat class="q-pa-none PageSection">
      <div>
        <q-checkbox
          v-model="searchQuery.onlyInsurance"
          label="Endast försäkringsägande"
          @update:model-value="loadTransactions"
        ></q-checkbox>
      </div>
      <q-card-section class="q-pa-none">
        <TransactionsTable :transactionsData="transactionsData" v-if="transactionsData" />
      </q-card-section>
    </q-card>
  </q-page>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator';

import { loadInstrument } from '@/models/instrument/instrumentService';
import { Instrument } from '@/models';
import TransactionsTable from '@/views/transaction/TransactionsTable.vue';
import { TransactionsData } from '@/models';
import { isToNewPage, setActiveNavigationModel } from '@/utils';
import { search } from '@/models/transaction/transactionService';
import TransactionSearchQuery from '@shared/web/models/transaction/TransactionSearchQuery';
import { isKapsureMode } from '@/appMode';
@Component({
  components: { TransactionsTable },
  mixins: [],
})
export default class InstrumentTransactionsPage extends Vue {
  instrument: Instrument;
  transactionsData: TransactionsData = null;
  searchQuery: TransactionSearchQuery;
  async created() {
    await this.loadData();
  }

  @Watch('$route')
  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }
    const { id } = this.$route.params;

    const instrument = await loadInstrument(id);
    this.instrument = instrument;
    setActiveNavigationModel(this.instrument);
    this.searchQuery = TransactionSearchQuery.newModel({
      entity: instrument.entity,
      instrument,
      includeCounterparts: true,
      onlyInsurance: isKapsureMode(),
    });
    await this.loadTransactions();
  }

  get ready() {
    return this.instrument != null;
  }

  async loadTransactions(): Promise<void> {
    this.transactionsData = await search(this.searchQuery);
  }
}
</script>
