<template>
  <q-page v-if="ready" class="DebtsRegisterPage">
    <div class="row justify-between">
      <h1>Skuldbok</h1>
    </div>
    <DebtsInfo :shareRegister="shareRegister" />
    <DebtsPositionTable :shareRegister="shareRegister" :readonly="true" />
  </q-page>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator';
import { InstrumentCategory, ShareRegister } from '@shared/web/models';
import DebtsInfo from '@shared/views/debtsRegister/DebtsInfo.vue';
import DebtsPositionTable from '@shared/views/debtsRegister/DebtsPositionTable.vue';
import LastEntityUpdate from '@shared/components/LastEntityUpdate.vue';

import { findShareRegister } from '@shared/web/models/shareRegister/shareRegisterService';
import { findEntity } from '@/models/entity/entityService';
import { isToNewPage, setActiveNavigationModel } from '@/utils';
import { Products } from '@shared/index';

@Component({
  components: { DebtsInfo, DebtsPositionTable, LastEntityUpdate },
})
export default class DebtsRegisterPage extends Vue {
  shareRegister: ShareRegister = null;

  async created() {
    await this.loadData();
  }

  @Watch('$route')
  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }
    const entity = await findEntity(this.$route.params.id);
    this.shareRegister = await findShareRegister({
      entity,
      instrumentCategory: InstrumentCategory.DEBT,
      instrumentProduct: Products.KAPCLEAR,
    });
    setActiveNavigationModel(entity);
  }

  get ready() {
    return this.shareRegister != null;
  }
}
</script>
