<template>
  <a-btn-link class="icon-left align-right" icon="svguse:/svg-icons.svg#icon-download" size="sm" @click="exportToExcel"
    >Excel</a-btn-link
  >
</template>
<script lang="ts">
import xlsx from 'json-as-xlsx';
import { Vue, Prop, Component } from 'vue-facing-decorator';
import { IssuerInfo } from '@/models';
import { findIssuerInfo } from '@/models/entity/issuerInfoService';
import { toExcelDataDestinations } from '@/views/positionList/excelExport';

@Component({
  components: {},
})
export default class ExportDestinationsExcel extends Vue {
  @Prop() instruction;
  @Prop() validator;

  issuerInfo: IssuerInfo = null;

  async exportToExcel() {
    const { instruction, validator } = this;
    const issuerInfo = await findIssuerInfo(this.instruction.entity);
    const fileName = this.instruction.entity.viewName;
    xlsx(toExcelDataDestinations({ instruction, validator, issuerInfo, sheet: fileName }), { fileName });
  }
}
</script>
