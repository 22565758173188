<template>
  <a-page-section class="PersonBasics padded">
    <div class="row q-col-gutter-x-xl">
      <q-list :separator="true" class="standard-list col-md-6">
        <a-label-item label="Personnummer">
          <div class="flex justify-between items-center">
            <div class="flex items-center">
              <InputNationalId
                v-bind="inputDefaults"
                v-model="entity.nationalId"
                :readonly="autoUpdate"
                :entity="entity"
              />
            </div>
          </div>
        </a-label-item>
        <a-label-item label="Förnamn">
          <q-input v-bind="inputDefaults" v-model="entity.person.firstName" :readonly="autoUpdate" />
        </a-label-item>
        <a-label-item label="Mellannamn">
          <q-input v-bind="inputDefaults" v-model="entity.person.middleName" :readonly="autoUpdate" />
        </a-label-item>
        <a-label-item label="Efternamn">
          <q-input
            v-bind="inputDefaults"
            v-model="entity.person.surName"
            :rules="validation?.surName"
            :readonly="autoUpdate"
          />
        </a-label-item>
        <a-list-item label="Skapad för" class="test-creator" v-if="!releaseEntity">
          <a-link :target="entity.creator" v-if="readonly" />
          <SelectIssuer v-model="entity.creator" :hideCreate="true" v-if="!readonly" />
        </a-list-item>
      </q-list>

      <q-list :separator="true" class="standard-list col-md-6">
        <a-label-item label="Adress">
          <q-input v-bind="inputDefaults" v-model="adress.address1" :readonly="autoUpdate"
        /></a-label-item>
        <a-label-item label="Postnummer">
          <q-input v-bind="inputDefaults" v-model="adress.zip" :readonly="autoUpdate"
        /></a-label-item>
        <a-label-item label="Stad">
          <q-input v-bind="inputDefaults" v-model="adress.city" :readonly="autoUpdate"
        /></a-label-item>
        <a-label-item label="Land">
          <SelectCountry
            v-model="entity.countryCode"
            v-bind="inputDefaults"
            :rules="validation?.countryCode"
            :readonly="autoUpdate"
          />
        </a-label-item>

        <a-label-item label="E-mail">
          <q-input v-bind="inputDefaults" v-model="adress.email" :readonly="readonly" />
        </a-label-item>
        <a-label-item label="Telefon">
          <q-input v-bind="inputDefaults" v-model="adress.phone" :readonly="readonly" />
        </a-label-item>
      </q-list>
    </div>
    <div class="row q-col-gutter-x-xl">
      <a-label-item label="Autouppdatering">
        {{ toBooleanLabel(entity.autoUpdate) }}
      </a-label-item>

      <q-btn
        v-if="readonly && canEnableAutoupdate"
        label="Slå på autouppdatering"
        v-bind="$defs.btn"
        flat
        dense
        size="md"
        @click="enableAutoupdate"
      />
      <a-list-item label="Aktiv">
        <a-select
          v-model="entity.active"
          v-bind="inputAttributes"
          :options="options?.booleanOptions"
          :readonly="autoUpdate"
          emit-value
          map-options
        />
      </a-list-item>
      <a-list-item label="Ändra till global person" v-if="entity.countryCode !== 'SE'">
        <q-checkbox v-model="releaseEntity" v-bind="inputAttributes" :disable="readonly || isGlobal" />
      </a-list-item>

      <a-list-item label="Skyddad identitet" v-if="entity.isPerson && entity.countryCode == 'SE'">
        <q-toggle v-model="entity.person.secrecyMarked" :disable="autoUpdate" />
      </a-list-item>
    </div>

    <q-btn
      label="Skicka ut välkomstmail"
      v-bind="$defs.btn"
      flat
      icon="email"
      size="md"
      @click="openSendWelcomeMailModal"
    />

    <LastActivity :model="entity" />
  </a-page-section>
</template>
<script lang="ts">
// @ts-nocheck
import { Component, Prop, Vue } from 'vue-facing-decorator';
import LastActivity from '@/views/activity/LastActivity.vue';
import SelectIssuer from '@/views/entity/SelectIssuer.vue';
import { validation, options } from '@shared/common';
import { Address, Entity, Position } from '@shared/web/models';
import { enableAutoupdate } from '@/models/entity/entityService';
import { SelectCountry, InputNationalId } from '@/components';
import { resendEmailToInvestor } from '@shared/services/mailService';
import SendWelcomeEmailModal from './SendWelcomeEmailModal.vue';

@Component({
  components: { SelectCountry, InputNationalId, LastActivity, SelectIssuer },
})
export default class PersonBasics extends Vue {
  @Prop() entity: Entity;
  @Prop() readonly: boolean;

  get releaseEntity() {
    return this.entity.isGlobal;
  }
  set releaseEntity(value) {
    this.entity.isGlobal = value;
    if (value) {
      this.entity.creator = null;
    }
  }

  get isGlobal() {
    return this.entity.isGlobal === true;
  }
  get adress() {
    if (this.entity.addresses.length === 0) {
      this.entity.addresses.push(Address.newModel({}));
    }
    return this.entity.addresses[0];
  }
  get options() {
    return options;
  }

  get inputDefaults() {
    return {
      dense: true,
      filled: true,
      square: true,
      readonly: this.readonly,
    };
  }

  get validation() {
    return validation;
  }

  get autoUpdate() {
    return this.entity.autoUpdate || this.readonly;
  }

  get canEnableAutoupdate() {
    return !this.entity.autoUpdate && this.entity.countryCode === 'SE';
  }
  async enableAutoupdate() {
    return enableAutoupdate(this.entity);
  }

  get inputAttributes() {
    return {
      dense: true,
      filled: true,
      square: true,
      readonly: this.readonly,
    };
  }

  openSendWelcomeMailModal() {
    this.$q.dialog({
      component: SendWelcomeEmailModal,
      componentProps: {
        entity: this.entity,
        address: this.adress,
        sendMail: async (position: Position) => {
          const issuerName = position.instrument.entity.legalEntity.name;

          const owner = this.entity;
          const email = owner.addresses[0].email;
          const investorName = owner.viewName;

          await resendEmailToInvestor(issuerName, email, investorName);
        },
      },
    });
  }
}
</script>
