<template>
  <a-page-section header="Händelser" class="InstrumentEventList">
    <div v-if="!list || !list.length" class="q-pa-md">Inga händelser hittades</div>
    <q-table
      v-if="list && list.length"
      :rows="list"
      :columns="columns"
      row-key="id"
      :pagination="pagination"
      hide-pagination
      hide-no-data
      flat
      class="standard-table"
    >
      <template v-slot:body-cell-type="props">
        <q-td :props="props">
          <router-link :to="props.row.viewUrl">{{ props.value }}</router-link>
        </q-td>
      </template>
      <template v-slot:body-cell-menu="props">
        <q-td :props="props">
          <q-btn flat round color="primary" icon="more_vert" size="sm">
            <q-menu>
              <q-list>
                <q-item clickable v-close-popup :to="'/corporate-event/' + props.row.id">
                  <q-item-section>Visa bolagshändelse</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </q-td>
      </template>
    </q-table>
  </a-page-section>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { toTransactionTypeLabel, toInstructionStateLabel } from '@shared/common';
import { format } from 'date-fns';
import { formatNumber } from '@/utils';
import * as _ from 'lodash';
import { QTableProps } from 'quasar';
@Component
export default class InstrumentEventList extends Vue {
  @Prop() list;
  @Prop() instrument;
  pagination = {
    rowsPerPage: 0,
    sortBy: 'settleDate',
  };
  columns: QTableProps['columns'] = [
    {
      field: 'settleDate',
      name: 'settleDate',
      label: 'Datum',
      align: 'left',
      format: value => {
        return value ? format(value, 'yyyy-MM-dd') : '';
      },
    },
    {
      field: row => row.type,
      name: 'type',
      label: 'Händelse',
      align: 'left',
      format: value => {
        return toTransactionTypeLabel(value);
      },
    },
    {
      field: row => {
        const source = _.find(row.instrumentSources, source => source.instrument === this.instrument);
        return source && source.quantity;
      },
      name: 'quantity',
      label: 'Antal värdepapper',
      align: 'left',
      format: value => {
        return formatNumber(value);
      },
    },
    {
      field: 'price',
      name: 'price',
      label: 'Kvotvärde',
      align: 'left',
      format: value => {
        return formatNumber(value, 2);
      },
    },
    {
      field: 'state',
      name: 'state',
      label: 'Status',
      align: 'left',
      format: value => {
        return toInstructionStateLabel(value);
      },
    },

    {
      name: 'menu',
      field: null,
      label: '',
      classes: 'q-table--col-auto-width',
    },
  ];
}
</script>
