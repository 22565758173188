import { isReactive, reactive } from 'vue';
import { Entity, Instrument } from '@shared/web/models';
import store from '@/store';
import { looksLikeEntity, looksLikeInstrument } from '@shared/utils/models';

type Model = Entity | Instrument;
type ActiveNavigationModel = Model | false | null;

export * from '@shared/utils/index';
export const makeReactive = obj => (isReactive(obj) ? obj : reactive(obj));

export function setActiveNavigationModel(model: ActiveNavigationModel) {
  if (model) {
    saveNavigationModelToLocalStorage(model);
  }
  store.activeNavigationModel = model;
}

function saveNavigationModelToLocalStorage(model: Model) {
  const history = getHistoricNavigationModelsFromLocalStorage();
  const lastModel = history?.[history.length - 1];
  if (lastModel?.id !== model.id) {
    const newHistory = [...history.map(m => m.serialize()), model.serialize()].slice(-100);
    localStorage.setItem('activeNavigationModels', JSON.stringify(newHistory));
  }
}

export function getHistoricNavigationModelsFromLocalStorage(): Model[] {
  const history = JSON.parse(localStorage.getItem('activeNavigationModels') || '[]');
  return history.map(obj => {
    if (looksLikeEntity(obj)) {
      return Entity.newModel(obj);
    }
    if (looksLikeInstrument(obj)) {
      return Instrument.newModel(obj);
    }
    return obj;
  });
}
