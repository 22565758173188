import { User, UserRole } from '@/models';
import { Model } from './base';
export default class Profile extends Model {
  loggedIn: boolean;
  user: User;

  get isAdmin() {
    return this.user?.roles?.indexOf(UserRole.ADMIN) > -1;
  }
}
