<template>
  <q-select
    v-bind="$defs.input"
    stack-label
    multiple
    v-model="selectedTypes"
    :options="availableTypes"
    :hint="
      allTypesSelected
        ? 'Alla händelsetyper är markerade'
        : `${selectedTypes.length} av ${availableTypes.length} typer markerade`
    "
    label="Händelsetyper"
  />
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';
import { EventLogTypeLabels } from '@shared/utils/eventLog';

@Component
export default class EventLogTypesSelect extends Vue {
  @Prop({ default: [] }) modelValue;
  selectedTypes = [];
  availableTypes = [];
  async mounted() {
    const types = Object.keys(EventLogTypeLabels).map(type => ({
      value: type,
      label: EventLogTypeLabels[type] || type,
    }));
    this.selectedTypes = types;
    this.availableTypes = types;
  }
  get allTypesSelected() {
    return this.selectedTypes.length === this.availableTypes.length;
  }
  @Watch('selectedTypes')
  onChange() {
    this.$emit('update:modelValue', this.selectedTypes);
  }
}
</script>
