<template>
  <q-page class="InstrumentPositionsPage" v-if="ready">
    <div class="row q-gutter-lg q-mt-sm">
      <div class="col">
        <h1>Innehav i {{ instrument.viewName }}</h1>
      </div>
    </div>

    <q-card flat class="q-pa-none PageSection">
      <div>
        <q-checkbox
          v-model="searchQuery.onlyInsurance"
          label="Endast försäkringsägande"
          @update:model-value="loadPositions"
        ></q-checkbox>
      </div>
      <q-card-section class="q-pa-none">
        <InstrumentPositionsTable :positions="positions" :instrument="instrument" />
      </q-card-section>
    </q-card>
  </q-page>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator';

import { loadInstrument } from '@/models/instrument/instrumentService';
import { Instrument, Position } from '@/models';
import InstrumentPositionsTable from './InstrumentPositionsTable.vue';
import { search } from '@/models/position/positionService';

import { isToNewPage, setActiveNavigationModel } from '@/utils';
import PositionSearchQuery from '@/models/position/PositionSearchQuery';
import { isKapsureMode } from '@/appMode';
@Component({
  components: { InstrumentPositionsTable },
  mixins: [],
})
export default class InstrumentPositionsPage extends Vue {
  instrument: Instrument;
  positions: Array<Position>;
  searchQuery: PositionSearchQuery;
  async created() {
    await this.loadData();
  }

  @Watch('$route')
  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }
    const { id } = this.$route.params;

    const instrument = await loadInstrument(id);
    setActiveNavigationModel(instrument);

    this.searchQuery = PositionSearchQuery.newModel({
      entity: instrument.entity,
      instrument,
      onlyInsurance: isKapsureMode(),
    });
    this.instrument = instrument;
    await this.loadPositions();
  }

  get ready() {
    return this.instrument != null;
  }

  async loadPositions() {
    this.positions = await search(this.searchQuery);
  }
}
</script>
