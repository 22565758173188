<template>
  <div class="IssueWarrantContent">
    <portal to="instruction-buttons">
      <q-btn v-bind="$defs.btn" @click="initExerciceInstruction" v-if="instruction.executed">Lös optioner</q-btn>
    </portal>
    <a-page-section class="padded">
      <div class="row q-col-gutter-x-xl">
        <q-list separator class="standard-list col-md-6">
          <a-list-item label="Emittent">
            <a-link :target="instruction.entity" v-if="readonly" />
            <SelectEntity
              v-model="instruction.entity"
              entityType="issuer"
              v-bind="$defs.input"
              :readonly="instruction.entity != null"
              :hideCreate="true"
              @update:modelValue="$emit('changeEntity')"
              v-else
            />
          </a-list-item>
        </q-list>
        <q-list separator class="standard-list col-md-6">
          <a-list-item label="Registreringsdag">
            <a-input-date v-model="instruction.registrationDate" v-bind="$defs.input" :readonly="true" />
          </a-list-item>
        </q-list>
      </div>
    </a-page-section>
    <WarrantData
      :readonly="readonly"
      :rightsData="instruction.corporateEvent.rightsData"
      :entity="shareRegister.entity"
      :instruction="instruction"
      v-if="shareRegister"
    />

    <DestinationsTableCard
      :shareRegister="shareRegister"
      v-if="shareRegister"
      :instruction="instruction"
      :validator="validator"
      :readonly="readonly"
    />
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';

import { Instruction, ShareRegister, TransactionType } from '@/models';
import WarrantData from '@/views/instrument/WarrantData.vue';
import SelectEntity from '@/views/entity/SelectEntity.vue';
import TableSettings from '@shared/web/models/shareRegister/TableSettings';
import { openNewInstruction } from '@/actions';
import DestinationsTableCard from '@/views/instruction/corporateEvent/DestinationsTableCard.vue';
@Component({
  components: {
    DestinationsTableCard,
    SelectEntity,
    WarrantData,
  },
})
export default class ExerciseWarrantContent extends Vue {
  @Prop() instruction: Instruction;
  @Prop() shareRegister: ShareRegister;
  @Prop({ default: true }) readonly: boolean;
  @Prop() validator;
  @Prop() disableAdd: boolean;
  tableSettings = new TableSettings();

  initExerciceInstruction() {
    openNewInstruction({
      type: TransactionType.EXERCISE_WARRANT,
      entity: this.instruction.entity,
      rootInstruction: this.instruction,
    });
  }
}
</script>
<style lang="scss">
.IssueWarrantContent {
  .q-markup-table {
    overflow: initial;
  }
  th.number {
    width: 12%;
  }
  .w50 {
    width: 50%;
  }
}
</style>
