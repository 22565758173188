<template>
  <a-page-section :header="header" class="InstructionList">
    <q-table
      :rows="list"
      :columns="columns"
      row-key="id"
      :pagination="pagination"
      :hide-pagination="hidePagination"
      flat
      :no-data-label="noDataLabel"
      :hide-no-data="!noDataLabel"
      class="standard-table"
    >
      <template v-slot:body-cell-entity="props">
        <q-td :props="props">
          <router-link :to="`/legal-entity/view/${props.row.entity.id}`">{{ props.value }}</router-link>
        </q-td>
      </template>
      <template v-slot:body-cell-type="props">
        <q-td :props="props">
          <router-link :to="props.row.viewUrl">{{ props.value }}</router-link>
        </q-td>
      </template>
      <template v-slot:body-cell-menu="props">
        <q-td :props="props">
          <q-btn flat round color="primary" icon="more_vert" size="sm">
            <q-menu>
              <q-list>
                <q-item clickable v-close-popup :to="'/instruction/view/' + props.row.id">
                  <q-item-section>Visa Ägarbyte</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </q-td>
      </template>
    </q-table>
  </a-page-section>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { toTransactionTypeLabel, toInstructionStateLabel } from '@shared/common';

import { formatDateTime, formatDay } from '@/utils';
@Component
export default class InstructionList extends Vue {
  @Prop() list;
  @Prop() header;
  @Prop({ default: false }) hidePagination;
  @Prop({ default: 0 }) rowsPerPage;
  @Prop({ default: null }) noDataLabel;
  @Prop({ default: false }) showEntity;
  @Prop({ default: 'instrument' }) sortBy;
  @Prop({ default: false }) descending;

  get pagination() {
    return {
      rowsPerPage: this.rowsPerPage,
      sortBy: this.sortBy,
      descending: this.descending,
    };
  }

  get columns() {
    const columns = [];
    if (this.showEntity) {
      columns.push({
        name: 'entity',
        label: 'Emittent',
        align: 'left',
        field: row => row.entity.viewName,
        sortable: true,
      });
    }
    columns.push({
      name: 'instrument',
      field: row => row.tradeEvent?.source.instrument?.viewName,
      label: 'Värdepapper',
      align: 'left',
      sortable: true,
    });
    columns.push({
      field: row => row.type,
      name: 'type',
      label: 'Typ',
      align: 'left',
      format: value => {
        return toTransactionTypeLabel(value);
      },
      sortable: true,
    });
    columns.push({
      name: 'status',
      field: 'calculatedState',
      label: 'Status',
      align: 'left',
      format: value => {
        return toInstructionStateLabel(value);
      },
      sortable: true,
    });
    columns.push({
      name: 'owner',
      field: row => row.tradeEvent?.source.owner?.viewName,
      label: 'Från ägare',
      align: 'left',
      sortable: true,
    });
    columns.push({
      name: 'destination',
      field: row => {
        const destinations = row.destinations;
        if (destinations.length === 0) {
          return null;
        }
        if (destinations.length === 1) {
          return destinations[0].owner?.viewName;
        }
        return destinations.length + ' ägare';
      },
      label: 'Till',
      align: 'left',
      sortable: true,
    });
    columns.push({
      name: 'settleDate',
      field: 'settleDate',
      label: 'Införd i aktieboken',
      align: 'left',
      format: value => {
        return value && formatDay(value);
      },
      sortable: true,
    });
    columns.push({
      name: 'submittedAt',
      field: row => row?.submittedAt,
      label: 'Inskickat',
      align: 'left',
      format: val => (val ? formatDateTime(new Date(val)) : ''),
      sortable: true,
    });
    columns.push({
      name: 'updatedAt',
      field: row => row.updatedAt,
      label: 'Uppdateringsdatum',
      align: 'left',
      format: formatDateTime,
      sortable: true,
    });
    columns.push({
      name: 'menu',
      label: '',
      classes: 'q-table--col-auto-width',
    });
    return columns;
  }
}
</script>
