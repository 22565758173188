<template>
  <tr class="InterimDestinationRow">
    <td></td>
    <td class="text-left" v-if="columns.instrument">
      <router-link :to="destination.instrument.viewUrl" v-if="destination.instrument.viewUrl">{{
        destination.instrument.viewName
      }}</router-link>
    </td>
    <td class="text-left" v-if="columns.owner">
      <a-link :target="destination.owner" />
    </td>

    <td class="text-left">
      <a-link :target="destination.investor" />
    </td>

    <td class="text-left">
      <InsuranceLink :instructionParty="destination" />
    </td>
    <td class="text-right">{{ `${destination.shareNumberFrom} - ${destination.shareNumberTo}` }}</td>
    <td class="text-right">{{ formatNumber(destination.quantity) }}</td>
  </tr>
</template>
<script lang="ts">
// @ts-nocheck
import { Instruction, InstructionParty } from '@/models';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import TableSettings from '@shared/web/models/shareRegister/TableSettings';
import InsuranceLink from '@/views/insurance/InsuranceLink.vue';
@Component({
  components: { InsuranceLink },
})
export default class InterimDestinationRow extends Vue {
  @Prop() destination: InstructionParty;
  @Prop() tableSettings: TableSettings;
  @Prop() instruction: Instruction;
  @Prop() readonly: boolean;
  @Prop() clickedButtons;
  @Prop() columns;
}
</script>
