<template>
  <div class="IssueShareContent">
    <a-page-section class="padded">
      <div class="row q-col-gutter-x-xl">
        <q-list separator class="standard-list col-md-6">
          <a-list-item label="Emittent">
            <a-link :target="instruction.entity" v-if="readonly" />
            <SelectEntity
              v-model="instruction.entity"
              entityType="issuer"
              v-bind="$defs.input"
              :readonly="instruction.entity != null"
              :hideCreate="true"
              @update:modelValue="$emit('changeEntity')"
              v-else
            />
          </a-list-item>
          <template v-if="shareRegister">
            <a-list-item label="Namn för BTA">
              <q-input v-model="issueShareData.name" v-bind="$defs.input" :readonly="readonly" />
            </a-list-item>
            <a-list-item label="KapID för BTA" v-if="issueShareData.interimShareKapID">
              {{ issueShareData.interimShareKapID }}
            </a-list-item>
            <a-list-item label="Röstandel">
              <a-input-number
                v-model="issueShareData.votingPower"
                v-bind="$defs.input"
                :readonly="readonly"
                :fractionDigits="2"
              />
            </a-list-item>

            <a-list-item label="Antal nya aktier">
              <a-input-number
                v-model="issueShareData.totalQuantity"
                v-bind="$defs.input"
                :readonly="readonly"
                :fractionDigits="0"
              />
            </a-list-item>
            <a-list-item label="Tecknade aktier">
              {{ capitalInfo.totalQuantity }}
            </a-list-item>
            <a-list-item label="STAM-aktie">
              <a-select
                v-model="issueShareData.underlyingInstrument"
                v-bind="$defs.input"
                :readonly="readonly"
                :options="shares"
                map-options
                option-label="viewName"
              />
            </a-list-item>
          </template>
        </q-list>
        <q-list v-if="shareRegister" separator class="standard-list col-md-6">
          <a-list-item label="Teckningskurs">
            <a-input-number
              v-model="instruction.price"
              v-bind="$defs.input"
              :readonly="readonly"
              :fractionDigits="2"
              :rules="priceRules"
            />
          </a-list-item>

          <a-list-item label="Totalt nytt  aktiekapital">
            {{ formatNumber(capitalInfo.totalCapitalForCurrentQuota) }}</a-list-item
          >
          <a-list-item label="Överkursfond"> {{ formatNumber(capitalInfo.totalCapitalDifference) }}</a-list-item>
          <a-list-item label="Total"> {{ formatNumber(capitalInfo.totalCapital, 2) }}</a-list-item>

          <a-list-item label="Avstämningsdag">
            <a-input-date v-model="instruction.corporateEvent.recordDate" v-bind="$defs.input" :readonly="readonly" />
          </a-list-item>

          <a-list-item label="Införd i aktieboken">
            <a-input-date v-model="instruction.settleDate" v-bind="$defs.input" :readonly="readonly" />
          </a-list-item>

          <a-list-item label="Registreringsdag BTA">
            <a-input-date
              v-model="instruction.corporateEvent.interimRegistrationDate"
              v-bind="$defs.input"
              :readonly="true"
            />
          </a-list-item>

          <a-list-item label="Registreringsdag">
            <a-input-date v-model="instruction.registrationDate" v-bind="$defs.input" :readonly="true" />
          </a-list-item>
          <LastActivity :model="instruction" />
        </q-list>
      </div>
    </a-page-section>

    <template v-if="showInterimDestinations">
      <h4 class="text-h4 text-weight-regular q-mb-md q-mt-xl">Innehav BTA</h4>
      <InterimDestinationsTableCard
        :shareRegister="settleDateShareRegister"
        :instruction="instruction"
        :validator="validator"
        :readonly="readonly || isInterim"
      />
    </template>

    <template v-if="shareRegister">
      <h4 class="text-h4 text-weight-regular q-mb-md q-mt-xl">Teckningslista Nyemission</h4>
      <DestinationsTableCard
        :shareRegister="shareRegister"
        v-if="shareRegister"
        :instruction="instruction"
        :validator="validator"
        :readonly="readonly"
      />
    </template>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';

import InstrumentSourceTable from '@/views/instruction/corporateEvent/InstrumentSourceTable.vue';
import { Instruction, InstructionState, Instrument, InstrumentGroup, ShareRegister } from '@/models';
import { options } from '@shared/common';
import SelectEntity from '@/views/entity/SelectEntity.vue';
import { validation } from '@shared/common';
import { requiredNumber } from '@shared/utils/validation';
import DestinationsTableCard from '@/views/instruction/corporateEvent/DestinationsTableCard.vue';
import InterimDestinationsTableCard from '@/views/instruction/corporateEvent/InterimDestinationsTableCard.vue';
import LastActivity from '@/views/activity/LastActivity.vue';
import issueShareValidator from '@shared/models/instructionValidator/issueShareValidator';
import { getInterimPositions } from '@/models/instruction/instructionService';

@Component({
  components: {
    SelectEntity,
    InstrumentSourceTable,
    DestinationsTableCard,
    LastActivity,
    InterimDestinationsTableCard,
  },
})
export default class IssueShareContent extends Vue {
  @Prop() instruction: Instruction;
  @Prop() shareRegister: ShareRegister;
  @Prop({ default: true }) readonly: boolean;
  @Prop() settleDateShareRegister: ShareRegister;

  mounted() {
    this.onSettleDateShareRegisterChange();
  }

  @Watch('settleDateShareRegister')
  @Watch('instruction.state')
  @Watch('readonly')
  onSettleDateShareRegisterChange() {
    if (this.instruction.state === InstructionState.EXECUTED_INTERIM) {
      this.instruction.interimPositions = this.interimPositions;
    }
  }

  get interimPositions() {
    return getInterimPositions(this.instruction, this.settleDateShareRegister);
  }

  get validator() {
    return issueShareValidator(this.instruction, this.instruction.entity);
  }

  get capitalInfo() {
    return this.validator.getCapitalInfo();
  }

  get issueShareData() {
    return this.instruction.corporateEvent.issueShareData;
  }

  get shares(): Array<Instrument> {
    return this.shareRegister.entity.instruments.filter(share =>
      [InstrumentGroup.SHA, InstrumentGroup.PREF].includes(share.group),
    );
  }

  get issuerData() {
    return this.shareRegister && this.shareRegister.getHistoricIssuerData();
  }

  get isExecuteInterim() {
    return this.instruction.state === InstructionState.EXECUTED_INTERIM;
  }

  get priceRules() {
    return [requiredNumber];
  }

  get options() {
    return options;
  }

  get validation() {
    return validation;
  }
  get isInterim() {
    return this.instruction.state === InstructionState.EXECUTED_INTERIM;
  }

  get showInterimDestinations() {
    return (
      this.settleDateShareRegister &&
      [InstructionState.EXECUTED_INTERIM, InstructionState.EXECUTED].includes(this.instruction.state)
    );
  }
}
</script>
<style lang="scss">
.IssueShareContent {
  .q-markup-table {
    overflow: initial;
  }
  th.number {
    width: 12%;
  }
  .w50 {
    width: 50%;
  }
}
</style>
