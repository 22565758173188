<template>
  <div class="AccruedInterest">
    <label class="col-1 col-md-2">
      <div>Acc ränta</div>
      <div>
        <NumberInput
          v-model="rightsData.accruedInterest"
          v-bind="$defs.input"
          class="number"
          :disable="readonly"
          @change="$emit('change')"
          @update:modelValue="$emit('input')"
        />
      </div>
    </label>
  </div>
</template>
<script lang="ts">
// @ts-nocheck
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { Instrument } from '@shared/web/models';
import NumberInput from '@shared/components/NumberInput.vue';

@Component({
  components: { NumberInput },
})
export default class AccruedInterest extends Vue {
  @Prop() instrument: Instrument;
  @Prop({ default: true }) readonly: boolean;

  get rightsData() {
    return this.instrument.rightsData;
  }
}
</script>

<style lang="scss">
.AccruedInterest {
  .number {
    max-width: 4rem;
  }
}
</style>
