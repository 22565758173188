<template>
  <q-page class="InvestmentOffersPage">
    <div class="row">
      <h1>Investeringserbjudande</h1>
    </div>
    <q-separator />
    <InvestmentOffersList
      :investmentOffers="investmentOffers"
      :createNewInvestmentOffer="createInvestmentOffer"
      :isEditView="true"
      class="editor"
    />
  </q-page>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import InvestmentOffersList from '@shared/views/investmentOffer/InvestmentOffersList.vue';
import {
  fetchInvestmentOffers,
  fetchInvestmentOfferById,
} from '@shared/web/models/investmentOffer/investmentOffersService';
import InvestmentOffer from '@shared/web/models/investmentOffer/InvestorOffer';
import { createInvestmentOffer } from '@/models/investmentOffer/investmentOfferService';
@Component({
  components: {
    InvestmentOffersList,
  },
})
export default class InvestmentOffersPage extends Vue {
  investmentOffers: InvestmentOffer[] = [];
  async mounted() {
    this.investmentOffers = await fetchInvestmentOffers();
  }
  async createInvestmentOffer() {
    const { id } = await createInvestmentOffer({
      offerCompanyName: '',
      title: '',
      text: '<p></p>',
      archived: false,
      draft: true,
    });
    fetchInvestmentOfferById(`${id}`);
    const routeLocation = {
      name: 'InvestmentOfferEdit',
      params: { id: `${id}` },
    };
    const resolvedRoute = this.$router.resolve(routeLocation);
    this.$router.push(resolvedRoute);
  }
}
</script>
<style type="scss"></style>
