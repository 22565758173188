<template>
  <a-btn-link class="icon-left align-right" icon="svguse:/svg-icons.svg#icon-download" size="sm" @click="exportToExcel"
    >Excel</a-btn-link
  >
</template>
<script lang="ts">
import xlsx from 'json-as-xlsx';
import { Vue, Prop, Component } from 'vue-facing-decorator';
import { ShareRegister } from '@/models';
import { toExcelDataPositions } from './excelExport';

@Component({
  components: {},
})
export default class ExportPositionExcel extends Vue {
  @Prop() shareRegister: ShareRegister;

  get excelSheetName() {
    return this.shareRegister.entity.viewName;
  }

  exportToExcel() {
    const fileName = this.excelSheetName;
    xlsx(toExcelDataPositions(fileName, this.shareRegister), { fileName });
  }
}
</script>
