<template>
  <tr>
    <td>
      <p>
        {{ source.instrument.viewName }}
      </p>
      <p class="nationalid-small" v-if="source.interimShareKapID">BTA: {{ source.interimShareKapID }}</p>
    </td>

    <td class="text-right marked-bg" v-if="columns.preTotalQuantity">
      {{ formatNumber(shareData.totalQuantity) }}
    </td>
    <td class="text-right marked-bg" v-if="columns.preTotalCapital">
      {{ formatNumber(shareData.totalCapital) }}
    </td>
    <td class="text-right">
      <a-input-number
        v-bind="$defs.input"
        input-class="text-right new-share"
        v-model="source.quantity"
        :readonly="readonly"
        :fractionDigits="0"
        :allowOnlyNumber="true"
      />
    </td>
    <td class="text-right" v-if="columns.ratio">{{ ratio }}</td>
    <td
      class="text-right validation"
      :class="{ valid: sourceValidation.validQuantity }"
      v-if="columns.subscribedQuantity"
    >
      {{ formatNumber(sourceValidation.totalQuantity) }}
    </td>
    <td class="text-right">{{ formatNumber(sourceValidation.totalCapitalForCurrentQuota, 2) }}</td>
    <td class="text-right" v-if="columns.totalCapitalDifference">
      {{ formatNumber(sourceValidation.totalCapitalDifference, 2) }}
    </td>
  </tr>
</template>
<script lang="ts">
import { Instruction, ShareRegister, InstrumentSource } from '@/models';

import { Component, Vue, Prop } from 'vue-facing-decorator';

@Component({
  components: {},
})
export default class SourceRow extends Vue {
  @Prop() source: InstrumentSource;
  @Prop() shareRegister: ShareRegister;
  @Prop() instruction: Instruction;
  @Prop() readonly: boolean;
  @Prop() validator;

  @Prop() columns;

  get sourceValidation() {
    return this.validator.forInstrumentSource(this.source.instrument);
  }

  get shareData() {
    return this.source.instrument.shareData.getHistoricVersion(this.shareRegister.settleDate);
  }

  get ratio() {
    const ratio = this.sourceValidation.ratio;
    if (ratio == null) {
      return null;
    }
    return `1 : ${ratio}`;
  }
}
</script>
