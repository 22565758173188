<template>
  <q-markup-table flat class="standard-table TransactionsTable" separator="horizontal">
    <thead>
      <tr>
        <th class="text-left holder-name-th"></th>

        <SortableTH property="settleDate" :transactionsData="transactionsData">Införd i aktieboken</SortableTH>
        <SortableTH property="tradeDate" :transactionsData="transactionsData">Affärsdag</SortableTH>
        <th class="text-left">Transaktionstyp</th>
        <SortableTH property="previousOwner" :transactionsData="transactionsData">Säljare</SortableTH>
        <SortableTH property="custodian" :transactionsData="transactionsData">Depåinstitut</SortableTH>
        <SortableTH property="owner" :transactionsData="transactionsData">Köpare</SortableTH>
        <SortableTH :alignRight="true" property="quantity" :transactionsData="transactionsData">Antal</SortableTH>
        <SortableTH :alignRight="true" property="amount" :transactionsData="transactionsData"
          >Nominellt Belopp</SortableTH
        >
        <SortableTH :alignRight="true" property="price" :transactionsData="transactionsData">Pris</SortableTH>
        <th class="text-right">Verifikationsnummer</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="{ holder, transactions } in groups" :key="holder?.id">
        <tr class="holder-row">
          <td colspan="20">
            <router-link :to="holder.viewUrl" class="standard-text">{{ holder.viewName }}</router-link>
          </td>
        </tr>

        <TransactionRow v-for="transaction in transactions" :key="transaction.id" :transaction="transaction" />
      </template>
    </tbody>
  </q-markup-table>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';

import { TransactionsData } from '@/models';

import TransactionRow from './TransactionRow.vue';
import SortableTH from './SortableTH.vue';
@Component({
  components: { TransactionRow, SortableTH },
})
export default class TransactionsTable extends Vue {
  @Prop() transactionsData: TransactionsData;

  get groups() {
    return this.transactionsData.getGroups();
  }
}
</script>
<style lang="scss">
.TransactionsTable {
  .q-input.number {
    width: 80px;
    margin-left: auto;
  }
  .holder-row {
    background: #f0f0f0;
  }
  .holder-name-th {
    width: 100px;
  }
}
</style>
