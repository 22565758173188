import { Entity, Instrument, ShareRegister } from '@shared/web/models';
import { InstrumentCategory, Products } from '@shared/models/types';
import { asModelId } from '@/utils';
import axios from 'axios';
import { modelHandler } from '../base';
export { makeShareRegisterValidation } from '@shared/shareRegister/shareRegisterService';

const baseUrl = '/api/share-register';
const { destroyModel } = modelHandler(ShareRegister, baseUrl);

export async function findShareRegister({
  entity,
  instrument,
  settleDate,
  instrumentCategory,
  instrumentProduct,
  onlyPreliminary,
}: {
  entity: Entity;
  instrument?: Instrument;
  settleDate?: Date;
  instrumentCategory?: InstrumentCategory;
  instrumentProduct?: Products;
  onlyPreliminary?: boolean;
}) {
  if (!entity) {
    return null;
  }

  const { data } = await axios.post(baseUrl + '/find', {
    entity: asModelId(entity),
    instrument: asModelId(instrument),
    settleDate,
    instrumentCategory,
    instrumentProduct,
    onlyPreliminary,
  });
  return ShareRegister.newModel(data, true);
}

export async function clearShareRegister(shareRegister: ShareRegister) {
  await destroyModel(shareRegister);
  shareRegister.positions.splice(0);
}

export async function setShareRegisterState(shareRegister: ShareRegister, update) {
  update.id = shareRegister.id;
  const { data } = await axios.patch(baseUrl + '/set-state', update);
  return ShareRegister.newModel(data, true);
}
