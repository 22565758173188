import store from '@/store';
import router from '@/router';
import { isKapsureEvent, isTradeEvent } from '@shared/instruction/utils';

export async function openNewInstruction(props) {
  store.newModelProps = props;
  const routeName = isTradeEvent(props.type) || isKapsureEvent(props.type) ? 'InstructionPage' : 'CorporateEventPage';
  router.push({
    name: routeName,
    params: { id: 'new' },
  });
}

export async function openNewInstrument(props) {
  store.newModelProps = props;

  router.push({
    name: 'InstrumentPage',
    params: { id: 'new' },
  });
}

export async function openNewInsurance(props) {
  store.newModelProps = props;

  router.push({
    name: 'InsurancePage',
    params: { id: 'new' },
  });
}
