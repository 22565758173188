<template>
  <div class="IssueRightsContent">
    <div class="row q-col-gutter-x-xl">
      <q-list v-if="shareRegister" separator class="standard-list col-md-6">
        <a-list-item label="Emittent">
          <a-link :target="instruction.entity" v-if="readonly" />
          <SelectEntity
            v-model="instruction.entity"
            entityType="issuer"
            v-bind="$defs.input"
            :readonly="readonly"
            :hideCreate="true"
            @update:modelValue="$emit('changeEntity')"
            v-else
          />
        </a-list-item>

        <a-list-item label="Datum">
          <a-input-date
            v-model="instruction.settleDate"
            v-bind="$defs.input"
            :disable="readonly"
            :readonly="readonly"
          />
        </a-list-item>
        <LastActivity :model="instruction" />
      </q-list>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';

import { Instruction, ShareRegister } from '@shared/web/models';

import SelectEntity from '@/views/entity/SelectEntity.vue';

import LastActivity from '@/views/activity/LastActivity.vue';

@Component({
  components: {
    SelectEntity,
    LastActivity,
  },
})
export default class IssueRightsContent extends Vue {
  @Prop() instruction: Instruction;
  @Prop() shareRegister: ShareRegister;
  @Prop({ default: true }) readonly: boolean;
}
</script>
<style lang="scss">
.IssueRightsContent {
  .q-markup-table {
    overflow: initial;
  }
  th.number {
    width: 12%;
  }
  .w50 {
    width: 50%;
  }
}
</style>
