<template>
  <div v-if="comment">
    <q-skeleton v-if="disabled" height="100px" />
    <CommentEditor v-if="!disabled" v-model="comment.text" />
    <q-btn :disable="disabled" class="q-mt-sm" @click="addComment">Lägg till</q-btn>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import CommentEditor from '@/views/comment/CommentEditor.vue';
import { Entity } from '@shared/web/models';
import Comment from '@shared/web/models/entity/Comment';
import axios from 'axios';

@Component({
  components: { CommentEditor },
})
export default class EntityCommentForm extends Vue {
  @Prop() entity: Entity;
  comment: Comment;

  created() {
    this.comment = new Comment();
  }

  get disabled() {
    return !this.entity;
  }
  async addComment() {
    await axios.post('/api/comment', {
      text: this.comment.text,
      entity: this.entity.id,
    });
    this.comment = new Comment();
    this.$emit('savedComment');
  }
}
</script>
