<template>
  <div>
    <q-table
      :rows="entity.legalEntity.contactPersons"
      :columns="columns"
      row-key="id"
      flat
      hide-pagination
      hide-no-data
      :pagination="pagination"
      class="standard-table"
    >
      <template v-slot:body-cell-actions="props">
        <q-td key="actions">
          <q-btn flat round color="grey-4" icon="more_vert" size="sm">
            <q-menu self="center left" anchor="center left" :offset="[70, 0]" content-class="a-tooltip shadow-1">
              <q-list>
                <q-item clickable v-close-popup @click="edit(props.row)">
                  <q-item-section>Ändra</q-item-section>
                </q-item>
                <q-item clickable v-close-popup @click="remove(props.row)">
                  <q-item-section>Ta bort</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </q-td>
      </template>
      <template v-slot:bottom-row>
        <a-table-row-btn @click="add" label="Lägg till kontaktperson" />
      </template>
    </q-table>
  </div>
</template>
<script lang="ts">
import { ContactInfo, Entity } from '@/models';
import { validation } from '@shared/common';
import { QTableProps } from 'quasar';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import ContactPersonModal from './ContactPersonModal.vue';
import { save as saveEntity } from '@/models/entity/entityService';
import { remove } from '@/utils';

@Component({
  components: {},
})
export default class ContactPersonTable extends Vue {
  @Prop({ required: true }) entity: Entity;

  add() {
    this.$q
      .dialog({
        component: ContactPersonModal,
        componentProps: {
          input: ContactInfo.newModel({ entity: this.entity }),
        },
      })
      .onOk(newContactPerson => {
        this.entity.legalEntity.contactPersons.push(newContactPerson);
        saveEntity(this.entity);
      });
  }

  save() {}

  edit(contactPerson) {
    this.$q
      .dialog({
        component: ContactPersonModal,
        componentProps: {
          input: contactPerson,
        },
      })
      .onOk(() => {
        saveEntity(this.entity);
      });
  }
  remove(contactPerson) {
    remove(this.entity.legalEntity.contactPersons, contactPerson);
    saveEntity(this.entity);
  }

  get validation() {
    return validation;
  }

  columns: QTableProps['columns'] = [
    {
      field: 'firstName',
      name: 'firstName',
      label: 'Namn',
      align: 'left',
    },
    {
      field: 'email',
      name: 'email',
      label: 'E-post',
      align: 'left',
    },
    {
      field: 'phone',
      name: 'phone',
      label: 'Telefon',
      align: 'left',
    },
    {
      field: 'role',
      name: 'role',
      label: 'Roll',
      align: 'left',
    },
    {
      name: 'actions',
      field: null,
      label: '',
    },
  ];

  pagination = {
    rowsPerPage: 0,
    sortBy: 'type',
  };
}
</script>
