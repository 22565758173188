<template>
  <router-link :to="targetLink" v-if="targetLink"
    ><slot>{{ target?.viewName }}</slot>
  </router-link>
  <slot v-else>{{ target?.viewName }}</slot>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';

@Component({})
export default class Link extends Vue {
  @Prop() target?: any;
  @Prop() to?: string;

  get targetLink() {
    return this.target?.viewUrl || this.to;
  }
}
</script>
