import { ShareRegister, TransactionsData, TransactionType } from '@/models';
import { makeShareRegisterValidation } from '@/models/shareRegister/shareRegisterService';
import TableSettings from '@shared/web/models/shareRegister/TableSettings';
import { toTransactionTypeLabel } from '@shared/common';
import { formatPercent, formatDay } from '@/utils';
import { IJsonSheet } from 'json-as-xlsx';

export const toExcelDataDestinations = ({ instruction, validator, issuerInfo, sheet }): IJsonSheet[] => {
  const type = instruction.type;
  const showInstrument = type !== TransactionType.ISSUE_SHARE;
  const showOwnedQuantity = type === TransactionType.RIGHTS_ISSUE;
  const showAllocation =
    type !== TransactionType.ISSUE_SHARE &&
    [TransactionType.RIGHTS_ISSUE, TransactionType.EXERCISE_WARRANT].includes(type);

  const columns = [
    ...(showInstrument ? [{ label: 'Aktie', value: 'instrument' }] : []),
    { label: 'Ägare', value: 'owner' },
    { label: 'Försäkringsägare', value: 'investor' },
    ...(showOwnedQuantity ? [{ label: 'Befintligt antal', value: 'owned' }] : []),
    ...(showAllocation ? [{ label: 'Nya aktier, pro rata', value: 'allocated' }] : []),
    { label: 'Tecknade', value: 'quantity' },
    { label: 'Depåförvar', value: 'custodian' },
    { label: 'Depånummer', value: 'custodianAccountNumber' },
    { label: 'Ägare gatuadress', value: 'streetAdress' },
    { label: 'Ägare postnummer', value: 'zip' },
    { label: 'Ägare stad', value: 'city' },
    { label: 'Ägare mail', value: 'email' },
    { label: 'Ägare telefon', value: 'phone' },
    { label: 'Försäkringsägare gatuadress', value: 'streetAdress' },
    { label: 'Försäkringsägare postnummer', value: 'zip' },
    { label: 'Försäkringsägare stad', value: 'city' },
    { label: 'Försäkringsägare mail', value: 'email' },
    { label: 'Försäkringsägare telefon', value: 'phone' },
    { label: 'Pantägare', value: 'pledgeOwner' },
    { label: 'Pantägare mail', value: 'email' },
    { label: 'Pantägare telefon', value: 'phone' },
  ];

  const getAmounts = destination => {
    const result = {
      quantity: destination.quantity,
    };
    const type = instruction.type;
    const isExcerciseWarrant = type === TransactionType.EXERCISE_WARRANT;
    const showOwnedQuantity = destination.instrument && type === TransactionType.RIGHTS_ISSUE;
    const showAllocation =
      destination.instrument && [TransactionType.RIGHTS_ISSUE, TransactionType.EXERCISE_WARRANT].includes(type);

    if (showOwnedQuantity) {
      const ownedQuantity = validator.forParty(destination).totalQuantity;
      Object.assign(result, { owned: ownedQuantity });

      if (showAllocation) {
        const ratio = validator.forInstrumentSource(destination.instrument).ratio || null;
        const allocationByRatio = ratio && ownedQuantity / ratio;

        Object.assign(result, { allocated: isExcerciseWarrant ? destination.quantity : allocationByRatio });
      }
    }

    return result;
  };

  const getAddressInfo = entity => {
    if (entity) {
      const { address1: streetAdress = null, zip = null, city = null } = entity.addresses[0] || {};
      return { streetAdress, zip, city };
    }
    return {};
  };

  const getContactInfo = entity => {
    if (entity) {
      const { email, phone } = issuerInfo.getContactInfo(entity);
      return { email, phone };
    }
    return {};
  };

  const mapToContentRow = destination => {
    const { instrument, owner, investor, custodian, pledgeOwner, custodianAccountNumber = null } = destination;

    return {
      instrument: instrument?.name,
      owner: owner.viewName,
      pledgeOwner: investor?.viewName,
      ...getAmounts(destination),
      custodian: custodian?.viewName,
      custodianAccountNumber,
      ...getContactInfo(owner),
      ...getAddressInfo(owner),
      ...getContactInfo(investor),
      ...getAddressInfo(investor),
      Pantägare: pledgeOwner?.viewName,
      ...getContactInfo(pledgeOwner),
    };
  };

  const content = instruction.destinations
    .map(mapToContentRow)
    .sort((a, b) => a.owner?.localeCompare(b.owner))
    .sort((a, b) => a.instrument?.localeCompare(b.instrument));

  return [{ columns, content, sheet }];
};

export const toExcelDataPositions = (sheet: string, shareRegister: ShareRegister): IJsonSheet[] => {
  const columns = [
    { label: 'Aktie', value: 'instrument' },
    { label: 'Ägare', value: 'owner' },
    { label: 'Antal', value: 'quantity' },
    { label: 'Kapitalandel', value: 'quota' },
    { label: 'Röstandel', value: 'votingPower' },
    { label: 'Aktiebrev', value: 'certificateIssued' },
  ];

  const content = shareRegister
    .getPositionGroups(new TableSettings())
    .map(data => {
      return data.positions.map(position => {
        const totals = makeShareRegisterValidation(shareRegister).forPosition(position);
        return {
          instrument: position.instrument.name,
          owner: position.owner.viewName,
          quantity: position.quantity,
          quota: formatPercent(totals.quantityQuota),
          votingPower: formatPercent(totals.votingPowerQuota),
          certificateIssued: position.certificateIssued === true ? 'Ja' : 'Nej',
        };
      });
    })
    .flat();
  return [{ sheet, columns, content }];
};

export const toExcelDataTransactions = (sheet: string, transactionsData: TransactionsData): IJsonSheet[] => {
  const columns = [
    { label: 'Aktie', value: 'instrument' },
    { label: 'Införd i aktieboken', value: 'settleDate' },
    { label: 'AffärsDag', value: 'tradeDate' },
    { label: 'Transaktiontyp', value: 'transactionType' },
    { label: 'Köpare', value: 'owner' },
    { label: 'Säljare', value: 'previousOwner' },
    { label: 'Antal', value: 'quantity' },
    { label: 'Nominellt belopp', value: 'amount' },
    { label: 'Pris', value: 'price' },
  ];

  const content = transactionsData
    .getGroups()
    .map(data => {
      return data.transactions.map(transaction => {
        return {
          instrument: transaction.instrument.name,
          settleDate: formatDay(transaction.settleDate),
          tradeDate: formatDay(transaction.tradeDate),
          transactionType: toTransactionTypeLabel(transaction.transactionType),
          owner: transaction.owner.viewName,
          previousOwner: transaction.previousOwner?.viewName,
          quantity: transaction.quantity,
          amount: transaction.amount,
          price: transaction.price,
        };
      });
    })
    .flat();

  return [{ sheet, columns, content }];
};
