<template>
  <div class="TradeEventBasics row q-col-gutter-x-xl">
    <q-list separator class="standard-list col-md-6">
      <a-list-item label="Transaktionstyp">
        <a-select
          v-bind="$defs.input"
          v-model="instruction.type"
          :options="typeOptions"
          :readonly="readonly"
          :rules="validation.required('Transaktionstyp')"
          emit-value
          map-options
        ></a-select>
      </a-list-item>
      <a-list-item label="Motpart" v-if="showCounterpart">
        <a-select
          v-model="instruction.counterpart"
          v-bind="$defs.input"
          :readonly="readonly"
          :options="counterpartsOptions"
          nullLabel="Ingen vald"
          map-options
        />
      </a-list-item>

      <a-list-item label="Emittent">
        <a-link :target="instruction.entity" v-if="readonly" />
        <SelectEntity
          v-model="instruction.entity"
          entityType="kapsureIssuer"
          v-bind="$defs.input"
          :readonly="isKapsureSettlement || readonly"
          :hideCreate="true"
          @update:modelValue="changeEntity"
          v-else
        />
      </a-list-item>
      <a-list-item label="Värdepapper">
        <a-link :target="destination.instrument" v-if="readonly" />
        <a-select
          v-model="destination.instrument"
          v-bind="$defs.input"
          :readonly="isKapsureSettlement || readonly"
          :options="shares"
          map-options
          option-label="viewName"
          :rules="validation.required('Värdepapper')"
          v-else
        />
      </a-list-item>

      <a-list-item :label="idType.label" v-for="idType in activeIdTypes" :key="idType.value">
        {{ instrument[idType.label] }}</a-list-item
      >
    </q-list>
    <q-list separator class="standard-list col-md-6">
      <a-list-item :label="settleDateLabel">
        <a-input-date
          v-model="instruction.settleDate"
          v-bind="$defs.input"
          :readonly="readonly"
          :rules="validation.required(settleDateLabel)"
        />
      </a-list-item>
      <a-list-item v-if="readonly" label="Registreringsdag">
        <a-input-date v-model="instruction.registrationDate" v-bind="$defs.input" :readonly="true" />
      </a-list-item>
      <a-list-item label="Status">
        {{ toInstructionStateLabel(instruction.calculatedState) }}
      </a-list-item>
      <p v-if="instruction.warning">
        Det finns pågående nyemission - med avstämningsdag före datum för införande i aktieboken.
      </p>
      <LastActivity :model="instruction" />
      <a-list-item class="notes" label="Anteckningar">
        <q-input v-model="instruction.notes" autogrow filled :readonly="readonly" />
      </a-list-item>
    </q-list>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import SourceRow from './SourceRow.vue';
import DestinationRow from './DestinationRow.vue';
import { Instruction, Instrument, InstructionParty, TransactionType } from '@/models';
import {
  kapsureCounterparts,
  options,
  toInstructionStateLabel,
  toTransactionTypeLabel,
  toTransactionTypeOptions,
} from '@shared/common';
import { kapsureSettlementTypes, kapsureBookingTypes } from '@shared/instruction/utils';
import SelectEntity from '@/views/entity/SelectEntity.vue';
import { validation } from '@shared/common';
import LastActivity from '@/views/activity/LastActivity.vue';
import { loadEntity } from '@/models/entity/entityService';
import { KapsureCounterpart } from '@shared/models/types';

@Component({
  components: {
    SourceRow,
    DestinationRow,
    SelectEntity,
    LastActivity,
  },
})
export default class TradeEventBasics extends Vue {
  @Prop() instruction: Instruction;
  @Prop() destination: InstructionParty;
  @Prop({ default: true }) readonly: boolean;
  @Prop({ default: false }) isKapsureSettlement: boolean;

  mounted() {
    this.settleToday;
  }

  get settleToday() {
    return (this.instruction.settleDate = this.instruction.settleDate || new Date());
  }

  toInstructionStateLabel(val) {
    return toInstructionStateLabel(val);
  }

  get typeOptions() {
    if (this.isKapsureSettlement) {
      return toTransactionTypeOptions(kapsureSettlementTypes);
    } else {
      return toTransactionTypeOptions(kapsureBookingTypes);
    }
  }

  get counterpartsOptions() {
    return [
      KapsureCounterpart.Pareto,
      KapsureCounterpart.Monitor,
      KapsureCounterpart.Pepins,
      KapsureCounterpart.SV_Ventures,
      KapsureCounterpart.Person,
      KapsureCounterpart.Company,
      KapsureCounterpart.Other,
    ].map(counterpart => ({
      value: counterpart,
      label: kapsureCounterparts[counterpart],
    }));
  }

  toTransactionTypeLabel(val) {
    return toTransactionTypeLabel(val);
  }

  async changeEntity() {
    await loadEntity(this.instruction.entity.id);
  }

  get showCounterpart() {
    return [TransactionType.KAPSURE_DVP_BUY, TransactionType.KAPSURE_DVP_SELL].includes(this.instruction.type);
  }

  get shares(): Array<Instrument> {
    return this.instruction.entity?.instruments.filter(instrument => !instrument.isKapclearProduct);
  }

  get options() {
    return options;
  }

  get validation() {
    return validation;
  }

  get settleDateLabel() {
    return this.isKapsureSettlement ? 'Utbokningsdag' : 'Inbokningsdag';
  }

  get instrument() {
    return this.destination.instrument;
  }

  get activeIdTypes() {
    return this.instrument?.activeIdTypes;
  }
}
</script>
