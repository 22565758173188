<template>
  <div class="IssuerAndSharesInfo">
    <q-list class="standard-list" :separator="true">
      <a-list-item label="Emittent">
        <router-link :to="entity.viewUrl"> {{ entity.viewName }}</router-link>
      </a-list-item>
      <IssuerSharesInfo :entity="entity" />
      <a-label-item label="Avstämningsbolag">
        {{ toBooleanLabel(issuerData.isRecordCompany) }}
      </a-label-item>
      <a-label-item label="Aktiebokstatus">
        <span class="validation" :class="{ valid: valid }">{{ shareRegisterStatusText }}</span>
        <span v-if="shareRegisterRequestedChangeMessage">
          {{ shareRegisterRequestedChangeMessage }}
        </span>
      </a-label-item>
    </q-list>
    <q-markup-table flat class="standard-table" bordered separator="horizontal">
      <thead>
        <tr>
          <th class="text-left">Aktie</th>
          <th class="text-right">Antal aktier</th>
          <th class="text-right">Enligt bolagsverket</th>
          <th class="text-right" v-if="displayAllocated">Antal fördelade</th>
          <th class="text-right" v-if="!isLocked"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="instrument in shares" :key="instrument.id">
          <td class="text-left">
            <router-link :to="instrument.viewUrl" class="standard-text">{{ instrument.viewName }}</router-link>
          </td>
          <td class="text-right">
            {{ formatNumber(instrument.shareData.totalQuantity) }}
          </td>
          <td class="text-right">
            {{ formatNumber(instrument.shareDataTotalQuantityFromRoaring) }}
          </td>
          <td
            class="text-right validation"
            :id="`instrument_${instrument.id}-number-distribution`"
            :class="{ valid: instrumentTotals(instrument).positionsValid }"
            v-if="displayAllocated"
          >
            {{ formatNumber(instrumentTotals(instrument).totalQuantity) }}
          </td>
          <td class="text-right p-1" v-if="!isLocked">
            <upload-excel :instrument-id="instrument.id" @uploaded="refresh" />
          </td>
        </tr>
        <tr>
          <td class="text-left">Totalt</td>
          <td class="text-right validation" :class="{ valid: entityTotals.valid }">
            <template v-if="displayAllocated">
              <template v-if="!entityTotals.valid">{{ formatNumber(entityTotals.totalQuantity) }} av</template>
              {{ formatNumber(issuerData.totalQuantity) }}
            </template>
            <template v-else>
              {{ formatNumber(entityTotals.totalQuantity) }}
            </template>
          </td>
          <td class="text-right">{{ formatNumber(issuerRoaringTotalQuantity) }}</td>
          <td
            class="text-right validation"
            :class="{ valid: entityTotals.validAllocatedQuantity }"
            v-if="displayAllocated"
          >
            {{ formatNumber(entityTotals.allocatedQuantity) }}
          </td>
        </tr>
      </tbody>
    </q-markup-table>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { toShareRegisterStatusText } from '@shared/common';
import { InstructionState, Instrument, ShareRegister } from '@/models';
import { makeShareRegisterValidation } from '@/models/shareRegister/shareRegisterService';
import IssuerSharesInfo from '@/components/IssuerSharesInfo.vue';
import UploadExcel from './UploadExcel.vue';

@Component({ components: { UploadExcel, IssuerSharesInfo } })
export default class IssuerAndSharesInfo extends Vue {
  @Prop() shareRegister: ShareRegister;
  @Prop() performSave;

  get entity() {
    return this.shareRegister.entity;
  }

  get entityTotals() {
    return this.shareRegisterValidation.forEntity();
  }

  get isLocked() {
    return this.issuerData.locked;
  }

  get displayAllocated() {
    return !this.isLocked;
  }

  get shareRegisterValidation() {
    return makeShareRegisterValidation(this.shareRegister);
  }

  get instrumentTotalsMap() {
    return this.shareRegisterValidation.getInstrumentMap();
  }

  instrumentTotals(instrument) {
    return this.instrumentTotalsMap.get(instrument);
  }

  get shares(): Array<Instrument> {
    return this.shareRegister.shares;
  }
  get issuerData() {
    return this.entity.issuerData;
  }

  get issuerRoaringTotalQuantity(): number {
    return this.entity.issuerDataTotalQuantityFromRoaring;
  }

  get valid() {
    return this.shareRegisterValidation.isValid();
  }
  get validPaySha() {
    return this.shareRegisterValidation.isValidNonPaySha();
  }

  get shareRegisterStatusText() {
    return toShareRegisterStatusText(this.entity, this.valid, this.validPaySha);
  }

  get shareRegisterRequestedChangeMessage() {
    return (
      this.entity.issuerData.shareRegisterState === InstructionState.REQUEST_CHANGE &&
      this.entity.shareRegisterRequestedChangeMessage
    );
  }

  public refresh() {
    this.$emit('refresh');
  }
}
</script>
