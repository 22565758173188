<template>
  <div class="LegalEntitySearch">
    <h4 class="table-header">Sök Juridisk Person</h4>
    <q-form @submit="search" class="q-gutter-x-md search-form">
      <div class="row">
        <q-input v-bind="$defs.input" v-model="searchQuery.query" placeholder="Namn eller bolagsnummer" />

        <q-btn label="Sök" type="submit" v-bind="$defs.btn" :loading="loading" />
      </div>

      <div class="row">
        <a-select
          v-model="searchQuery.type"
          v-bind="$defs.input"
          :options="typeOptions"
          emit-value
          map-options
          label="Typ"
        />
        <a-select
          v-model="searchQuery.status"
          v-bind="$defs.input"
          :options="options.shareRegisterStateOptions"
          emit-value
          map-options
          label="Aktiebokstatus"
        />
        <a-select
          v-model="searchQuery.active"
          v-bind="$defs.input"
          :options="options.booleanAndAllOptions"
          emit-value
          map-options
          label="Aktiv"
        />
        <a-select
          v-model="searchQuery.field"
          v-bind="$defs.input"
          :options="options.fieldOptions"
          emit-value
          map-options
          label="Sök i fält"
        />
      </div>
    </q-form>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import { search } from '@/models/entity/entityService';
import LESearchQuery from '@/models/entity/LESearchQuery';
import { options } from '@shared/common';

const defaultQuery = { active: true, type: 'issuer' };

@Component
export default class LegalEntitySearch extends Vue {
  loading = false;
  searchQuery = null;

  get options() {
    return options;
  }

  get typeOptions() {
    return [
      ...options.typeOptions,
      {
        value: 'issuerOnlyWarrants',
        label: 'Emittent (enbart TO)',
      },
    ];
  }

  created() {
    this.searchQuery = new LESearchQuery({ ...defaultQuery, ...this.$route.query });
  }

  mounted() {
    if (!this.searchQuery.isEmpty()) {
      this.search();
    }
  }

  async search() {
    this.loading = true;

    this.$router.replace({
      query: this.searchQuery.asRequestParams(),
    });

    try {
      const searchResult = await search(this.searchQuery, { isPerson: false });
      this.$emit('search', searchResult);
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style lang="scss">
.LegalEntitySearch {
  .search-form {
    max-width: 800px;
  }
}
</style>
