<template>
  <div class="InstrumentSearch">
    <h4 class="table-header">Sök Värdepapper</h4>
    <q-form @submit="search" class="q-gutter-x-md search-form">
      <div class="row">
        <q-input v-bind="$defs.input" v-model="searchQuery.query" placeholder="Namn" class="fill-width" />

        <q-btn label="Sök" type="submit" v-bind="$defs.btn" :loading="loading" />
      </div>
      <div class="row">
        <a-select
          v-model="searchQuery.category"
          v-bind="$defs.input"
          :options="withAllOption(options.instrumentCategoryOptions)"
          emit-value
          map-options
          label="Kategori"
        />
        <a-select
          v-model="searchQuery.positionsValid"
          v-bind="$defs.input"
          :options="positionsValidOptions"
          emit-value
          map-options
          label="Status"
        />
        <a-select
          v-model="searchQuery.product"
          v-bind="$defs.input"
          :options="options.productOptions"
          emit-value
          map-options
          label="Produkt"
        />
      </div>
    </q-form>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import { search } from '@/models/instrument/instrumentService';
import SearchQuery from '@/models/instrument/InstrumentSearchQuery';
import { options } from '@shared/common';

@Component
export default class PersonSearch extends Vue {
  loading = false;
  searchQuery = null;

  created() {
    this.searchQuery = new SearchQuery(this.$route.query);
  }

  get options() {
    return options;
  }

  mounted() {
    if (!this.searchQuery.isEmpty()) {
      this.search();
    }
  }

  get positionsValidOptions() {
    return [
      { value: null, label: 'Alla' },
      { value: true, label: 'Klara' },
      { value: false, label: 'Preliminära/Felaktiga' },
    ];
  }

  async search() {
    this.loading = true;

    this.$router.replace({
      query: this.searchQuery.asRequestParams(),
    });

    try {
      const searchResult = await search(this.searchQuery);
      this.$emit('search', searchResult);
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style lang="scss">
.InstrumentSearch {
  .search-form {
    max-width: 800px;
  }
}
</style>
