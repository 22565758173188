<template>
  <div>
    <q-tabs class="q-mt-lg" active-class="bg-grey-2" align="left">
      <q-route-tab to="/search/legal-entities" name="legalEntities" icon="apartment" label="Juridiska personer" />
      <q-route-tab to="/search/persons" name="persons" icon="group" label="Fysiska personer" />
      <q-route-tab to="/search/instruments" name="instruments" icon="description" label="Värdepapper" />
      <q-route-tab to="/search/positions" name="positions" icon="receipt" label="Innehav" />
      <q-route-tab to="/search/transactions" name="transactions" icon="list" label="Transaktioner" />
      <q-route-tab to="/search/trade-events" name="trade-events" icon="sync_alt" label="Ägarbyten" />
      <q-route-tab to="/search/corporate-events" name="corporateEvents" icon="file_present" label="Bolagshändelser" />
    </q-tabs>
    <div class="q-px-sm">
      <div v-if="activeTab === 'legal-entities'">
        <LegalEntitySearch @search="searchLegalEntities" ref="legalEntitySearch" />
        <LegalEntityList :list="legalEntities" class="q-mt-lg" @reload="triggerSearchLegalEntities" />
      </div>
      <div v-if="activeTab === 'persons'">
        <PersonSearch @search="searchPersons" ref="personSearch" />
        <PersonList :list="persons" @reload="triggerSearchPersons" />
      </div>
      <div v-if="activeTab === 'instruments'">
        <InstrumentSearch @search="searchInstruments" />
        <InstrumentList :list="instruments" />
      </div>
      <div v-if="activeTab === 'positions'">
        <PositionSearchWithResult />
      </div>
      <div v-if="activeTab === 'transactions'">
        <TransactionSearchWithResult />
      </div>
      <div v-if="activeTab === 'trade-events'" class="q-mt-lg">
        <InstructionSearchWithResult :header="false" :main-type="tradeType" :show-create-button="true" />
      </div>
      <div v-if="activeTab === 'corporate-events'" class="q-mt-lg">
        <InstructionSearchWithResult :header="false" :main-type="corporateType" :show-create-button="true" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import LegalEntitySearch from '@/views/legalEntityList/LegalEntitySearch.vue';
import LegalEntityList from '@/views/legalEntityList/LegalEntityList.vue';
import PersonSearch from '@/views/personList/PersonSearch.vue';
import PersonList from '@/views/personList/PersonList.vue';
import InstrumentSearch from '@/views/instrumentList/InstrumentSearch.vue';
import InstrumentList from '@/views/instrumentList/InstrumentList.vue';
import PositionSearch from '@/views/positionList/PositionSearch.vue';
import PositionSearchWithResult from '@/views/positionList/PositionSearchWithResult.vue';
import TransactionSearchWithResult from '@/views/transaction/TransactionSearchWithResult.vue';
import InstructionSearchWithResult from '@/views/instructionList/InstructionSearchWithResult.vue';
import { InstructionMainType } from '@shared/models/types';

@Component({
  components: {
    InstructionSearchWithResult,
    TransactionSearchWithResult,
    PositionSearchWithResult,
    PositionSearch,
    InstrumentList,
    InstrumentSearch,
    PersonList,
    PersonSearch,
    LegalEntityList,
    LegalEntitySearch,
  },
})
export default class SearchPage extends Vue {
  legalEntities = [];
  persons = [];
  instruments = [];
  positions = [];
  transactions = [];
  tradeEvents = [];
  corporateEvents = [];

  mounted() {}

  get activeTab() {
    return this.$route.path.split('/').pop();
  }
  get tradeType() {
    return InstructionMainType.TRADE_EVENT;
  }
  get corporateType() {
    return InstructionMainType.CORPORATE_EVENT;
  }
  searchLegalEntities(searchResult) {
    this.legalEntities = searchResult;
  }
  triggerSearchPersons() {
    const personSearch = this.$refs.personSearch as any;
    personSearch.search();
  }
  triggerSearchLegalEntities() {
    const legalEntitySearch = this.$refs.legalEntitySearch as any;
    legalEntitySearch.search();
  }
  searchPersons(searchResult) {
    this.persons = searchResult;
  }
  searchInstruments(searchResult) {
    this.instruments = searchResult;
  }
  searchPositions(searchResult) {
    this.positions = searchResult;
  }
  searchTransactions(searchResult) {
    this.transactions = searchResult;
  }
}
</script>
