<template>
  <q-table
    :rows="transactions"
    :columns="columns"
    :pagination="pagination"
    row-key="id"
    flat
    hide-pagination
    hide-no-data
    class="standard-table"
  >
    <template v-slot:body-cell="props">
      <q-td :props="props">
        <template v-if="props.value?.viewUrl">
          <router-link :to="props.value.viewUrl"> {{ props.value.viewName }}</router-link>
        </template>
        <template v-else>{{ props.value }}</template>
      </q-td>
    </template>
    <template v-slot:body-cell-ownership="props">
      <q-td :props="props">
        <InsuranceLink :instructionParty="props.row" />
      </q-td>
    </template>
    <template v-slot:body-cell-transactionType="props">
      <q-td :props="props">
        <template v-if="props.row.instruction">
          <router-link :to="`/instruction/view/${props.row.instruction.id ?? props.row.instruction}`">
            {{ props.value }}</router-link
          >
        </template>
        <template v-else>{{ props.value }}</template>
      </q-td>
    </template>
  </q-table>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { Transaction } from '@/models';
import { QTableProps } from 'quasar';
import InsuranceLink from '@/views/insurance/InsuranceLink.vue';
import { formatDay, formatNumber } from '@shared/utils';

import { toTransactionTypeLabel } from '@shared/common';

@Component({
  components: {
    InsuranceLink,
  },
})
export default class OwnerTransactionsTable extends Vue {
  @Prop() transactions: Array<Transaction>;

  columns: QTableProps['columns'] = [
    {
      field: 'settleDate',
      name: 'settleDate',
      label: 'Införd i aktieboken',
      align: 'left',
      format: value => formatDay(value),
    },
    {
      field: 'transactionType',
      name: 'transactionType',
      label: 'Transaktionstyp',
      align: 'left',
      format: value => toTransactionTypeLabel(value),
    },
    {
      field: 'instrument',
      name: 'instrument',
      label: 'Värdepapper',
      align: 'left',
    },
    {
      field: 'ownership',
      name: 'ownership',
      label: 'Försäkring',
      align: 'left',
    },

    {
      field: 'quantity',
      format: value => value && formatNumber(value),
      name: 'quantity',
      label: 'Antal',
      align: 'right',
    },
    {
      field: 'amount',
      format: value => value && formatNumber(value),
      name: 'amount',
      label: 'Belopp',
      align: 'right',
    },
    {
      field: 'price',
      format: value => value && formatNumber(value),
      name: 'price',
      label: 'Pris',
      align: 'right',
    },
  ];
  pagination = {
    rowsPerPage: 0,
    sortBy: 'settleDate',
    descending: true,
  };
}
</script>
