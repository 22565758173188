<template>
  <div class="TransactionSearch">
    <h4 class="table-header">Sök Transaktioner</h4>
    <q-form @submit="search" class="search-form row">
      <div class="col col-12 col-md-8">
        <div class="row q-col-gutter-md q-mb-sm">
          <div class="col-8">
            <SelectEntity
              v-model="searchQuery.entity"
              entityType="issuer"
              v-bind="$defs.input"
              :hideCreate="true"
              label="Emittent"
            />
          </div>
          <div class="col col-4 flex">
            <q-btn
              label="Sök"
              type="submit"
              class="full-width"
              v-bind="$defs.btn"
              :loading="loading"
              :disable="searchQuery.entity == null"
            />
          </div>
        </div>

        <div class="row q-col-gutter-md q-mb-sm">
          <div class="col col-12 col-md-4">
            <a-select
              v-bind="$defs.input"
              v-model="searchQuery.instrument"
              :options="instruments"
              map-options
              null-label="Alla"
              :disable="searchQuery.entity == null"
              option-label="viewName"
              label="Värdepapper"
            ></a-select>
          </div>
          <div class="col col-12 col-md-4">
            <a-select
              v-bind="$defs.input"
              v-model="searchQuery.owner"
              :options="owners"
              map-options
              null-label="Alla"
              :disable="searchQuery.entity == null"
              option-label="viewName"
              label="Ägare"
            ></a-select>
          </div>
          <div class="col col-12 col-md-4">
            <a-select
              v-bind="$defs.input"
              v-model="searchQuery.custodian"
              :options="custodians"
              map-options
              null-label="Alla"
              :disable="searchQuery.entity == null"
              option-label="viewName"
              label="Förvar"
            ></a-select>
          </div>
        </div>
        <div class="row q-col-gutter-md q-mb-sm">
          <div class="col col-12 col-md-4">
            <a-select
              v-model="searchQuery.dateType"
              v-bind="$defs.input"
              :options="dateTypes"
              emit-value
              map-options
              class="fill-width"
              label="Datumperiod"
            />
          </div>
          <div class="col col-12 col-md-4">
            <a-input-date
              v-model="searchQuery.from"
              v-bind="$defs.input"
              :disable="!searchQuery.dateType"
              label="Från"
            />
          </div>
          <div class="col col-12 col-md-4">
            <a-input-date v-model="searchQuery.to" v-bind="$defs.input" :disable="!searchQuery.dateType" label="Till" />
          </div>
        </div>
        <div>
          <div class="col col-12 col-md-3 col-lg-2">
            <q-checkbox v-model="searchQuery.onlyInsurance" label="Endast försäkringsägande"></q-checkbox>
          </div>
        </div>
      </div>
    </q-form>
  </div>
</template>

<script lang="ts">
import { sortBy } from 'lodash';
import { Component, Vue, Prop, Watch } from 'vue-facing-decorator';
import { Entity, Instrument } from '@/models';
import { search } from '@/models/transaction/transactionService';
import TransactionSearchQuery from '@shared/web/models/transaction/TransactionSearchQuery';
import { search as searchInstruments } from '@/models/instrument/instrumentService';

import { options } from '@shared/common';
import SelectEntity from '@/views/entity/SelectEntity.vue';
import InstrumentSearchQuery from '@/models/instrument/InstrumentSearchQuery';
import { findCustodians } from '@/models/entity/entityService';
@Component({
  components: { SelectEntity },
})
export default class TransactionSearch extends Vue {
  loading = false;
  instruments: Instrument[] = null;
  owners: Entity[] = null;
  custodians: Entity[] = null;
  @Prop() searchQuery: TransactionSearchQuery;

  get options() {
    return options;
  }

  async mounted() {
    if (!this.searchQuery.isEmpty()) {
      await this.search();
    }
    this.custodians = sortBy(await findCustodians(), 'viewName');
  }

  get positionsValidOptions() {
    return [
      { value: null, label: 'Alla' },
      { value: true, label: 'Klara' },
      { value: false, label: 'Preliminära/Felaktiga' },
    ];
  }

  get dateTypes() {
    return [
      { value: null, label: 'Ingen vald' },
      { value: 'registrationDate', label: 'Registreringsdag' },
      { value: 'settleDate', label: 'Införd i aktieboken' },
      { value: 'tradeDate', label: 'Affärsdag' },
    ];
  }

  @Watch('searchQuery.entity', { immediate: true })
  async issuerChange(entity) {
    if (entity == null) {
      this.instruments = null;
      return;
    }
    this.instruments = sortBy(await searchInstruments(new InstrumentSearchQuery({ entity: entity.id })), 'name');
    this.searchQuery.owner = null;
    this.searchQuery.instrument = null;
  }

  async search() {
    this.loading = true;

    this.$router.replace({
      query: this.searchQuery.asRequestParams(),
    });

    try {
      const searchResult = await search(this.searchQuery);
      if (this.searchQuery.owner == null) {
        this.owners = sortBy(searchResult.getUniqueOwners(), 'viewName');
      }

      this.$emit('search', searchResult);
    } finally {
      this.loading = false;
    }
  }
}
</script>
