<template>
  <q-dialog class="q-pa-md" ref="dialog">
    <div class="full-width CommentEditorModal q-pa-sm">
      <CommentEditor v-model="comment.text" />

      <q-card-actions align="right">
        <q-btn flat @click="onCancel">Avbryt</q-btn>
        <q-btn @click="updateComment">Uppdatera</q-btn>
      </q-card-actions>
    </div>
  </q-dialog>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { DialogComponent } from '@shared/mixins';
import axios from 'axios';
import { serializedChanges } from '@/models/base';
import CommentEditor from '@/views/comment/CommentEditor.vue';
import Comment from '@shared/web/models/entity/Comment';

@Component({
  components: { CommentEditor },
  mixins: [DialogComponent],
})
export default class CommentEditorModal extends Vue {
  @Prop() update: Comment;
  comment: Comment;
  initialCommentText: string;

  created() {
    this.comment = this.update;
    this.initialCommentText = this.update.text;
  }

  async updateComment() {
    const update = serializedChanges(this.comment);
    const { data } = await axios.put('/api/comment/update', update);
    this.comment.lastActivity = data.lastActivity;
    this.hide();
  }
  onCancel() {
    this.update.text = this.initialCommentText;
    this.hide();
  }
}
</script>
<style type="scss">
.CommentEditorModal {
  background: white;
}
</style>
