<template>
  <q-dialog ref="dialog">
    <div class="q-dialog-plugin EntityValuationModal">
      <h4 class="table-header">Värdera emittent</h4>
      <div v-if="!loading">
        <q-form @submit="done">
          <q-table
            :rows="rows"
            :columns="columns"
            :pagination="pagination"
            flat
            hide-pagination
            class="standard-table custom-table q-mb-md"
          >
            <template v-slot:header="props">
              <q-tr class="">
                <q-th style="width: 5%" :props="props" key="included">Inkluderad</q-th>
                <q-th style="width: 35%" :props="props" key="viewName">Värdepapper</q-th>
                <q-th style="width: 10%" :props="props" key="isin">Id</q-th>
                <q-th style="width: 15%" :props="props" key="price">Pris</q-th>
                <q-th style="width: 15%" :props="props" key="companyValue">Bolagsvärde</q-th>
                <q-th style="width: 15%" :props="props" key="method">Värderingsmetod</q-th>
                <q-th style="width: 15%" :props="props" key="date">Prisdatum</q-th>
                <q-th style="width: 10%" :props="props" key="period">Värderingsperiod</q-th>
              </q-tr>
            </template>
            <template v-slot:body-cell-included="props">
              <q-td class="q-ml-md" auto-width>
                <q-checkbox v-model="props.row.included" />
              </q-td>
            </template>

            <template v-slot:body-cell-price="props">
              <q-td :props="props" auto-width>
                <a-input-number
                  v-bind="$defs.input"
                  v-model="props.row.instrumentValuation.pricePercent"
                  :disable="!props.row.included"
                  :rules="validation.requiredNumber"
                  :suffix="props.row.instrumentValuation.instrument.priceSuffix"
                  :maximumFractionDigits="4"
                />
              </q-td>
            </template>
            <template v-slot:body-cell-companyValue="props">
              <q-td :props="props" auto-width>
                <a-input-number v-bind="$defs.input" v-model="props.row.instrumentValuation.companyValue" />
              </q-td>
            </template>
            <template v-slot:body-cell-method="props">
              <q-td :props="props">
                <q-select
                  v-bind="$defs.input"
                  v-model="props.row.instrumentValuation.method"
                  :options="valuationMethods"
                  :disable="!props.row.included"
                  :rules="validation.required('Metod')"
                />
              </q-td>
            </template>
            <template v-slot:body-cell-date="props">
              <q-td :props="props">
                <a-input-date
                  v-bind="$defs.input"
                  v-model="props.row.instrumentValuation.date"
                  :disable="!props.row.included"
                />
              </q-td>
            </template>
            <template v-slot:body-cell-period="props">
              <q-td :props="props">
                <q-select
                  v-bind="$defs.input"
                  v-model="props.row.instrumentValuation.period"
                  :options="quarters"
                  :disable="!props.row.included"
                />
              </q-td>
            </template>
          </q-table>

          <div class="row">
            <h5 class="q-pl-md">Kommentar</h5>
            <div class="col-12 q-mt-sm">
              <q-input class="q-mx-md" v-model="commentTitle" dense label="Titel" />
              <CommentEditor v-model="comment" />
            </div>
          </div>
          <q-card-actions align="right" class="q-pa-md">
            <q-btn v-bind="$defs.btn" flat @click="cancel">Avbryt</q-btn>
            <q-btn v-bind="$defs.btn" type="submit">Spara</q-btn>
          </q-card-actions>
        </q-form>
      </div>
    </div>
  </q-dialog>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { DialogComponent } from '@shared/mixins';
import { QTableProps } from 'quasar';
import CommentEditor from '@/views/comment/CommentEditor.vue';
import { Entity, EntityValuation, Instrument, InstrumentValuation } from '@/models';
import { ValuationMethod, Quarter } from '@shared/models/types';
import { save } from '@/models/valuation/valuationService';
import { validation } from '@shared/common';
import { formatNumber } from '@shared/utils';
@Component({
  mixins: [DialogComponent],
  components: { CommentEditor },
})
export default class EntityValuationModal extends Vue {
  @Prop() instruments: Array<Instrument>;
  @Prop() latestValuation: EntityValuation;
  @Prop() entity: Entity;
  @Prop({ required: false }) existingEntityValuation: EntityValuation;

  entityValuation: EntityValuation;
  rows = [];
  comment: string = '';
  commentTitle: string = '';
  loading: boolean = true;
  quarters;

  created() {
    if (this.existingEntityValuation) {
      this.comment = this.existingEntityValuation.comment;
      this.commentTitle = this.existingEntityValuation.commentTitle;
      this.entityValuation = EntityValuation.newModel({ id: this.existingEntityValuation.id, entity: this.entity.id });
      this.instruments.forEach(instrument => {
        const existingInstrumentValuation = this.existingEntityValuation.instrumentValuations.find(
          valuation => valuation.instrument.id === instrument.id,
        );
        this.rows.push({
          included: !!existingInstrumentValuation,
          name: instrument.name,
          instrumentValuation:
            existingInstrumentValuation ?? InstrumentValuation.newModel({ instrument: instrument.id }),
          isin: instrument.instrumentId,
        });
      });
    } else {
      this.entityValuation = EntityValuation.newModel({ entity: this.entity.id });
      this.instruments.forEach(instrument => {
        const latestInstrumentValuation: InstrumentValuation = this.latestValuation?.instrumentValuations.find(
          valuation => valuation.instrument.id === instrument.id,
        );

        this.rows.push({
          included: instrument.isKapsureProduct,
          name: instrument.name,
          instrumentValuation: InstrumentValuation.newModel({
            instrument: instrument.id,
            method: latestInstrumentValuation?.method || null,
          }),
          isin: instrument.instrumentId,
        });
      });
    }
    this.quarters = this.availableQuarters;
    this.loading = false;
  }

  async done() {
    this.entityValuation.comment = this.comment;
    this.entityValuation.commentTitle = this.commentTitle;
    this.entityValuation.instrumentValuations = this.rows
      .filter(row => row.included)
      .map(row => row.instrumentValuation);

    await save(this.entityValuation);

    this.$emit('ok');
    this.hide();
  }

  get valuationMethods() {
    return Object.values(ValuationMethod);
  }

  get availableQuarters() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentQuarter = Math.floor((currentDate.getMonth() + 3) / 3);
    const quarters = [Quarter.ONE, Quarter.TWO, Quarter.THREE, Quarter.FOUR];

    const startYear = currentQuarter === 1 ? currentYear - 1 : currentYear;
    const startQuarter = currentQuarter === 1 ? 4 : currentQuarter - 1;

    return [startYear, startYear + 1].flatMap(year =>
      quarters.reduce((acc, q, index) => {
        if (!(year === startYear && index + 1 < startQuarter)) {
          acc.push(`${year} ${q}`);
        }
        return acc;
      }, []),
    );
  }

  columns: QTableProps['columns'] = [
    {
      name: 'included',
      field: 'included',
      label: 'Inkludera',
      align: 'left',
    },
    {
      name: 'viewName',
      field: 'name',
      label: 'Värdepapper',
      style: row => {
        if (!row.included) return 'text-decoration: line-through';
      },
      align: 'left',
    },
    {
      name: 'isin',
      field: 'isin',
      label: 'Id',
      align: 'left',
    },
    {
      name: 'price',
      field: 'price',
      label: 'Pris',
      style: 'width: 10px;',
      align: 'left',
    },
    {
      name: 'companyValue',
      field: row => formatNumber(row.instrumentValuation.companyValue),
      label: 'Bolagsvärde',
      style: 'width: 10px;',
      align: 'left',
    },
    {
      name: 'method',
      field: 'method',
      label: 'Metod',
      align: 'left',
    },
    {
      name: 'date',
      field: 'date',
      label: 'Prisdatum',
      style: 'width: 150px;',
      align: 'left',
    },
    {
      name: 'period',
      field: 'period',
      label: 'Värderingsperiod',
      align: 'left',
    },
  ];
  pagination = {
    rowsPerPage: 0,
  };

  get validation() {
    return validation;
  }
}
</script>

<style lang="scss">
.EntityValuationModal {
  min-width: 70%;
}
.custom-table {
  tbody:before {
    content: '@';
    display: block;
    line-height: 1px;
    text-indent: -99999px;
  }
  table {
    border-bottom: 2px solid $secondary;
  }
}
</style>
