<template>
  <a-btn-link class="icon-left align-right" icon="svguse:/svg-icons.svg#icon-download" size="sm" @click="exportToExcel"
    >Excel</a-btn-link
  >
</template>
<script lang="ts">
import xlsx from 'json-as-xlsx';
import { Vue, Prop, Component } from 'vue-facing-decorator';
import { TransactionsData } from '@/models';
import { toExcelDataTransactions } from '../positionList/excelExport';

@Component({
  components: {},
})
export default class ExportTransactionExcel extends Vue {
  @Prop() transactionsData: TransactionsData;

  get excelSheetName() {
    return this.transactionsData.entity.viewName;
  }

  exportToExcel() {
    const fileName = this.excelSheetName;
    xlsx(toExcelDataTransactions(fileName, this.transactionsData), { fileName });
  }
}
</script>
